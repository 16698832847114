import { URLS } from '../../api/urls';
export const productReleaseCountdownOverlay = (productReleaseData) => {
    return `
    <div id="product-release-details-container">
        <div class="app-product-release-gradient"></div>
        <div class="app-product-release-header">
            <span class="app-product-release-title">${productReleaseData.release_title}</span>      
            <span class="app-product-release-description">${productReleaseData.release_description}</span>   
        </div>
        
        <div class="app-product-release-body-countdown-container">
        
          <div class="app-product-release-countdown-wrapper">
            <div class="app-countdown-circles">
              <img class="app-countdown-icon" src="${URLS.OVERLAY_COUNTDOWN_ICON}">
              <img src="${URLS.OVERLAY_COUNTDOWN_SECONDS_DIAL}"/>
            </div>
            <div id="product-release-countdown-container">
              <span class="app-countdown-days"></span>
              <div class="app-countdown-time">
                  <span class="app-countdown-hours"></span>
                  <span class="app-countdown-minutes"></span>
                  <span class="app-countdown-seconds"></span>
              </div>
            </div>
          </div>
        
        </div>        
    </div>
  `;
};
