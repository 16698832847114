import { InputFieldView } from '../input-field/input-field-component.view';
import { loader } from '../../../../../components/loader';
import '../../../../../../styles/overlay/harry-potter-shopify/components/out-of-stock/index.scss';
export const OutOfStockComponentView = (product) => {
    return `
    <div class="app-shopify-out-of-stock-form">
      <div class="app-shopify-out-of-stock-title">Out of Stock</div>
      <div class="app-shopify-out-of-stock-description">Sign up with your email address to recieve a notification when the ${product.name} is back in stock.</div>
      <div class="input-field-wrapper is-hidden">
        ${InputFieldView({
        label: 'Email',
        placeholder: 'user@email.com',
        identifier: 'out-of-stock-email',
        type: 'email',
        hasMaxLength: false
    })}
      </div>
      <div class="notify-me-button-wrapper is-flex">
        <div id="notify-me-button" class="personalize-overlay-button hp-custom-button">
          <div class="btn-border">
            <div class="left-border">
              <div class="square"></div>
            </div>
            <div class="right-border">
              <div class="square"></div>
            </div>
          </div>
          <span>Notify Me</span>
          ${loader({})}
        </div>
      </div>
      <div class="app-shopify-out-of-stock-agreement is-hidden">
        By clicking ‘NOTIFY ME’ above, you agree to the <a href="https://policies.warnerbros.com/terms/en-gb/html/terms_en-gb_1.1.0.html" target="_blank">Terms of Use</a> and <a href="https://policies.warnerbros.com/privacy/" target="_blank">Privacy Policy</a>
      </div>
    </div>
    <div class="app-shopify-out-of-stock-response is-hidden">You’re on the list! We’ll let you know when it’s back in stock.</div>
    <form class="is-completely-hidden">
      <input type="text">
    </form>
  `;
};
