import { AppUtils } from '../../utils/app_utils';
import { WebUtils } from '../../utils/web_utils';
import { InputEventUtils } from '../../utils/input_event_utils';
import { Metrics } from '../../metrics';
import { MATOMO_EVENT_NAMES } from '../../metric-events';
import { ProductInfoBase } from './product-info-base_overlay';
import { BUTTONS_NAMES } from '../../shared/constants';
import { UrlUtils } from '../../api/urls';
import { productInfoPinsView } from './product-info-pins.view';
import sanitizeHtml from 'sanitize-html';
export class ProductInfoPins extends ProductInfoBase {
    setItemContent() {
        const description = sanitizeHtml(this.product.description);
        const sanitizedProduct = Object.assign(Object.assign({}, this.product), { description });
        this.productTitle = this.product.name;
        this.container.innerHTML = productInfoPinsView(sanitizedProduct, this.item.planogram.name);
    }
    // TODO move this method to base class after settings implementation for ebay and amazon buttons
    setBuyButtonUrl(buttonEl, buttonUrl, button) {
        if (buttonUrl) {
            if (!this.product.price_in_user_currency_str) {
                buttonEl.classList.add('is-hidden');
            }
            InputEventUtils.addSelectEvents(buttonEl, () => {
                WebUtils.openLink(buttonUrl);
                Metrics.storeTheEvent(this.item.planogram.name, 'click', button.button_type === BUTTONS_NAMES.CUSTOM
                    ? `${MATOMO_EVENT_NAMES.CLICK_CUSTOM_BUY_BUTTON}-${this.item.identifier}-${this.productName}`
                    : `${MATOMO_EVENT_NAMES.CLICK_CUSTOM_BUY_SET_BUTTON}-${this.item.identifier}-${this.productName}`);
                const { identifier, name, price_in_user_currency_str, category_name } = this.product;
                if (price_in_user_currency_str) {
                    Metrics.storeEcommerceEvent({
                        sku: identifier,
                        productName: UrlUtils.slugify(name),
                        category: category_name,
                        productPrice: AppUtils.getPriceValueFromStr(price_in_user_currency_str)
                    });
                }
            });
        }
        else {
            buttonEl.classList.add('is-completely-hidden');
            console.error(`URL for ${button.button_type} buy button is not defined properly`);
        }
    }
}
