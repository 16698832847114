import { productFieldsComponentView } from './product-fields_component.view';
import { Metrics } from '../../../../metrics';
import { MATOMO_EVENT_NAMES } from '../../../../metric-events';
import { AppState } from '../../../../shared/app.state';
import { UrlUtils } from '../../../../api/urls';
export class ProductFields {
    constructor(product, mainContainer, overlaySettings) {
        this.product = product;
        this.mainContainer = mainContainer;
        this.overlaySettings = overlaySettings;
        this.initComponent();
    }
    initComponent() {
        const { show_details } = this.overlaySettings;
        const productFieldsContainer = this.mainContainer.querySelector('.app-ecommerce-description-container');
        const productFields = document.createElement('div');
        productFieldsContainer.append(productFields);
        productFields.outerHTML = !this.product.description ? '' : productFieldsComponentView(this.product, show_details);
        this.handleClicksOnAdditionalFields();
    }
    handleClicksOnAdditionalFields() {
        this.productFields =
            this.mainContainer.querySelectorAll('.app-ecommerce-product-additional-field-title-wrapper');
        this.productFields.forEach(el => {
            el.addEventListener('click', () => this.addClassToSelectedField(el));
        });
    }
    addClassToSelectedField(field) {
        var _a, _b;
        this.productFields.forEach(el => {
            if (el === field) {
                return;
            }
            el.parentElement.classList.remove('active');
        });
        Metrics.storeTheEvent(AppState.planogramName, 'open', MATOMO_EVENT_NAMES.CLICK_ECOMMERCE_OVERLAY_PRODUCT_DETAILS_DESCRIPTION((_a = this.product) === null || _a === void 0 ? void 0 : _a.identifier, UrlUtils.slugify((_b = this.product) === null || _b === void 0 ? void 0 : _b.name)));
        field.parentElement.classList.toggle('active');
    }
}
