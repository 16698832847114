import '../../../../../../styles/overlay/harry-potter-shopify/components/input-field/index.scss';
export const InputFieldView = ({ label, placeholder = 'Enter here', helper, maxlength, identifier, type, hasMaxLength = true }) => {
    return `
    <div class="input-field-component">
      <label class="field">
        <div class="field-info">
            <span class="field-label">${label}</span>
            ${helper ? `<span class="field-helper">${helper}</span>` : ''}
        </div>  
        <span class="input-field-border">
          <span class="left-border">
            <span class="square"></span>
          </span>
          <span class="right-border">
            <span class="square"></span>
          </span>
          <input 
            id = "${identifier || ''}"
            ${hasMaxLength ? `maxlength="${maxlength || '15'}"` : ''}
            placeholder="${placeholder}" 
            type="${type || 'text'}" 
            value="" 
            required 
            tabindex="1"
          />
        </span>
      </label>
    </div>
  `;
};
