export class ArrayUtils {
    static append(array, value, maxSize) {
        if (value === undefined || Number.isNaN(value)) {
            return array;
        }
        if (array.length >= maxSize) {
            array.shift();
        }
        array.push(value);
        return array;
    }
    static average(array) {
        const sum = array.reduce((a, b) => a + b);
        return sum / array.length;
    }
    static shuffle(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }
    static groupBy(items, property) {
        return items.reduce((acc, item) => {
            !acc[item[property]] ? (acc[item[property]] = [item]) : acc[item[property]].push(item);
            return acc;
        }, {});
    }
    static omit(items, property) {
        const newItems = Object.assign({}, items);
        delete newItems[property];
        return items;
    }
    static removeFromArray(obj, array) {
        let j = 0;
        for (let i = 0, l = array.length; i < l; i++) {
            if (array[i] !== obj) {
                array[j++] = array[i];
            }
        }
        array.length = j;
    }
    static createMultiArray(...inputArgs) {
        if (inputArgs.length === 0) {
            return [];
        }
        const array = new Array(inputArgs[0]);
        if (inputArgs.length > 1) {
            const nextArgs = inputArgs.slice(1);
            for (let i = 0; i < array.length; i++) {
                array[i] = ArrayUtils.createMultiArray(...nextArgs);
            }
        }
        return array;
    }
}
