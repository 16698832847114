import { Group, Mesh, MeshBasicMaterial, PlaneBufferGeometry } from 'three';
export default class LodDebug extends Group {
    constructor(physicalTextures) {
        super();
        this.materials = [];
        const side = Math.ceil(Math.sqrt(physicalTextures.length));
        const scale = 1 / side;
        physicalTextures.forEach((t, i) => {
            const material = new MeshBasicMaterial({
                map: t,
                transparent: true,
            });
            this.materials.push(material);
            const mesh = new Mesh(new PlaneBufferGeometry(1, 1), material);
            mesh.scale.set(scale, scale, 1);
            mesh.position.set((i % side) * scale, Math.floor(i / side) * scale, 0);
            this.add(mesh);
        });
    }
    update() {
        this.materials.forEach(it => (it.needsUpdate = true));
    }
    dispose() {
        this.materials.forEach(it => it.dispose());
    }
}
