import subscribe from 'callbag-subscribe';
import pipe from 'callbag-pipe';
import { AccountService } from '../api/services/account.service';
import { AccountSignIn } from './account-sign-in/account-sign-in';
import { AccountSignUp } from './account-sign-up/account-sign-up';
import { AccountResetPass } from './account-reset-pass/account-reset-pass';
import { AccountEmailSent } from './account-email-sent/account-email-sent';
import { AccountProfile } from './account-profile/account-profile';
import { ACCESS_TOKEN_KEY, ACCOUNT_OVERLAY_TYPE, ECOMMERCE_BUTTON_TYPE } from '../shared/constants';
import { InputEventUtils } from '../utils/input_event_utils';
import { accountView } from './account.view';
import { Metrics } from '../metrics';
import { MATOMO_EVENT_NAMES } from '../metric-events';
import { AppState } from '../shared/app.state';
import { FontLoader } from '../font_loader';
import { AppUtils } from '../utils/app_utils';
import { OverlayScrollbar } from '../components/overlay_scrollbar';
import { WebUtils } from '../utils/web_utils';
import { HTMLUtils } from '../utils/html_utils';
export class Account {
    constructor(planogram, shoppingCartService) {
        this.planogram = planogram;
        this.shoppingCartService = shoppingCartService;
        this.cleanUp();
        this.onStorageChange = this.onStorageChange.bind(this);
        this.accountService = new AccountService();
        this.wrapperEl = document.getElementById('account');
        const isHarryPotterClient = AppUtils.isHarryPotterClient(this.planogram, this.planogram.clientName);
        this.wrapperEl.innerHTML = accountView(isHarryPotterClient);
        this.signIn = new AccountSignIn(this.accountService, document.getElementById('account-sign-in'));
        this.signUp = new AccountSignUp(this.accountService, document.getElementById('account-sign-up'));
        this.resetPass = new AccountResetPass(this.accountService, document.getElementById('account-reset-pass'));
        this.emailSent = new AccountEmailSent(this.accountService, document.getElementById('account-email-sent'));
        this.profile = new AccountProfile(this.accountService, this.shoppingCartService, document.getElementById('account-profile'));
        this.init();
        this.overlayScroll = new OverlayScrollbar('.account-overlay-wrapper');
        pipe(this.accountService.accountActiveStateSubject, subscribe({
            next: (data) => {
                this.onActiveStateChange(data);
            }
        }));
        pipe(this.accountService.accountLoadingStateSubject, subscribe({ next: this.onLoadingStateChange.bind(this) }));
        pipe(this.accountService.accountOverlayTypeSubject, subscribe({ next: this.switchView.bind(this) }));
        pipe(this.accountService.accountUserDataSubject, subscribe({
            next: (userData) => {
                Account.isLogged = !!userData;
                if ((!Account.isLogged && this.shoppingCartService.shoppingCart) ||
                    (this.planogram.isMultipassKeyAvailable && this.shoppingCartService.shoppingCart)) {
                    this.shoppingCartService.clearShoppingCartTimer();
                    this.shoppingCartService.updateShoppingCartState();
                }
                if (userData && !this.shoppingCartService.isMultipassEnabled) {
                    this.shoppingCartService.clearShoppingCartTimer();
                    this.shoppingCartService.clearShoppingCartToken();
                    this.shoppingCartService.storeEmail(userData === null || userData === void 0 ? void 0 : userData.email);
                }
                if (userData === null || userData === void 0 ? void 0 : userData.last_incomplete_checkout) {
                    this.shoppingCartService.clearShoppingCartState();
                    this.shoppingCartService.saveShoppingCartState({ checkout: userData.last_incomplete_checkout });
                }
                else if ((userData === null || userData === void 0 ? void 0 : userData.last_incomplete_checkout) === null &&
                    this.shoppingCartService.shoppingCart &&
                    !this.shoppingCartService.isMultipassEnabled &&
                    !this.planogram.isMultipassKeyAvailable) {
                    this.shoppingCartService.attachShoppingCartToUser();
                }
                this.onUserDataChange(userData);
            }
        }));
        window.addEventListener('storage', this.onStorageChange);
        this.initSavedUser();
    }
    init() {
        this.accountSettings = this.planogram.ecommerceOverlaySettingsSignIn;
        this.userProfileBtnSettings = this.planogram.ecommerceOverlaySettingsButtons;
        this.userProfileBtn = document.querySelector('.account-profile-button');
        const backBtn = this.wrapperEl.querySelector('.back-btn');
        const overlayCloseBtn = this.wrapperEl.querySelector('.account-overlay-header .close-btn');
        const drawerCloseBtn = this.wrapperEl.querySelector('.account-profile-header .close-btn');
        const backdrop = this.wrapperEl.querySelector('.account-backdrop');
        this.userProfileBtn.classList.remove('is-hidden');
        InputEventUtils.addSelectEvents(this.userProfileBtn, () => {
            Metrics.storeTheEvent(AppState.planogramName, 'open', MATOMO_EVENT_NAMES.OPEN_USER_PROFILE_OVERLAY);
            if (!this.shoppingCartService.getMultipassToken && this.planogram.isMultipassKeyAvailable) {
                WebUtils.redirectToSpecificUrl(this.planogram.multipassRedirectUrl);
                return;
            }
            this.accountService.setActiveState(true);
        });
        InputEventUtils.addSelectEvents(backBtn, () => this.accountService.setOverlayType(ACCOUNT_OVERLAY_TYPE.SIGN_IN));
        InputEventUtils.addSelectEvents(overlayCloseBtn, () => this.accountService.setActiveState(false));
        InputEventUtils.addSelectEvents(drawerCloseBtn, () => this.accountService.setActiveState(false));
        InputEventUtils.addSelectEvents(backdrop, () => this.accountService.setActiveState(false));
        this.wrapperEl.classList.remove('is-completely-hidden');
        this.userProfileBtn.classList.remove('is-hidden');
        this.setOverlayFonts();
        this.setColorAndButtonSettings(this.accountSettings, 'ecommerce-account');
        this.setProfileButtonSettings(this.userProfileBtnSettings);
    }
    setOverlayFonts() {
        var _a;
        (_a = this.accountSettings) === null || _a === void 0 ? void 0 : _a.ecommerce_fonts.forEach(font => {
            const fontSettings = Object.assign(Object.assign({}, font), { assignment: `ecommerce-account-${font.assignment}` });
            FontLoader.mountCustomFont(fontSettings);
        });
    }
    setColorAndButtonSettings(settings, assignment) {
        var _a;
        const root = document.documentElement;
        const primaryButtons = this.wrapperEl.querySelectorAll('.account-button-overlay-primary');
        const secondaryButtons = this.wrapperEl.querySelectorAll('.account-button-overlay-secondary');
        if (settings === null || settings === void 0 ? void 0 : settings.background_color) {
            const primaryColorRgb = AppUtils.hex2rgb(settings.background_color);
            root.style.setProperty(`--${assignment}-primary-color`, `${settings.background_color}`);
            root.style.setProperty(`--${assignment}-primary-color-rgb`, `${primaryColorRgb}`);
        }
        if (settings === null || settings === void 0 ? void 0 : settings.secondary_color) {
            const secondaryColorRgb = AppUtils.hex2rgb(settings.secondary_color);
            root.style.setProperty(`--${assignment}-secondary-color`, `${settings.secondary_color}`);
            root.style.setProperty(`--${assignment}-secondary-color-rgb`, `${secondaryColorRgb}`);
        }
        if (settings === null || settings === void 0 ? void 0 : settings.ecommerce_text_fields) {
            if (settings === null || settings === void 0 ? void 0 : settings.ecommerce_text_fields.border_width) {
                root.style.setProperty(`--${assignment}-text-fields-border-width`, `${parseInt(settings.ecommerce_text_fields.border_width)}px`);
            }
            if (settings === null || settings === void 0 ? void 0 : settings.ecommerce_text_fields.border_color) {
                root.style.setProperty(`--${assignment}-text-fields-border-color`, `${settings.ecommerce_text_fields.border_color}`);
            }
        }
        (_a = settings === null || settings === void 0 ? void 0 : settings.ecommerce_buttons) === null || _a === void 0 ? void 0 : _a.forEach(button => {
            if (button.color) {
                root.style.setProperty(`--${assignment}-${button.button_type}-button-color`, `${button.color}`);
            }
            if (button.use_border) {
                root.style.setProperty(`--${assignment}-${button.button_type}-button-border-color`, `${button.border_color}`);
                root.style.setProperty(`--${assignment}-${button.button_type}-button-border-width`, `${button.border_width.replace(/px/gi, '')}px`);
                if (button.button_type === 'primary') {
                    primaryButtons === null || primaryButtons === void 0 ? void 0 : primaryButtons.forEach(primaryButton => primaryButton === null || primaryButton === void 0 ? void 0 : primaryButton.classList.add('with-border'));
                }
                else {
                    secondaryButtons === null || secondaryButtons === void 0 ? void 0 : secondaryButtons.forEach(secondaryButton => secondaryButton === null || secondaryButton === void 0 ? void 0 : secondaryButton.classList.add('with-border'));
                }
            }
        });
    }
    setProfileButtonSettings(settings) {
        var _a;
        (_a = settings === null || settings === void 0 ? void 0 : settings.ecommerce_icon_buttons) === null || _a === void 0 ? void 0 : _a.forEach(button => {
            var _a, _b;
            if (button.button_type === ECOMMERCE_BUTTON_TYPE.USER_PROFILE && ((_a = button.other_asset) === null || _a === void 0 ? void 0 : _a.url)) {
                const imgElem = document.createElement('img');
                imgElem.onerror = () => {
                    this.userProfileBtn.querySelector('img').remove();
                };
                imgElem.setAttribute('src', (_b = button.other_asset) === null || _b === void 0 ? void 0 : _b.url);
                this.userProfileBtn.appendChild(imgElem);
            }
        });
    }
    onActiveStateChange(active) {
        Account.isAccountActive = active;
        this.toggleFormFields(active);
        if (active) {
            this.wrapperEl.classList.add('active');
        }
        else {
            this.wrapperEl.classList.remove('active');
            if (!Account.isLogged) {
                this.wrapperEl.querySelector('.account-overlay-wrapper').addEventListener('transitionend', () => {
                    this.clearAllForms();
                    this.switchView(ACCOUNT_OVERLAY_TYPE.SIGN_IN);
                }, { once: true });
            }
        }
    }
    onLoadingStateChange(loading) {
        this.shoppingCartService.processingSubject(1, loading);
        loading ? this.wrapperEl.classList.add('loading') : this.wrapperEl.classList.remove('loading');
    }
    switchView(type) {
        const classNames = [
            ACCOUNT_OVERLAY_TYPE.SIGN_IN,
            ACCOUNT_OVERLAY_TYPE.SIGN_UP,
            ACCOUNT_OVERLAY_TYPE.RESET_PASS,
            ACCOUNT_OVERLAY_TYPE.EMAIL_SENT,
            ACCOUNT_OVERLAY_TYPE.PROFILE
        ];
        this.wrapperEl.classList.remove(...classNames);
        this.wrapperEl.classList.add(type);
    }
    onUserDataChange(userData) {
        this.accountService.setOverlayType(userData ? ACCOUNT_OVERLAY_TYPE.PROFILE : ACCOUNT_OVERLAY_TYPE.SIGN_IN);
    }
    onStorageChange({ storageArea, key, newValue }) {
        if (storageArea === window.localStorage && key === ACCESS_TOKEN_KEY) {
            newValue ? this.initSavedUser() : this.accountService.setUserData(null);
        }
    }
    initSavedUser() {
        if (window.localStorage.getItem(ACCESS_TOKEN_KEY)) {
            this.accountService.setLoadingState(true);
            this.accountService
                .verifyToken()
                .then(resp => this.accountService.setUserData(resp.user))
                .catch(() => window.localStorage.removeItem(ACCESS_TOKEN_KEY))
                .finally(() => this.accountService.setLoadingState(false));
        }
        else if (this.shoppingCartService.getMultipassToken) {
            this.accountService.setUserData({
                display_name: this.shoppingCartService.email,
                email: this.shoppingCartService.email,
                last_incomplete_checkout: null
            });
        }
    }
    clearAllForms() {
        const overlaysWithForms = [this.signIn, this.signUp, this.resetPass];
        overlaysWithForms.forEach(form => form === null || form === void 0 ? void 0 : form.clearForm());
    }
    toggleFormFields(active) {
        const overlaysWithForms = [this.signIn, this.signUp, this.resetPass];
        overlaysWithForms.forEach(form => form === null || form === void 0 ? void 0 : form.toggleFields(active));
    }
    cleanUp() {
        const wrapper = document.querySelector('.account-profile-button');
        HTMLUtils.removeChildElements(wrapper);
    }
    dispose() {
        window.removeEventListener('storage', this.onStorageChange);
    }
}
