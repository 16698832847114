import infosService from '../api/services/infos-base_overlay.service';
import { infosOverlayView } from './infos-base_overlay-view';
import { InputEventUtils } from '../utils/input_event_utils';
import { FontLoader } from '../font_loader';
import { AppUtils } from '../utils/app_utils';
import { OverlayScrollbar } from '../components/overlay_scrollbar';
import { SPHERE_EVENT_NAMES as EVENTS } from '../event-names';
import { sphereEventHandler } from '../custom_event_utils';
import { FullScreenButton } from '../components/overlay-fullscreen-mode';
export class InfoOverlay {
    constructor(container, planogramId, versionId, infoType, options, closeCallback) {
        this.container = container;
        this.options = options;
        this.closeCallback = closeCallback;
        infosService.getView(planogramId, versionId, infoType).then((data) => {
            var _a;
            if (data) {
                if (data.planogram_info_fonts && data.planogram_info_fonts.length) {
                    const infosFonts = data.planogram_info_fonts.map(fontData => {
                        const modifiedFontData = AppUtils.deepClone(fontData);
                        modifiedFontData.assignment = `infos-${fontData.assignment}`;
                        return modifiedFontData;
                    });
                    FontLoader.mountCustomFonts(infosFonts);
                }
                this.initTemplateContent(this.container, data);
                if ((_a = this.options) === null || _a === void 0 ? void 0 : _a.fullscreen) {
                    const fullscreenButtonContainer = this.container.querySelector('.infos-overlay-fullscreen-button');
                    this.fullscreenButton = new FullScreenButton(fullscreenButtonContainer);
                }
                else {
                    sphereEventHandler.emit(EVENTS.OVERLAY.SHOW_INFO_OVERLAY_CONTENT, data);
                }
                return new OverlayScrollbar('.text-content');
            }
            else {
                if (this.closeCallback) {
                    this.closeCallback();
                }
                console.error(`${infoType} infos overlay is empty/disabled in Pubtool`);
            }
        });
    }
    initTemplateContent(container, data) {
        container.innerHTML = infosOverlayView(data);
        const closeButton = this.container.querySelector('.infos-overlay .overlay-button-close');
        if (closeButton) {
            InputEventUtils.addSelectEvents(closeButton, this.closeOverlay.bind(this));
        }
        else {
            console.error('element not found - ".overlay-button-close"');
        }
    }
    closeOverlay(e) {
        if (e) {
            e.stopPropagation();
        }
        if (this.closeCallback) {
            this.closeCallback();
        }
        else {
            console.error('"Close" function is not set up');
        }
    }
    dispose() {
        FontLoader.unmountCustomFont('infos');
    }
    // Using in ProductCanvas
    handleClick(e) {
        return false;
    }
}
