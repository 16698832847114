export class AccountBaseOverlay {
    constructor(accountService, wrapperEl) {
        this.accountService = accountService;
        this.wrapperEl = wrapperEl;
        this.formEl = this.wrapperEl.querySelector('.account-overlay-form');
        this.errorEl = this.wrapperEl.querySelector('.account-overlay-error');
        this.formEl.addEventListener('submit', e => {
            this.onSubmit(e);
        });
        this.init();
    }
    onError(err) {
        err.json().then(error => this.showError(error.errors.base));
    }
    showError(errorText) {
        this.errorEl.classList.remove('is-hidden');
        this.errorEl.innerText = errorText;
    }
    hideError() {
        this.errorEl.classList.add('is-hidden');
        this.errorEl.innerText = '';
    }
    clearForm() {
        this.formEl.querySelectorAll('input').forEach(formInput => (formInput.value = ''));
        this.hideError();
    }
    toggleFields(active) {
        this.formEl.querySelectorAll('input').forEach(formInput => {
            active ? formInput.removeAttribute('disabled') : formInput.setAttribute('disabled', 'true');
        });
    }
}
