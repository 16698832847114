import { EntranceGuide } from './entrance-guide';
import { isTouchSupported } from './utils/platform';
import { L10nUtils } from './utils/l10n_utils';
export const entranceGuideView = () => {
    const deviceView = isTouchSupported ? 'mobile' : 'desktop';
    return `
    <div
      id="entrance-guide"
      class="with-scroll is-hidden ${EntranceGuide.settings.disable_glow ? 'without-glow' : ''} ${EntranceGuide.settings.position}"
    >
        ${EntranceGuide.settings.entrance_animation_fonts.map(animation => {
        var _a, _b, _c;
        const currLang = L10nUtils.getCurrentLanguage();
        const title = (_a = animation[deviceView].title[currLang]) !== null && _a !== void 0 ? _a : animation[deviceView].title[L10nUtils.fallbackLanguage];
        if (animation.hidden) {
            return;
        }
        return `
            <div class="${animation.action_type}-indicator guide-indicator is-hidden">
              <div class="indicator-container">
                <div class="indicator-text ${!((_b = animation.other_asset) === null || _b === void 0 ? void 0 : _b.url) ? 'without-icon' : ''}">${title}</div>
                ${((_c = animation.other_asset) === null || _c === void 0 ? void 0 : _c.url) ?
            `<img alt="" class="indicator-icon" src="${animation.other_asset.url}">` :
            ''}
              </div>
            </div>
          `;
    }).join('')}
    </div>
`;
};
