import { SphereGeometry } from 'shared/geometries/SphereGeometry';
import { Planogram } from './planogram';
import { isProductOverlayAction } from 'shared/interfaces/planogram';
import { SphereItemType } from 'shared/interfaces/planogram';
import { disposeObject3D } from './utils/disposeThree';
import { Group, Mesh, Vector2 } from 'three';
import { isLodItem } from './utils/planogram_utils';
export class SphereItem {
    overridePosition(x, y) {
        this.x = x;
        this.y = y;
        this.updateAzimuthStartRadians();
    }
    overrideSize(width, height) {
        this.width = width;
        this.height = height;
        this.updateAzimuthStartRadians();
    }
    updateAzimuthStartRadians() {
        this.azimuthStartRadians = SphereGeometry.calcAzimuthStartRadians(this.x, Math.max(0, this.width), this.planogram.width);
    }
    constructor(params, planogram) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s;
        this.planogram = planogram;
        this.itemData = params;
        this.data = params.data;
        this.id = params.id;
        this.x = params.x;
        this.y = params.y;
        this.width = params.width;
        this.height = params.height;
        this.updateAzimuthStartRadians();
        // TODO: is this useful at all? It used to be set to data.imageName which was always undefined for both images and products
        this.imageName = (_a = this.data.image_name) !== null && _a !== void 0 ? _a : (_c = (_b = this.data) === null || _b === void 0 ? void 0 : _b.picture) === null || _c === void 0 ? void 0 : _c.name;
        this.renderOrder = params.renderOrder;
        if (params.action !== undefined && isProductOverlayAction(params.action)) {
            this.data.product = {
                id: ((_d = params.action.data) === null || _d === void 0 ? void 0 : _d.productId) || ((_e = this.data.product) === null || _e === void 0 ? void 0 : _e.id),
                identifier: ((_f = params.action.data) === null || _f === void 0 ? void 0 : _f.productIdentifier) || ((_g = this.data.product) === null || _g === void 0 ? void 0 : _g.identifier),
                name: ((_h = params.action.data) === null || _h === void 0 ? void 0 : _h.productName) || ((_j = this.data.product) === null || _j === void 0 ? void 0 : _j.name)
            };
        }
        if ((_l = (_k = params.data) === null || _k === void 0 ? void 0 : _k.product) === null || _l === void 0 ? void 0 : _l.name) {
            this.name = params.data.product.name;
        }
        else if ((_o = (_m = params.data) === null || _m === void 0 ? void 0 : _m.item) === null || _o === void 0 ? void 0 : _o.name) {
            // TODO: is this branch ever hit?
            this.name = params.data.item.name;
        }
        if ((_q = (_p = params.data) === null || _p === void 0 ? void 0 : _p.product) === null || _q === void 0 ? void 0 : _q.identifier) {
            this.identifier = params.data.product.identifier;
        }
        else if ((_s = (_r = params.data) === null || _r === void 0 ? void 0 : _r.product) === null || _s === void 0 ? void 0 : _s.id) {
            this.identifier = params.data.product.id.toString();
        }
        if (params.action) {
            this.action = params.action;
        }
        this.type = params.type;
    }
    createMesh(_) {
        this.geometry = this.generateGeometry();
        this.object3D = new Group();
        const mesh = new Mesh(this.geometry, this.material);
        if (![
            SphereItemType.Image,
            SphereItemType.Product,
            SphereItemType.Text,
            SphereItemType.TextArea,
            SphereItemType.Video,
            SphereItemType.Shape
        ].includes(this.type) ||
            ((this.type === SphereItemType.Image || this.type === SphereItemType.Product) && !isLodItem(this.itemData))) {
            this.object3D.visible = false;
        }
        this.object3D.renderOrder = this.renderOrder;
        // Render order is added to the mesh to make it easier to sort elements when dealing only with meshes.
        mesh.renderOrder = this.renderOrder;
        mesh.userData = {
            component: this
        };
        this.object3D.add(mesh);
        if (this.hasInput()) {
            mesh.layers.enable(2);
            this.object3D.layers.enable(2);
        }
        return Promise.resolve();
    }
    hasInput() {
        return this.action !== undefined || this.type === SphereItemType.Video;
    }
    generateGeometry() {
        const azimuthLength = SphereGeometry.calcAzimuthLengthRadians(Math.abs(this.width), this.planogram.width);
        const cols = Math.max(1, Math.ceil(azimuthLength / ((2 * Math.PI) / Planogram.COLUMN_COUNT)));
        const rows = Math.max(1, Math.ceil(Math.abs(this.height) / (this.planogram.height / Planogram.ROW_COUNT)));
        const isReflectedOnX = Math.sign(this.width) < 0;
        // us are calculated going backwards from the right
        // since texture is on outside of sphere
        const geom = new SphereGeometry(Planogram.ALPHA, this.planogram.largeRadius, this.planogram.fixedRadius, cols, rows, this.azimuthStartRadians, azimuthLength, this.planogram.height, this.y, this.height, true, new Vector2(isReflectedOnX ? 0 : 1, 1), new Vector2(isReflectedOnX ? 1 : 0, 0));
        return geom;
    }
    onClick(position) { }
    onHoverEnter() { }
    onHoverLeave() { }
    getPosition() {
        return new Vector2(this.x, this.y);
    }
    getSize() {
        return new Vector2(Math.abs(this.width), Math.abs(this.height));
    }
    getViewportCenter() {
        return new Vector2(this.x + 0.5 * Math.abs(this.width), this.y + 0.5 * Math.abs(this.height) - this.planogram.height * 0.25);
    }
    getCenter(sphereShape) {
        const planogramCenter = this.getViewportCenter();
        const sphereCenter = sphereShape.fromPlanogramCoordinateViewer(planogramCenter, this.planogram.size());
        return [sphereShape.sample(sphereCenter), sphereShape.normalAt(sphereCenter).multiplyScalar(-1)];
    }
    dispose() {
        var _a, _b;
        disposeObject3D(this.object3D);
        (_a = this.geometry) === null || _a === void 0 ? void 0 : _a.dispose();
        this.geometry = undefined;
        (_b = this.material) === null || _b === void 0 ? void 0 : _b.dispose();
        this.material = undefined;
    }
}
