import PlanogramPoint, { planogramWidth } from 'shared/utils/PlanogramPoint';
import CylinderQuadTree from 'shared/utils/CylinderQuadTree';
import PlanogramBox from 'shared/utils/PlanogramBox';
import { debugCommand } from 'shared/utils/debug';
function treeDataReducer(data) {
    return {
        point: new PlanogramPoint(),
        minPixelPlanogramRatio: data.reduce((min, it) => Math.min(min, it.minPixelPlanogramRatio), +Infinity),
        maxPixelPlanogramRatio: data.reduce((max, it) => Math.max(max, it.maxPixelPlanogramRatio), 0),
        canUpgrade: data.some(it => it.canUpgrade),
        canDowngrade: data.some(it => it.canDowngrade),
        someNotLoaded: data.some(it => it.someNotLoaded),
        someLoaded: data.some(it => it.someLoaded),
        chunkId: 0,
        minDistanceToAtlas: data.reduce((min, it) => Math.min(min, it.minDistanceToAtlas), 0),
        maxDistanceToAtlas: data.reduce((max, it) => Math.max(max, it.maxDistanceToAtlas), 0),
        distanceToUnload: data.reduce((min, it) => Math.min(min, it.distanceToUnload), 0),
    };
}
function defaultTreeData() {
    return {
        point: new PlanogramPoint(),
        minPixelPlanogramRatio: +Infinity,
        maxPixelPlanogramRatio: 0,
        canDowngrade: false,
        canUpgrade: false,
        someNotLoaded: false,
        someLoaded: false,
        chunkId: 0,
        minDistanceToAtlas: 0,
        maxDistanceToAtlas: 0,
        distanceToUnload: 0,
    };
}
export default class ChunkSpatialTree extends CylinderQuadTree {
    constructor(tilePriority) {
        super(planogramWidth, treeDataReducer, defaultTreeData, new PlanogramBox({
            min: { x: 0, y: -Infinity },
            max: { x: planogramWidth, y: +Infinity },
        }));
        this.tilePriority = tilePriority;
        const self = this;
        debugCommand('lodSpatialTree', () => self);
    }
    findWithBlacklist(blacklist, rate) {
        const best = this.findLowestRating((data, box) => blacklist.has(data.chunkId) ? +Infinity : rate(data, box));
        if (best === undefined || blacklist.has(best.chunkId))
            return undefined;
        else
            return best;
    }
    findUpgrade(blacklist) {
        const best = this.findWithBlacklist(blacklist, (data, box) => this.tilePriority.rateUpgrade(data, box));
        if (best === undefined || !best.canUpgrade || !this.tilePriority.needsUpgrade(best))
            return undefined;
        else
            return best;
    }
    findDowngrade(blacklist) {
        const best = this.findWithBlacklist(blacklist, (data, box) => this.tilePriority.rateDowngrade(data, box));
        if (best === undefined || !best.someLoaded)
            return undefined;
        else
            return best;
    }
    findAliased(blacklist) {
        const best = this.findWithBlacklist(blacklist, (data, box) => this.tilePriority.rateAliasing(data, box));
        if (best === undefined || !best.canDowngrade || !this.tilePriority.needsDowngrade(best))
            return undefined;
        else
            return best;
    }
}
