import { BufferAttribute, BufferGeometry, CubicBezierCurve3, CurvePath, Vector3 } from 'three';
import { Planogram } from '../planogram';
import { SphereGeometry } from 'shared/geometries/SphereGeometry';
const Z_VECTOR_3 = new Vector3(0, 0, 1);
export class CurveGeometry extends BufferGeometry {
    constructor(points, thickness, scale, objectX, objectY, largeRadius, fixedRadius, planogramHeight, planogramWidth) {
        super();
        this.type = 'CurveGeometry';
        this.parameters = {
            points,
            thickness,
            scale,
            objectX,
            objectY,
            largeRadius,
            fixedRadius,
            planogramHeight,
            planogramWidth
        };
        this.setDefaultsAndLimits();
        this.calculateGeometryAttributes();
        this.buildGeometry();
    }
    dispose() {
        this.indices = null;
        this.vertices = null;
        super.dispose();
    }
    setDefaultsAndLimits() {
        this.points = this.parameters.points;
        this.thickness = this.parameters.thickness;
        this.scaleParameter = this.parameters.scale;
        this.objectX = this.parameters.objectX;
        this.objectY = this.parameters.objectY;
        this.largeRadius = this.parameters.largeRadius;
        this.fixedRadius = this.parameters.fixedRadius;
        this.planogramHeight = this.parameters.planogramHeight;
        this.planogramWidth = this.parameters.planogramWidth;
    }
    calculateGeometryAttributes(levelOfDetail = 3) {
        const curvePath = CurveGeometry.curvePathFromPoints(this.points, this.scaleParameter);
        const vertices2D = [];
        const index = [];
        const uvs = [];
        const normal = new Vector3();
        let indexOffset;
        let cumulativeCurveLength = 0;
        let cumulativeDivisionCount = 0;
        let curve;
        let curveLength;
        let divisions;
        let curveLengths;
        let curvePoints;
        for (let curveIndex = 0; curveIndex < curvePath.curves.length; curveIndex++) {
            curve = curvePath.curves[curveIndex];
            curveLength = curve.getLength();
            divisions = Math.round((curveLength / 25) * levelOfDetail);
            curve.arcLengthDivisions = divisions;
            curveLengths = curve.getLengths();
            curvePoints = curve.getPoints(divisions);
            curvePoints.forEach((curvePoint, pointIndex) => {
                curvePoint = curvePoints[pointIndex];
                curve.getTangent(pointIndex / (curvePoints.length - 1), normal);
                normal.applyAxisAngle(Z_VECTOR_3, Math.PI / 2).multiplyScalar(this.thickness);
                vertices2D.push(curvePoint.x + normal.x, curvePoint.y + normal.y);
                vertices2D.push(curvePoint.x - normal.x, curvePoint.y - normal.y);
                uvs.push(cumulativeCurveLength + curveLengths[pointIndex], 1, cumulativeCurveLength + curveLengths[pointIndex], -1);
                indexOffset = (pointIndex - 1) * 2 + cumulativeDivisionCount * 2;
                if (indexOffset >= 0) {
                    index.push(0 + indexOffset, 1 + indexOffset, 2 + indexOffset, 1 + indexOffset, 3 + indexOffset, 2 + indexOffset);
                }
            });
            cumulativeCurveLength += curveLength > 0 ? curveLength : 0;
            cumulativeDivisionCount += divisions + 1;
        }
        this.vertices = this.projectPointsToSphere(vertices2D);
        this.uvs = uvs;
        this.indices = index;
        this.curveLength = cumulativeCurveLength;
    }
    static curvePathFromPoints(points, scale) {
        const curve = new CurvePath();
        for (let i = 0; i < points.length - 2; i += 6) {
            const curveSection = new CubicBezierCurve3(new Vector3(points[i] * scale[0], points[i + 1] * scale[1], 0), new Vector3(points[i + 2] * scale[0], points[i + 3] * scale[1], 0), new Vector3(points[i + 4] * scale[0], points[i + 5] * scale[1], 0), new Vector3(points[i + 6] * scale[0], points[i + 7] * scale[1], 0));
            curve.add(curveSection);
        }
        return curve;
    }
    projectPointsToSphere(points2D) {
        const spherePoints = new Float32Array((points2D.length / 2) * 3);
        const surfaceLength = SphereGeometry.calcTopToBottomSurfaceLength(Planogram.ALPHA, this.largeRadius, this.fixedRadius);
        const heightAdjustment = (surfaceLength - this.planogramHeight) / 2 + this.objectY;
        const rotationVector = new Vector3(0, 1, 0);
        const pointVector = new Vector3(0, 0, 0);
        let yDistance, rotationAngle;
        for (let i = 0; i < points2D.length / 2; i++) {
            yDistance = points2D[i * 2 + 1] + heightAdjustment;
            rotationAngle =
                SphereGeometry.calcAzimuthStartRadians(points2D[i * 2] + this.objectX, 0, this.planogramWidth) -
                    SphereGeometry.ROTATION_OFFSET;
            const intersect = SphereGeometry.calcSpherePoint(yDistance, surfaceLength, Planogram.ALPHA, this.largeRadius, this.fixedRadius);
            const adjustedIntersect = SphereGeometry.distortionAdjustment(intersect, yDistance, surfaceLength, Planogram.ALPHA, this.largeRadius, this.fixedRadius);
            pointVector.setX(adjustedIntersect.point[0]);
            pointVector.setY(adjustedIntersect.point[1]);
            pointVector.setZ(0);
            pointVector.applyAxisAngle(rotationVector, rotationAngle);
            spherePoints[i * 3] = pointVector.x;
            spherePoints[i * 3 + 1] = pointVector.y;
            spherePoints[i * 3 + 2] = pointVector.z;
        }
        return spherePoints;
    }
    buildGeometry() {
        this.setIndex(this.indices);
        this.setAttribute('position', new BufferAttribute(this.vertices, 3));
        this.setAttribute('uv', new BufferAttribute(new Float32Array(this.uvs), 2));
    }
}
