import { ACCESS_TOKEN_KEY, CUSTOM_HEADER_KEY } from '../shared/constants';
export const reqSettings = (method, data, signal, withToken = true) => {
    const accesstoken = window.localStorage.getItem(ACCESS_TOKEN_KEY);
    const xLiteHeaderVal = window.sessionStorage.getItem(CUSTOM_HEADER_KEY);
    const authHeaders = (withToken && accesstoken) ? { accesstoken } : {};
    const xLiteHeader = (withToken && xLiteHeaderVal) ? { [CUSTOM_HEADER_KEY]: xLiteHeaderVal } : {};
    return {
        method,
        signal,
        headers: Object.assign(Object.assign({ 'Accept': 'application/json', 'Content-Type': 'application/json' }, authHeaders), xLiteHeader),
        body: data ? JSON.stringify(data) : null
    };
};
