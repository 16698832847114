import { Material, Mesh, ShaderMaterial } from 'three';
export function disposeMaterial(material) {
    var _a;
    if (material instanceof ShaderMaterial) {
        disposeUniforms(material.uniforms);
        material.uniforms = undefined;
    }
    (_a = material.map) === null || _a === void 0 ? void 0 : _a.dispose();
    material.dispose();
}
export function disposeUniforms(uniforms) {
    for (const k in uniforms) {
        if (uniforms[k].value.dispose) {
            uniforms[k].value.dispose();
        }
        delete uniforms[k];
    }
}
export function disposeMesh(mesh) {
    var _a;
    (_a = mesh.geometry) === null || _a === void 0 ? void 0 : _a.dispose();
    const material = mesh.material;
    if (material instanceof Material) {
        disposeMaterial(material);
    }
    else if (material instanceof Array) {
        material.forEach(m => disposeMaterial(m));
    }
    mesh.geometry = undefined;
    mesh.material = undefined;
    mesh.userData = undefined;
}
export function disposeObject3D(object3D) {
    if (!object3D) {
        return;
    }
    object3D.children.forEach(ch => {
        disposeObject3D(ch);
    });
    object3D.remove(...object3D.children);
    if (object3D instanceof Mesh) {
        disposeMesh(object3D);
    }
    object3D.userData = undefined;
}
