import { FontsService } from './api/services/fonts.service';
import { AppUtils } from './utils/app_utils';
import { WebUtils } from './utils/web_utils';
const storedFonts = new Map();
export class FontLoader {
    static init(planogramId) {
        const fontsService = new FontsService();
        FontLoader.initFontContainer();
        fontsService
            .getFonts(planogramId)
            .then(fonts => {
            if (!Array.isArray(fonts) || fonts.length === 0) {
                return [];
            }
            return fonts;
        })
            .then(FontLoader.mountCustomFonts);
    }
    static storeCustomFont(key, val) {
        storedFonts.set(key, val);
    }
    static getCustomFont(key) {
        return storedFonts.get(key);
    }
    static initFontContainer() {
        const fontContainer = document.createElement('div');
        const styleElement = document.createElement('style');
        const fontExampleContainer = document.createElement('div');
        const existedFontContainer = document.getElementById('font-container');
        if (existedFontContainer) {
            existedFontContainer.remove();
        }
        fontContainer.setAttribute('id', 'font-container');
        fontContainer.setAttribute('aria-hidden', 'true');
        fontContainer.prepend(styleElement);
        fontContainer.appendChild(fontExampleContainer);
        fontContainer.style.position = 'fixed';
        fontContainer.style.opacity = '0';
        fontContainer.style.pointerEvents = 'none';
        document.body.prepend(fontContainer);
    }
    static mountCustomFonts(styles) {
        styles.forEach(style => FontLoader.mountCustomFont(style));
    }
    static mountCustomFont(style) {
        if (!style) {
            return;
        }
        const fontContainer = document.getElementById('font-container');
        const fontExampleContainer = fontContainer.querySelector('div');
        const root = document.documentElement;
        const { assignment, font, font_size, color, font_weight, line_height, letter_spacing } = style;
        const fontFormat = FontLoader.getFontFormat(font === null || font === void 0 ? void 0 : font.filename);
        if (fontFormat) {
            const fontAssignment = document.getElementById(`${assignment}-font`);
            if (fontAssignment) {
                fontAssignment.remove();
            }
            const fontExample = document.createElement('p');
            let fontName = WebUtils.removeFileExtension(font.name);
            const storedCustomFont = FontLoader.getCustomFont(font.file_url);
            if (!storedCustomFont) {
                FontLoader.addCustomFont(font);
            }
            else {
                fontName = WebUtils.removeFileExtension(storedCustomFont.name);
            }
            fontExample.innerText = 'Example';
            fontExample.style.fontFamily = `"${fontName}"`;
            fontExample.setAttribute('id', `${assignment}-font`);
            fontExampleContainer.appendChild(fontExample);
            root.style.setProperty(`--${assignment}-font`, `"${fontName}"`);
        }
        if (font_size) {
            root.style.setProperty(`--${assignment}-font-size`, `${font_size}`);
            root.style.setProperty(`--${assignment}-font-value`, `${parseFloat(font_size)}`);
        }
        if (font_weight) {
            root.style.setProperty(`--${assignment}-font-weight`, `${font_weight}`);
        }
        if (color) {
            root.style.setProperty(`--${assignment}-font-color`, `${color}`);
            const list = [
                'infos-content',
                'cookies-description',
                'cookies-table-text',
                'ecommerce-overlay-header',
                'ecommerce-overlay-captions',
                'ecommerce-overlay-price',
                'ecommerce-account-validation',
                'ecommerce-account-text_field_info',
                'ecommerce-overlay-options'
            ];
            if (list.includes(assignment)) {
                const rgbValue = AppUtils.hex2rgb(color);
                root.style.setProperty(`--${assignment}-font-color-rgb`, `${rgbValue}`);
            }
        }
        if (line_height) {
            root.style.setProperty(`--${assignment}-line-height`, `${line_height}`);
        }
        if (letter_spacing) {
            root.style.setProperty(`--${assignment}-letter-spacing`, `${letter_spacing}`);
        }
    }
    static addCustomFont(font) {
        const fontFace = new FontFace(WebUtils.removeFileExtension(font.name), `url(${font === null || font === void 0 ? void 0 : font.file_url})`);
        document.fonts.add(fontFace);
        FontLoader.storeCustomFont(font.file_url, font);
        fontFace.load().catch(error => console.error(error));
        return fontFace;
    }
    static unmountCustomFont(prefix) {
        const root = document.documentElement;
        const rootStyles = Array.from(root.style);
        const formattedPrefix = `--${prefix}`;
        for (let i = 0; i < rootStyles.length; i++) {
            const val = rootStyles[i];
            if (val.startsWith(formattedPrefix)) {
                root.style.removeProperty(val);
            }
        }
    }
    static getFontFormat(fontUrl) {
        const fontFormat = fontUrl.match(/\.[0-9a-z]+$/i)[0].substring(1);
        switch (fontFormat) {
            case 'ttf':
                return 'truetype';
            case 'otf':
                return 'opentype';
            case 'woff':
                return 'woff';
            case 'woff2':
                return 'woff2';
        }
    }
}
