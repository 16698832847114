var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { isMobile } from '../../utils/platform';
import { InputEventUtils } from '../../utils/input_event_utils';
import { ProductInfoBase } from './product-info-base_overlay';
import { L10nUtils } from '../../utils/l10n_utils';
import { productInfoLegoView } from './product-info-lego.view';
import sanitizeHtml from 'sanitize-html';
export class ProductInfoLego extends ProductInfoBase {
    init() {
        const _super = Object.create(null, {
            init: { get: () => super.init }
        });
        return __awaiter(this, void 0, void 0, function* () {
            yield _super.init.call(this);
            this.handlePolybagsProduct();
        });
    }
    initOverlay() {
        super.initOverlay();
        this.initInfoTextControls();
    }
    handleClick(e) {
        if (this.isViewGallery()) {
            const tokens = ['app-product-overlay', 'app-product-content-wrapper', 'app-product-carousel'];
            if (e.target.closest('#app-image-carousel')) {
                tokens.push('splide__slide');
            }
            return tokens.some(token => e.target.classList.contains(token));
        }
        else {
            return e.target === this.mainContainer;
        }
    }
    setItemContent() {
        const description = this.product.description
            ? sanitizeHtml(this.product.description)
            : this.getDefaultDescription(this.product);
        const sanitizedProduct = Object.assign(Object.assign({}, this.product), { description });
        this.productTitle = this.product.name;
        this.container.innerHTML = productInfoLegoView(sanitizedProduct, this.item.planogram.name);
    }
    initInfoTextControls() {
        const infoButton = document.querySelector('.info-icon');
        const productOverlay = document.querySelector('.app-product-overlay');
        const toggleClassName = 'is-info-text-active';
        const parentContainer = document.querySelector('.app-product-price');
        const toggleInfoText = (e) => {
            const target = e.target;
            e.stopPropagation();
            if (!parentContainer) {
                console.error('element not found - ".app-product-price"');
            }
            if (target.classList.contains('info-icon')) {
                parentContainer.classList.toggle(toggleClassName);
            }
            else {
                parentContainer.classList.remove(toggleClassName);
            }
        };
        if (isMobile) {
            InputEventUtils.addSelectEvents(infoButton, toggleInfoText);
            InputEventUtils.addSelectEvents(productOverlay, toggleInfoText);
        }
        else {
            InputEventUtils.addHoverEvent(infoButton, toggleInfoText);
        }
    }
    getDefaultDescription(item) {
        return `
    <p class="${!item.name ? 'is-hidden' : ''}">${item.name}</p>
    <p>Code: ${item.identifier}</p>
    <p class="${!item.pieces ? 'is-hidden' : ''}">${L10nUtils.l10n('product-details-overlay.metadata.pieces')}: ${item.pieces}</p>
    <p class="${!item.age ? 'is-hidden' : ''}">${L10nUtils.l10n('product-details-overlay.metadata.age')}: ${item.age}</p>
    <p class="${!item.year ? 'is-hidden' : ''}">${L10nUtils.l10n('product-details-overlay.metadata.year')}: ${item.year}</p>
    `;
    }
    handlePolybagsProduct() {
        if (this.product.is_polybags !== 'yes') {
            return;
        }
        // Hide price
        const priceContainer = document.querySelector('.app-product-price');
        priceContainer === null || priceContainer === void 0 ? void 0 : priceContainer.classList.add('is-hidden');
        // Hide custom buy button
        const customButton = document.getElementById('custom-button');
        customButton === null || customButton === void 0 ? void 0 : customButton.classList.add('is-hidden');
        // Hide rakuten buy button
        const rakutenButton = document.getElementById('rakuten-button');
        rakutenButton === null || rakutenButton === void 0 ? void 0 : rakutenButton.classList.add('is-hidden');
    }
}
