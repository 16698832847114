var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { isMobile } from '../../utils/platform';
import { InputEventUtils } from '../../utils/input_event_utils';
import { ProductInfoBase } from './product-info-base_overlay';
import { productInfoNinjagoView } from './product-info-ninjago.view';
import sanitizeHtml from 'sanitize-html';
export class ProductInfoNinjago extends ProductInfoBase {
    init() {
        const _super = Object.create(null, {
            init: { get: () => super.init }
        });
        return __awaiter(this, void 0, void 0, function* () {
            yield _super.init.call(this);
            this.handleOutOfStockProduct();
        });
    }
    initOverlay() {
        super.initOverlay();
        this.initInfoTextControls();
    }
    handleClick(e) {
        if (this.isViewGallery()) {
            const tokens = ['app-product-overlay', 'app-product-content-wrapper', 'app-product-carousel'];
            if (e.target.closest('#app-image-carousel')) {
                tokens.push('splide__slide');
            }
            return tokens.some(token => e.target.classList.contains(token));
        }
        else {
            return e.target === this.mainContainer;
        }
    }
    setItemContent() {
        const description = this.product.description ? sanitizeHtml(this.product.description) : '';
        const sanitizedProduct = Object.assign(Object.assign({}, this.product), { description });
        this.productTitle = this.product.name;
        this.container.innerHTML = productInfoNinjagoView(sanitizedProduct, this.item.planogram.name);
    }
    initInfoTextControls() {
        const infoButton = document.querySelector('.info-icon');
        const productOverlay = document.querySelector('.app-product-overlay');
        const toggleClassName = 'is-info-text-active';
        const parentContainer = document.querySelector('.app-product-price');
        const toggleInfoText = (e) => {
            const target = e.target;
            e.stopPropagation();
            if (!parentContainer) {
                console.error('element not found - ".app-product-price"');
            }
            if (target.classList.contains('info-icon')) {
                parentContainer.classList.toggle(toggleClassName);
            }
            else {
                parentContainer.classList.remove(toggleClassName);
            }
        };
        if (isMobile) {
            InputEventUtils.addSelectEvents(infoButton, toggleInfoText);
            InputEventUtils.addSelectEvents(productOverlay, toggleInfoText);
        }
        else {
            InputEventUtils.addHoverEvent(infoButton, toggleInfoText);
        }
    }
    handleOutOfStockProduct() {
        if (this.product.price_in_user_currency_str) {
            return;
        }
        // Hide custom button if price is empty
        const customButton = document.getElementById('custom-button');
        const galleryCheckoutButtonContainer = document.getElementById('checkout-gallery-button');
        const galleryInfoButtonContainer = document.getElementById('info-gallery-button');
        const checkoutButton = document.getElementById('checkout-button');
        this.mainContainer.classList.remove('checkout-flow');
        this.mainContainer.classList.add('no-buttons');
        customButton === null || customButton === void 0 ? void 0 : customButton.classList.add('is-hidden');
        galleryInfoButtonContainer === null || galleryInfoButtonContainer === void 0 ? void 0 : galleryInfoButtonContainer.classList.add('no-checkout');
        galleryCheckoutButtonContainer === null || galleryCheckoutButtonContainer === void 0 ? void 0 : galleryCheckoutButtonContainer.classList.add('is-hidden');
        checkoutButton === null || checkoutButton === void 0 ? void 0 : checkoutButton.classList.add('is-hidden');
    }
}
