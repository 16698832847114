export { LAYOUT_SWITCHER_TYPE } from '../../shared/constants';
export const layoutSwitcherView = ({ type = 'text' }) => {
    return `
    <div class="layout-switcher is-${type}-type fixed-control-button ${type === 'text' ? 'is-long' : ''}">
        <div class="layout-switcher_container">
          ${type === 'text' ? `
          <div class='layout-switcher_search'>
            <input type='text'>
          </div>` : ''}
          <div class="${type === 'text' ? 'scroll-wrapper' : ''}">
            <div class='layout-items_wrapper'></div>
          </div>
          ${type === 'text' ? `
          <div class='layout-switcher_last-item'></div> 
          ` : ''}
        </div>
    </div>
  `;
};
