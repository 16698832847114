import { InfoOverlay } from './infos-base_overlay';
import { InputEventUtils } from '../utils/input_event_utils';
import { overlayEventsHandler } from '../custom_event_utils';
export class Privacy extends InfoOverlay {
    constructor(container, planogramId, verionId, infoType, item, options, closeCallback) {
        super(container, planogramId, verionId, infoType, options, closeCallback);
    }
    initTemplateContent(container, data) {
        super.initTemplateContent(container, data);
        const cookiesToggleButton = document.querySelector('.infos-overlay .cookies-btn');
        if (cookiesToggleButton) {
            cookiesToggleButton.classList.remove('is-hidden');
            InputEventUtils.addSelectEvents(cookiesToggleButton, this.toggleView.bind(this));
        }
        else {
            console.error('element not found - ".privacy-overlay .cookies-btn"');
        }
    }
    toggleView(e) {
        if (e) {
            e.stopPropagation();
        }
        overlayEventsHandler.emit('showCookieTable');
    }
}
