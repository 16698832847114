var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import subscribe from 'callbag-subscribe';
import pipe from 'callbag-pipe';
import { IdealOverlayView } from './ideal-product_info.view';
import { InputEventUtils } from '../../utils/input_event_utils';
import { Metrics } from '../../metrics';
import { MATOMO_EVENT_NAMES } from '../../metric-events';
import { ProductFields } from '../shopify-product-info/components/product-fields/product-fields_component';
import { ShopifyButtons } from '../shopify-product-info/components/buttons/shopify-buttons_component';
import { VariantsComponent } from '../shopify-product-info/components/variants/variants_component';
import { AppUtils } from '../../utils/app_utils';
import { FontLoader } from '../../font_loader';
import { L10nUtils } from '../../utils/l10n_utils';
import { CurrencyUtils } from '../../utils/currency_utils';
import { AppState } from '../../shared/app.state';
import { ProductInfoBase } from '../product-info/product-info-base_overlay';
import { PRODUCT_GALLERY_ITEM } from '../../shared/constants';
import { shopifyOverlayHandler } from '../../custom_event_utils';
import { ShoppingCartWidgetComponent } from '../../shopping-cart-widget/shopping-cart-widget.component';
import { IdealService } from '../../api/services/ideal.service';
import { IdealQuantityPicker } from './components/quantity-picker/quantity-picker';
export class IdealProductInfo extends ProductInfoBase {
    constructor(container, item, planogram, options, productInfoService, shoppingCartService, currencyService, closeCallback) {
        super(container, item, options, productInfoService, closeCallback);
        this.planogram = planogram;
        this.shoppingCartService = shoppingCartService;
        this.currencyService = currencyService;
        this.showShoppingCartWidget = false;
        this.getPreSelectSlide = variant => {
            var _a;
            if (!(variant === null || variant === void 0 ? void 0 : variant.image_id)) {
                return;
            }
            const imgVariantIndex = this.quantityVideoSlides +
                ((_a = this.product) === null || _a === void 0 ? void 0 : _a.product_images.findIndex(img => (img === null || img === void 0 ? void 0 : img.shopify_id) === variant.image_id || (img === null || img === void 0 ? void 0 : img.id) === variant.image_id));
            const currentSlideIndex = this.gallerySplide.index;
            if (imgVariantIndex >= 0 && imgVariantIndex !== currentSlideIndex) {
                this.gallerySplide.go(imgVariantIndex);
            }
        };
        this.shopifyService = new IdealService();
        this.quantityVideoSlides = 0;
        this.blurInformationBound = this.blurInformation.bind(this);
        pipe(this.shopifyService.selectedProductSubject, subscribe({
            next: (variant) => {
                this.updateProductPrice(variant);
                this.getPreSelectSlide(variant);
                this.variant = variant;
            }
        }));
        pipe(this.shoppingCartService.processingSubject, subscribe({
            next: (loading) => {
                var _a;
                if (!loading &&
                    this.shoppingCartWidget &&
                    !((_a = this.shoppingCartWidget) === null || _a === void 0 ? void 0 : _a.panelVisibility) &&
                    this.showShoppingCartWidget) {
                    this.shoppingCartWidget.panelVisibility = true;
                }
            }
        }));
        shopifyOverlayHandler.listen('shoppingCartVisibility', this.blurInformationBound);
    }
    get enablePersonalizeOverlay() {
        return false;
    }
    init() {
        const _super = Object.create(null, {
            init: { get: () => super.init }
        });
        return __awaiter(this, void 0, void 0, function* () {
            this.overlaySettings = this.item.planogram.ecommerceOverlaySettingsPdp;
            yield _super.init.call(this);
            this.shopifyService.updateProductData(this.product);
        });
    }
    setItemContent() {
        this.container.innerHTML = IdealOverlayView(this.product);
        this.mainContainer = this.container.querySelector('.ideal-template');
        if (this.overlaySettings.enable_buttons) {
            this.variantComponents = this.product.options
                .sort((prevOption, nextOption) => prevOption.position - nextOption.position)
                .map(option => {
                return new VariantsComponent(option, this.product, this.mainContainer, this.shopifyService);
            });
            this.quantityPicker = new IdealQuantityPicker(this.product, this.mainContainer, this.shopifyService, this.shoppingCartService);
            this.buttons = new ShopifyButtons(this, this.product, this.overlaySettings, this.mainContainer, this.quantityPicker, this.shopifyService, this.shoppingCartService);
            this.shoppingCartWidget = new ShoppingCartWidgetComponent(this.planogram, this.shoppingCartService, this.currencyService, null, this.quantityPicker, 'app-ecommerce-cart-container', this.product);
        }
        this.productFields = new ProductFields(this.product, this.mainContainer, this.overlaySettings);
        this.setOverlayFonts();
        this.syncEcommerceOverlayColor();
    }
    initImageSlider() {
        super.initImageSlider();
        if (this.product.video_first) {
            this.quantityVideoSlides = this.productSlides.slides.reduce((acc, item) => {
                return item.type === PRODUCT_GALLERY_ITEM.VIDEO ? ++acc : acc;
            }, 0);
        }
    }
    setOverlayFonts() {
        var _a;
        (_a = this.overlaySettings) === null || _a === void 0 ? void 0 : _a.ecommerce_fonts.forEach(font => {
            const fontSettings = Object.assign(Object.assign({}, font), { assignment: `ecommerce-overlay-${font.assignment}` });
            FontLoader.mountCustomFont(fontSettings);
        });
    }
    syncEcommerceOverlayColor() {
        var _a, _b;
        const root = document.documentElement;
        if ((_a = this.overlaySettings) === null || _a === void 0 ? void 0 : _a.background_color) {
            const ecommerceOverlayColorRgb = AppUtils.hex2rgb(this.overlaySettings.background_color);
            root.style.setProperty(`--ecommerce-overlay-primary-color`, `${this.overlaySettings.background_color}`);
            root.style.setProperty(`--ecommerce-overlay-primary-color-rgb`, `${ecommerceOverlayColorRgb}`);
        }
        if ((_b = this.overlaySettings) === null || _b === void 0 ? void 0 : _b.secondary_color) {
            const ecommerceOverlayColorRgb = AppUtils.hex2rgb(this.overlaySettings.secondary_color);
            root.style.setProperty(`--ecommerce-overlay-secondary-color`, `${this.overlaySettings.secondary_color}`);
            root.style.setProperty(`--ecommerce-overlay-secondary-color-rgb`, `${ecommerceOverlayColorRgb}`);
        }
    }
    initProductPrice() { }
    initDescriptionControls() { }
    initGalleryViewControls() { }
    initOverlayElements() { }
    initPurchasingFlow() { }
    setCustomButtons(buttons) { }
    initOverlayControls() {
        const closeButton = this.mainContainer.querySelector('#ideal-product-details-container .overlay-button-close');
        if (closeButton) {
            InputEventUtils.addSelectEvents(closeButton, e => {
                Metrics.storeTheEvent(this.item.planogram.name, 'click', MATOMO_EVENT_NAMES.CLICK_ECOMMERCE_OVERLAY_CLOSE_BUTTON(this.productIdentifier, this.productName));
                this.closeOverlay(e);
            });
        }
        else {
            console.error('element not found - ".overlay-button-close"');
        }
    }
    blurInformation(options) {
        options.value
            ? this.mainContainer.classList.add('is-cart-active')
            : this.mainContainer.classList.remove('is-cart-active');
    }
    getStockValue(variant) {
        const priceWrapper = this.mainContainer.querySelector('.app-ecommerce-product-price');
        const stockValueContainer = priceWrapper.querySelector('.app-ecommerce-product-stock-value');
        let stockValue;
        if (variant === null || variant === void 0 ? void 0 : variant.isAutoSelected) {
            stockValueContainer.classList.add('is-hidden');
        }
        else {
            stockValueContainer.classList.remove('is-hidden');
        }
        switch (true) {
            case (variant === null || variant === void 0 ? void 0 : variant.quantity) > 0 && (variant === null || variant === void 0 ? void 0 : variant.quantity) < 10: {
                stockValue = `${L10nUtils.l10n('shopify-product-overlay.product-stock-n')}: ${variant === null || variant === void 0 ? void 0 : variant.quantity}`;
                break;
            }
            case (variant === null || variant === void 0 ? void 0 : variant.quantity) >= 10: {
                stockValue = L10nUtils.l10n('shopify-product-overlay.product-stock-available');
                break;
            }
            default: {
                stockValue = L10nUtils.l10n('shopify-product-overlay.product-out-of-stock');
            }
        }
        stockValueContainer.innerText = stockValue;
    }
    updateProductPrice(selectedVariant) {
        if (!this.mainContainer) {
            return;
        }
        const priceWrapper = this.mainContainer.querySelector('.app-ecommerce-product-price');
        const priceContainer = priceWrapper.querySelector('.app-ecommerce-product-price-value');
        const discountPriceContainer = priceWrapper
            .querySelector('.app-ecommerce-product-discount-price-value');
        priceWrapper.classList.remove('has-discount-price', 'is-unavailable', 'is-unavailable-without-margin');
        this.getStockValue(selectedVariant);
        if (!selectedVariant && !selectedVariant.isAutoSelected) {
            priceWrapper.classList.add('is-unavailable-without-margin');
            priceContainer.innerText = '';
            discountPriceContainer.innerText = '';
            return;
        }
        else if (selectedVariant.quantity <= 0 && !selectedVariant.isAutoSelected) {
            priceWrapper === null || priceWrapper === void 0 ? void 0 : priceWrapper.classList.add('is-unavailable');
        }
        const { selectedCurrencyCode, currenciesList } = this.currencyService;
        const getPriceValue = price => CurrencyUtils.getPriceWithSymbolOrCode(price, selectedCurrencyCode, currenciesList);
        if ((selectedVariant === null || selectedVariant === void 0 ? void 0 : selectedVariant.compare_at_price) && parseFloat(selectedVariant === null || selectedVariant === void 0 ? void 0 : selectedVariant.compare_at_price)) {
            // due to weird behaviour of shopify api discount price logic
            priceContainer.innerText = getPriceValue(selectedVariant.compare_at_price);
            discountPriceContainer.innerText = getPriceValue(selectedVariant.price);
            priceWrapper.classList.add('has-discount-price');
        }
        else {
            priceContainer.innerText = getPriceValue(selectedVariant.price);
            discountPriceContainer.innerText = '';
        }
    }
    handleImageMatomoEvents(e) {
        var _a;
        const target = e.target;
        if (target.closest('.splide__slide')) {
            let name = '';
            let imgElement;
            if (target.dataset.slideType === 'li') {
                imgElement = target.querySelector('img');
            }
            else if (target.dataset.slideType === 'img') {
                imgElement = target;
            }
            if (imgElement) {
                name = imgElement.getAttribute('data-name');
            }
            Metrics.storeTheEvent(AppState.planogramName, 'click', `${MATOMO_EVENT_NAMES.CLICK_ECOMMERCE_OVERLAY_PRODUCT_IMAGE((_a = this.product) === null || _a === void 0 ? void 0 : _a.identifier)}${name ? '-' + name : ''}`);
        }
    }
    handleThumbnailMatomoEvents(e) {
        const target = e.target;
        if (target.closest('.splide__slide')) {
            const thumbnailImage = target.closest('.splide__slide').querySelector('img');
            const thumbnailType = thumbnailImage.getAttribute('data-type');
            Metrics.storeTheEvent(AppState.planogramName, 'click', MATOMO_EVENT_NAMES.CLICK_ECOMMERCE_PRODUCT_THUMBNAIL(thumbnailType, this.productIdentifier, this.productName));
        }
    }
    getGalleryCarouselConfig(productSlides) {
        return {
            type: 'loop',
            perPage: 1,
            pagination: false,
            keyboard: true,
            speed: 100,
            updateOnMove: true,
            lazyLoad: 'nearby',
            // @ts-ignore
            video: {
                playerOptions: {
                    youtube: {
                        playlist: undefined
                    }
                }
            }
        };
    }
    getThumbsCarouselConfig(productSlides) {
        return {
            autoWidth: true,
            autoHeight: true,
            gap: '1.53vw',
            speed: 150,
            rewind: true,
            pagination: false,
            isNavigation: true,
            lazyLoad: 'sequential',
            perPage: Math.min(productSlides.length - 1, 5),
            focus: 4,
            perMove: 1,
            keyboard: false,
            breakpoints: {
                1200: {
                    gap: 18
                },
                1024: {
                    gap: 16
                }
            }
        };
    }
    validateVariants() {
        return this.variantComponents.every(variantComponent => variantComponent === null || variantComponent === void 0 ? void 0 : variantComponent.validate());
    }
    dispose() {
        var _a, _b, _c, _d;
        // TODO: Fix unsubscribe from listeners in splide
        //  (splide video extension not able to destroy with invalid youtube video in slide)
        try {
            (_a = this.gallerySplide) === null || _a === void 0 ? void 0 : _a.destroy(true);
            (_b = this.thumbnailSplide) === null || _b === void 0 ? void 0 : _b.destroy(true);
        }
        catch (e) {
            console.error('Gallery destroy error:', e);
        }
        (_c = this.shoppingCartWidget) === null || _c === void 0 ? void 0 : _c.dispose();
        (_d = this.quantityPicker) === null || _d === void 0 ? void 0 : _d.dispose();
    }
}
