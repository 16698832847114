import { InputFieldView } from '../../components/input-field/input-field-component.view';
export const InputFieldsHogwartsTrunkComponentView = (product) => {
    const field = InputFieldView({
        label: product.input_fields[0].name,
        placeholder: 'HJP',
        maxlength: 3
    });
    return `
    <div class="input-field-wrapper">${field}</div>
  `;
};
