import { PRODUCT_VIDEO_PROVIDER, PRODUCT_VIDEO_THUMB_TYPE } from '../shared/constants';
import { URLS } from '../api/urls';
const YOUTUBE_URL_REGEX = /(?:youtube\.com\/\S*(?:(?:\/e(?:mbed))?\/|watch\?(?:\S*?&?v\=))|youtu\.be\/)([a-zA-Z0-9_-]{6,11})/g;
const VIMEO_URL_REGEX = /^((?:https?:)?\/\/)?((?:www|m|player)\.)?((?:vimeo\.com))(?:$|\/|)(\S+)?$/;
var YOUTUBE_THUMB_VERSION;
(function (YOUTUBE_THUMB_VERSION) {
    YOUTUBE_THUMB_VERSION["DEFAULT"] = "default";
    YOUTUBE_THUMB_VERSION["HQ"] = "hqdefault";
    YOUTUBE_THUMB_VERSION["MQ"] = "mqdefault";
    YOUTUBE_THUMB_VERSION["SD"] = "sddefault";
    YOUTUBE_THUMB_VERSION["MAX"] = "maxresdefault";
})(YOUTUBE_THUMB_VERSION || (YOUTUBE_THUMB_VERSION = {}));
var VIMEO_THUMB_VERSION;
(function (VIMEO_THUMB_VERSION) {
    VIMEO_THUMB_VERSION["LARGE"] = "large";
    VIMEO_THUMB_VERSION["MEDIUM"] = "medium";
    VIMEO_THUMB_VERSION["SMALL"] = "small";
})(VIMEO_THUMB_VERSION || (VIMEO_THUMB_VERSION = {}));
export class VideoUtils {
    static getYoutubeId(url) {
        const [a, , b] = url.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
        if (b !== undefined) {
            return b.split(/[^0-9a-z_-]/i)[0];
        }
        else {
            return a;
        }
    }
    static getVimeoId(url) {
        return url.match(VIMEO_URL_REGEX)[4];
    }
    static getYoutubeVideoThumbnail(url, version = YOUTUBE_THUMB_VERSION.HQ) {
        const id = VideoUtils.getYoutubeId(url);
        return `https://img.youtube.com/vi/${id}/${version}.jpg`;
    }
    static getVimeoThumbnail(url, version = VIMEO_THUMB_VERSION.LARGE) {
        const id = VideoUtils.getVimeoId(url);
        return `https://vumbnail.com/${id}_${version}.jpg`;
    }
    static thumbnailUrl(url, type) {
        const provider = this.parseVideoProvider(url);
        switch (provider) {
            case PRODUCT_VIDEO_PROVIDER.YOUTUBE: {
                const version = type === PRODUCT_VIDEO_THUMB_TYPE.VIDEO_PREV ? YOUTUBE_THUMB_VERSION.HQ : YOUTUBE_THUMB_VERSION.DEFAULT;
                return VideoUtils.getYoutubeVideoThumbnail(url, version);
            }
            case PRODUCT_VIDEO_PROVIDER.VIMEO: {
                const version = type === PRODUCT_VIDEO_THUMB_TYPE.VIDEO_PREV ? VIMEO_THUMB_VERSION.LARGE : VIMEO_THUMB_VERSION.MEDIUM;
                return VideoUtils.getVimeoThumbnail(url, version);
            }
            default: {
                return URLS.OVERLAY_GALLERY_VIDEO_IMAGE_FALLBACK;
            }
        }
    }
    static parseVideoProvider(url) {
        if (url.match(YOUTUBE_URL_REGEX))
            return PRODUCT_VIDEO_PROVIDER.YOUTUBE;
        else if (url.match(VIMEO_URL_REGEX))
            return PRODUCT_VIDEO_PROVIDER.VIMEO;
        else
            return PRODUCT_VIDEO_PROVIDER.DIRECT_LINK;
    }
    static getSlideUrlOrId(url) {
        const provider = this.parseVideoProvider(url);
        if (provider === PRODUCT_VIDEO_PROVIDER.YOUTUBE) {
            return VideoUtils.getYoutubeId(url);
        }
        return VideoUtils.sanitizeUrl(url);
    }
    static sanitizeUrl(url) {
        const provider = this.parseVideoProvider(url);
        switch (provider) {
            case PRODUCT_VIDEO_PROVIDER.YOUTUBE:
                return `https://www.youtube.com/embed/${VideoUtils.getYoutubeId(url)}`;
            case PRODUCT_VIDEO_PROVIDER.VIMEO:
                return `https://player.vimeo.com/${VideoUtils.getVimeoId(url)}`;
            case PRODUCT_VIDEO_PROVIDER.DIRECT_LINK:
                return url;
            default:
                console.error('The existing link is not correct');
                return undefined;
        }
    }
}
VideoUtils.generatePoster = (src, frameTimeInMilliseconds = 0.0) => {
    return new Promise((resolve, reject) => {
        const canvas = document.createElement('canvas');
        const video = document.createElement('video');
        const context = canvas.getContext('2d');
        const maxAllowedRes = 2048;
        video.setAttribute('crossorigin', 'anonymous');
        video.setAttribute('preload', 'auto');
        video.setAttribute('src', src);
        if (!context) {
            return reject(`can't generate poster for ${video.src}`);
        }
        video.currentTime = frameTimeInMilliseconds;
        if (video.readyState === 0) {
            video.load();
        }
        video.addEventListener('loadedmetadata', () => {
            const maxResolution = Math.min(maxAllowedRes, Math.max(video.videoWidth, video.videoHeight));
            const resolutionScaling = Math.min(maxResolution / video.videoWidth, maxResolution / video.videoHeight);
            canvas.width = video.videoWidth * resolutionScaling;
            canvas.height = video.videoHeight * resolutionScaling;
        });
        video.addEventListener('loadeddata', () => {
            context.drawImage(video, 0, 0, canvas.width, canvas.height);
            canvas.toBlob(blob => {
                const url = URL.createObjectURL(blob);
                resolve(url);
            }, 'image/webp', 0.6);
        });
        video.addEventListener('error', () => reject(`Error loading ${video.src}`));
    });
};
