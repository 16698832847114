import { URLS } from './api/urls';
import { sphereEventHandler } from './custom_event_utils';
import { SPHERE_EVENT_NAMES } from './event-names';
import { CookiesManagement } from './cookies_management';
function toggleVisibility(element, isVisible) {
    if (isVisible) {
        element.classList.remove('is-hidden');
    }
    else {
        element.classList.add('is-hidden');
    }
}
function updateButtonImage(button, oldImg, newImg) {
    button.removeChild(oldImg);
    button.appendChild(newImg);
}
function loadAudio(audioElement, src) {
    audioElement.src = src;
}
const interruptEvents = [
    SPHERE_EVENT_NAMES.VIDEO.PLAY_WITH_AUDIO,
    SPHERE_EVENT_NAMES.OVERLAY.SHOW_CONTENT,
    SPHERE_EVENT_NAMES.CART.OPEN,
    SPHERE_EVENT_NAMES.ACCOUNT.OPEN
];
const resumeEvents = [
    SPHERE_EVENT_NAMES.VIDEO.STOP_WITH_AUDIO,
    SPHERE_EVENT_NAMES.OVERLAY.CLOSE_CONTENT,
    SPHERE_EVENT_NAMES.CART.CLOSE,
    SPHERE_EVENT_NAMES.ACCOUNT.CLOSE
];
export class SphereAudio {
    constructor(planogram) {
        this.isEnvironmentalAudioPlaying = false;
        this.isActionAudioPlaying = false;
        this.isEnvironmentalAudioMuted = true;
        this.interruptionCount = 0;
        this.handleInterrupt = () => {
            this.interruptionCount++;
            if (this.isActionAudioPlaying)
                this.stopActionAudio();
            this.environmentalAudio.muted = true;
        };
        this.handleResume = () => {
            this.interruptionCount--;
            if (this.interruptionCount <= 0) {
                this.interruptionCount = 0;
                this.environmentalAudio.muted = this.isEnvironmentalAudioMuted;
                if (!this.isEnvironmentalAudioPlaying) {
                    this.environmentalAudio.play().then(() => (this.isEnvironmentalAudioPlaying = true));
                }
            }
        };
        this.disposed = false;
        this.initButtons(planogram);
        this.initImages(planogram);
        this.initAudio(planogram);
    }
    playActionAudio(src) {
        loadAudio(this.actionAudio, src);
        this.actionAudio.muted = false;
        this.environmentalAudio.muted = true;
        this.actionAudio.play().then(() => {
            this.isActionAudioPlaying = true;
        });
        this.showStopButton();
    }
    mute() {
        if (!this.isEnvironmentalAudioMuted && this.environmentalAudioSrc) {
            this.isEnvironmentalAudioMuted = true;
            this.updateAudioState();
        }
    }
    unmute() {
        if (this.isEnvironmentalAudioMuted && this.environmentalAudioSrc) {
            this.isEnvironmentalAudioMuted = false;
            this.updateAudioState();
        }
    }
    toggleMute() {
        this.isEnvironmentalAudioMuted = !this.isEnvironmentalAudioMuted;
        this.updateAudioState();
    }
    updateAudioState() {
        this.environmentalAudio.muted =
            this.isEnvironmentalAudioMuted || this.isActionAudioPlaying || this.interruptionCount > 0;
        if (!this.isEnvironmentalAudioPlaying && !this.environmentalAudio.muted) {
            this.environmentalAudio.play().then(() => (this.isEnvironmentalAudioPlaying = true));
        }
        updateButtonImage(this.muteButton, this.isEnvironmentalAudioMuted ? this.unmuteImg : this.muteImg, this.isEnvironmentalAudioMuted ? this.muteImg : this.unmuteImg);
    }
    initAudio(planogram) {
        var _a;
        this.environmentalAudio = document.createElement('audio');
        // safari IOS will pause audio if another media element starts playing
        this.environmentalAudio.onpause = () => (this.isEnvironmentalAudioPlaying = false);
        this.actionAudio = document.createElement('audio');
        this.actionAudio.volume = planogram.volume * 0.01;
        this.environmentalAudio.volume = planogram.volume * 0.01;
        this.actionAudio.onended = () => this.stopActionAudio();
        this.muteRef = this.mute.bind(this);
        this.unmuteRef = this.unmute.bind(this);
        this.playActionAudio = this.playActionAudio.bind(this);
        this.environmentalAudioSrc = (_a = planogram.audioSettings) === null || _a === void 0 ? void 0 : _a.url;
        interruptEvents.forEach(event => {
            sphereEventHandler.listen(event, this.handleInterrupt);
        });
        resumeEvents.forEach(event => {
            sphereEventHandler.listen(event, this.handleResume);
        });
        sphereEventHandler.listen(SPHERE_EVENT_NAMES.COOKIES.ALLOW_AUDIO, this.unmuteRef);
        sphereEventHandler.listen(SPHERE_EVENT_NAMES.COOKIES.DISABLE_AUDIO, this.muteRef);
        sphereEventHandler.listen(SPHERE_EVENT_NAMES.AUDIO.PLAY_ACTION, ({ url }) => this.playActionAudio(url));
        if (this.environmentalAudioSrc) {
            this.muteButton.appendChild(this.muteImg);
            this.showMuteButton();
            loadAudio(this.environmentalAudio, planogram.audioSettings.url);
            this.environmentalAudio.muted = true;
            this.environmentalAudio.loop = true;
            this.environmentalAudio.preload = 'auto';
        }
    }
    initButtons(planogram) {
        this.muteButton = document.getElementById('audio-mute-button');
        this.muteButton.onclick = () => {
            CookiesManagement.allowAudio = CookiesManagement.initialAudioValue = !CookiesManagement.allowAudio;
            this.toggleMute();
        };
        this.stopButton = document.getElementById('audio-stop-button');
        this.stopButton.onclick = () => this.stopActionAudio();
        this.muteButton.style.backgroundColor = planogram.audioBackgroundColor;
        this.stopButton.style.backgroundColor = planogram.audioBackgroundColor;
    }
    initImages(planogram) {
        const stopImg = planogram.createImage('stop', URLS.AUDIO_STOP_ICON);
        this.stopButton.appendChild(stopImg);
        this.muteImg = planogram.createImage('mute', URLS.AUDIO_MUTE_ICON);
        this.unmuteImg = planogram.createImage('unmute', URLS.AUDIO_UNMUTE_ICON);
    }
    stopActionAudio() {
        this.actionAudio.src = '';
        this.isActionAudioPlaying = false;
        this.environmentalAudio.muted = this.isEnvironmentalAudioMuted;
        this.hideStopButton();
    }
    showMuteButton() {
        toggleVisibility(this.muteButton, true);
    }
    hideMuteButton() {
        toggleVisibility(this.muteButton, false);
    }
    showStopButton() {
        toggleVisibility(this.stopButton, true);
    }
    hideStopButton() {
        toggleVisibility(this.stopButton, false);
    }
    dispose() {
        var _a;
        if (this.disposed)
            return;
        this.disposed = true;
        this.environmentalAudio.pause();
        this.environmentalAudio = null;
        this.actionAudio.pause();
        this.actionAudio = null;
        this.muteImg.remove();
        this.unmuteImg.remove();
        this.muteButton.onclick = null;
        this.stopButton.onclick = null;
        (_a = this.stopButton.firstChild) === null || _a === void 0 ? void 0 : _a.remove();
        this.hideStopButton();
        this.hideMuteButton();
        interruptEvents.forEach(event => {
            sphereEventHandler.off(event, this.handleInterrupt);
        });
        resumeEvents.forEach(event => {
            sphereEventHandler.off(event, this.handleResume);
        });
        sphereEventHandler.off(SPHERE_EVENT_NAMES.COOKIES.ALLOW_AUDIO, this.unmuteRef);
        sphereEventHandler.off(SPHERE_EVENT_NAMES.COOKIES.DISABLE_AUDIO, this.muteRef);
        sphereEventHandler.off(SPHERE_EVENT_NAMES.AUDIO.PLAY_ACTION, this.playActionAudio);
    }
}
