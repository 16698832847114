export default class MultiSet {
    constructor() {
        this.data = new Map();
    }
    add(v) {
        var _a;
        this.data.set(v, ((_a = this.data.get(v)) !== null && _a !== void 0 ? _a : 0) + 1);
    }
    has(v) {
        return this.data.has(v);
    }
    remove(v) {
        var _a;
        const current = ((_a = this.data.get(v)) !== null && _a !== void 0 ? _a : 0) - 1;
        if (current <= 0)
            this.data.delete(v);
        else
            this.data.set(v, current);
    }
    get size() {
        return this.data.size;
    }
}
