import { ClampToEdgeWrapping, DataTexture, LinearFilter, RGBAFormat, UVMapping, UnsignedByteType, Vector2, } from 'three';
import { assertStatement, assertTrue, debugCommand } from 'shared/utils/debug';
import { TILE_SIZE } from './parameters';
export default class PhysicalTextures {
    get freeSlots() {
        return this._freeSlots;
    }
    constructor(textureCount, textureSize, renderer, frameUploadLimit) {
        this.textureCount = textureCount;
        this.textureSize = textureSize;
        this.renderer = renderer;
        this.frameUploadLimit = frameUploadLimit;
        // TODO: use DataArrayTexture?
        this.textures = []; // indices are texture ids
        this.occupied = []; // indices are slot ids, values are amount of times the slot is used
        this.slotCache = new Map(); // maps textures to their slot ids
        this.textureCache = []; // maps slot ids to textures, used only to clean slotCache correctly
        this.uploadQueue = [];
        const side = this.textureTileSide();
        this.totalSlots = side * side * textureCount;
        this._freeSlots = this.totalSlots;
        for (let i = 0; i < this.totalSlots; i++)
            this.occupied.push(0);
        const data = new Uint8Array(4 * textureSize * textureSize).fill(0);
        for (let i = 0; i < textureCount; i++) {
            const texture = new DataTexture(data, textureSize, textureSize, RGBAFormat, UnsignedByteType, UVMapping, ClampToEdgeWrapping, ClampToEdgeWrapping, LinearFilter, LinearFilter);
            texture.needsUpdate = true;
            this.textures.push(texture);
        }
        debugCommand('freeSlots', () => this.freeSlots);
    }
    storeTile(texture) {
        if (this._freeSlots === 0)
            throw new Error('Physical textures are full');
        const usedSlot = this.findUsedSlot(texture);
        if (usedSlot !== undefined) {
            if (this.occupied[usedSlot] === 0)
                this._freeSlots--;
            this.occupied[usedSlot]++;
            return {
                slot: usedSlot,
                cost: 0,
                loaded: Promise.resolve(),
            };
        }
        const slot = this.findFreeSlot();
        this.occupied[slot]++;
        this._freeSlots--;
        this.slotCache.set(texture, slot);
        this.textureCache[slot] = texture;
        return {
            slot,
            cost: 1,
            loaded: new Promise(resolve => {
                this.uploadQueue.push({ texture, slot, resolve });
            }),
        };
    }
    freeSlot(slot) {
        this.occupied[slot]--;
        assertTrue(this.occupied[slot] >= 0, 'Freeing a free slot');
        if (this.occupied[slot] === 0)
            this._freeSlots++;
        const t = this.textureCache[slot];
        if (t !== undefined)
            this.slotCache.delete(t);
    }
    slotUseCount(slot) {
        return this.occupied[slot];
    }
    update() {
        const noWork = this.uploadQueue.length === 0;
        this.uploadQueue.splice(0, this.frameUploadLimit).forEach(({ texture, slot, resolve }) => {
            const physicalTexture = this.textures[this.tileSlotTexture(slot)];
            const position = this.tileSlotCoordinate(slot);
            // in curator, atlas tiles are smaller than TILE_SIZE, and UV offsets count from the bottom of the tile
            const textureHeight = texture.image.naturalHeight;
            assertStatement(() => Number.isInteger(Math.log2(textureHeight)), 'Invalid texture height');
            position.y += TILE_SIZE - textureHeight;
            this.renderer.copyTextureToTexture(position, texture, physicalTexture);
            resolve();
        });
        return noWork;
    }
    findUsedSlot(texture) {
        return this.slotCache.get(texture);
    }
    findFreeSlot() {
        const free = this.occupied.findIndex(v => v === 0);
        if (free === -1)
            throw new Error('Physical textures are full');
        return free;
    }
    textureTileSide() {
        return Math.floor(this.textureSize / TILE_SIZE);
    }
    tileSlotTexture(slot) {
        const side = this.textureTileSide();
        return Math.floor(slot / (side * side));
    }
    tileSlotCoordinate(slot) {
        const side = this.textureTileSide();
        const textureSlot = slot % (side * side);
        const slotPosition = new Vector2(textureSlot % side, Math.floor(textureSlot / side));
        return slotPosition.multiplyScalar(TILE_SIZE);
    }
    dispose() {
        this.textures.forEach(it => it.dispose());
        this.uploadQueue = [];
    }
}
