import { MathUtils, Vector2 } from 'three';
export const HEIGHT = 2500;
export const WIDTH = 10000;
const planogramConfig = {
    HEIGHT,
    WIDTH,
    EQUATOR_RADIUS: WIDTH / (Math.PI * 2.0),
    SHELL_RADIUS: (WIDTH / (Math.PI * 2.0)) * 3.0,
    ALPHA: MathUtils.degToRad(10.0),
    VIEWABLE_LIMIT_TOP: 3350,
    VIEWABLE_LIMIT_BOTTOM: 1650,
    PAGES_WIDE: 256,
    PAGES_HIGH: 64,
    MAX_OBJECT_SIZE: new Vector2(WIDTH / 2, HEIGHT),
};
export default planogramConfig;
