export class ShoppingCartUtils {
    static getStoredShoppingCart(checkoutId) {
        return window.localStorage.getItem(checkoutId);
    }
    static deleteStoredShoppingCart(checkoutId) {
        window.localStorage.removeItem(checkoutId);
    }
    static isMatchingVariant(selectedOptions, variant) {
        return selectedOptions.option1 === variant.option1 &&
            selectedOptions.option2 === variant.option2 &&
            selectedOptions.option3 === variant.option3;
    }
}
