import { URLS } from '../api/urls';
import { updateUIElementsVisibility } from '../utils/ui_elements_utils';
import { Metrics } from '../metrics';
import { MATOMO_EVENT_NAMES } from '../metric-events';
import { AppState } from '../shared/app.state';
export class WindowOrientationPopup {
    constructor(closeCallback) {
        this.closeCallback = closeCallback;
        this.container = document.querySelector('#window-orientation');
        if (!this.container) {
            console.error('Can\'t find container for popup - "#window-orientation"');
            return;
        }
        updateUIElementsVisibility(false, 'is-hidden-by-orientation-popup');
        this.initTemplateContent();
        this.handleClicks();
        this.container.classList.remove('is-hidden');
    }
    initTemplateContent() {
        this.container.innerHTML = `
    <div class="popup-container app-window-orientation">
      <div class="overlay-button overlay-button-close will-close">
        <span></span>
      </div>
      <div class="main">
        <img alt="Rotate device to landscape mode icon" src="${URLS.PORTRAIT_VIEW_ROTATE_ICON}">
      </div>
    </div>
  `;
    }
    handleClicks() {
        const closeButton = document.querySelector('.app-window-orientation .overlay-button-close');
        ['touchstart', 'click'].forEach(trigger => {
            this.container.addEventListener(trigger, e => {
                e.stopPropagation();
                e.preventDefault();
            });
            if (closeButton) {
                closeButton.addEventListener(trigger, e => {
                    e.stopPropagation();
                    e.preventDefault();
                    Metrics.storeTheEvent(AppState.planogramName, 'click', `${MATOMO_EVENT_NAMES.CLICK_MOBILE_ORIENTATION_POPUP_CLOSE_BUTTON}`);
                    this.closeOverlay(e);
                });
            }
            else {
                console.error('element not found - ".overlay-button-close"');
            }
        });
    }
    closeOverlay(e) {
        e === null || e === void 0 ? void 0 : e.stopPropagation();
        document.querySelector('#main-container').removeChild(this.container);
        updateUIElementsVisibility(true, 'is-hidden-by-orientation-popup');
        // tslint:disable-next-line:no-unused-expression
        this.closeCallback && this.closeCallback();
    }
}
