import { InputFieldView } from '../../components/input-field/input-field-component.view';
export const InputFieldsTriwizardShirtComponentView = (product) => {
    const field = InputFieldView({
        label: product.input_fields[0].identifier,
        helper: 'Max letters 9',
        maxlength: 9
    });
    return `
    <div class="input-field-wrapper">${field}</div>
  `;
};
