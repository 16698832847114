import { L10nUtils } from '../../utils/l10n_utils';
import { button } from '../components/button';
import { input } from '../components/input';
export const resetPassView = () => {
    return `
    <div class="account-view" id="account-reset-pass">
        <div class="account-overlay-title">
          <span>${L10nUtils.l10n('account.panel.reset-pass.title')}</span>
        </div>
        <form id="reset-pass-form" class="account-overlay-form">
          <div class="account-overlay-fields-with-error">
            <div class="account-overlay-fields">
              ${input({
        placeholder: L10nUtils.l10n('account.panel.input.email.placeholder'),
        name: 'email',
        type: 'email',
        required: true
    })}
            </div>
            <div class="account-overlay-error-container">
              <div class="account-overlay-error is-hidden"></div>
            </div>
          </div>
          ${button({
        text: L10nUtils.l10n('account.panel.reset-pass.submit-button.text'),
        type: 'submit'
    })}
        </form>
    </div>
  `;
};
