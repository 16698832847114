import { InputEventUtils } from '../../utils/input_event_utils';
import { CurrencyUtils } from '../../utils/currency_utils';
export class ShoppingCartItemBaseComponent {
    constructor(shoppingCartItems, product, currencyService, shoppingCartService) {
        this.shoppingCartItems = shoppingCartItems;
        this.product = product;
        this.currencyService = currencyService;
        this.shoppingCartService = shoppingCartService;
        this.mainContainer = document.createElement('div');
        InputEventUtils.addSelectEvents(this.mainContainer, this.handleClicks.bind(this));
    }
    handleClicks(e) {
        if (this.shoppingCartItems.processing) {
            return;
        }
        e.stopPropagation();
        const quantityEl = e.target.closest('.app-shopping-cart-item-quantity-button');
        const removeEl = e.target.closest('.app-shopping-cart-item-delete');
        const productTitle = e.target.closest('.app-shopping-cart-item-title');
        if (productTitle) {
            this.shoppingCartItems.navigateToProduct(this.product);
        }
        if (quantityEl) {
            this.changeQuantity(quantityEl);
        }
        if (removeEl) {
            this.deleteItem();
        }
    }
    getProductPrice() {
        const { selectedCurrencyCode, currenciesList } = this.currencyService;
        const totalPrice = (parseFloat(this.product.price) * this.product.quantity).toFixed(2);
        return CurrencyUtils.getPriceWithSymbolOrCode(totalPrice, selectedCurrencyCode, currenciesList);
    }
}
