import { Group, Mesh, MeshBasicMaterial, TextureLoader, Vector2 } from 'three';
import Meta from './video_controls.json';
import { SphereGeometry } from '../geometries/sphere_geometry';
import { Planogram } from '../planogram';
import { URLS } from '../api/urls';
import { disposeMesh } from '../utils/disposeThree';
import { sphereEventHandler } from '../custom_event_utils';
import { SPHERE_EVENT_NAMES } from '../event-names';
const textureLoader = new TextureLoader();
const PLAY_SIZE = 0.2;
const SHARE_SIZE = 0.15;
function createControlElementMesh(video, material, frame, textureSize, relativePosition, relativeSize) {
    const maxSize = video.getSize().multiply(relativeSize);
    const aspect = frame.w / frame.h;
    const size = new Vector2(Math.min(maxSize.x, maxSize.y * aspect), Math.min(maxSize.y, maxSize.x / aspect));
    // (videoSize - size) * relativePosition + videoPosition
    const position = video.getSize().sub(size).multiply(relativePosition).add(video.getPosition());
    const azimuthLength = SphereGeometry.calcAzimuthLengthRadians(size.width, video.planogram.width);
    const azimuthStartRadians = SphereGeometry.calcAzimuthStartRadians(position.x, size.width, video.planogram.width);
    const cols = Math.ceil(azimuthLength / ((2 * Math.PI) / Planogram.COLUMN_COUNT));
    const rows = Math.ceil(size.height / (video.planogram.height / Planogram.ROW_COUNT));
    const geometry = new SphereGeometry(Planogram.ALPHA, video.planogram.largeRadius, video.planogram.fixedRadius, cols, rows, azimuthStartRadians, azimuthLength, video.planogram.height, position.y, size.height, true);
    // Get the control mesh size and positions
    const uvs = geometry.getAttribute('uv');
    // Resize and offset the default 0-1 uvs to the frame coordinates and size
    for (let i = 0; i < uvs.count; i++) {
        uvs.setXY(i, (frame.x + uvs.getX(i) * frame.w) / textureSize.w, 1 - (frame.y + (1 - uvs.getY(i)) * frame.h) / textureSize.h);
    }
    uvs.needsUpdate = true;
    const controlElementMesh = new Mesh(geometry, material);
    controlElementMesh.layers.enable(2);
    controlElementMesh.renderOrder = video.renderOrder + 0.2;
    return controlElementMesh;
}
class ShareButton {
    constructor(video, group, material) {
        const textureSize = Meta.size;
        this.video = video;
        this.hovered = 0;
        this.shareMesh = createControlElementMesh(this.video, material, Meta.share, textureSize, new Vector2(1, 1).multiplyScalar(1 - SHARE_SIZE * 0.2), new Vector2(SHARE_SIZE, SHARE_SIZE));
        group.add(this.shareMesh);
        this.shareMesh.userData.component = this;
    }
    onClick() {
        if (!this.shareMesh.visible)
            return;
        sphereEventHandler.emit(SPHERE_EVENT_NAMES.VIDEO.SHARE, this.video);
    }
    update() {
        this.shareMesh.visible = !this.video.isPlaying || this.hovered > 0;
    }
    onHoverEnter() {
        this.hovered++;
        this.update();
    }
    onHoverLeave() {
        this.hovered--;
        this.update();
    }
    dispose() {
        disposeMesh(this.shareMesh);
        this.video = undefined;
    }
}
class PlayPauseButton {
    constructor(video, group, material) {
        const textureSize = Meta.size;
        this.video = video;
        this.hovered = 0;
        const origin = new Vector2(0.5, 0.5);
        const size = new Vector2(1, 1).multiplyScalar(PLAY_SIZE);
        this.playMesh = createControlElementMesh(this.video, material, Meta.play, textureSize, origin, size);
        group.add(this.playMesh);
        this.pauseMesh = createControlElementMesh(this.video, material, Meta.pause, textureSize, origin, size);
        group.add(this.pauseMesh);
        this.playMesh.userData.component = this;
        this.pauseMesh.userData.component = this;
    }
    onClick() {
        if (this.video.isPlaying) {
            this.video.pause(true);
        }
        else {
            this.video.play();
        }
    }
    update() {
        this.playMesh.visible = !this.video.isPlaying;
        this.pauseMesh.visible = this.hovered > 0 && this.video.isPlaying;
    }
    onHoverEnter() {
        this.hovered++;
        this.update();
    }
    onHoverLeave() {
        this.hovered--;
        this.update();
    }
    dispose() {
        disposeMesh(this.pauseMesh);
        disposeMesh(this.playMesh);
        this.video = undefined;
    }
}
export class VideoControlsComponent {
    constructor(video) {
        this.group = new Group();
        this.disposed = false;
        textureLoader.load(URLS.VIDEO_CONTROLS_ICONS, texture => {
            if (this.disposed) {
                texture.dispose();
                return;
            }
            this.texture = texture;
            const material = new MeshBasicMaterial({
                depthTest: false,
                transparent: true,
                opacity: 1.0,
                alphaTest: 0.5,
                map: texture
            });
            this.playPauseButton = new PlayPauseButton(video, this.group, material);
            if (video.share)
                this.shareButton = new ShareButton(video, this.group, material);
            this.update();
        });
    }
    update() {
        var _a, _b;
        (_a = this.playPauseButton) === null || _a === void 0 ? void 0 : _a.update();
        (_b = this.shareButton) === null || _b === void 0 ? void 0 : _b.update();
    }
    onClick() {
        var _a;
        (_a = this.playPauseButton) === null || _a === void 0 ? void 0 : _a.onClick(); // Clicking on the video should play/pause
    }
    onHoverEnter() {
        var _a, _b;
        (_a = this.shareButton) === null || _a === void 0 ? void 0 : _a.onHoverEnter();
        (_b = this.playPauseButton) === null || _b === void 0 ? void 0 : _b.onHoverEnter();
    }
    onHoverLeave() {
        var _a, _b;
        (_a = this.shareButton) === null || _a === void 0 ? void 0 : _a.onHoverLeave();
        (_b = this.playPauseButton) === null || _b === void 0 ? void 0 : _b.onHoverLeave();
    }
    dispose() {
        var _a, _b, _c;
        this.disposed = true;
        (_a = this.playPauseButton) === null || _a === void 0 ? void 0 : _a.dispose();
        (_b = this.shareButton) === null || _b === void 0 ? void 0 : _b.dispose();
        (_c = this.texture) === null || _c === void 0 ? void 0 : _c.dispose();
        this.group.clear();
        this.group.removeFromParent();
    }
}
