import { showLoadingScreen } from '../../utils/loading_screen_utils';
const loadingStage = (name, weight) => ({
    name,
    weight
});
export const LOADING_STAGES = {
    TILES_FOR_LOD: loadingStage('TILES_FOR_LOD', 10),
    ITEM_MESHES: loadingStage('ITEM_MESHES', 5),
    INIT_SPHERE: loadingStage('INIT_SPHERE', 1)
};
class LoadingProgress {
    constructor() {
        this.stages = {};
        this.reset();
        this.totalWeight = Object.values(LOADING_STAGES).reduce((totalWeight, stage) => totalWeight + stage.weight, 0);
    }
    addStage(stage) {
        this.stages[stage.name] = {
            fullProgress: 1,
            completedProgress: 0
        };
    }
    progressStage(stage, fullProgress) {
        const name = stage.name;
        this.stages[name].completedProgress++;
        this.stages[name].fullProgress = fullProgress;
        if (this.stages[name].completedProgress > this.stages[name].fullProgress) {
            console.error(`Progressing stage ${name} past full progress`);
        }
        this.updateLoadingScreen();
    }
    getTotalProgress() {
        const stagesArray = Object.keys(this.stages);
        if (stagesArray.length === 0) {
            return 100;
        }
        const progress = stagesArray.reduce((totalProgress, stageName) => {
            const { fullProgress, completedProgress } = this.stages[stageName];
            const weight = LOADING_STAGES[stageName].weight;
            return totalProgress + (completedProgress / fullProgress) * (weight / this.totalWeight);
        }, 0);
        return Math.round(progress * 100);
    }
    updateLoadingScreen() {
        const totalProgress = this.getTotalProgress();
        if (totalProgress < 100) {
            showLoadingScreen(true);
        }
        else {
            if (this.loadedCallback) {
                this.loadedCallback();
            }
            showLoadingScreen(false);
        }
    }
    reset() {
        this.stages = {};
        Object.values(LOADING_STAGES).forEach(stage => this.addStage(stage));
        if (this.resetCallback) {
            this.resetCallback();
        }
    }
    onLoad(callback) {
        this.loadedCallback = callback;
    }
    onReset(callback) {
        this.resetCallback = callback;
    }
}
const loadingProgress = new LoadingProgress();
export default loadingProgress;
