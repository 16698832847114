import { L10nUtils } from '../utils/l10n_utils';
export const CookiesTableView = (data) => {
    const title = data === null || data === void 0 ? void 0 : data.title;
    return `
    <div class="cookies-table-overlay overlay-container is-flex is-vertical">
      <div class="cookies-table-overlay-controls is-flex">
        <div class="overlay-button overlay-button-back">
          <span></span>
        </div>
        <div class="overlay-button overlay-button-close">
          <span></span>
        </div>
      </div>
      <div class="cookies-table-header">${title}</div>
      <div class="cookies-table is-flex has-flex-wrap text-wrapper">
        <div class="cookies-wrapper allow-scroll">
          <div class="cookies-table-titles is-flex">
              <div class="cookie-label cookie-name-column">${L10nUtils.l10n('cookies-overlay.table-header.name-column')}</div>
              <div class="cookie-label cookie-data-collected-column">${L10nUtils.l10n('cookies-overlay.table-header.data-collected-column')}</div>
              <div class="cookie-label cookie-type-column">${L10nUtils.l10n('cookies-overlay.table-header.type-column')}</div>
              <div class="cookie-label cookie-description-column">${L10nUtils.l10n('cookies-overlay.table-header.description-column')}</div>
              <div class="cookie-label cookie-expiry-column">${L10nUtils.l10n('cookies-overlay.table-header.expiry-column')}</div>
          </div>
        </div>
        <div class="text-fade"></div>
      </div>
    </div>
  `;
};
