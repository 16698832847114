import { L10nUtils } from '../../utils/l10n_utils';
import { button } from '../components/button';
import { input } from '../components/input';
export const signUpView = () => {
    return `
    <div class="account-view" id="account-sign-up">
      <div class="account-overlay-title">
        <span>${L10nUtils.l10n('account.panel.sign-up.title')}</span>
      </div>
      <form id="sign-up-form" class="account-overlay-form">
        <div class="account-overlay-fields-with-error">
          <div class="account-overlay-fields">
            ${input({
        placeholder: L10nUtils.l10n('account.panel.input.first-name.placeholder'),
        name: 'first-name'
    })}
            ${input({
        placeholder: L10nUtils.l10n('account.panel.input.last-name.placeholder'),
        name: 'last-name'
    })}
            ${input({
        placeholder: L10nUtils.l10n('account.panel.input.email.placeholder'),
        name: 'email',
        type: 'email',
        required: true
    })}
            ${input({
        placeholder: L10nUtils.l10n('account.panel.input.password.placeholder'),
        name: 'password',
        type: 'password',
        required: true
    })} 
          </div>
          <div class="account-overlay-error-container">
            <div class="account-overlay-error is-hidden"></div>
          </div>
        </div>
        ${button({
        text: L10nUtils.l10n('account.panel.sign-up.submit-button.text'),
        type: 'submit'
    })}
      </form>
      <div class="account-overlay-sign-up-or">${L10nUtils.l10n('account.panel.sign-up.or')}</div>
      ${button({
        text: L10nUtils.l10n('account.panel.sign-up.sign-in-button.text'),
        className: 'link-to-sign-in',
        secondary: true
    })}
    </div>
  `;
};
