// @ts-ignore
import { OverlayScrollbars } from 'overlayscrollbars';
import { WebUtils } from '../utils/web_utils';
import { overlayEventsHandler } from '../custom_event_utils';
import { SPHERE_EVENT_NAMES } from '../event-names';
function getScrollData(scrollInstance) {
    const { viewport } = scrollInstance.elements();
    return {
        position: {
            y: viewport.scrollTop
        },
        max: {
            y: viewport.scrollHeight - viewport.clientHeight
        }
    };
}
export class OverlayScrollbar {
    constructor(selector, options = {}) {
        this.selector = selector;
        this.initScroll(options);
    }
    initScroll(options) {
        if (!this.selector) {
            return;
        }
        // Add a custom scroll bar to the scroll areas
        document.querySelectorAll(this.selector).forEach((wrapper) => {
            var _a;
            let scrollInstance;
            scrollInstance = OverlayScrollbars(wrapper, {
                paddingAbsolute: true,
                overflow: {
                    x: 'hidden'
                },
                scrollbars: {
                    autoHide: (_a = options.autoHide) !== null && _a !== void 0 ? _a : 'scroll'
                }
            }, {
                scroll(osInstance) {
                    const scrollData = getScrollData(osInstance);
                    WebUtils.handleTextFadeOnScroll(wrapper, scrollData);
                    overlayEventsHandler.emit(SPHERE_EVENT_NAMES.OVERLAY.OVERLAY_USER_SCROLL);
                },
                updated(osInstance) {
                    const scrollData = getScrollData(osInstance);
                    WebUtils.handleTextFadeOnScroll(wrapper, scrollData);
                }
            });
            WebUtils.handleTextFadeOnScroll(wrapper, getScrollData(scrollInstance));
        });
    }
}
