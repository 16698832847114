import '../../../../../../styles/overlay/harry-potter-shopify/components/checkbox-field/index.scss';
export const CheckboxFieldView = (label, checkboxId) => {
    return `
    <div class="checkbox-field-component">
      <label class="field checkbox small" for="${checkboxId}">
        <div class="checkbox-wrapper">
          <input type="checkbox" id="${checkboxId}">
          <span class="box"></span>
        </div>
        <span class="checkbox-label">${label}</span>
      </label>
    </div>
  `;
};
