var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __asyncValues = (this && this.__asyncValues) || function (o) {
    if (!Symbol.asyncIterator) throw new TypeError("Symbol.asyncIterator is not defined.");
    var m = o[Symbol.asyncIterator], i;
    return m ? m.call(o) : (o = typeof __values === "function" ? __values(o) : o[Symbol.iterator](), i = {}, verb("next"), verb("throw"), verb("return"), i[Symbol.asyncIterator] = function () { return this; }, i);
    function verb(n) { i[n] = o[n] && function (v) { return new Promise(function (resolve, reject) { v = o[n](v), settle(resolve, reject, v.done, v.value); }); }; }
    function settle(resolve, reject, d, v) { Promise.resolve(v).then(function(v) { resolve({ value: v, done: d }); }, reject); }
};
import Splide from '@splidejs/splide';
import { Video } from '@splidejs/splide-extension-video';
import { debounce } from 'lodash';
import { InputEventUtils } from '../utils/input_event_utils';
import { VideoUtils } from '../utils/video_utils';
import { BrowserUtils } from '../utils/browser_utils';
import { CDN_RESOURCES, UrlUtils } from '../api/urls';
import { isTouchSupported } from '../utils/platform';
import { AssetTypes } from 'shared/interfaces/planogram';
import { overlayEventsHandler } from '../custom_event_utils';
import { SPHERE_EVENT_NAMES } from '../event-names';
import { FullScreenButton } from '../components/overlay-fullscreen-mode';
import { Overlay } from '../overlay';
const DOTS_PER_PAGE = 6;
const COUNTER_VISIBILITY_DUR = 3000;
export class ContentCarouselOverlay {
    constructor(container, item, options, closeCallback) {
        var _a;
        this.container = container;
        this.options = options;
        this.closeCallback = closeCallback;
        this.hideCounter = debounce(() => {
            this.counterContainer.classList.add('fade-out');
        }, COUNTER_VISIBILITY_DUR).bind(this);
        this.closeOverlay = this.closeOverlay.bind(this);
        this.updateCarousel = this.updateCarousel.bind(this);
        this.carouselSlides = item.action.data.items;
        this.container.innerHTML = `
      <div class="iframe-overlay carousel-overlay overlay-container ${isTouchSupported ? 'with-touch' : ''}">
        <div class="overlay-button overlay-button-close" role="button" tabindex="0">
          <span></span>
        </div>
        <div class="app-content-carousel-container" aria-live="polite" >
          <div id="app-content-carousel" class="splide">
            <div class="splide__track">
              <div class="carousel-counter"></div>
              <ul class="splide__list"></ul>
              <div class="carousel-fullscreen-container"></div>
            </div>
            <div class="splide__arrows">
              <button class="splide__arrow splide__arrow--prev">
                <svg width="71" height="72" viewBox="0 0 71 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g opacity="0.7" filter="url(#filter0_bd_2258_30874)">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M35.2891 61.9946C49.7497 61.9946 61.4724 50.2719 61.4724 35.8113C61.4724 21.3506 49.7497 9.62793 35.2891 9.62793C20.8284 9.62793 9.10571 21.3506 9.10571 35.8113C9.10571 50.2719 20.8284 61.9946 35.2891 61.9946ZM40.467 26.3314C41.1061 25.6924 41.1061 24.6562 40.467 24.0171C39.828 23.3781 38.7918 23.3781 38.1527 24.0171L26.3586 35.8113L38.1527 47.6054C38.7918 48.2445 39.828 48.2445 40.467 47.6054C41.1061 46.9663 41.1061 45.9302 40.467 45.2911L30.9872 35.8113L40.467 26.3314Z" fill="white"/>
                  </g>
                  <defs>
                    <filter id="filter0_bd_2258_30874" x="0.0142751" y="0.536492" width="70.5496" height="70.5496" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                      <feGaussianBlur in="BackgroundImageFix" stdDeviation="2.18194"/>
                      <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2258_30874"/>
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                      <feMorphology radius="1.09097" operator="dilate" in="SourceAlpha" result="effect2_dropShadow_2258_30874"/>
                      <feOffset/>
                      <feGaussianBlur stdDeviation="4.00023"/>
                      <feColorMatrix type="matrix" values="0 0 0 0 0.123507 0 0 0 0 0.123507 0 0 0 0 0.13812 0 0 0 0.16 0"/>
                      <feBlend mode="normal" in2="effect1_backgroundBlur_2258_30874" result="effect2_dropShadow_2258_30874"/>
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_2258_30874" result="shape"/>
                    </filter>
                  </defs>
                </svg>
              </button>
              <button class="splide__arrow splide__arrow--next">
                <svg width="71" height="72" viewBox="0 0 71 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g opacity="0.7" filter="url(#filter0_bd_2258_30874)">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M35.2891 61.9946C49.7497 61.9946 61.4724 50.2719 61.4724 35.8113C61.4724 21.3506 49.7497 9.62793 35.2891 9.62793C20.8284 9.62793 9.10571 21.3506 9.10571 35.8113C9.10571 50.2719 20.8284 61.9946 35.2891 61.9946ZM40.467 26.3314C41.1061 25.6924 41.1061 24.6562 40.467 24.0171C39.828 23.3781 38.7918 23.3781 38.1527 24.0171L26.3586 35.8113L38.1527 47.6054C38.7918 48.2445 39.828 48.2445 40.467 47.6054C41.1061 46.9663 41.1061 45.9302 40.467 45.2911L30.9872 35.8113L40.467 26.3314Z" fill="white"/>
                  </g>
                  <defs>
                    <filter id="filter0_bd_2258_30874" x="0.0142751" y="0.536492" width="70.5496" height="70.5496" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                      <feGaussianBlur in="BackgroundImageFix" stdDeviation="2.18194"/>
                      <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2258_30874"/>
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                      <feMorphology radius="1.09097" operator="dilate" in="SourceAlpha" result="effect2_dropShadow_2258_30874"/>
                      <feOffset/>
                      <feGaussianBlur stdDeviation="4.00023"/>
                      <feColorMatrix type="matrix" values="0 0 0 0 0.123507 0 0 0 0 0.123507 0 0 0 0 0.13812 0 0 0 0.16 0"/>
                      <feBlend mode="normal" in2="effect1_backgroundBlur_2258_30874" result="effect2_dropShadow_2258_30874"/>
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_2258_30874" result="shape"/>
                    </filter>
                  </defs>
                </svg>
              </button>
            </div>
            <div class="splide__controls">
              <div class="splide__pagination-container"></div>
            </div>
          </div>
        </div>
        <div id="app-content-carousel-title"></div>
      </div>
      <div class="app-overlay-backdrop"></div>
    `;
        this.initSlider();
        const closeButton = this.container.querySelector('.overlay-container .overlay-button-close');
        this.controlsContainer = this.container.querySelector('.splide__controls');
        if ((_a = this.options) === null || _a === void 0 ? void 0 : _a.fullscreen) {
            const fullscreenButtonContainer = this.container.querySelector('.carousel-fullscreen-container');
            this.fullscreenButton = new FullScreenButton(fullscreenButtonContainer);
        }
        if (closeButton) {
            InputEventUtils.addSelectEvents(closeButton, this.closeOverlay.bind(this));
        }
        else {
            console.error('element not found - ".overlay-button-close"');
        }
        overlayEventsHandler.listen(SPHERE_EVENT_NAMES.OVERLAY.FULLSCREEN, this.updateCarousel);
        overlayEventsHandler.listen(SPHERE_EVENT_NAMES.OVERLAY.JOURNEY_OVERLAY_CLOSE, this.closeOverlay);
    }
    initSlider() {
        return __awaiter(this, void 0, void 0, function* () {
            var _a, e_1, _b, _c;
            const sliderWrapper = this.container.querySelector('#app-content-carousel .splide__track');
            const slidesContainer = this.container.querySelector('#app-content-carousel .splide__list');
            this.counterContainer = this.container.querySelector('.carousel-counter');
            try {
                for (var _d = true, _e = __asyncValues(this.carouselSlides), _f; _f = yield _e.next(), _a = _f.done, !_a; _d = true) {
                    _c = _f.value;
                    _d = false;
                    const slide = _c;
                    if (slide.assetType === AssetTypes.VIDEO) {
                        slide.thumbnailUrl = yield VideoUtils.generatePoster(slide.url);
                    }
                    else if (slide.assetType === AssetTypes.IMAGE) {
                        slide.thumbnailUrl = UrlUtils.insertValueToUrl(CDN_RESOURCES.PRODUCT_IMAGE, `/pubtool-images/${BrowserUtils.pickImageVariant(slide.thumbnails)}`);
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (!_d && !_a && (_b = _e.return)) yield _b.call(_e);
                }
                finally { if (e_1) throw e_1.error; }
            }
            const imageLoadPromises = this.carouselSlides.map(slide => {
                return new Promise((resolve, reject) => {
                    const liSlide = document.createElement('li');
                    const imgSlide = document.createElement('img');
                    liSlide.classList.add('splide__slide');
                    liSlide.append(imgSlide);
                    imgSlide.onload = () => resolve();
                    imgSlide.onerror = () => reject();
                    if (slide.assetType === AssetTypes.IMAGE) {
                        imgSlide.src = slide.thumbnailUrl;
                    }
                    else if (slide.assetType === AssetTypes.VIDEO) {
                        imgSlide.src = slide.thumbnailUrl;
                        liSlide.setAttribute(`data-splide-html-video`, slide.url);
                    }
                    slidesContainer.append(liSlide);
                });
            });
            try {
                yield Promise.all(imageLoadPromises);
            }
            catch (error) {
                console.error('Error loading images:', error);
            }
            const carouselContainer = this.container.querySelector('.splide');
            this.carouselSplide = new Splide(carouselContainer, this.carouselConfig());
            this.carouselSplide.on('arrows:mounted', () => {
                const arrows = this.container.querySelector('.splide__arrows');
                this.controlsContainer.append(arrows);
            });
            this.carouselSplide.on('pagination:mounted', () => {
                const paginationContainer = this.controlsContainer.querySelector('.splide__pagination-container');
                const pagination = this.container.querySelector('.splide__pagination');
                if (this.carouselSlides.length === 1) {
                    this.controlsContainer.classList.add('is-disabled');
                }
                else if (this.carouselSlides.length > DOTS_PER_PAGE) {
                    this.controlsContainer.classList.add('with-pages');
                }
                paginationContainer.append(pagination);
            });
            this.carouselSplide.on('pagination:updated', (data, prev, curr) => {
                var _a;
                const dots = this.container.querySelectorAll('.splide__pagination li');
                dots.forEach((dot, index) => {
                    index === (curr === null || curr === void 0 ? void 0 : curr.page) ? dot.classList.add('active') : dot.classList.remove('active');
                });
                this.controlsContainer.style.setProperty('max-width', `${Math.min(DOTS_PER_PAGE, data.items.length)}em`);
                const shiftValue = (1 / data.items.length) * 100;
                const transformVal = Math.max(0, ((curr === null || curr === void 0 ? void 0 : curr.page) + 1) * shiftValue - DOTS_PER_PAGE * shiftValue);
                const paginationContainer = this.container.querySelector('.splide__pagination');
                const activeSlideIndex = (_a = curr === null || curr === void 0 ? void 0 : curr.page) !== null && _a !== void 0 ? _a : 0;
                const totalAmount = Math.max(1, data.items.length);
                paginationContainer === null || paginationContainer === void 0 ? void 0 : paginationContainer.setAttribute('style', `transform: translateX(-${transformVal}%)`);
                this.counterContainer.textContent = `${activeSlideIndex + 1} / ${totalAmount}`;
            });
            // fix issue with play/pause
            this.carouselSplide.event.on('video:pause', e => {
                requestAnimationFrame(() => {
                    var _a, _b, _c, _d;
                    if ((_a = e === null || e === void 0 ? void 0 : e.slide) === null || _a === void 0 ? void 0 : _a.dataset.splideHtmlVideo) {
                        (_b = e.player) === null || _b === void 0 ? void 0 : _b.pause();
                        (_d = (_c = e.ui) === null || _c === void 0 ? void 0 : _c.wrapper) === null || _d === void 0 ? void 0 : _d.setAttribute('style', '');
                    }
                });
            });
            this.carouselSplide.on('active', activeSlide => {
                const { width, height } = activeSlide.slide.firstElementChild;
                if (width > 0 && height > 0) {
                    sliderWrapper.style.aspectRatio = `${width} / ${height}`;
                }
                this.counterContainer.classList.remove('fade-out');
                this.hideCounter();
                const slide = this.carouselSlides[activeSlide.index];
                if (slide !== undefined)
                    this.updateTitle(slide.seoTitle);
            });
            this.carouselSplide.on('move', () => {
                overlayEventsHandler.emit(SPHERE_EVENT_NAMES.OVERLAY.OVERLAY_USER_ACTION);
            });
            this.carouselSplide.mount({ Video });
        });
    }
    updateCarousel() {
        requestAnimationFrame(() => {
            this.carouselSplide.emit('resize', {});
        });
    }
    carouselConfig() {
        return {
            perPage: 1,
            type: 'loop',
            speed: 100,
            cover: true,
            updateOnMove: true,
            lazyLoad: false,
            // @ts-ignore
            video: {
                playerOptions: {
                    htmlVideo: {
                        playsInline: true
                    },
                    youtube: {
                        playlist: undefined
                    }
                }
            }
        };
    }
    closeOverlay(e) {
        e === null || e === void 0 ? void 0 : e.preventDefault();
        e === null || e === void 0 ? void 0 : e.stopPropagation();
        if (this.closeCallback) {
            this.closeCallback();
            this.carouselSlides.forEach(slide => {
                if (slide.assetType === AssetTypes.VIDEO) {
                    URL.revokeObjectURL(slide.thumbnailUrl);
                }
            });
        }
        else {
            console.error('"Close" function is not set up');
        }
    }
    handleClick(e) {
        var _a;
        return e.target.tagName === 'DIV' && ((_a = e.target.className) === null || _a === void 0 ? void 0 : _a.includes('app-overlay-backdrop'));
    }
    updateTitle(seoTitle) {
        const titleElement = this.container.querySelector('#app-content-carousel-title');
        titleElement.replaceChildren();
        if (this.options.title && seoTitle !== undefined) {
            Overlay.showSeoTitle(titleElement, seoTitle);
        }
    }
}
