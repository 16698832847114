import * as Sentry from '@sentry/browser';
import { SphereApp } from './sphere_app';
import Router from './router';
import { PLANOGRAM_NAME } from './shared/constants';
import { AppUtils } from './utils/app_utils';
import { WINDOW_ORIENTATION, WindowOrientationSupervisor } from './window-orientation/window-orientation.supervisor';
let sphereApp;
function detectViewportChanges() {
    const vh = window.innerHeight * 0.01;
    const vw = window.innerWidth * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    document.documentElement.style.setProperty('--vw', `${vw}px`);
}
function isPortraitOrientation() {
    return window.matchMedia('only screen and (orientation: portrait)').matches;
}
function updateMobileWindowOrientation() {
    WindowOrientationSupervisor.changeWindowOrientation(isPortraitOrientation() ? WINDOW_ORIENTATION.PORTRAIT : null);
}
const replaysSessionSampleRate = (() => {
    switch (SPHERE_ENV) {
        case 'dev':
            return 0.4;
        case 'test':
            return 0.2;
        default:
            return 0.1;
    }
})();
function initSentry() {
    // NODE_ENV defaults to production on hosted envirnoments
    if (process.env.NODE_ENV === 'production') {
        const integrations = [Sentry.browserTracingIntegration()];
        if (['dev', 'test'].includes(SPHERE_ENV.toLowerCase())) {
            integrations.push(Sentry.replayIntegration({
                maskAllText: false,
                blockAllMedia: false
            }), Sentry.replayCanvasIntegration());
        }
        Sentry.init({
            dsn: SENTRY_DSN,
            environment: SPHERE_ENV,
            release: WEB_VERSION,
            integrations,
            tracesSampleRate: 1, // 0..1
            replaysSessionSampleRate,
            replaysOnErrorSampleRate: 1
        });
    }
}
function onSizeChange(callback) {
    let active = true;
    let width = window.innerWidth;
    let height = window.innerHeight;
    let isPortrait = isPortraitOrientation();
    function loop() {
        if (!active) {
            return;
        }
        if (window.innerWidth !== width || window.innerHeight !== height || isPortrait !== isPortraitOrientation()) {
            width = window.innerWidth;
            height = window.innerHeight;
            isPortrait = isPortraitOrientation();
            callback(width, height);
        }
        requestAnimationFrame(loop);
    }
    requestAnimationFrame(loop);
    return () => {
        active = false;
    };
}
function refreshSize() {
    updateMobileWindowOrientation();
    sphereApp.resizeCanvas();
    detectViewportChanges();
}
export function sphereAppInitialise(planogramName = PLANOGRAM_NAME.MAIN) {
    initSentry();
    detectViewportChanges();
    Router.init(`${window.location.origin}`);
    sphereApp = new SphereApp({
        planogramName: AppUtils.getPlanogramName(planogramName),
        debug: document.body.dataset.debug,
        sigmoid: document.body.dataset.sigmoid,
        zoomControls: document.body.dataset.zoom,
        limits: document.body.dataset.limits,
        canvas: document.getElementById('gl-canvas')
    });
    refreshSize();
    onSizeChange(refreshSize);
    if (CI_BUILD_NUMBER) {
        console.log('%c⛏\tJenkins = ' + CI_BUILD_NUMBER, 'background: #000; color: #3d8007');
    }
    console.log('%c🌎\tapp = ' + WEB_VERSION, 'background: #000; color: #3d8007');
}
