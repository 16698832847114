import { LayoutSwitcher } from '../layout-switcher/layout-switcher';
import { sphereEventHandler } from '../../custom_event_utils';
import { SPHERE_EVENT_NAMES as EVENTS } from '../../event-names';
import { CURRENCY_CODE_KEY, LAYOUT_SWITCHER_TYPE } from '../../shared/constants';
export class CurrencySwitcher {
    constructor(currencyService) {
        this.currencyService = currencyService;
        this.onStorageChange = this.onStorageChange.bind(this);
        this.container = document.getElementById('currency-switcher');
        const { currenciesList } = this.currencyService;
        if (!currenciesList || currenciesList.length <= 1) {
            this.setAvailability(false);
            return;
        }
        this.initLayoutSwitcher();
        this.setAvailability(true);
        sphereEventHandler.listen(EVENTS.OVERLAY.SHOW_CONTENT, () => {
            this.isOverlayActive = true;
        });
        window.addEventListener('storage', this.onStorageChange);
    }
    initLayoutSwitcher() {
        const { selectedCurrencyCode, currenciesList } = this.currencyService;
        this.layoutSwitcher = new LayoutSwitcher({
            container: this.container,
            type: LAYOUT_SWITCHER_TYPE.TEXT,
            children: currenciesList.map(({ name, symbol }) => ({ element: name, id: name, symbol: symbol })),
            handleSelect: (data) => this.currencyService.setCurrency(data)
        });
        this.layoutSwitcher.setActiveState(selectedCurrencyCode);
    }
    setAvailability(data) {
        if (data && !this.isOverlayActive) {
            this.container.classList.remove(CurrencySwitcher.hideClass);
        }
        else {
            this.container.classList.add(CurrencySwitcher.hideClass);
        }
    }
    onStorageChange({ storageArea, key, newValue }) {
        if (storageArea === window.localStorage && key === CURRENCY_CODE_KEY && newValue) {
            this.layoutSwitcher.setActiveState(newValue);
        }
    }
    dispose() {
        var _a;
        window.removeEventListener('storage', this.onStorageChange);
        (_a = this.layoutSwitcher) === null || _a === void 0 ? void 0 : _a.dispose();
    }
}
CurrencySwitcher.hideClass = 'is-hidden';
