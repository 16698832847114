import { Vector2 } from 'three';
import { assertDefined } from 'shared/utils/debug';
import { computeImageMapSize, parseLodId } from './helpers';
import { TILE_BORDER_SIZE } from './parameters';
function isLoaded(state) {
    return state.lodData !== undefined && state.mapPosition !== undefined;
}
export default class ImageRegistry {
    constructor(tileMap, tileLoader) {
        this.tileMap = tileMap;
        this.tileLoader = tileLoader;
        this.state = new Map();
        this.loading = false;
        this.onImageReadyCallback = () => { };
    }
    isLoading() {
        return this.loading;
    }
    updateImages(images, lodLoader) {
        const staleIds = Array.from(this.state.keys()).filter(id => !images.has(id));
        staleIds.forEach(id => {
            this.disposeState(this.state.get(id));
            this.state.delete(id);
        });
        const newImages = Array.from(images.entries()).filter(([id, _]) => !this.state.has(id));
        newImages.forEach(([id, extraData]) => {
            this.state.set(id, { id, extraData });
        });
        images.forEach((extraData, id) => {
            const state = this.state.get(id);
            assertDefined(state, 'Updating unknown image');
            if (state.extraData !== undefined &&
                (!extraData.position.isEqual(state.extraData.position) ||
                    !extraData.size.equals(state.extraData.size))) {
                this.tileLoader.updateImage(id, extraData);
            }
            state.extraData = extraData;
        });
        if (newImages.length > 0) {
            const uniqueImageIds = Array.from(new Set(newImages.map(([_, it]) => it.lodId)));
            this.loading = true;
            lodLoader(uniqueImageIds).then(data => {
                data.forEach(lodData => {
                    this.state.forEach(state => {
                        const parsedLodId = parseLodId(state.extraData.lodId).id;
                        if (parsedLodId === lodData.id && !isLoaded(state)) {
                            this.finalizeState(state, lodData);
                            this.tileLoader.addImage(state);
                            this.onImageReadyCallback(state.id);
                        }
                    });
                });
                this.loading = false;
            });
        }
    }
    onImageReady(callback) {
        this.onImageReadyCallback = callback;
    }
    finalizeState(state, lodData) {
        state.lodData = lodData;
        state.mapPosition = this.tileMap.reserveSpace(computeImageMapSize(state.lodData));
    }
    disposeState(state) {
        if (isLoaded(state)) {
            this.tileLoader.removeImage(state);
            this.tileMap.releaseSpace(state.mapPosition, computeImageMapSize(state.lodData));
        }
    }
    getImageUniforms(id) {
        return this.stateToUniforms(this.state.get(id));
    }
    stateToUniforms(state) {
        if (state === undefined || !isLoaded(state))
            return undefined;
        const lodData = state.lodData;
        const itemMapSize = computeImageMapSize(lodData);
        const fullSize = new Vector2(...lodData.full_size);
        const fitSize = new Vector2(...lodData.fit_size);
        if (lodData.lods_version > 0) {
            fitSize.addScalar(itemMapSize * TILE_BORDER_SIZE * 2 - 2 * TILE_BORDER_SIZE);
        }
        const contentRatio = fitSize.divide(fullSize);
        const contentOffset = new Vector2(1.0, 1.0).sub(contentRatio).multiplyScalar(0.5);
        return {
            itemMapCoordinates: { value: state.mapPosition },
            itemMapSize: { value: itemMapSize },
            contentRatio: { value: contentRatio },
            contentOffset: { value: contentOffset },
            opacity: { value: state.extraData.opacity },
        };
    }
}
