var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { InputEventUtils } from '../utils/input_event_utils';
import { VideoUtils } from '../utils/video_utils';
import { Overlay } from '../overlay';
import { ApiService } from '../api/api';
import { URLS, UrlUtils } from '../api/urls';
export class VideoOverlay {
    constructor(container, actionData, options, closeCallback, autoplay) {
        this.options = options;
        this.closeCallback = closeCallback;
        const link = actionData.url;
        const videoURL = VideoUtils.sanitizeUrl(link);
        if (!videoURL) {
            console.error('Generating process of youtube url was failed');
            setTimeout(() => {
                this.closeOverlay();
            }, 0);
            return;
        }
        this.container = container;
        this.container.classList.remove('direct-link');
        const videoContainer = document.createElement('div');
        const backdrop = document.createElement('div');
        const holder = document.createElement('div');
        const closeButton = document.createElement('div');
        const closeButtonElement = document.createElement('span');
        closeButton.classList.add('overlay-button', 'overlay-button-close', 'will-close');
        closeButton.appendChild(closeButtonElement);
        holder.classList.add('iframe-holder');
        videoContainer.classList.add('video-overlay', 'overlay-container', 'is-flex', 'is-vertical', 'is-vertically-aligned-center', 'is-horizontally-aligned-center');
        backdrop.classList.add('close-backdrop');
        videoContainer.appendChild(holder);
        holder.appendChild(closeButton);
        this.container.appendChild(backdrop);
        this.container.appendChild(videoContainer);
        InputEventUtils.addSelectEvents(closeButton, this.closeOverlay.bind(this));
        this.fetchType(link).then(type => {
            var _a;
            const isVideo = type && type.match(/^video\/.*$/) !== null;
            const content = document.createElement(isVideo ? 'video' : 'iframe');
            content.src = videoURL;
            if (isVideo) {
                this.container.classList.add('direct-link');
                content.setAttribute('controls', '');
                content.setAttribute('playsinline', '');
                if (autoplay) {
                    content.setAttribute('autoplay', '');
                }
            }
            else {
                content.setAttribute('frameBorder', '0');
                content.setAttribute('allowfullscreen', '');
            }
            holder.appendChild(content);
            if ((_a = this.options) === null || _a === void 0 ? void 0 : _a.title) {
                const setSeoTitle = (title) => {
                    if (!title)
                        return;
                    holder.classList.add('with-title');
                    Overlay.showSeoTitle(holder, title);
                };
                if (actionData.videoId) {
                    const url = UrlUtils.insertFewValuesToUrl(URLS.VIDEO_URL, { id: actionData.videoId });
                    new ApiService()
                        .get(url)
                        .then(resp => resp.json())
                        .then(data => setSeoTitle(data.seo_title));
                }
                else
                    this.getVideoSeoTitle(link).then(setSeoTitle);
            }
        });
    }
    fetchType(url) {
        return fetch(url, { method: 'HEAD' })
            .then(r => {
            return r.headers.get('Content-Type');
        })
            .catch(() => undefined);
    }
    closeOverlay(e) {
        if (e) {
            e.stopPropagation();
        }
        if (this.closeCallback) {
            this.closeCallback();
        }
        else {
            console.error('"Close" function is not set up');
        }
    }
    getVideoSeoTitle(url) {
        return __awaiter(this, void 0, void 0, function* () {
            const noembedUrl = `https://noembed.com/embed?url=${encodeURIComponent(url)}`;
            return fetch(noembedUrl)
                .then(response => response.json())
                .then(data => data.title);
        });
    }
    handleClick(e) {
        return ['close-backdrop', 'video-overlay'].some(token => e.target.classList.contains(token));
    }
}
