import pipe from 'callbag-pipe';
import subscribe from 'callbag-subscribe';
import { ShopifyShoppingCartItemComponent } from './components/shopify-shopping-cart-item_component';
import { WebUtils } from '../utils/web_utils';
import { Metrics } from '../metrics';
import { MATOMO_EVENT_NAMES } from '../metric-events';
import { AppState } from '../shared/app.state';
import { UrlUtils } from '../api/urls';
import { IdealCartService } from '../api/services/ideal-cart.service';
import { IdealShoppingCartItemComponent } from './components/ideal-shopping-cart-item_component';
export class ShoppingCartItems {
    constructor(scPanelWrapper, shoppingCartService, currencyService, redirectToProduct) {
        this.shoppingCartService = shoppingCartService;
        this.currencyService = currencyService;
        this.redirectToProduct = redirectToProduct;
        this.shoppingCartContainer = scPanelWrapper.querySelector('.app-shopping-cart-panel-container');
        this.itemsContainer = scPanelWrapper.querySelector('.app-shopping-cart-items-container');
        pipe(this.shoppingCartService.checkoutSubject, subscribe({
            next: (checkout) => {
                if (checkout === null || checkout === void 0 ? void 0 : checkout.products)
                    this.initProducts(checkout.products);
                else
                    this.clearCart();
            },
            error: () => {
                this.clearCart();
            }
        }));
    }
    get processing() {
        return this.shoppingCartContainer.classList.contains('loading');
    }
    updateShoppingCart(product) {
        if (this.processing) {
            return;
        }
        this.shoppingCartService.updateShoppingCartQuantity(product);
    }
    removeItemFromCart(product) {
        this.shoppingCartService.removeItemFromCart(product);
    }
    navigateToProduct(product) {
        const productTitle = UrlUtils.slugify(product.title);
        if (product.sphere_name && product.product_id) {
            const productLink = `sphere#${product.sphere_name}#${product.product_id}`;
            this.redirectToProduct(productLink);
            Metrics.storeTheEvent(AppState.planogramName, 'click', MATOMO_EVENT_NAMES.CLICK_SHOPPING_CART_OVERLAY_PRODUCT_TITLE(product.product_id, productTitle));
        }
        else if (product.store_url) {
            WebUtils.openLink(product.store_url);
            Metrics.storeTheEvent(AppState.planogramName, 'click', MATOMO_EVENT_NAMES.CLICK_SHOPPING_CART_OVERLAY_PRODUCT_TITLE(product.product_id, productTitle));
        }
    }
    initProducts(products) {
        this.clearCart();
        if (!(products === null || products === void 0 ? void 0 : products.length)) {
            return;
        }
        const resultItems = document.createDocumentFragment();
        products.forEach(product => {
            const shoppingCartItem = this.shoppingCartService instanceof IdealCartService ?
                new IdealShoppingCartItemComponent(this, product, this.currencyService, this.shoppingCartService) :
                new ShopifyShoppingCartItemComponent(this, product, this.currencyService, this.shoppingCartService);
            const shoppingCartItemElem = shoppingCartItem.renderItem();
            resultItems.appendChild(shoppingCartItemElem);
        });
        this.itemsContainer.appendChild(resultItems);
    }
    clearCart() {
        this.itemsContainer.innerHTML = '';
    }
}
