import pipe from 'callbag-pipe';
import subscribe from 'callbag-subscribe';
import { CDN_RESOURCES, UrlUtils } from '../api/urls';
import { searchEventHandler } from '../custom_event_utils';
import { InputEventUtils } from '../utils/input_event_utils';
import { SEARCH_EVENT_NAMES as SEARCH_EVENTS } from '../event-names';
import { Metrics } from '../metrics';
import { MATOMO_EVENT_NAMES } from '../metric-events';
import { L10nUtils } from '../utils/l10n_utils';
export class SearchResults {
    constructor(searchService, redirectToProduct) {
        this.searchService = searchService;
        this.redirectToProduct = redirectToProduct;
        this.searchWrapper = document.querySelector('.search-wrapper');
        this.searchResultsContainer = document.getElementById('search-results-container');
        this.searchResults = document.getElementById('search-results');
        if (!this.searchResultsContainer) {
            console.error('Can not found element with id - "#search-results-container"');
        }
        if (!this.searchWrapper) {
            console.error('Can not found element with class - ".search-wrapper"');
        }
        pipe(L10nUtils.languageLoadedSubject, subscribe({
            next: (langCode) => {
                if (!langCode) {
                    return;
                }
                this.handleLanguageChanged();
            }
        }));
        pipe(this.searchService.searchResultsSubject, subscribe({
            next: (data) => {
                searchEventHandler.emit(SEARCH_EVENTS.UPDATE_SEARCH, { results: data === null || data === void 0 ? void 0 : data.results });
                this.initResults(data === null || data === void 0 ? void 0 : data.results, data === null || data === void 0 ? void 0 : data.filterId);
            }
        }));
        InputEventUtils.addSelectEvents(this.searchResults, this.handleClicks.bind(this));
    }
    static get AMOUNT_OF_ITEMS() {
        return [12, 10, 9, 6, 5];
    }
    initResults(searchResults, filterId) {
        if (!searchResults) {
            return;
        }
        this.searchResultsContainer.className = '';
        SearchResults.AMOUNT_OF_ITEMS.forEach(amount => {
            if (searchResults.products.length < amount) {
                return;
            }
            this.searchResultsContainer.classList.add(`without-flex-${amount}`);
        });
        this.renderResults(searchResults.products, filterId);
    }
    updateSearchSettings(settings) {
        this.searchSettings = settings;
    }
    renderResults(results, filterId) {
        if (!this.searchResults) {
            console.error('Can not found element with id - "#search-results"');
        }
        this.clearResults();
        const resultItems = document.createDocumentFragment();
        results.forEach(item => {
            var _a;
            const planogramItem = (_a = this.searchSettings.other_planogram_versions) === null || _a === void 0 ? void 0 : _a.find(planogram => {
                if (filterId) {
                    return filterId === planogram.id;
                }
                return item.planogram_version_ids.includes(planogram.id);
            });
            if (!planogramItem) {
                return;
            }
            const itemContainer = document.createElement('div');
            const itemImage = document.createElement('img');
            const imageUrl = item.product_images.variants.VARIANT_MEDIUM_WEBP;
            itemImage.setAttribute('src', UrlUtils.insertValueToUrl(CDN_RESOURCES.PRODUCT_IMAGE, imageUrl));
            itemContainer.setAttribute('data-link-attr', `sphere#${planogramItem.planogram_name}#${item.identifier}`);
            itemContainer.setAttribute('data-identifier', item.identifier);
            itemContainer.setAttribute('data-product_name', item.name);
            itemContainer.appendChild(itemImage);
            itemContainer.setAttribute('role', 'button');
            itemContainer.setAttribute('aria-label', `${item.identifier} ${item.name}`);
            itemContainer.classList.add('search-item');
            resultItems.appendChild(itemContainer);
        });
        this.searchResults.appendChild(resultItems);
    }
    handleClicks(e) {
        const el = e.target.closest('.search-item');
        if (!el) {
            return;
        }
        const productLink = el.getAttribute('data-link-attr');
        const identifier = el.getAttribute('data-identifier');
        const productName = el.getAttribute('data-product_name');
        Metrics.storeTheEvent(this.searchService.planogramName, 'click', MATOMO_EVENT_NAMES.CLICK_SEARCH_RESULT(identifier, UrlUtils.slugify(productName)));
        this.redirectToProduct(productLink, productName);
        searchEventHandler.emit(SEARCH_EVENTS.CLOSE_SEARCH);
    }
    clearResults() {
        this.searchResults.innerHTML = '';
    }
    handleLanguageChanged() {
        if (!this.searchResultsContainer) {
            return;
        }
        const noResultsImage = this.searchResultsContainer.querySelector('.no-results img');
        const noResultsText = this.searchResultsContainer.querySelector('.no-results span');
        if (noResultsImage) {
            noResultsImage.setAttribute('alt', L10nUtils.l10n('search-overlay.no-results.alt-text'));
        }
        if (noResultsText) {
            noResultsText.innerText = L10nUtils.l10n('search-overlay.no-results.text');
        }
    }
}
