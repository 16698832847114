export class CountdownTimeValues {
}
export var HP_OVERLAY_THEMES;
(function (HP_OVERLAY_THEMES) {
    HP_OVERLAY_THEMES["ROBE"] = "Personalised Robe";
    HP_OVERLAY_THEMES["SEEKER_TSHIRT"] = "Personalised House Seeker T-Shirt";
    HP_OVERLAY_THEMES["TRIWIZARD_SHIRT"] = "Personalised Triwizard Shirt";
    HP_OVERLAY_THEMES["CREST_KEYRING"] = "Personalised Hogwarts Crest Keyring";
    HP_OVERLAY_THEMES["LETTER_KEYCHAIN"] = "Personalised Acceptance Letter Charm";
    HP_OVERLAY_THEMES["NOTEBOOK"] = "Personalised Embossed Notebook";
    HP_OVERLAY_THEMES["TRUNK"] = "Gift Trunk";
    HP_OVERLAY_THEMES["FROG"] = "Chocolate Frog";
    HP_OVERLAY_THEMES["ACCEPTANCE_LETTER"] = "Personalised Hogwarts Acceptance letter";
    HP_OVERLAY_THEMES["HOGWARTS_TRUNK_US"] = "Hogwarts trunk US";
    HP_OVERLAY_THEMES["DEFAULT"] = "default_theme";
})(HP_OVERLAY_THEMES || (HP_OVERLAY_THEMES = {}));
