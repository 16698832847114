import { isMobile } from '../../utils/platform';
import { InputEventUtils } from '../../utils/input_event_utils';
import { ProductInfoBase } from './product-info-base_overlay';
import { productInfoDefaultView } from './product-info-default.view';
import sanitizeHtml from 'sanitize-html';
export class ProductInfoDefault extends ProductInfoBase {
    initOverlay() {
        super.initOverlay();
        this.initInfoTextControls();
    }
    handleClick(e) {
        if (this.isViewGallery()) {
            const tokens = ['app-product-overlay', 'app-product-content-wrapper', 'app-product-carousel'];
            if (e.target.closest('#app-image-carousel')) {
                tokens.push('splide__slide');
            }
            return tokens.some(token => e.target.classList.contains(token));
        }
        else {
            return e.target === this.mainContainer;
        }
    }
    setItemContent() {
        const description = this.product.description ? sanitizeHtml(this.product.description) : '';
        const sanitizedProduct = Object.assign(Object.assign({}, this.product), { description });
        this.productTitle = this.product.name;
        this.container.innerHTML = productInfoDefaultView(sanitizedProduct, this.item.planogram.name);
    }
    initInfoTextControls() {
        const infoButton = document.querySelector('.info-icon');
        const productOverlay = document.querySelector('.app-product-overlay');
        const toggleClassName = 'is-info-text-active';
        const parentContainer = document.querySelector('.app-product-price');
        const toggleInfoText = (e) => {
            const target = e.target;
            e.stopPropagation();
            if (!parentContainer) {
                console.error('element not found - ".app-product-price"');
            }
            if (target.classList.contains('info-icon')) {
                parentContainer.classList.toggle(toggleClassName);
            }
            else {
                parentContainer.classList.remove(toggleClassName);
            }
        };
        if (isMobile) {
            InputEventUtils.addSelectEvents(infoButton, toggleInfoText);
            InputEventUtils.addSelectEvents(productOverlay, toggleInfoText);
        }
        else {
            InputEventUtils.addHoverEvent(infoButton, toggleInfoText);
        }
    }
}
