import * as THREE from 'three';
import { rotate2Dinto3DVector } from 'shared/utils/GeometryUtils';
export class SphereGeometry extends THREE.BufferGeometry {
    static get ROTATION_OFFSET() {
        return 1.5 * Math.PI;
    }
    constructor(alpha, shellRadius, equatorRadius, widthSegments, heightSegments, azimuthStart, azimuthLength, planogramHeight, itemY, itemHeight, requiresUVs, startUV = new THREE.Vector2(1, 1), endUV = new THREE.Vector2(0, 0)) {
        super();
        this.type = 'SphereGeometry';
        this.parameters = {
            alpha,
            shellRadius,
            equatorRadius,
            widthSegments,
            heightSegments,
            azimuthStart,
            azimuthLength,
            planogramHeight,
            itemY,
            itemHeight,
            requiresUVs,
            startUV,
            endUV,
        };
        this.setDefaultsAndLimits();
        this.initCoreArrays();
        this.generateVertices();
        this.buildGeometry(requiresUVs);
    }
    dispose() {
        this.indices = [];
        this.vertices = [];
        this.normals = [];
        super.dispose();
    }
    setDefaultsAndLimits() {
        this.alpha = this.parameters.alpha;
        this.shellRadius = this.parameters.shellRadius;
        this.equatorRadius = this.parameters.equatorRadius;
        this.widthSegments = this.parameters.widthSegments;
        this.heightSegments = this.parameters.heightSegments;
        this.azimuthStart = this.parameters.azimuthStart - SphereGeometry.ROTATION_OFFSET;
        this.azimuthLength = this.parameters.azimuthLength;
        this.planogramHeight = this.parameters.planogramHeight;
        this.itemY = this.parameters.itemY;
        this.itemHeight = this.parameters.itemHeight;
        this.startUV = this.parameters.startUV;
        this.endUV = this.parameters.endUV;
    }
    initCoreArrays() {
        this.indices = [];
        this.vertices = [];
        this.normals = [];
        this.uvs = [];
    }
    get alpha() {
        return this.parameters.alpha;
    }
    set alpha(newAlpha) {
        this.parameters.alpha = newAlpha;
    }
    get shellRadius() {
        return this.parameters.shellRadius;
    }
    set shellRadius(radius) {
        let shellRadius = radius || 1;
        if (shellRadius < this.parameters.equatorRadius) {
            shellRadius = this.parameters.equatorRadius;
        }
        this.parameters.shellRadius = shellRadius;
    }
    get equatorRadius() {
        return this.parameters.equatorRadius;
    }
    set equatorRadius(radius) {
        this.parameters.equatorRadius = radius || 1;
    }
    get widthSegments() {
        return this.parameters.widthSegments;
    }
    set widthSegments(width) {
        this.parameters.widthSegments = Math.max(1, Math.floor(width));
    }
    get heightSegments() {
        return this.parameters.heightSegments;
    }
    set heightSegments(height) {
        this.parameters.heightSegments = Math.max(1, Math.floor(height));
    }
    get azimuthStart() {
        return this.parameters.azimuthStart;
    }
    set azimuthStart(azimuthRads) {
        this.parameters.azimuthStart = azimuthRads !== undefined ? azimuthRads : 0;
    }
    get azimuthLength() {
        return this.parameters.azimuthLength;
    }
    set azimuthLength(length) {
        const azimuthLen = length !== undefined ? length : Math.PI * 2;
        this.parameters.azimuthLength = THREE.MathUtils.clamp(azimuthLen, 0, Math.PI * 2);
    }
    /*
     * The point at which the curve changes from using the
     * large radius, to the finishing smaller curve.
     */
    static calcIntersectPoint(alpha, shellRadius, equatorRadius) {
        return [
            Math.cos(alpha) * shellRadius - (shellRadius - equatorRadius),
            Math.sin(alpha) * shellRadius,
        ];
    }
    /*
     * The angle from the sphere origin to the point at which the curve
     * changes from using the large radius, to the finishing smaller curve.
     */
    static cutoffAltitudeAngle(intersect) {
        return Math.atan2(intersect[1], intersect[0]);
    }
    /*
     * Finds length 'a' given sides 'a', 'b' and non opposite 'angle'
     * Does this using the laws of cosines.
     */
    static calcThirdTriangleSide(c, b, angle) {
        return b * Math.cos(angle) + Math.sqrt(Math.pow(c, 2) - Math.pow(b, 2) * Math.pow(Math.sin(angle), 2));
    }
    /*
     * Radius of the capping top sphere
     */
    static smallRadius(intersectX, alpha) {
        return intersectX / Math.cos(alpha);
    }
    /*
     * Distance from origin to where the large radius cuts it.
     */
    static shellRadiusOriginCut(alpha, shellRadius, equatorRadius) {
        return Math.tan(alpha) * (shellRadius - equatorRadius);
    }
    static arcLength(angleInRads, radius) {
        return angleInRads * radius;
    }
    static angleFromArcLength(arcLength, radius) {
        return arcLength / radius;
    }
    // Length from top pole to bottom pole along the sphere surface
    static calcTopToBottomSurfaceLength(alpha, shellRadius, equatorRadius) {
        const midArcLength = 2 * SphereGeometry.arcLength(alpha, shellRadius);
        const intersect = SphereGeometry.calcIntersectPoint(alpha, shellRadius, equatorRadius);
        const smallRadius = SphereGeometry.smallRadius(intersect[0], alpha);
        const endingArcAngle = Math.PI / 2 - alpha;
        const endingArcLength = SphereGeometry.arcLength(endingArcAngle, smallRadius);
        return midArcLength + 2 * endingArcLength;
    }
    // Calculates the (x,y) point on sphere given a distance from the south pole.
    // length: sphere surface length from pole to point
    // fullSurfaceLength: sphere surface length from pole to pole
    static calcSpherePoint(length, fullSurfaceLength, alpha, shellRadius, equatorRadius) {
        // Sphere is symetrical so just do calc for top
        let posLength = fullSurfaceLength / 2;
        const inTopHalf = length >= posLength;
        if (inTopHalf) {
            posLength = length - posLength;
        }
        else {
            posLength -= length;
        }
        // Within mid large radius
        let spherePoint;
        const mainArcLength = SphereGeometry.arcLength(alpha, shellRadius);
        if (posLength <= mainArcLength) {
            spherePoint = SphereGeometry.calcSpherePointInMainArc(posLength, shellRadius, equatorRadius);
        }
        else {
            spherePoint = SphereGeometry.calcSpherePointInEndingArc(posLength, fullSurfaceLength, alpha, shellRadius, equatorRadius);
        }
        if (!inTopHalf) {
            spherePoint.point[1] *= -1;
        }
        spherePoint.normal.normalize();
        return spherePoint;
    }
    static calcSpherePointInMainArc(posLength, shellRadius, equatorRadius) {
        // Within mid large radius
        const angle = SphereGeometry.angleFromArcLength(posLength, shellRadius);
        const point = SphereGeometry.calcIntersectPoint(angle, shellRadius, equatorRadius);
        const normal = new THREE.Vector2(point[0] + shellRadius, point[1]);
        return { point, normal };
    }
    static calcSpherePointInEndingArc(posLength, fullSurfaceLength, alpha, shellRadius, equatorRadius) {
        // Within top ending arc
        const toTopLength = fullSurfaceLength / 2 - posLength;
        const adj = Math.cos(alpha) * shellRadius - (shellRadius - equatorRadius);
        const smallRadius = adj / Math.cos(alpha);
        const angle = SphereGeometry.angleFromArcLength(toTopLength, smallRadius);
        const x = Math.sin(angle) * smallRadius;
        const y = Math.cos(angle) * smallRadius;
        const yCut = SphereGeometry.shellRadiusOriginCut(alpha, shellRadius, equatorRadius);
        const point = [x, y + yCut];
        const normal = new THREE.Vector2(x, y);
        return { point, normal };
    }
    static distortionAdjustment(originalIntersect, ySurfaceDistance, fullSurfaceLength, alpha, shellRadius, equatorRadius) {
        const adjustment = originalIntersect.normal.angle();
        const radius = originalIntersect.point[0];
        const shortening = adjustment * (1.0 - radius / equatorRadius);
        let yDist = ySurfaceDistance + shortening;
        if (ySurfaceDistance >= fullSurfaceLength / 2) {
            const upperDistance = ySurfaceDistance - fullSurfaceLength / 2;
            yDist = upperDistance - shortening + fullSurfaceLength / 2;
        }
        return SphereGeometry.calcSpherePoint(yDist, fullSurfaceLength, alpha, shellRadius, equatorRadius);
    }
    generateVertices() {
        const grid = [];
        let index = 0;
        const surfaceLength = SphereGeometry.calcTopToBottomSurfaceLength(this.alpha, this.shellRadius, this.equatorRadius);
        const heightAdjustment = (surfaceLength - this.planogramHeight) / 2;
        const gridStep = this.itemHeight / this.heightSegments;
        let yDistance = this.itemY + heightAdjustment;
        const uvSegmentWidth = (this.endUV.x - this.startUV.x) / this.widthSegments;
        const uvSegmentHeight = (this.endUV.y - this.startUV.y) / this.heightSegments;
        for (let iy = 0; iy <= this.heightSegments; iy += 1) {
            const verticesRow = [];
            const v = this.startUV.y + iy * uvSegmentHeight;
            for (let ix = 0; ix <= this.widthSegments; ix += 1) {
                this.calcVertexDetails(ix, iy, uvSegmentWidth, uvSegmentHeight, v, yDistance, surfaceLength);
                verticesRow.push(index);
                index += 1;
            }
            grid.push(verticesRow);
            yDistance += gridStep;
        }
        this.calcIndices(grid);
    }
    calcVertexDetails(ix, iy, uvSegmentWidth, uvSegmentHeight, v, yDistance, surfaceLength) {
        const segmentSize = ix / this.widthSegments;
        const u = this.startUV.x + ix * uvSegmentWidth;
        const intersect = SphereGeometry.calcSpherePoint(yDistance, surfaceLength, this.alpha, this.shellRadius, this.equatorRadius);
        const adjustedIntersect = SphereGeometry.distortionAdjustment(intersect, yDistance, surfaceLength, this.alpha, this.shellRadius, this.equatorRadius);
        const angle = this.azimuthStart + segmentSize * this.azimuthLength;
        const vertex = rotate2Dinto3DVector(adjustedIntersect.point[0], adjustedIntersect.point[1], angle);
        const normalVertex = rotate2Dinto3DVector(intersect.normal.x, intersect.normal.y, angle);
        this.vertices.push(vertex.x, vertex.y, vertex.z);
        this.calcAndPushSphereNormal(normalVertex);
        this.uvs.push(u, 1 - v);
    }
    calcAndPushSphereNormal(normal) {
        this.normals.push(normal.x, normal.y, normal.z);
    }
    calcIndices(grid) {
        for (let iy = 0; iy < this.heightSegments; iy += 1) {
            for (let ix = 0; ix < this.widthSegments; ix += 1) {
                const a = grid[iy][ix + 1];
                const b = grid[iy][ix];
                const c = grid[iy + 1][ix];
                const d = grid[iy + 1][ix + 1];
                this.indices.push(a, b, d);
                this.indices.push(b, c, d);
            }
        }
    }
    buildGeometry(requiresUVs) {
        this.setIndex(this.indices);
        const buffer = new ArrayBuffer((this.vertices.length + this.uvs.length) * 4);
        const interleavedFloat32Buffer = new Float32Array(buffer);
        if (requiresUVs) {
            for (let bi = 0, vi = 0, uvi = 0; vi < this.vertices.length; bi += 5, vi += 3, uvi += 2) {
                interleavedFloat32Buffer[bi] = this.vertices[vi];
                interleavedFloat32Buffer[bi + 1] = this.vertices[vi + 1];
                interleavedFloat32Buffer[bi + 2] = this.vertices[vi + 2];
                interleavedFloat32Buffer[bi + 3] = this.uvs[uvi];
                interleavedFloat32Buffer[bi + 4] = this.uvs[uvi + 1];
            }
            const interleavedBuffer32 = new THREE.InterleavedBuffer(interleavedFloat32Buffer, 5);
            this.setAttribute('position', new THREE.InterleavedBufferAttribute(interleavedBuffer32, 3, 0, false));
            this.setAttribute('uv', new THREE.InterleavedBufferAttribute(interleavedBuffer32, 2, 3, true));
        }
        else {
            this.setAttribute('position', new THREE.Float32BufferAttribute(this.vertices, 3));
        }
    }
    static calcAzimuthStartRadians(x, width, planogramWidth) {
        return Math.PI * 2 - (width + x) * ((Math.PI * 2) / planogramWidth);
    }
    static calcAzimuthLengthRadians(width, planogramWidth) {
        return width * ((Math.PI * 2) / planogramWidth);
    }
}
