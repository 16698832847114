import { productInfoUalView } from './product-info-ual.view';
import { ProductInfoBase } from './product-info-base_overlay';
import { InputEventUtils } from '../../utils/input_event_utils';
import { Metrics } from '../../metrics';
import { MATOMO_EVENT_NAMES } from '../../metric-events';
import sanitizeHtml from 'sanitize-html';
export class ProductInfoUal extends ProductInfoBase {
    constructor() {
        super(...arguments);
        this.supportGalleryView = false;
    }
    setItemContent() {
        var _a;
        const description = sanitizeHtml(this.product.description);
        const sanitizedProduct = Object.assign(Object.assign({}, this.product), { description });
        this.container.innerHTML = productInfoUalView(sanitizedProduct, this.item.planogram.name, (_a = this.item.planogram.sharingButton) === null || _a === void 0 ? void 0 : _a.enabled);
    }
    setCustomButtons() { }
    setThumbsSwitchLength() {
        if (this.gallerySplide && this.thumbnailSplide) {
            const thumbsLength = this.thumbnailSplide.length - 1;
            const maxLength = 3;
            const perPage = Math.min(thumbsLength, maxLength);
            this.thumbnailSplide.options = { perPage, focus: perPage - 1 };
            this.gallerySplide.emit('resize', {});
            this.thumbnailSplide.emit('resize', {});
        }
    }
    getThumbsCarouselConfig(productSlides) {
        return {
            autoWidth: true,
            autoHeight: true,
            gap: '1.875vw',
            speed: 150,
            rewind: true,
            pagination: false,
            isNavigation: true,
            lazyLoad: 'sequential',
            start: productSlides.imageIndex,
            perMove: 1,
            perPage: Math.min(productSlides.length - 1, 3),
            focus: 2,
            keyboard: false,
            breakpoints: {
                896: {
                    gap: 10
                }
            }
        };
    }
    initSharingButton() {
        const sharingButton = this.item.planogram.sharingButton;
        if (!(sharingButton === null || sharingButton === void 0 ? void 0 : sharingButton.enabled)) {
            return;
        }
        this.mainContainer.classList.add('with-sharing-button');
        this.setupSharingProviders(sharingButton.sharing_providers);
        this.setupMobileSharingButton(sharingButton);
    }
    setupMobileSharingButton(config) {
        this.sharePopupContainer = document.getElementById('share-mobile-popup');
        const shareMobileButton = document.getElementById('share-mobile');
        if (!this.sharePopupContainer || !shareMobileButton) {
            return;
        }
        const sharePopupBackdrop = document.querySelector('.app-popup-backdrop');
        this.sharePopupContainer.classList.remove('is-hidden');
        shareMobileButton.classList.remove('is-hidden');
        InputEventUtils.addSelectEvents(shareMobileButton, e => {
            e.stopPropagation();
            Metrics.storeTheEvent(this.item.planogram.name, 'click', `${MATOMO_EVENT_NAMES.CLICK_SHARE_BUTTON}product_details-${this.item.identifier}-${this.productName}`);
            this.sharePopupContainer.classList.add('active');
        });
        InputEventUtils.addSelectEvents(sharePopupBackdrop, e => {
            e.stopPropagation();
            this.sharePopupContainer.classList.remove('active');
        });
    }
}
