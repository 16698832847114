var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Cookies from 'js-cookie';
import { isTouchSupported } from './utils/platform';
import { sphereEventHandler } from './custom_event_utils';
import { SPHERE_EVENT_NAMES as EVENTS } from './event-names';
import { entranceGuideView } from './entrance-guide.view';
import { WindowOrientationSupervisor } from './window-orientation/window-orientation.supervisor';
import { WebUtils } from './utils/web_utils';
import { AppUtils } from './utils/app_utils';
import { FontLoader } from './font_loader';
import { ANIMATION_TYPE } from './interfaces/planogram.interface';
export class EntranceGuide {
    static get MS_BEFORE_SHOW() {
        return 1000;
    }
    static get MAIN_INACTIVITY_MS() {
        return 10000;
    }
    static get INDICATOR_DISPLAY_MS() {
        return 1500;
    }
    static init(skipWindowOrientationPopup = false) {
        if (!WindowOrientationSupervisor.isOverlayShown() &&
            !skipWindowOrientationPopup &&
            !AppUtils.isSassenbachClient(this.clientName, this.planogramName) &&
            !EntranceGuide.settings.hide_guidance) {
            WindowOrientationSupervisor.trackWindowOrientation();
            return;
        }
        if (Cookies.get('entrance_animation') && !EntranceGuide.settings.repeat_animation) {
            return;
        }
        this.storeAnimationSettings();
        this.initView();
        this.wrapper = document.getElementById('entrance-guide');
        this.zoomIndicator = document.querySelector('#entrance-guide > .zoom-indicator');
        this.dragIndicator = document.querySelector('#entrance-guide > .drag-indicator');
        this.clickIndicator = document.querySelector('#entrance-guide > .click-indicator');
        this.onElementHandler = this.onElementHandler.bind(this);
        this.onSphereInit = this.onSphereInit.bind(this);
        this.clear = this.clear.bind(this);
        this.show = this.show.bind(this);
        this.destroy = this.destroy.bind(this);
        this.start();
    }
    static initView() {
        const entranceGuideElement = document.createElement('div');
        document.body.appendChild(entranceGuideElement);
        entranceGuideElement.outerHTML = entranceGuideView();
    }
    static storeAnimationSettings() {
        const root = document.documentElement;
        const bgColorRgb = AppUtils.hex2rgb(EntranceGuide.settings.background_color);
        root.style.setProperty(`--entrance-animation-background-color`, EntranceGuide.settings.background_color);
        root.style.setProperty(`--entrance-animation-background-color-rgb`, bgColorRgb);
        EntranceGuide.settings.entrance_animation_fonts.forEach(animation => {
            FontLoader.mountCustomFonts([
                Object.assign(Object.assign({}, animation.desktop), { assignment: `entrance-animation-${animation.action_type}-desktop` }),
                Object.assign(Object.assign({}, animation.mobile), { assignment: `entrance-animation-${animation.action_type}-mobile` })
            ]);
        });
    }
    static start() {
        if (Cookies.get('entrance_animation') && !EntranceGuide.settings.repeat_animation) {
            return;
        }
        if (this.wrapper && this.zoomIndicator && this.dragIndicator) {
            this.wrapper.classList.add(isTouchSupported ? 'with-touch' : 'with-scroll');
            this.inactivityTimeout = window.setTimeout(this.show, this.MS_BEFORE_SHOW);
            sphereEventHandler.listen(EVENTS.SPHERE.INIT, this.onSphereInit);
            sphereEventHandler.listen([EVENTS.OVERLAY.SHOW_CONTENT, EVENTS.SPHERE.RESET].join(' '), this.destroy);
        }
        else {
            console.error('EntranceGuide elements not found');
        }
    }
    static destroy() {
        var _a, _b, _c, _d, _e, _f;
        this.clear();
        sphereEventHandler.off([EVENTS.OVERLAY.SHOW_CONTENT, EVENTS.SPHERE.RESET].join(' '), this.destroy);
        this.wrapper.classList.add('is-hidden');
        (_a = this.zoomIndicator) === null || _a === void 0 ? void 0 : _a.classList.add('is-hidden');
        (_b = this.dragIndicator) === null || _b === void 0 ? void 0 : _b.classList.add('is-hidden');
        (_c = this.clickIndicator) === null || _c === void 0 ? void 0 : _c.classList.add('is-hidden');
        this.wrapper.classList.remove('fade-in', 'fade-out');
        (_d = this.zoomIndicator) === null || _d === void 0 ? void 0 : _d.classList.remove('bounce-in', 'bounce-out');
        (_e = this.dragIndicator) === null || _e === void 0 ? void 0 : _e.classList.remove('bounce-in', 'bounce-out');
        (_f = this.clickIndicator) === null || _f === void 0 ? void 0 : _f.classList.remove('bounce-in', 'bounce-out');
    }
    static clear() {
        clearTimeout(this.inactivityTimeout);
        sphereEventHandler.off([EVENTS.TILT_AND_PAN_TO].join(' '), this.clear);
    }
    static onSphereInit() {
        clearTimeout(this.clickIndicatorCloseTimeout);
        clearTimeout(this.clickInactivityTimeout);
        sphereEventHandler.off(EVENTS.SPHERE.INIT, this.onSphereInit);
        this.destroy();
        this.start();
    }
    static show() {
        return __awaiter(this, void 0, void 0, function* () {
            this.clear();
            this.wrapper.classList.remove('is-hidden');
            this.wrapper.classList.add('fade-in');
            for (const animation of this.settings.entrance_animation_fonts) {
                if (animation.hidden) {
                    continue;
                }
                if (this.settings.with_animation && animation.action_type === ANIMATION_TYPE.ZOOM) {
                    sphereEventHandler.emit(EVENTS.ENTRANCE_ANIMATION.ZOOM_ANIMATION);
                }
                if (this.settings.with_animation && animation.action_type === ANIMATION_TYPE.DRAG) {
                    sphereEventHandler.emit(EVENTS.ENTRANCE_ANIMATION.DRAG_ANIMATION);
                }
                yield this.guideStep(this[`${animation.action_type}Indicator`], resolve => window.setTimeout(resolve, this.INDICATOR_DISPLAY_MS), this.onElementHandler.bind(this, this[`${animation.action_type}Indicator`], animation.complete, animation.action_type));
            }
        });
    }
    static onElementHandler(elem, complete) {
        if (complete) {
            return () => {
                Cookies.set('entrance_animation', 'shown', {
                    expires: 365,
                    domain: WebUtils.getCookieDomain(),
                    sameSite: "Lax" /* COOKIES_ATTRIBUTES.SAME_SITE_LAX */
                });
                setTimeout(this.completeAnimationHandler.call(this, elem), this.INDICATOR_DISPLAY_MS, () => sphereEventHandler.off(EVENTS.SPHERE.INIT, this.onSphereInit));
            };
        }
        return resolve => {
            elem.classList.add('bounce-out');
            elem.addEventListener('animationend', () => {
                elem.classList.add('is-hidden');
                resolve();
            }, { once: true });
        };
    }
    static completeAnimationHandler(elem) {
        return (onComplete = Function()) => {
            elem.classList.remove('bounce-in');
            this.wrapper.classList.remove('fade-in');
            this.wrapper.classList.add('fade-out');
            this.wrapper.addEventListener('animationend', e => {
                if (e.target === this.wrapper) {
                    onComplete();
                    this.destroy();
                }
            }, { once: true });
        };
    }
    static guideStep(el, before = Function(), onComplete = Function()) {
        let savedListener;
        return new Promise(resolve => {
            savedListener = resolve;
            el.classList.remove('is-hidden');
            el.classList.add('bounce-in');
            before(resolve);
        }).then(() => {
            return new Promise(resolve => {
                onComplete(savedListener)(resolve);
            });
        });
    }
}
