export class SelectFieldComponent {
    constructor(selectWrapper, onChange) {
        this.selectWrapper = selectWrapper;
        this.selectEl = this.selectWrapper.querySelector('select');
        this.selectEl.addEventListener('change', () => {
            if (onChange) {
                onChange(this.value);
            }
        });
    }
    get value() {
        return this.selectEl.value;
    }
    validate() {
        return this.selectEl.reportValidity();
    }
}
