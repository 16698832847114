import { CylinderBox } from "shared/utils/modulo.util";
import { planogramWidth } from "./PlanogramPoint";
import { debugCommand } from "./debug";
export default class PlanogramBox extends CylinderBox {
    constructor(box2) {
        super(planogramWidth);
        if (box2)
            this.fromBox2(box2);
    }
}
debugCommand("box", () => new PlanogramBox());
