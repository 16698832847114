import pipe from 'callbag-pipe';
import subscribe from 'callbag-subscribe';
import { InputEventUtils } from '../utils/input_event_utils';
import { searchEventHandler } from '../custom_event_utils';
import { SEARCH_EVENT_NAMES as SEARCH_EVENTS } from '../event-names';
import { Metrics } from '../metrics';
import { MATOMO_EVENT_NAMES } from '../metric-events';
export class SearchFilters {
    constructor(searchService) {
        this.searchService = searchService;
        this.searchFiltersContainer = document.getElementById('search-filters-container');
        this.storedClickedFilterElement = null;
        this.isRequestPending = false;
        if (!this.searchFiltersContainer) {
            console.error('Element with id - "#search-filters-container" not found');
        }
        pipe(this.searchService.searchFilterSettingsSubject, subscribe({
            next: (results) => {
                searchEventHandler.emit(SEARCH_EVENTS.UPDATE_SEARCH, { results });
                this.renderFilters(results);
            }
        }));
        searchEventHandler.listen(SEARCH_EVENTS.REQUEST_STATUS, (data) => {
            this.isRequestPending = data.state;
        });
        InputEventUtils.addSelectEvents(this.searchFiltersContainer, this.handleClicks.bind(this));
    }
    updateSearchSettings(settings) {
        this.searchSettings = settings;
    }
    renderFilters(searchResults) {
        const filterButtons = searchResults === null || searchResults === void 0 ? void 0 : searchResults.aggregations.planogram_version_ids;
        if (!this.searchFiltersContainer) {
            console.error('Element with id - "search-filters-container" not found');
            return;
        }
        this.clearFilters();
        const buttons = document.createDocumentFragment();
        this.searchFiltersContainer.classList.remove('is-filtering-active');
        filterButtons === null || filterButtons === void 0 ? void 0 : filterButtons.forEach(button => {
            var _a, _b, _c;
            const buttonWrapper = document.createElement('div');
            const badgeCount = document.createElement('span');
            const buttonInfo = this.searchSettings.other_planogram_versions.find(planogram => planogram.id === button.id);
            const buttonIcon = document.createElement('img');
            buttonWrapper.classList.add('search-filter-button');
            badgeCount.classList.add('search-filter-badge-counter');
            badgeCount.innerText = button.count > 99 ? '99+' : button.count;
            buttonIcon.setAttribute('src', (_b = (_a = buttonInfo === null || buttonInfo === void 0 ? void 0 : buttonInfo.search_setting.control_button) === null || _a === void 0 ? void 0 : _a.url) !== null && _b !== void 0 ? _b : (_c = this.searchSettings.default_control_button) === null || _c === void 0 ? void 0 : _c.url);
            buttonWrapper.setAttribute('data-planogram_id-attr', button.id);
            buttonWrapper.setAttribute('data-planogram_name', buttonInfo === null || buttonInfo === void 0 ? void 0 : buttonInfo.planogram_name);
            buttonWrapper.setAttribute('aria-label', `${badgeCount.innerText} elements in ${buttonInfo === null || buttonInfo === void 0 ? void 0 : buttonInfo.planogram_name}`);
            buttonWrapper.setAttribute('role', `button`);
            badgeCount.setAttribute('aria-hidden', 'true');
            buttonWrapper.appendChild(badgeCount);
            buttonWrapper.appendChild(buttonIcon);
            buttons.appendChild(buttonWrapper);
        });
        this.searchFiltersContainer.appendChild(buttons);
    }
    clearFilters() {
        this.searchFiltersContainer.innerHTML = '';
    }
    handleClicks(e) {
        var _a;
        const clickedFilter = e.target.closest('.search-filter-button');
        if (!clickedFilter || this.isRequestPending) {
            return;
        }
        const filterId = clickedFilter.getAttribute('data-planogram_id-attr');
        const filterPlanogramName = clickedFilter.getAttribute('data-planogram_name');
        const searchQuery = document.getElementById('search-input').value;
        Metrics.storeTheEvent(this.searchService.planogramName, 'click', MATOMO_EVENT_NAMES.CLICK_SEARCH_RESULT_ICON(filterPlanogramName));
        if (this.storedClickedFilterElement !== clickedFilter) {
            this.searchFiltersContainer.classList.add('is-filtering-active');
            (_a = this.storedClickedFilterElement) === null || _a === void 0 ? void 0 : _a.classList.remove('active-filter');
            clickedFilter.classList.add('active-filter');
            this.storedClickedFilterElement = clickedFilter;
            this.searchService.filterResults(searchQuery, filterId);
        }
        else {
            this.searchFiltersContainer.classList.remove('is-filtering-active');
            clickedFilter.classList.remove('active-filter');
            this.storedClickedFilterElement = null;
            this.searchService.filterResults(searchQuery);
        }
    }
}
