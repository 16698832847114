import pipe from 'callbag-pipe';
import subscribe from 'callbag-subscribe';
import makeBehaviorSubject from 'callbag-behavior-subject';
import { ApiService } from '../api';
import { URLS, UrlUtils } from '../urls';
import { EventType } from '../../interfaces/shopping-cart.interface';
import { L10nUtils } from '../../utils/l10n_utils';
import { Account } from '../../account/account';
import { ACCESS_TOKEN_KEY, CURRENCY_CODE_KEY, SHOPIFY_MULTIPASS_TOKEN_KEY } from '../../shared/constants';
import Router from '../../router';
import { AppUtils } from '../../utils/app_utils';
import { ShoppingCartUtils } from '../../utils/shopping-cart_utils';
// 1 minute is equal to 60secs and 60secs is equal to 60 * 1000 = 60000ms
const SHOPPING_CART_UPDATE_INTERVAL = 60 * 1000;
const SHOPPING_CART_TIME_THRESHOLD = 30 * 60 * 1000;
export class ShoppingCartService extends ApiService {
    constructor(planogram, currencyService) {
        super();
        this.planogram = planogram;
        this.currencyService = currencyService;
        this.onShoppingCartStorageChange = this.onShoppingCartStorageChange.bind(this);
        this.checkoutId = `checkout-${this.planogram.clientName}`;
        this.mutlipassId = `multipass_token-${this.planogram.clientName}`;
        const storedCheckoutState = ShoppingCartUtils.getStoredShoppingCart(this.checkoutId);
        this.checkoutSubject = makeBehaviorSubject(JSON.parse(storedCheckoutState) || null);
        this.processingSubject = makeBehaviorSubject(false);
        window.addEventListener('storage', this.onShoppingCartStorageChange);
        this.checkoutSubjectRef = pipe(this.checkoutSubject, subscribe({
            next: (data) => {
                if (data && !data.last_update) {
                    data = Object.assign(Object.assign({}, data), { last_update: new Date().getTime() });
                }
                this.shoppingCart = data;
            },
            error: () => {
                this.shoppingCart = null;
            }
        }));
    }
    get getMultipassToken() {
        return window.localStorage.getItem(this.mutlipassId);
    }
    fireShoppingCartUpdates(variant) {
        return this.shoppingCart ? this.updateShoppingCart(variant) : this.createShoppingCart(variant);
    }
    attachShoppingCartToUser() {
        const requestData = {
            checkout: this.shoppingCart.products.map((product) => {
                return {
                    variantId: product.variant_id,
                    quantity: product.quantity,
                    customAttributes: product.custom_attributes
                };
            })
        };
        this.processingSubject(EventType.Data, true);
        return this.createCartRequest(requestData)
            .then(() => ShoppingCartUtils.deleteStoredShoppingCart(this.checkoutId))
            .finally(() => this.processingSubject(EventType.Data, false));
    }
    updateShoppingCartQuantity(variant) {
        const indexOfVariant = this.getIndexVariant(variant);
        if (indexOfVariant !== -1) {
            this.shoppingCart.checkout[indexOfVariant].quantity = variant.quantity;
        }
        this.processingSubject(EventType.Data, true);
        return this.updateCartRequest()
            .then(data => this.saveShoppingCartState(data))
            .catch(err => this.clearShoppingCartState(err))
            .finally(() => this.processingSubject(EventType.Data, false));
    }
    clearShoppingCartState(err) {
        this.checkoutSubject(EventType.End, err);
        ShoppingCartUtils.deleteStoredShoppingCart(this.checkoutId);
        if (err) {
            throw err;
        }
    }
    clearShoppingCartToken() {
        this.isMultipassEnabled = false;
        window.localStorage.removeItem(this.mutlipassId);
    }
    removeItemFromCart(variant) {
        const indexOfVariant = this.getIndexVariant(variant);
        this.shoppingCart.checkout.splice(indexOfVariant, 1);
        this.processingSubject(EventType.Data, true);
        return this.updateCartRequest()
            .then(data => this.saveShoppingCartState(data))
            .catch(err => this.clearShoppingCartState(err))
            .finally(() => this.processingSubject(EventType.Data, false));
    }
    generateMultipassLink(link) {
        const url = UrlUtils.insertFewValuesToUrl(URLS.SHOPIFY_GENERATE_MULTIPASS_LINK, {
            token: this.getMultipassToken,
            link
        });
        return this.get(url)
            .then(resp => resp.json());
    }
    createCartRequest(data) {
        const currentLang = L10nUtils.getCurrentLanguage();
        const url = UrlUtils.insertFewValuesToUrl(URLS.SHOPIFY_CHECKOUT_CREATE, { lang: currentLang, currency_code: this.currencyService.selectedCurrencyCode });
        return this.post(url, data)
            .then(resp => resp.json());
    }
    updateCartRequest() {
        const currentLang = L10nUtils.getCurrentLanguage();
        const url = UrlUtils.insertFewValuesToUrl(URLS.SHOPIFY_CHECKOUT_UPDATE, { id: this.shoppingCart.checkout_id, lang: currentLang, currency_code: this.currencyService.selectedCurrencyCode });
        return this.put(url, {
            checkout: this.shoppingCart.checkout
        }).then(resp => resp.json());
    }
    createShoppingCart(variant) {
        const requestData = {
            checkout: [
                {
                    variantId: variant.variant_id,
                    quantity: variant.quantity,
                    customAttributes: variant.custom_attributes
                }
            ]
        };
        this.processingSubject(EventType.Data, true);
        return this.createCartRequest(requestData)
            .then(data => this.saveShoppingCartState(data))
            .catch(err => this.clearShoppingCartState(err))
            .finally(() => this.processingSubject(EventType.Data, false));
    }
    updateShoppingCart(variant) {
        const indexOfVariant = this.getIndexVariant(variant);
        const overallQuantity = AppUtils.overallQuantity(this.shoppingCart, variant, variant === null || variant === void 0 ? void 0 : variant.per_order_limit);
        let availableQuantity = variant.inventory_quantity - overallQuantity;
        if (indexOfVariant !== -1) {
            if (variant.per_order_limit) {
                availableQuantity = variant.per_order_limit - overallQuantity;
                this.shoppingCart.checkout[indexOfVariant].quantity = Math.min(this.shoppingCart.checkout[indexOfVariant].quantity + variant.quantity, availableQuantity < variant.per_order_limit ?
                    this.shoppingCart.checkout[indexOfVariant].quantity + availableQuantity :
                    variant.per_order_limit);
            }
            else {
                this.shoppingCart.checkout[indexOfVariant].quantity = Math.min(this.shoppingCart.checkout[indexOfVariant].quantity + variant.quantity, availableQuantity < variant.inventory_quantity ?
                    this.shoppingCart.checkout[indexOfVariant].quantity + availableQuantity :
                    variant.inventory_quantity);
            }
            this.shoppingCart.checkout[indexOfVariant].customAttributes = variant.custom_attributes;
        }
        else {
            if (variant.per_order_limit) {
                availableQuantity = variant.per_order_limit - overallQuantity;
            }
            const shoppingCartVariant = {
                variantId: variant.variant_id,
                quantity: variant.quantity > availableQuantity ? availableQuantity : variant.quantity,
                customAttributes: variant.custom_attributes
            };
            this.shoppingCart.checkout.push(shoppingCartVariant);
        }
        this.processingSubject(EventType.Data, true);
        return this.updateCartRequest()
            .then(data => this.saveShoppingCartState(data))
            .catch(err => this.clearShoppingCartState(err))
            .finally(() => this.processingSubject(EventType.Data, false));
    }
    onShoppingCartStorageChange({ storageArea, key, newValue }) {
        if (storageArea === window.localStorage && key === this.checkoutId) {
            if (newValue) {
                const checkout = JSON.parse(newValue);
                this.saveShoppingCartState({ checkout: checkout });
            }
            else {
                this.clearShoppingCartState();
            }
        }
        else if (storageArea === window.localStorage && key === ACCESS_TOKEN_KEY && !newValue) {
            this.clearShoppingCartState();
        }
        else if (storageArea === window.localStorage && key === CURRENCY_CODE_KEY && newValue && Account.isLogged) {
            this.updateShoppingCartCurrency(newValue);
        }
    }
    getIndexVariant(variant) {
        var _a;
        return (_a = this.shoppingCart.checkout) === null || _a === void 0 ? void 0 : _a.findIndex((product) => {
            var _a, _b;
            if (!((_a = variant.custom_attributes) === null || _a === void 0 ? void 0 : _a.length) && !((_b = product.customAttributes) === null || _b === void 0 ? void 0 : _b.length)) {
                return product.variantId === variant.variant_id;
            }
            if (product.customAttributes === undefined || variant.custom_attributes === undefined) {
                return product.variantId === variant.variant_id;
            }
            return Array.from(product.customAttributes).every(({ key, value }, index) => {
                var _a, _b, _c, _d;
                return key === ((_b = (_a = variant.custom_attributes) === null || _a === void 0 ? void 0 : _a[index]) === null || _b === void 0 ? void 0 : _b.key) &&
                    value === ((_d = (_c = variant.custom_attributes) === null || _c === void 0 ? void 0 : _c[index]) === null || _d === void 0 ? void 0 : _d.value);
            });
        });
    }
    checkMultipassToken() {
        const currentUrl = new URL(window.location.href);
        const multipassToken = currentUrl.searchParams.get(SHOPIFY_MULTIPASS_TOKEN_KEY) ||
            this.getMultipassToken;
        if (multipassToken) {
            const url = UrlUtils.insertValueToUrl(URLS.SHOPIFY_VERIFY_MULTIPASS_TOKEN, multipassToken);
            return this.get(url)
                .then((resp) => resp.json().then(data => {
                this.isMultipassEnabled = !!data.status.status && this.planogram.isMultipassKeyAvailable;
                if (this.isMultipassEnabled) {
                    window.localStorage.setItem(this.mutlipassId, multipassToken);
                }
                else {
                    window.localStorage.removeItem(this.mutlipassId);
                }
                return data;
            }))
                .catch(err => {
                console.warn(err);
                this.clearShoppingCartToken();
            })
                .finally(() => Router.removeParamFromUrl(SHOPIFY_MULTIPASS_TOKEN_KEY));
        }
        return Promise.resolve();
    }
    saveShoppingCartState(data) {
        var _a, _b;
        const checkoutProducts = (_b = (_a = data.checkout) === null || _a === void 0 ? void 0 : _a.products) === null || _b === void 0 ? void 0 : _b.map((product) => {
            return {
                variantId: product.variant_id,
                quantity: product.quantity,
                customAttributes: product.custom_attributes
            };
        });
        const parsedData = Object.assign(Object.assign({}, data.checkout), { checkout: checkoutProducts });
        this.checkoutSubject(EventType.Data, parsedData);
        if (!Account.isLogged || this.isMultipassEnabled) {
            this.updateShoppingCartState();
            parsedData.last_update = new Date().getTime();
            window.localStorage.setItem(this.checkoutId, JSON.stringify(parsedData));
        }
        return parsedData;
    }
    storeEmail(email) {
        if (email) {
            this.email = email;
        }
    }
    updateShoppingCartCurrency(currencyCode) {
        if (!this.shoppingCart) {
            return Promise.resolve();
        }
        const url = UrlUtils.insertFewValuesToUrl(URLS.SHOPIFY_CHECKOUT_UPDATE_CURRENCY, { id: this.shoppingCart.checkout_id, currency_code: currencyCode, lang: L10nUtils.getCurrentLanguage() });
        this.processingSubject(EventType.Data, true);
        ShoppingCartService.isProcessing = true;
        return this.put(url, { checkout: this.shoppingCart.checkout })
            .then(resp => resp.json())
            .then(data => this.saveShoppingCartState(data))
            .catch(err => this.clearShoppingCartState(err))
            .finally(() => {
            this.processingSubject(EventType.Data, false);
            ShoppingCartService.isProcessing = false;
        });
    }
    isShoppingCartOutdated() {
        var _a;
        if (!Account.isLogged && ((_a = this.shoppingCart) === null || _a === void 0 ? void 0 : _a.last_update)) {
            const currentTime = new Date().getTime();
            return this.shoppingCart.last_update + SHOPPING_CART_TIME_THRESHOLD < currentTime;
        }
        return false;
    }
    trackShoppingCartUpdates() {
        AppUtils.requestTimeoutRaf(() => this.updateShoppingCartState(), SHOPPING_CART_UPDATE_INTERVAL, fn => this.cancelAnimationFn = fn);
    }
    updateShoppingCartState(forceUpdate = false) {
        var _a;
        this.clearShoppingCartTimer();
        if (!Account.isLogged && this.isShoppingCartOutdated() || forceUpdate && !ShoppingCartService.isProcessing) {
            this.updateShoppingCartCurrency((_a = this.shoppingCart) === null || _a === void 0 ? void 0 : _a.currency_code)
                .finally(() => this.trackShoppingCartUpdates());
            return;
        }
        else if (this.shoppingCart) {
            this.trackShoppingCartUpdates();
        }
    }
    clearShoppingCartTimer() {
        var _a;
        (_a = this.cancelAnimationFn) === null || _a === void 0 ? void 0 : _a.call(this);
    }
    dispose() {
        window.removeEventListener('storage', this.onShoppingCartStorageChange);
        this.checkoutSubject(EventType.End, true);
        this.processingSubject(EventType.End, true);
        this.checkoutSubjectRef();
    }
}
