import pipe from 'callbag-pipe';
import subscribe from 'callbag-subscribe';
import { ProductInfoService } from './product_info.service';
import { L10nUtils } from '../../utils/l10n_utils';
import { URLS, UrlUtils } from '../urls';
export class ShopifyProductInfoService extends ProductInfoService {
    constructor(currencyService) {
        super();
        this.currencyService = currencyService;
        pipe(this.currencyService.changeCurrencySubject, subscribe({ next: (currencyCode) => {
                if (this.currencyService.selectedCurrencyCode !== currencyCode) {
                    ProductInfoService.clearProductInfoCache();
                }
            } }));
    }
    getView(code) {
        const productInfo = ProductInfoService.getProductInfo(code);
        if (productInfo) {
            return Promise.resolve(productInfo);
        }
        const url = UrlUtils.insertFewValuesToUrl(URLS.SHOPIFY_PRODUCT_VIEW, {
            code,
            lang: L10nUtils.getCurrentLanguage(),
            currency_code: this.currencyService.selectedCurrencyCode
        });
        return this.get(url)
            .then(resp => resp.json())
            .then(product => {
            ProductInfoService.setProductInfo(product.identifier, product);
            return product;
        });
    }
    getProducts(listOfProducts) {
        const currentLang = L10nUtils.getCurrentLanguage();
        const url = UrlUtils.insertFewValuesToUrl(URLS.SHOPIFY_PRODUCTS, {
            lang: currentLang,
            currency_code: this.currencyService.selectedCurrencyCode
        });
        return this.post(url, { identifiers: listOfProducts })
            .then(resp => resp.json())
            .then(data => {
            data.products.map(product => ProductInfoService.setProductInfo(product.identifier, product));
            return data.products;
        });
    }
}
