import { QuantityPicker } from '../../../shopify-product-info/components/quantity-picker/quantity-picker';
export class IdealQuantityPicker extends QuantityPicker {
    constructor(product, mainContainer, shopifyService, shoppingCartService) {
        super(product, mainContainer, shopifyService, shoppingCartService);
        this.product = product;
        this.mainContainer = mainContainer;
        this.shopifyService = shopifyService;
        this.shoppingCartService = shoppingCartService;
    }
    get product_quantity() {
        return this.quantity;
    }
    get product_variant_id() {
        var _a;
        return (_a = this.variant) === null || _a === void 0 ? void 0 : _a.sku;
    }
    get per_order_limit() {
        return undefined;
    }
    get product_variant_inventory_quantity() {
        var _a;
        return (_a = this.variant) === null || _a === void 0 ? void 0 : _a.inventory_quantity;
    }
    updateAvailableQuantity() {
        var _a;
        this.availableQuantity = (_a = this.variant) === null || _a === void 0 ? void 0 : _a.quantity;
    }
}
