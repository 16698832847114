import { CDN_RESOURCES, URLS, UrlUtils } from './api/urls';
import { BrowserUtils } from './utils/browser_utils';
import { VideoUtils } from './utils/video_utils';
import { PRODUCT_GALLERY_ITEM, PRODUCT_VIDEO_PROVIDER, PRODUCT_VIDEO_THUMB_TYPE } from './shared/constants';
const fallbackSlide = {
    image_name: 'fallback image',
    variants: null,
    seo_title: '',
    accessibility_description: ''
};
export class ProductSlides {
    constructor({ product_images: images, product_videos: videos, video_first }, item) {
        const imageItems = (images.length === 0 ? [fallbackSlide] : images)
            .map(({ image_name, variants, seo_title, accessibility_description }) => {
            const path = BrowserUtils.pickImageVariant(variants);
            const fallbackImage = URLS.OVERLAY_GALLERY_IMAGE_FALLBACK;
            const thumb = variants === null || variants === void 0 ? void 0 : variants.VARIANT_THUMBNAIL_WEBP;
            return {
                type: PRODUCT_GALLERY_ITEM.IMAGE,
                name: image_name,
                url: path ? UrlUtils.insertValueToUrl(CDN_RESOURCES.PRODUCT_IMAGE, path) : fallbackImage,
                thumbUrl: thumb ? UrlUtils.insertValueToUrl(CDN_RESOURCES.PRODUCT_IMAGE, thumb) : fallbackImage,
                seo_title: seo_title,
                accessibility_description: accessibility_description
            };
        });
        const videoItems = (videos !== null && videos !== void 0 ? videos : []).map(({ provider, video_url, seo_title, accessibility_description }) => ({
            type: PRODUCT_GALLERY_ITEM.VIDEO,
            videoUrlOrId: VideoUtils.getSlideUrlOrId(video_url),
            thumbUrl: VideoUtils.thumbnailUrl(video_url, PRODUCT_VIDEO_THUMB_TYPE.THUMB_PREV),
            videoPreview: VideoUtils.thumbnailUrl(video_url, PRODUCT_VIDEO_THUMB_TYPE.VIDEO_PREV),
            provider: provider === PRODUCT_VIDEO_PROVIDER.CDN ? PRODUCT_VIDEO_PROVIDER.HTML : provider,
            seo_title: seo_title,
            accessibility_description: accessibility_description
        }));
        this.slides = video_first ? [...videoItems, ...imageItems] : [...imageItems, ...videoItems];
        const clickedImageName = this.removeFileExtension(item.imageName);
        this.imageIndex = 0;
        for (let i = 0; i < images.length; i++) {
            if (this.removeFileExtension(images[i].image_name) === clickedImageName) {
                this.imageIndex = video_first ? 0 : i;
                break;
            }
        }
        this.length = this.slides.length;
    }
    removeFileExtension(imageFilename) {
        if (!imageFilename) {
            return imageFilename;
        }
        return imageFilename.replace(/\.\w+$/, '');
    }
}
