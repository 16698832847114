var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ShopifyProductInfo } from '../shopify-product_info';
import { VariantsComponent } from '../components/variants/variants_component';
import { QuantityPicker } from '../components/quantity-picker/quantity-picker';
import { ShopifyButtons } from '../components/buttons/shopify-buttons_component';
import { ProductFields } from '../components/product-fields/product-fields_component';
import { ShoppingCartWidgetComponent } from '../../../shopping-cart-widget/shopping-cart-widget.component';
import { shopifyHarryPotterOverlayView } from './shopify-harry-potter-product_info.view';
import { CurrencyUtils } from '../../../utils/currency_utils';
import { InputFieldsComponent } from './input-fields/input-fields-component';
import { OutOfStockComponent } from './components/out-of-stock/out-of-stock-component';
export class ShopifyHarryPotterProductInfo extends ShopifyProductInfo {
    constructor(container, item, planogram, redirectToProduct, options, productInfoService, shoppingCartService, currencyService, closeCallback) {
        super(container, item, planogram, redirectToProduct, options, productInfoService, shoppingCartService, currencyService, closeCallback);
        this.planogram = planogram;
        this.redirectToProduct = redirectToProduct;
        this.shoppingCartService = shoppingCartService;
        this.currencyService = currencyService;
    }
    get isProductAvailable() {
        var _a;
        return ((_a = this.variant) === null || _a === void 0 ? void 0 : _a.inventory_quantity) > 0;
    }
    init() {
        const _super = Object.create(null, {
            init: { get: () => super.init }
        });
        return __awaiter(this, void 0, void 0, function* () {
            yield _super.init.call(this);
            if (this.product.input_fields.length && this.overlaySettings.enable_buttons) {
                this.inputFields = new InputFieldsComponent(this.container, this.product, this.shopifyService);
            }
        });
    }
    setItemContent() {
        this.container.innerHTML = shopifyHarryPotterOverlayView(this.product, this.overlaySettings.enable_buttons);
        this.mainContainer = document.getElementById('shopify-product-details-container');
        if (this.overlaySettings.enable_buttons) {
            this.variantComponents = this.product.options.map(option => {
                return new VariantsComponent(option, this.product, this.mainContainer, this.shopifyService);
            });
            this.quantityPicker = new QuantityPicker(this.product, this.mainContainer, this.shopifyService, this.shoppingCartService);
            this.outOfStock = new OutOfStockComponent(this.planogram, this.mainContainer, this.product, this.shopifyService, this.shoppingCartService);
            this.buttons = new ShopifyButtons(this, this.product, this.overlaySettings, this.mainContainer, this.quantityPicker, this.shopifyService, this.shoppingCartService);
            this.shoppingCartWidget = new ShoppingCartWidgetComponent(this.planogram, this.shoppingCartService, this.currencyService, this.redirectToProduct, this.quantityPicker, 'app-ecommerce-cart-container', this.product);
        }
        this.productFields = new ProductFields(this.product, this.mainContainer, this.overlaySettings);
        this.setOverlayFonts();
        this.syncEcommerceOverlayColor();
    }
    updateProductPrice(selectedVariant) {
        if (!this.mainContainer) {
            return;
        }
        const priceWrapper = this.mainContainer.querySelector('.app-ecommerce-product-price');
        const priceContainer = priceWrapper.querySelector('.app-ecommerce-product-price-value');
        const discountPriceContainer = priceWrapper.querySelector('.app-ecommerce-product-discount-price-value');
        priceWrapper.classList.remove('has-discount-price', 'is-unavailable', 'is-unavailable-without-margin');
        if (!selectedVariant && !selectedVariant.isAutoSelected) {
            priceWrapper.classList.add('is-unavailable-without-margin');
            priceContainer.innerText = '';
            discountPriceContainer.innerText = '';
            return;
        }
        else if (selectedVariant.inventory_quantity <= 0 && !selectedVariant.isAutoSelected) {
            priceWrapper === null || priceWrapper === void 0 ? void 0 : priceWrapper.classList.add('is-unavailable');
        }
        const { selectedCurrencyCode, currenciesList } = this.currencyService;
        const getPriceValue = price => CurrencyUtils.getPriceForHarryPotterWithSymbolOrCode(price, selectedCurrencyCode, currenciesList);
        if ((selectedVariant === null || selectedVariant === void 0 ? void 0 : selectedVariant.compare_at_price) && parseFloat(selectedVariant === null || selectedVariant === void 0 ? void 0 : selectedVariant.compare_at_price)) {
            // due to weird behaviour of shopify api discount price logic
            priceContainer.innerHTML = getPriceValue(selectedVariant.compare_at_price);
            discountPriceContainer.innerHTML = getPriceValue(selectedVariant.price);
            priceWrapper.classList.add('has-discount-price');
        }
        else {
            priceContainer.innerHTML = getPriceValue(selectedVariant.price);
            discountPriceContainer.innerHTML = '';
        }
    }
    dispose() {
        var _a;
        super.dispose();
        (_a = this.outOfStock) === null || _a === void 0 ? void 0 : _a.dispose();
    }
}
