import { shopifyOverlayHandler } from '../../../../../custom_event_utils';
export class InputFieldComponent {
    constructor(inputWrapper, options) {
        var _a;
        this.inputWrapper = inputWrapper;
        this.liveValidation = true;
        this.options = options || {};
        this.validationPattern = ((_a = this.options) === null || _a === void 0 ? void 0 : _a.validationPattern) || /[^a-zA-Z .'/-]/g;
        if (this.options.liveValidation === false) {
            this.liveValidation = this.options.liveValidation;
        }
        this.inputEl = this.inputWrapper.querySelector('input');
        this.inputEl.addEventListener('input', e => this.onInputChange(e));
        this.inputEl.addEventListener('focusin', () => shopifyOverlayHandler.emit('inputFieldFocus'));
        this.inputEl.addEventListener('focusout', () => shopifyOverlayHandler.emit('inputFieldFocus'));
    }
    get value() {
        return this.inputEl.value;
    }
    validate() {
        return this.inputEl.reportValidity();
    }
    setMaxLength(value) {
        this.inputEl.setAttribute('maxlength', value.toString());
        this.inputEl.value = this.inputEl.value.slice(0, value);
    }
    onInputChange(event) {
        const el = event.target;
        if (this.liveValidation) {
            // @ts-ignore
            el.value = el.value.replaceAll(this.validationPattern, '');
        }
        if (this.options.uppercase) {
            el.value = el.value.toUpperCase();
        }
        if (this.options.onChange) {
            this.options.onChange(el.value);
        }
        el.value = el.value.slice(0, this.inputEl.maxLength);
    }
}
