import { L10nUtils } from '../../utils/l10n_utils';
import { URLS } from '../../api/urls';
export const shopifyOverlayView = (product) => {
    const checkMarkIcon = `
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity=".65">
        <path d="m14.183 5.133-7.334 7.334a.605.605 0 0 1-.466.2.605.605 0 0 1-.467-.2L2.583 9.133a.644.644 0 0 1 0-.933.644.644 0 0 1 .933 0l2.867 2.867L13.249 4.2a.644.644 0 0 1 .934 0 .644.644 0 0 1 0 .933z" fill="#000"/>
        <mask id="a" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="2" y="4" width="13" height="9">
          <path d="m14.183 5.133-7.334 7.334a.605.605 0 0 1-.466.2.605.605 0 0 1-.467-.2L2.583 9.133a.644.644 0 0 1 0-.933.644.644 0 0 1 .933 0l2.867 2.867L13.249 4.2a.644.644 0 0 1 .934 0 .644.644 0 0 1 0 .933z" fill="currentColor"/>
        </mask>
      </g>
    </svg>
  `;
    const shareLinkSrc = URLS.OVERLAY_SHARE_LINK_ICON;
    const shareDeviceSrc = URLS.OVERLAY_SHARE_DEVICE_ICON;
    const twitterShareLinkSrc = URLS.OVERLAY_TWITTER_ICON;
    const whatsappShareLinkSrc = URLS.OVERLAY_WHATSAPP_ICON;
    const telegramShareLinkSrc = URLS.OVERLAY_TELEGRAM_ICON;
    const viberShareLinkSrc = URLS.OVERLAY_VIBER_ICON;
    return `
    <div id="shopify-product-details-container" class="app-shopify-template">
      <div class="app-ecommerce-product-overlay">
        <div class="app-ecommerce-product-overlay-controls app-product-overlay-controls">
          <div class="overlay-button overlay-button-close">
            <span></span>
          </div>
        </div>
        <div class="app-ecommerce-product-content-wrapper">
          <div class="app-ecommerce-product-carousel">
            <div class="app-ecommerce-image-carousel-container">
              <div id="app-image-carousel" class="splide">
                <div class="splide__arrows">
                  <button class="splide__arrow splide__arrow--prev">
                    <span class="overlay-arrow overlay-arrow-prev"></span>
                  </button>
                  <button class="splide__arrow splide__arrow--next">
                    <span class="overlay-arrow overlay-arrow-next"></span>
                  </button>
                </div>
                <div class="splide__track">
                  <ul class="splide__list"></ul>
                </div>
              </div>
              
              <div class="see_inside-button-wrapper">
                <div id="see_inside-button" class="is-hidden">
                  ${L10nUtils.l10n('shopify-product-overlay.see-inside-btn-text')}
                </div>
              </div>
            </div>
            
            <div class="app-ecommerce-thumbnail-carousel-container">
              <div id="app-thumbnail-carousel" class="splide">
                <div id="app-ecommerce-thumbnail-splide-arrows" class="splide__arrows">
                  <button class="splide__arrow splide__arrow--prev">
                    <span class="overlay-arrow overlay-arrow-prev"></span>
                  </button>
                  <button class="splide__arrow splide__arrow--next">
                    <span class="overlay-arrow overlay-arrow-next"></span>
                  </button>
                </div>
                <div class="splide__track">
                  <ul class="splide__list">
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="app-ecommerce-product-details-container">
            <div class="text-wrapper allow-scroll">
              <div class="share-mobile-container">
                <div id="share-mobile" class="is-hidden" role="button" tabindex="0">
                  <span class="share-mobile-text">${L10nUtils.l10n('product-details-overlay.share-button.text')}</span>
                  <img src="${shareDeviceSrc}">
                </div>
              </div>
              <div class="app-ecommerce-product-details-header">
                <div class="app-ecommerce-product-title">${product.name}</div>
              </div>
              <div class="app-ecommerce-product-price">
                <span class="app-ecommerce-product-discount-price-value"></span>
                <span class="app-ecommerce-product-price-value"></span>
                <span class="app-ecommerce-product-stock-value"></span>
              </div>
              <div class="app-ecommerce-variants-container"></div>
              <div class="app-ecommerce-quantity-picker-container"></div>
              <div class="app-ecommerce-buttons-container"></div>
              <div class="app-ecommerce-description-container"></div>
              <div class="app-share-links is-hidden">
                <img class="share-button-whatsapp share-button is-hidden" src="${whatsappShareLinkSrc}" role="button" tabindex="0">
                <img class="share-button-telegram share-button is-hidden" src="${telegramShareLinkSrc}" role="button" tabindex="0">
                <img class="share-button-viber share-button is-hidden" src="${viberShareLinkSrc}" role="button" tabindex="0">
                <img class="share-button-twitter share-button is-hidden" src="${twitterShareLinkSrc}" role="button" tabindex="0">
                <div class="share-link-holder">
                  <img class="share-button-copy_link share-button is-hidden" src="${shareLinkSrc}" role="button" tabindex="0">
                  <div class="share-success-message">
                    ${checkMarkIcon}
                    <span>${L10nUtils.l10n('product-details-overlay.share.notification')}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-fade"></div>
          </div>
        </div>
        <div id="share-mobile-popup" class="is-hidden">
          <div class="app-popup">
            <div class="share-button-imgs-wrapper">
              <img class="share-button-whatsapp share-button is-hidden" src="${whatsappShareLinkSrc}">
              <img class="share-button-telegram share-button is-hidden" src="${telegramShareLinkSrc}">
              <img class="share-button-viber share-button is-hidden" src="${viberShareLinkSrc}">
              <img class="share-button-twitter share-button is-hidden" src="${twitterShareLinkSrc}">
              <img class="share-button-copy_link share-button is-hidden" src="${shareLinkSrc}">
            </div>
          </div>
          <div class="app-popup-backdrop"></div>
        </div>
        <div id="app-ecommerce-cart-container" aria-hidden="true"></div>
      </div>
    </div>
  `;
};
