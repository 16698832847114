export function updateUIElementsVisibility(isShow, customClass = 'is-hidden') {
    const elementsToHide = [
        document.getElementById('fullscreen-button-list'),
        document.getElementById('sphere-logo'),
        document.getElementById('search-input-container'),
        document.getElementById('sphere-l10n-container'),
        document.getElementById('top-right-container'),
        document.getElementById('top-left-container'),
        document.getElementById('bottom-container')
    ];
    if (isShow) {
        elementsToHide.forEach(el => el === null || el === void 0 ? void 0 : el.classList.remove(customClass));
    }
    else {
        elementsToHide.forEach(el => el === null || el === void 0 ? void 0 : el.classList.add(customClass));
    }
}
