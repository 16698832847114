import pipe from 'callbag-pipe';
import subscribe from 'callbag-subscribe';
import { buttonsComponentView } from './shopify-buttons_component.view';
import { InputEventUtils } from '../../../../utils/input_event_utils';
import { WebUtils } from '../../../../utils/web_utils';
import { Metrics } from '../../../../metrics';
import { MATOMO_EVENT_NAMES } from '../../../../metric-events';
import { loader } from '../../../../components/loader';
import { AppState } from '../../../../shared/app.state';
import { ShopifyProductInfo } from '../../shopify-product_info';
import { AppUtils } from '../../../../utils/app_utils';
import { UrlUtils } from '../../../../api/urls';
import { L10nUtils } from '../../../../utils/l10n_utils';
export class ShopifyButtons {
    constructor(shopifyProductInfo, product, overlaySettings, mainContainer, quantityPicker, shopifyService, shoppingCartService) {
        this.shopifyProductInfo = shopifyProductInfo;
        this.product = product;
        this.overlaySettings = overlaySettings;
        this.mainContainer = mainContainer;
        this.quantityPicker = quantityPicker;
        this.shopifyService = shopifyService;
        this.shoppingCartService = shoppingCartService;
        this.initComponent();
        this.handleButtonClicks();
        pipe(this.shopifyService.selectedProductSubject, subscribe({
            next: (variant) => {
                this.updateButtonsState(variant);
            }
        }));
    }
    initComponent() {
        var _a;
        const buttonsContainer = this.mainContainer.querySelector('.app-ecommerce-buttons-container');
        const buttons = document.createElement('div');
        buttonsContainer.append(buttons);
        buttons.outerHTML = buttonsComponentView();
        this.buttonsWrapper = this.mainContainer.querySelector('.app-ecommerce-product-buy-buttons-wrapper');
        (_a = this.overlaySettings) === null || _a === void 0 ? void 0 : _a.ecommerce_buttons.forEach(button => {
            if (AppUtils.isHarryPotterClient(this.shopifyProductInfo.item.planogram, AppState.clientName) &&
                button.button_type === 'buy_now' &&
                !this.shopifyProductInfo.enablePersonalizeOverlay) {
                return;
            }
            if (button.color) {
                document.documentElement.style.setProperty(`--${button.button_type}-button-color`, `${button.color}`);
            }
            if (button.color_disabled) {
                document.documentElement.style.setProperty(`--${button.button_type}-button-color-disabled`, `${button.color_disabled}`);
            }
            if (button.use_border) {
                document.documentElement.style.setProperty(`--${button.button_type}-button-border-color`, `${button.border_color}`);
                document.documentElement.style.setProperty(`--${button.button_type}-button-border-width`, `${button.border_width.replace(/px/gi, '')}px`);
            }
            if (button.use_border_disabled) {
                document.documentElement.style.setProperty(`--${button.button_type}-button-border-color-disabled`, `${button.border_color_disabled}`);
                document.documentElement.style.setProperty(`--${button.button_type}-button-border-width-disabled`, `${button.border_width_disabled.replace(/px/gi, '')}px`);
            }
            const buttonItem = ShopifyButtons.createButtonItem(button, this.overlaySettings.button_shape, this.shoppingCartService.isMultipassEnabled);
            const buttonWrapper = document.createElement('div');
            this.buttonsWrapper.append(buttonWrapper);
            buttonWrapper.outerHTML = buttonItem;
        });
    }
    handleButtonClicks() {
        var _a, _b;
        const buyNowButton = (_a = this.buttonsWrapper) === null || _a === void 0 ? void 0 : _a.querySelector('#buy_now-button');
        const addToCartButton = (_b = this.buttonsWrapper) === null || _b === void 0 ? void 0 : _b.querySelector('#add_to_cart-button');
        if (buyNowButton) {
            InputEventUtils.addSelectEvents(buyNowButton, () => {
                var _a, _b;
                if (!this.shopifyProductInfo.validateVariants()) {
                    return;
                }
                if (this.isProductAvailable && !this.shopifyProductInfo.enablePersonalizeOverlay) {
                    Metrics.storeTheEvent(AppState.planogramName, 'click', MATOMO_EVENT_NAMES.ECOMMERCE_CLICK_BUY_NOW(this.product.identifier, UrlUtils.slugify(this.product.name)));
                    if (this.shoppingCartService.getMultipassToken) {
                        const link = window.open();
                        buyNowButton.classList.add('loading');
                        this.shoppingCartService
                            .generateMultipassLink(this.variant.buy_btn_url)
                            .then(resp => (link.location = resp === null || resp === void 0 ? void 0 : resp.link))
                            .finally(() => buyNowButton.classList.remove('loading'));
                    }
                    else {
                        WebUtils.openLink(this.variant.buy_btn_url);
                    }
                }
                else if (this.shopifyProductInfo.enablePersonalizeOverlay && this.isProductAvailable) {
                    (_b = (_a = this.shopifyProductInfo).openPersonalizedOverlay) === null || _b === void 0 ? void 0 : _b.call(_a);
                }
            });
        }
        if (addToCartButton) {
            InputEventUtils.addSelectEvents(addToCartButton, () => {
                var _a, _b, _c;
                if ((this.shopifyProductInfo instanceof ShopifyProductInfo &&
                    !this.shopifyProductInfo.enablePersonalizeOverlay &&
                    this.shopifyProductInfo.inputFields &&
                    !this.shopifyProductInfo.inputFields.validate()) ||
                    !this.shopifyProductInfo.validateVariants()) {
                    return;
                }
                if (this.isProductAvailable && !this.processing) {
                    Metrics.storeTheEvent(AppState.planogramName, 'click', MATOMO_EVENT_NAMES.ECOMMERCE_CLICK_ADD_TO_CART(this.product.identifier, UrlUtils.slugify(this.product.name)));
                    this.processing = this.shopifyProductInfo.showShoppingCartWidget = true;
                }
                if (this.isProductAvailable &&
                    !addToCartButton.classList.contains('loading') &&
                    !this.shopifyProductInfo.enablePersonalizeOverlay) {
                    addToCartButton.classList.add('loading');
                    const data = Object.assign(Object.assign({}, this.variant), { quantity: this.quantityPicker.product_quantity });
                    if (this.shopifyProductInfo instanceof ShopifyProductInfo &&
                        ((_a = this.shopifyProductInfo.inputFields) === null || _a === void 0 ? void 0 : _a.withPersonalization)) {
                        data.custom_attributes = this.shopifyProductInfo.inputFields.values;
                    }
                    this.shoppingCartService
                        .fireShoppingCartUpdates(data)
                        .finally(() => {
                        addToCartButton.classList.remove('loading');
                        this.processing = this.shopifyProductInfo.showShoppingCartWidget = false;
                    });
                }
                else if (this.shopifyProductInfo.enablePersonalizeOverlay && this.isProductAvailable) {
                    (_c = (_b = this.shopifyProductInfo).openPersonalizedOverlay) === null || _c === void 0 ? void 0 : _c.call(_b);
                }
            });
        }
    }
    updateButtonsState(variant) {
        this.buttonsWrapper.classList.remove('disabled');
        this.isProductAvailable = true;
        this.variant = variant;
        if ((!variant || (variant === null || variant === void 0 ? void 0 : variant.inventory_quantity) <= 0) && !(variant === null || variant === void 0 ? void 0 : variant.isAutoSelected)) {
            this.buttonsWrapper.classList.add('disabled');
            this.isProductAvailable = false;
        }
    }
    static createButtonItem(button, shape, isMutlipassEnabled) {
        var _a;
        const currLang = L10nUtils.getCurrentLanguage();
        const buttonTitle = (_a = button.title[currLang]) !== null && _a !== void 0 ? _a : button.title[L10nUtils.fallbackLanguage];
        return `
        <div
        id="${button.button_type}-button"
        class=
        "${shape}-button hp-custom-button app-ecommerce-product-button
        ${button.use_border ? ' with-border' : ''}
        ${button.use_border_disabled ? ' disabled-with-border' : ''}
        ${button.icon_url ? ' with-icon' : ''}
        ${!Boolean(buttonTitle) ? ' without-title' : ''}">
          ${button.icon_url ? `<img src="${button.icon_url}">` : ''}
          <div class="btn-border">
            <div class="left-border">
              <div class="square"></div>
            </div>
            <div class="right-border">
              <div class="square"></div>
            </div>
          </div>
          <span>${buttonTitle !== null && buttonTitle !== void 0 ? buttonTitle : ''}</span>
          ${button.button_type === 'add_to_cart' || isMutlipassEnabled ? `${loader({})}` : ''}
        </div>
        `;
    }
}
