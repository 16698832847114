import { reqSettings } from './request_settings';
export class ApiService {
    get(url, headers) {
        return fetch(url, reqSettings('GET', null, null, headers))
            .then(this.checkResponse);
    }
    getWithAbort(url, signal) {
        return fetch(url, reqSettings('GET', null, signal))
            .then(this.checkResponse);
    }
    getWithoutToken(url) {
        return fetch(url, reqSettings('GET', undefined, undefined, false))
            .then(this.checkResponse);
    }
    post(url, data) {
        return fetch(url, reqSettings('POST', data))
            .then(this.checkResponse);
    }
    postWithoutToken(url, data) {
        return fetch(url, reqSettings('POST', data, undefined, false))
            .then(this.checkResponse);
    }
    put(url, data) {
        return fetch(url, reqSettings('PUT', data))
            .then(this.checkResponse);
    }
    patch(url, data) {
        return fetch(url, reqSettings('PATCH', data))
            .then(this.checkResponse);
    }
    delete(url, data) {
        return fetch(url, reqSettings('DELETE', data))
            .then(this.checkResponse);
    }
    checkResponse(response) {
        if (response.ok) {
            return response;
        }
        else {
            throw response;
        }
    }
}
