import { InputEventUtils } from '../../utils/input_event_utils';
import { ACCOUNT_OVERLAY_TYPE } from '../../shared/constants';
import { AccountBaseOverlay } from '../account-base-overlay/account-base-overlay';
import { Metrics } from '../../metrics';
import { AppState } from '../../shared/app.state';
import { MATOMO_EVENT_NAMES } from '../../metric-events';
export class AccountSignIn extends AccountBaseOverlay {
    init() {
        const linkElToResetPass = this.wrapperEl.querySelector('.link-to-reset-pass');
        const linkElToSignUp = this.wrapperEl.querySelector('.link-to-sign-up');
        InputEventUtils.addSelectEvents(linkElToResetPass, () => {
            Metrics.storeTheEvent(AppState.planogramName, 'open', MATOMO_EVENT_NAMES.OPEN_RESET_PASSWORD);
            this.accountService.setOverlayType(ACCOUNT_OVERLAY_TYPE.RESET_PASS);
        });
        InputEventUtils.addSelectEvents(linkElToSignUp, () => {
            Metrics.storeTheEvent(AppState.planogramName, 'open', MATOMO_EVENT_NAMES.OPEN_CREATE_ACCOUNT);
            this.accountService.setOverlayType(ACCOUNT_OVERLAY_TYPE.SIGN_UP);
        });
    }
    onSubmit(e) {
        Metrics.storeTheEvent(AppState.planogramName, 'click', MATOMO_EVENT_NAMES.CLICK_USER_PROFILE_OVERLAY_LOGIN);
        e.preventDefault();
        const data = new FormData(this.formEl);
        const getValue = key => data.get(key).toString();
        this.accountService.setLoadingState(true);
        this.accountService
            .signIn(getValue('email'), getValue('password'))
            .then(resp => {
            this.accountService.setUserData(resp.user);
            this.accountService.setActiveState(false);
            this.hideError();
            this.clearForm();
        })
            .catch(error => this.onError(error))
            .finally(() => this.accountService.setLoadingState(false));
    }
}
