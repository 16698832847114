export var ImageProcessingStatus;
(function (ImageProcessingStatus) {
    ImageProcessingStatus["Pending"] = "pending";
    ImageProcessingStatus["Downloading"] = "downloading";
    ImageProcessingStatus["CreatingVariants"] = "creating_variants";
    ImageProcessingStatus["ValidatingDimensions"] = "validating_dimensions";
    ImageProcessingStatus["DimensionsOk"] = "dimensions_ok";
    ImageProcessingStatus["DimensionsError"] = "dimensions_error";
    ImageProcessingStatus["PreparingToCutTiles"] = "preparing_to_cut_tiles";
    ImageProcessingStatus["CuttingTiles"] = "cutting_tiles";
    ImageProcessingStatus["UploadingTiles"] = "uploading_tiles";
    ImageProcessingStatus["ErrorGenerating"] = "error_generating";
    ImageProcessingStatus["Generated"] = "generated";
})(ImageProcessingStatus || (ImageProcessingStatus = {}));
export var AssetTypes;
(function (AssetTypes) {
    AssetTypes["IMAGE"] = "image";
    AssetTypes["VIDEO"] = "video";
})(AssetTypes || (AssetTypes = {}));
export var AnimationPathType;
(function (AnimationPathType) {
    AnimationPathType["Custom"] = "custom";
    AnimationPathType["NavigationArrows"] = "navigation-arrows";
    AnimationPathType["Journey"] = "journey";
})(AnimationPathType || (AnimationPathType = {}));
export function isPlanogramVersionImage(item) {
    return item.type === SphereItemType.Image;
}
export function isPlanogramVersionCluster(item) {
    return item.type === SphereItemType.Cluster;
}
export function isPlanogramVersionProduct(item) {
    return item.type === SphereItemType.Product;
}
export function isPlanogramVersionTextArea(item) {
    return item.type === SphereItemType.TextArea || item.type === SphereItemType.Text;
}
export function isPlanogramVersionVideo(item) {
    return item.type === SphereItemType.Video;
}
export function isPlanogramVersionShape(item) {
    return item.type === SphereItemType.Shape;
}
export function isPlanogramVersionCurve(item) {
    return item.type === SphereItemType.Curve;
}
export function isPlanogramVersionGroup(item) {
    return item.type === SphereItemType.Group;
}
export function isPlanogramVersionSocialFeed(item) {
    return item.type === SphereItemType.SocialFeed;
}
export var ActionType;
(function (ActionType) {
    ActionType["AboutUs"] = "about-us";
    ActionType["Animate"] = "animate";
    ActionType["Audio"] = "audio";
    ActionType["ContactUs"] = "contact-us";
    ActionType["ContentOverlay"] = "iframe";
    ActionType["CookiesPolicy"] = "cookies-policy";
    ActionType["ExternalLink"] = "external-link";
    ActionType["IframeLink"] = "iframe-link";
    ActionType["LegalNotice"] = "copyright";
    ActionType["PrivacyPolicy"] = "privacy-policy";
    ActionType["ProductOverlay"] = "product-overlay";
    ActionType["SingleImage"] = "single-image";
    ActionType["SocialLink"] = "social-link";
    ActionType["VideoOverlay"] = "video-overlay";
    ActionType["CarouselOverlay"] = "content-carousel";
})(ActionType || (ActionType = {}));
export function isLinkAction(action) {
    return (action.data !== undefined &&
        (action.type === ActionType.ExternalLink || action.type === ActionType.IframeLink));
}
export function isProductOverlayAction(action) {
    return action.data !== undefined && action.type === ActionType.ProductOverlay;
}
export function isVideoOverlayAction(action) {
    return action.data !== undefined && action.type === ActionType.VideoOverlay;
}
export function isCarouselOverlay(action) {
    return action.data !== undefined && action.type === ActionType.CarouselOverlay;
}
export function isContentOverlayAction(action) {
    return action.data !== undefined && action.type === ActionType.ContentOverlay;
}
export var SocialFeedSource;
(function (SocialFeedSource) {
    SocialFeedSource["Instagram"] = "Instagram";
    SocialFeedSource["Facebook"] = "Facebook";
    SocialFeedSource["X"] = "X";
    SocialFeedSource["YouTube"] = "Youtube";
    SocialFeedSource["TikTok"] = "TikTok";
    SocialFeedSource["Snapchat"] = "Snapchat";
    SocialFeedSource["Twitch"] = "Twitch";
    SocialFeedSource["Linkfire"] = "Linkfire";
})(SocialFeedSource || (SocialFeedSource = {}));
export function isSocialLinkAction(action) {
    return action.data !== undefined && action.type === ActionType.SocialLink;
}
export function isAnimateAction(action) {
    return action.data !== undefined && action.type === ActionType.Animate;
}
export function isAudioAction(action) {
    return action.data !== undefined && action.type === ActionType.Audio;
}
export function isSingleImageAction(action) {
    return action.type === ActionType.SingleImage;
}
export function isInfoOverlayAction(action) {
    return (action.type === ActionType.AboutUs ||
        action.type === ActionType.ContactUs ||
        action.type === ActionType.CookiesPolicy ||
        action.type === ActionType.LegalNotice ||
        action.type === ActionType.PrivacyPolicy);
}
export function isOverlayAction(action) {
    return (action.type === ActionType.ProductOverlay ||
        action.type === ActionType.VideoOverlay ||
        action.type === ActionType.SingleImage ||
        action.type === ActionType.ContentOverlay ||
        action.type === ActionType.ContactUs ||
        action.type === ActionType.CookiesPolicy ||
        action.type === ActionType.LegalNotice ||
        action.type === ActionType.PrivacyPolicy ||
        action.type === ActionType.AboutUs ||
        action.type === ActionType.SocialLink ||
        action.type === ActionType.CarouselOverlay ||
        action.type === ActionType.IframeLink);
}
export var ClusterGridMode;
(function (ClusterGridMode) {
    ClusterGridMode["Aspect"] = "ASPECT";
    ClusterGridMode["Fill"] = "BALANCE";
})(ClusterGridMode || (ClusterGridMode = {}));
export var ClusterMode;
(function (ClusterMode) {
    ClusterMode["Free"] = "FREE";
    ClusterMode["Grid"] = "GRID";
})(ClusterMode || (ClusterMode = {}));
export var GroupedImageProcessingStatus;
(function (GroupedImageProcessingStatus) {
    GroupedImageProcessingStatus["Pending"] = "pending";
    GroupedImageProcessingStatus["DimensionsOk"] = "dimensions_ok";
    GroupedImageProcessingStatus["ErrorGenerating"] = "error_generating";
    GroupedImageProcessingStatus["DimensionsError"] = "dimensions_error";
    GroupedImageProcessingStatus["Generated"] = "generated";
})(GroupedImageProcessingStatus || (GroupedImageProcessingStatus = {}));
export var PlanogramStatus;
(function (PlanogramStatus) {
    PlanogramStatus["DRAFT"] = "draft";
    PlanogramStatus["PUBLISHED"] = "published";
    PlanogramStatus["GENERATED"] = "generated";
    PlanogramStatus["GENERATING"] = "generating";
    PlanogramStatus["PUBLISHED_DRAFTED"] = "published_drafted";
})(PlanogramStatus || (PlanogramStatus = {}));
export var SearchAttribute;
(function (SearchAttribute) {
    SearchAttribute["Identifier"] = "identifier";
    SearchAttribute["Name"] = "name";
})(SearchAttribute || (SearchAttribute = {}));
export var TextHorizontalAlignment;
(function (TextHorizontalAlignment) {
    TextHorizontalAlignment["LEFT"] = "left";
    TextHorizontalAlignment["CENTER"] = "center";
    TextHorizontalAlignment["RIGHT"] = "right";
})(TextHorizontalAlignment || (TextHorizontalAlignment = {}));
export var TextVerticalAlignment;
(function (TextVerticalAlignment) {
    TextVerticalAlignment["TOP"] = "top";
    TextVerticalAlignment["MIDDLE"] = "middle";
    TextVerticalAlignment["BOTTOM"] = "bottom";
})(TextVerticalAlignment || (TextVerticalAlignment = {}));
export var SphereItemType;
(function (SphereItemType) {
    SphereItemType["Default"] = "DEFAULT";
    SphereItemType["Group"] = "GROUP";
    SphereItemType["Image"] = "IMAGE";
    SphereItemType["Video"] = "VIDEO";
    SphereItemType["Text"] = "TEXT";
    SphereItemType["TextArea"] = "TEXT_AREA";
    SphereItemType["Particles"] = "PARTICLES";
    SphereItemType["Shape"] = "SHAPE";
    SphereItemType["Curve"] = "CURVE";
    SphereItemType["Cluster"] = "CLUSTER";
    SphereItemType["Product"] = "PRODUCT";
    SphereItemType["SocialFeed"] = "SOCIAL_FEED";
    SphereItemType["AnimationPath"] = "ANIMATION_PATH";
})(SphereItemType || (SphereItemType = {}));
export var FeedType;
(function (FeedType) {
    FeedType["Image"] = "Image";
    FeedType["Video"] = "Video";
})(FeedType || (FeedType = {}));
