export class Sigmoid {
    static get STOP_THRESHOLD() { return 0.0001; }
    constructor(params) {
        this.curveMaxValue = params.curveMaxValue;
        this.xValueOfMidpoint = params.xValueOfMidpoint;
        this.gradient = params.gradient;
    }
    yValue(xValue) {
        const sigmoidReading = this.sigmoidReading(xValue);
        return (sigmoidReading < Sigmoid.STOP_THRESHOLD) ? 0 : sigmoidReading;
    }
    // f(x) =  L / 1+e^(a+kx)
    // L = max value of sigmoid curve
    // e = Euler's number
    // a = the x-value of the sigmoid curve's midpoint
    // k = the steepness of the curve (gradient)
    // www.desmos.com/calculator/kn9tpwdan5
    sigmoidReading(xValue) {
        const x = ((this.xValueOfMidpoint) + (this.gradient * xValue));
        return this.curveMaxValue / (1 + (Math.pow(Math.exp(1), x)));
    }
}
