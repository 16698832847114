import { InputFieldView } from '../../components/input-field/input-field-component.view';
export const InputFieldsCrestKeyringComponentView = (product) => {
    const field = InputFieldView({
        label: product.input_fields[0].name,
        placeholder: 'Severus',
        maxlength: 12
    });
    return `
    <div class="input-field-wrapper">${field}</div>
  `;
};
