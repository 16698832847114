import { ApiService } from '../api';
import { URLS, UrlUtils } from '../urls';
export class OutOfStockService extends ApiService {
    getCountry(shopName) {
        const url = UrlUtils.insertValueToUrl(URLS.SHOPIFY_COUNTRY, shopName);
        return this.get(url)
            .then(resp => resp.json())
            .then((data) => { var _a, _b; return (_b = (_a = data === null || data === void 0 ? void 0 : data.detected_values) === null || _a === void 0 ? void 0 : _a.country) === null || _b === void 0 ? void 0 : _b.handle; })
            .catch(e => console.error(e));
    }
}
