import { SPHERE_EVENT_NAMES } from './event-names';
import { ViewableLimits } from './viewable_limits';
import { sphereEventHandler } from './custom_event_utils';
import { Box2, MathUtils, PerspectiveCamera, Ray, Vector2, Vector3 } from 'three';
import { WebUtils } from './utils/web_utils';
import PlanogramPoint from 'shared/utils/PlanogramPoint';
import { degToRad } from 'three/src/math/MathUtils';
function rotateVec3ToYZPlane(vec) {
    // Squash into a 2d vector on an x,y graph as if looking down the 3D y axis
    const fromTopVec2 = new Vector2(vec.x, vec.z);
    const angleFromX = fromTopVec2.angle();
    // Find z by rotating onto the 2d y axis
    fromTopVec2.rotateAround(new Vector2(0, 0), -(angleFromX - Math.PI / 2));
    return new Vector3(0, vec.y, fromTopVec2.y);
}
function angleToRotate(cameraVec3, normalVec3) {
    const cameraPoint = new Vector2(cameraVec3.z, cameraVec3.y);
    const normalPoint = new Vector2(normalVec3.z, normalVec3.y);
    let angleToRotate = normalPoint.angle() - cameraPoint.angle();
    if (normalPoint.angle() > Math.PI) {
        if (cameraPoint.angle() < Math.PI) {
            angleToRotate -= Math.PI * 2;
        }
    }
    if (-angleToRotate > Math.PI * 2 || (-angleToRotate > Math.PI * 1.5 && cameraPoint.angle() > Math.PI * 1.5)) {
        angleToRotate += Math.PI * 2;
    }
    return -angleToRotate;
}
function adjustAngleForZoom(fullAngle, zoomFraction) {
    if (zoomFraction < ZOOM_START_MOTION) {
        return 0.0;
    }
    if (zoomFraction >= ZOOM_END_MOTION) {
        return fullAngle;
    }
    const a = zoomFraction - ZOOM_START_MOTION;
    const b = ZOOM_END_MOTION - ZOOM_START_MOTION;
    return (a / b) * fullAngle;
}
const ASPECT_RATIO_LIMIT = (2 * 16) / 9;
const MIN_FIELD_OF_VIEW = 0.8;
const RADIUS_SCALAR = 1.3;
export const BASE_FOV = 26.68;
// The Camera starts above/off the normal at max zoomout.
// As the camera zooms in, it starts to move towards the surface
// normal (ZOOM_START_MOTION) then reaches the normal at ZOOM_END_MOTION
const ZOOM_START_MOTION = 0.5;
const ZOOM_END_MOTION = 0.75;
export class Camera {
    static get NEAR_CLIPPING() {
        return 1200;
    }
    constructor(planogram, sphereShape) {
        this.planogram = planogram;
        this.sphereShape = sphereShape;
        this.updateCallbacks = [];
        this.perspectiveCamera = new PerspectiveCamera(BASE_FOV, WebUtils.aspectRatio(), Camera.NEAR_CLIPPING, 9999999);
        this.viewport = new Box2(new Vector2(0, 0), new Vector2(0, 0));
        this.viewableLimits = new ViewableLimits(planogram);
        this.cameraHeight = planogram.cameraPosition;
        this.perspectiveCamera.matrixAutoUpdate = false;
        this.initCameraAngles(planogram.startPoint);
        this.initCameraPosition();
        this.pivotPoint = new Vector3(0, 0, 0);
        this.updateMaxFOV();
        this.updateCamera();
    }
    get fixedRadius() {
        return this.planogram.fixedRadius;
    }
    initialZPosition() {
        return this.fixedRadius * RADIUS_SCALAR;
    }
    calcInitialYPosition(cameraPosition) {
        if (typeof cameraPosition !== 'number' || isNaN(cameraPosition)) {
            console.error('cameraPosition value is not a number');
            return 0.27 * this.fixedRadius;
        }
        else if (cameraPosition >= -0.1 && cameraPosition < 0)
            return -0.1 * this.fixedRadius;
        else if (cameraPosition >= 0 && cameraPosition <= 0.1)
            return 0.1 * this.fixedRadius;
        else
            return cameraPosition * this.fixedRadius;
    }
    calcInitialCameraDownTilt(cameraPosition) {
        const cameraY = this.calcInitialYPosition(cameraPosition);
        return Math.atan2(cameraY, this.initialZPosition() + this.planogram.fixedRadius);
    }
    zoomFraction(fov, maxFOV) {
        return 1.0 - (fov - MIN_FIELD_OF_VIEW) / ((maxFOV !== null && maxFOV !== void 0 ? maxFOV : BASE_FOV) - MIN_FIELD_OF_VIEW);
    }
    get position() {
        return this.perspectiveCamera.position;
    }
    get ray() {
        return new Ray(this.position, this.perspectiveCamera.getWorldDirection(new Vector3()));
    }
    get horizontalFOV() {
        return this.initialFOV * ASPECT_RATIO_LIMIT;
    }
    updateAspect() {
        this.perspectiveCamera.aspect = WebUtils.aspectRatio();
        this.updateMaxFOVFromNewAspect();
        this.adjustFOVWhenHeightChanges();
        this.updateTiltAngle();
        this.rotateToNormal(this.intersect);
    }
    updateMaxFOV() {
        this.maxFOV = this.viewableLimits.maxFOV(this.perspectiveCamera.position);
        this.perspectiveCamera.fov = this.maxFOV;
        this.initialFOV = this.maxFOV;
    }
    angleFromInitialCameraPosTo(normal, pivot) {
        const cameraVec3 = this.initialCameraPosition.clone();
        this.pivotPoint = rotateVec3ToYZPlane(pivot);
        this.pivotPoint.z *= -1;
        // Make pivot point the origin for camera vector
        cameraVec3.sub(this.pivotPoint);
        return angleToRotate(cameraVec3, rotateVec3ToYZPlane(normal));
    }
    rotateToNormal(intersect) {
        this.intersect = intersect;
        if (!intersect) {
            return;
        }
        const cameraToNormalAngle = this.angleFromInitialCameraPosTo(intersect.normal, intersect.point);
        const fullAjustmentAngle = cameraToNormalAngle - this.initialCameraDownTilt;
        this.zoomAdjustAngle = adjustAngleForZoom(fullAjustmentAngle, this.currentZoomFraction());
    }
    currentZoomFraction() {
        return this.zoomFraction(this.perspectiveCamera.fov, this.maxFOV);
    }
    planogramPoint() {
        const ray = this.ray;
        const t = this.sphereShape.castRayFarthest(ray);
        if (!t)
            return new PlanogramPoint();
        const spherePoint = this.sphereShape.reverse(ray.at(t, new Vector3()));
        return new PlanogramPoint(this.sphereShape.planogramCoordinateViewer(spherePoint, this.planogram.size()));
    }
    zoom() {
        return Math.tan(degToRad(this.maxFOV)) / Math.tan(degToRad(this.perspectiveCamera.fov));
    }
    baseZoomPlanogramHeight() {
        return this.viewableLimits.getLimitHeight();
    }
    onUpdate(callback) {
        this.updateCallbacks.push(callback);
    }
    pivotCameraForZoom() {
        this.perspectiveCamera.translateX(this.pivotPoint.x);
        this.perspectiveCamera.translateY(this.pivotPoint.y);
        this.perspectiveCamera.translateZ(this.pivotPoint.z);
        this.perspectiveCamera.rotateX(this.zoomAdjustAngle);
        this.perspectiveCamera.translateX(-this.pivotPoint.x);
        this.perspectiveCamera.translateY(-this.pivotPoint.y);
        this.perspectiveCamera.translateZ(-this.pivotPoint.z);
    }
    updateCamera() {
        this.perspectiveCamera.position.set(0, 0, 0);
        this.perspectiveCamera.rotation.set(0, 0, 0);
        this.perspectiveCamera.rotateY(this.panAngle);
        this.pivotCameraForZoom();
        this.perspectiveCamera.translateX(this.initialCameraPosition.x);
        this.perspectiveCamera.translateY(this.initialCameraPosition.y);
        this.perspectiveCamera.translateZ(this.initialCameraPosition.z);
        this.perspectiveCamera.rotateX(this.tiltAngle);
        this.perspectiveCamera.updateMatrix();
        this.perspectiveCamera.updateProjectionMatrix();
        this.perspectiveCamera.updateMatrixWorld();
        this.computeViewport();
        this.updateCallbacks.forEach(callback => callback());
    }
    tiltAndPanBy(adjustment) {
        var _a, _b;
        const angleToPan = (_a = adjustment.pan) !== null && _a !== void 0 ? _a : 0;
        const angleToTilt = (_b = adjustment.tilt) !== null && _b !== void 0 ? _b : 0;
        if (angleToPan === 0 && angleToTilt === 0) {
            return;
        }
        if (angleToTilt !== 0) {
            this.updateTiltAngle(angleToTilt);
        }
        if (angleToPan !== 0) {
            this.panAngle = (this.panAngle + angleToPan) % (Math.PI * 2);
        }
    }
    zoomBy(zoomFactor) {
        const fov = this.perspectiveCamera.fov * zoomFactor;
        this.zoomIn(fov);
        sphereEventHandler.emit(SPHERE_EVENT_NAMES.CAMERA.ZOOM_BY, { fov, zoomFactor }); // Mouse wheel, O & I keys
    }
    zoomIn(fov) {
        this.perspectiveCamera.fov = fov;
        this.clampCameraFOVToMaxFOV();
    }
    fov() {
        return this.perspectiveCamera.fov;
    }
    clampFOV(fov) {
        return MathUtils.clamp(fov, MIN_FIELD_OF_VIEW, this.maxFOV);
    }
    update(adjustment) {
        if (adjustment.fov) {
            this.zoomIn(adjustment.fov);
        }
        this.tiltAndPanBy(adjustment);
        this.updateCamera();
    }
    updateMaxFOVFromNewAspect() {
        if (this.perspectiveCamera.aspect > ASPECT_RATIO_LIMIT) {
            this.maxFOV = this.horizontalFOV / this.perspectiveCamera.aspect;
        }
        else {
            this.maxFOV = this.initialFOV;
        }
        this.clampCameraFOVToMaxFOV();
    }
    adjustFOVWhenHeightChanges() {
        if (this.previousWindowHeight) {
            this.perspectiveCamera.fov *= window.innerHeight / this.previousWindowHeight;
        }
        this.previousWindowHeight = window.innerHeight;
        this.clampCameraFOVToMaxFOV();
    }
    clampCameraFOVToMaxFOV() {
        this.perspectiveCamera.fov = this.clampFOV(this.perspectiveCamera.fov);
    }
    initCameraAngles(arc) {
        this.panAngle = -(arc / this.fixedRadius);
        this.tiltAngle = 0;
        this.zoomAdjustAngle = 0;
    }
    initCameraPosition() {
        this.perspectiveCamera.translateOnAxis(new Vector3(0, 1, 0), this.calcInitialYPosition(this.cameraHeight));
        this.perspectiveCamera.translateOnAxis(new Vector3(0, 0, 1), this.initialZPosition());
        this.initialCameraPosition = this.perspectiveCamera.position.clone();
        this.initialCameraDownTilt = this.calcInitialCameraDownTilt(this.cameraHeight);
    }
    updateTiltAngle(angleToTilt = 0) {
        this.tiltAngle = this.viewableLimits.newAngle(this.perspectiveCamera, this.tiltAngle, this.zoomAdjustAngle, angleToTilt);
    }
    projectCameraRay(direction) {
        const origin = this.perspectiveCamera.position;
        const cameraDirection = direction.unproject(this.perspectiveCamera).sub(origin).normalize();
        const ray = new Ray(origin, cameraDirection);
        const t = this.sphereShape.castRayFarthest(ray);
        if (t === undefined)
            return undefined;
        return ray.at(t, new Vector3());
    }
    sceneToPlanogramCoordinate(v) {
        const sample = this.sphereShape.reverse(v);
        return this.sphereShape.planogramCoordinateViewer(sample, this.planogram.size());
    }
    computeViewport() {
        const left = this.projectCameraRay(new Vector3(-1, 0, 0));
        const right = this.projectCameraRay(new Vector3(1, 0, 0));
        const top = this.projectCameraRay(new Vector3(0, 1, 0));
        const bottom = this.projectCameraRay(new Vector3(0, -1, 0));
        // TODO some raycasts can fail on the first render frame, needs investigation
        if (left && right && top && bottom) {
            const min = new Vector2(this.sceneToPlanogramCoordinate(left).x, this.sceneToPlanogramCoordinate(bottom).y);
            const max = new Vector2(this.sceneToPlanogramCoordinate(right).x, this.sceneToPlanogramCoordinate(top).y);
            this.viewport = new Box2(min, max);
        }
    }
    getViewport() {
        return this.viewport.clone();
    }
}
