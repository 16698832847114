import { Sigmoid } from '../maths/sigmoid';
import { Animation } from './animation';
export class MomentumAnimation extends Animation {
    static get X_AXIS() {
        return 'x';
    }
    static get Y_AXIS() {
        return 'y';
    }
    static get MINIMUM_ANGULAR_SPEED_FOR_MOMENTUM() {
        return 0.0001;
    }
    constructor(currentAngularSpeed, lastRotationCurveTime, axis) {
        super();
        this.currentAngularSpeed = currentAngularSpeed;
        this.lastRotationCurveTime = lastRotationCurveTime;
        this.axis = axis;
        this.speedCurve = new Sigmoid(MomentumAnimation.momentum);
    }
    update() {
        const rotation = this.calculateMomentumRotationCurve(Date.now());
        if (Math.abs(rotation) > MomentumAnimation.MINIMUM_ANGULAR_SPEED_FOR_MOMENTUM) {
            if (this.axis === 'x') {
                return { pan: rotation };
            }
            if (this.axis === 'y') {
                return { tilt: rotation };
            }
        }
        return undefined;
    }
    calculateMomentumRotationCurve(now) {
        let secondsSinceRelease = (now - this.lastRotationCurveTime) / 1000;
        if (secondsSinceRelease <= 0) {
            secondsSinceRelease = 1 / 60.0;
        }
        const speedScale = this.speedCurve.yValue(secondsSinceRelease);
        this.currentAngularSpeed = this.currentAngularSpeed * speedScale;
        const rotateAngle = this.currentAngularSpeed * (secondsSinceRelease * 1000);
        this.lastRotationCurveTime = now;
        return rotateAngle;
    }
}
MomentumAnimation.momentum = {
    curveMaxValue: 0.95,
    xValueOfMidpoint: -5,
    gradient: 120
};
