import { Box2, Ray, Raycaster, Vector2, Vector3 } from 'three';
import { ArrayUtils } from '../utils/array_utils';
import { debounce } from 'lodash';
import { AutoRotateAnimation } from '../animations/auto_rotate_animation';
import { MomentumAnimation } from '../animations/momentum_animation';
import { ZoomToAnimation } from '../animations/zoom_to_animation';
import { RotationUtils } from '../utils/rotation_utils';
import { BASE_FOV } from '../camera';
import { CookiesManagement } from '../cookies_management';
import { SPHERE_EVENT_NAMES as EVENTS } from '../event-names';
import { sphereEventHandler } from '../custom_event_utils';
import { Metrics } from '../metrics';
import { MATOMO_EVENT_NAMES } from '../metric-events';
import { invertYAxis, normalizeMouse } from '../utils/math_utils';
import { WebUtils } from '../utils/web_utils';
import { SPHERE_BG_EVENT_NAME } from '../shared/constants';
import { Modulo } from '../utils/moduloUtils';
import { Planogram } from '../planogram';
const ENTRANCE_ANIMATION_ZOOM_IN_FOV = 21.34;
const ENTRANCE_ANIMATION_DRAG_VALUE = 400;
export class CameraControls {
    static get ROTATE_LEFT() {
        return 1;
    }
    static get ROTATE_RIGHT() {
        return -1;
    }
    static get TILT_UP() {
        return 1;
    }
    static get TILT_DOWN() {
        return -1;
    }
    static get MAX_SPEED_ELEMENT_COUNT() {
        return 5;
    }
    static limitSpeed(speed) {
        const MAXMUM_MOMENTUM_START_SPEED = 0.05;
        if (Math.abs(speed) > MAXMUM_MOMENTUM_START_SPEED) {
            return undefined;
        }
        return speed;
    }
    constructor(camera, sphereShape, sphereItems, planogram) {
        this.camera = camera;
        this.sphereShape = sphereShape;
        this.sphereItems = sphereItems;
        this.planogram = planogram;
        this.modulo = new Modulo(Planogram.pages());
        this.clusterMidPoint = new Vector3();
        this.storedClusterNormal = new Vector3();
        this.animationChain = new Set();
        this.angularSpeedsX = [];
        this.angularSpeedsY = [];
        this.analyticZoomEventFunc = debounce(this.analyticZoomEvent.bind(this), 400);
        sphereEventHandler.listen(EVENTS.ENTRANCE_ANIMATION.ZOOM_ANIMATION, this.entranceAnimationZoom.bind(this));
        sphereEventHandler.listen(EVENTS.ENTRANCE_ANIMATION.DRAG_ANIMATION, this.entranceAnimationDrag.bind(this));
    }
    findFarthestMouseIntersect(screenPosition, camera) {
        const mousePoint = normalizeMouse(screenPosition.x, screenPosition.y);
        const caster = new Raycaster();
        caster.setFromCamera(mousePoint, camera !== null && camera !== void 0 ? camera : this.camera.perspectiveCamera);
        return this.sphereShape.castRayFarthestPoint(caster.ray, new Vector3());
    }
    analyticZoomEvent(zoomDirection, currentZoom, coords, mouseOnPlanogram) {
        var _a, _b;
        const nearestItem = this.nearestItem(mouseOnPlanogram);
        Metrics.storeTheEvent(this.planogram.name, 'zoom', MATOMO_EVENT_NAMES.CAMERA_ZOOM(zoomDirection, currentZoom, Math.round(coords.x), Math.round(coords.y), (_b = (_a = nearestItem === null || nearestItem === void 0 ? void 0 : nearestItem.itemData) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : SPHERE_BG_EVENT_NAME));
    }
    nearestItem(mouseOnPlanogram) {
        const viewport = this.camera.getViewport();
        const itemBox = new Box2();
        const validItems = this.sphereItems.items.filter(it => viewport.containsBox(itemBox.setFromCenterAndSize(it.getViewportCenter(), it.getSize())));
        if (validItems.length === 0)
            return undefined;
        else
            return validItems.reduce((nearest, it) => {
                if (this.modulo.distanceV(mouseOnPlanogram, it.getViewportCenter()) <
                    this.modulo.distanceV(mouseOnPlanogram, nearest.getViewportCenter()))
                    return it;
                else
                    return nearest;
            });
    }
    currentZoomFraction() {
        return this.camera.currentZoomFraction();
    }
    autoRotate(direction) {
        const autoRotate = AutoRotateAnimation.instance;
        autoRotate.addRotation(direction, this.currentZoomFraction());
        if (autoRotate.isNotRotating()) {
            this.stopAutoRotate();
        }
        else {
            this.addAnimation(autoRotate);
        }
        sphereEventHandler.emit(EVENTS.AUTOROTATE, { direction }); // R & L Keys
    }
    stopAutoRotate() {
        const autoRotate = AutoRotateAnimation.instance;
        autoRotate.clearRotation();
        this.removeAnimation(autoRotate);
    }
    zoomBy(zoomFactor) {
        this.camera.zoomBy(zoomFactor);
    }
    animateZoomFov() {
        if (!this.planogram.clustersOrder) {
            return;
        }
        const current = this.sphereShape.castRayFarthestPoint(this.camera.ray, new Vector3());
        const clusterHorMid = new Vector3(this.clusterMidPoint.x, 1, this.clusterMidPoint.z);
        const fullItemFOV = this.camera.clampFOV(BASE_FOV);
        const cluster = this.sphereShape.castRayFarthestPoint(new Ray(clusterHorMid, this.storedClusterNormal), new Vector3());
        const animation = new ZoomToAnimation({
            currentFOV: this.camera.fov(),
            currentPoint: current,
            targetFOV: fullItemFOV,
            targetPoint: cluster,
            duration: this.planogram.animationSettings.duration,
            transitionType: this.planogram.animationSettings.transition_type,
            panBeforeZoom: this.planogram.animationSettings.pan_before_zoom
        });
        this.addAnimation(animation);
        sphereEventHandler.emit(EVENTS.ANIMATE_ZOOM_FOV);
        return animation;
    }
    entranceAnimationZoom() {
        const makeZoomAnimation = (fov, endCallback) => {
            const point = this.sphereShape.castRayFarthestPoint(this.camera.ray, new Vector3());
            return new ZoomToAnimation({
                currentFOV: this.camera.fov(),
                currentPoint: point,
                targetFOV: fov,
                targetPoint: point,
                endCallback,
                duration: this.planogram.animationSettings.duration,
                transitionType: this.planogram.animationSettings.transition_type,
                panBeforeZoom: this.planogram.animationSettings.pan_before_zoom
            });
        };
        const endCallbackFunc = () => this.addAnimation(makeZoomAnimation(BASE_FOV));
        const fullItemFOV = this.camera.clampFOV(ENTRANCE_ANIMATION_ZOOM_IN_FOV);
        this.addAnimation(makeZoomAnimation(fullItemFOV, endCallbackFunc));
    }
    entranceAnimationDrag() {
        const currentPoint = new Vector3();
        this.sphereShape.castRayFarthestPoint(this.camera.ray, currentPoint);
        const finalDestination = currentPoint.clone();
        finalDestination.x -= ENTRANCE_ANIMATION_DRAG_VALUE;
        const makeDragAnimation = (duration, endCallback) => {
            this.sphereShape.castRayFarthestPoint(this.camera.ray, currentPoint);
            const fullItemFOV = this.camera.clampFOV(this.camera.fov());
            return new ZoomToAnimation({
                currentFOV: this.camera.fov(),
                currentPoint,
                targetFOV: fullItemFOV,
                targetPoint: finalDestination,
                endCallback,
                duration: this.planogram.animationSettings.duration * duration,
                transitionType: this.planogram.animationSettings.transition_type,
                panBeforeZoom: this.planogram.animationSettings.pan_before_zoom
            });
        };
        const dragToInitialPositionCallback = () => {
            finalDestination.x -= ENTRANCE_ANIMATION_DRAG_VALUE;
            const dragToInitialPosition = makeDragAnimation(0.5);
            this.addAnimation(dragToInitialPosition);
        };
        const dragLeftAnimationCallback = () => {
            finalDestination.x += 2 * ENTRANCE_ANIMATION_DRAG_VALUE;
            const dragLeftAnimation = makeDragAnimation(1.0, dragToInitialPositionCallback);
            this.addAnimation(dragLeftAnimation);
        };
        const dragRightAnimation = makeDragAnimation(0.5, dragLeftAnimationCallback);
        this.addAnimation(dragRightAnimation);
    }
    zoomToPoint(newPoint, zoomScaleFactor) {
        this.moveCameraToFlattenView();
        let previousIntersect = this.pointerIntersect;
        if (!previousIntersect) {
            previousIntersect = this.findFarthestMouseIntersect(newPoint);
        }
        const oldZoomLevel = this.camera.currentZoomFraction();
        this.camera.zoomBy(zoomScaleFactor);
        this.camera.updateCamera();
        if (this.camera.currentZoomFraction() !== oldZoomLevel) {
            const mouseIntersect = this.findFarthestMouseIntersect(newPoint);
            const mouseOnPlanogram = this.sphereShape.reverse(mouseIntersect);
            const coords = this.sphereShape.planogramCoordinate(new Vector2(newPoint.x, newPoint.y));
            const zoomDirection = this.camera.currentZoomFraction() > oldZoomLevel ? 'zoom_in' : 'zoom_out';
            const currentZoom = Math.round(this.camera.currentZoomFraction() * 100);
            if ((coords === null || coords === void 0 ? void 0 : coords.length) && currentZoom !== this.oldZoom) {
                this.analyticZoomEventFunc(zoomDirection, currentZoom, coords, mouseOnPlanogram);
                this.oldZoom = currentZoom;
            }
        }
        const newIntersect = this.findFarthestMouseIntersect(newPoint);
        this.tiltAndPanBetween(newIntersect, previousIntersect);
        sphereEventHandler.emit(EVENTS.HEATMAP.ZOOM, {
            x: newPoint.x,
            y: newPoint.y,
            zoomLevel: oldZoomLevel,
            newZoomLevel: this.camera.currentZoomFraction
        });
    }
    onMovementStart(pointer) {
        this.lastUpdatedAt = Date.now();
        this.pointerIntersect = this.findFarthestMouseIntersect(pointer);
        this.pointerCameraState = this.camera.perspectiveCamera.clone();
        sphereEventHandler.emit(EVENTS.ON_MOVEMENT_START, { pointer }); // mouse click
    }
    tiltAndPanToSpherePoint(spherePoint) {
        const midIntersect = this.sphereShape.castRayFarthestPoint(this.camera.ray, new Vector3());
        this.tiltAndPanBetween(midIntersect, spherePoint.point);
    }
    tiltAndPanTo(pointer) {
        const newIntersect = this.findFarthestMouseIntersect(pointer, this.pointerCameraState);
        this.tiltAndPanBetween(newIntersect, this.pointerIntersect);
        this.pointerIntersect = newIntersect;
        this.lastUpdatedAt = Date.now();
        sphereEventHandler.emit(EVENTS.TILT_AND_PAN_TO, { pointer }); // mouse pan
    }
    tiltAndPanBy(adjustment) {
        this.camera.tiltAndPanBy(adjustment);
        this.moveCameraToFlattenView();
        this.camera.updateCamera();
    }
    onMovementEnd() {
        var _a, _b;
        if (this.isDragging()) {
            const avgAngularSpeedsX = ArrayUtils.average(this.angularSpeedsX);
            const startPoint = this.sphereShape.planogramCoordinateViewer(this.sphereShape.reverse(this.pointerIntersect), this.planogram.size());
            const invertedStartPoint = invertYAxis(startPoint, this.planogram.size().y);
            const finalPoint = this.sphereShape.planogramCoordinate(new Vector2(window.innerWidth / 2, window.innerHeight / 2));
            const dragDirection = avgAngularSpeedsX < 0 ? 'right' : 'left';
            const midIntersect = this.sphereShape.castRayFarthestPoint(this.camera.ray, new Vector3());
            const nearestItem = this.nearestItem(this.sphereShape.reverse(midIntersect));
            Metrics.storeTheEvent(this.planogram.name, 'drag', MATOMO_EVENT_NAMES.DRAG_EVENT(dragDirection, Math.round(invertedStartPoint.x), Math.round(invertedStartPoint.y), Math.round(finalPoint.x), Math.round(finalPoint.y), (_b = (_a = nearestItem === null || nearestItem === void 0 ? void 0 : nearestItem.itemData) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : SPHERE_BG_EVENT_NAME));
            sphereEventHandler.emit(EVENTS.HEATMAP.MOVE, {
                x: window.innerWidth / 2,
                y: window.innerHeight / 2,
                zoomLevel: this.camera.currentZoomFraction(),
                direction: dragDirection
            });
            this.addAnimation(new MomentumAnimation(avgAngularSpeedsX, this.lastUpdatedAt, MomentumAnimation.X_AXIS));
            this.addAnimation(new MomentumAnimation(ArrayUtils.average(this.angularSpeedsY), this.lastUpdatedAt, MomentumAnimation.Y_AXIS));
        }
        this.angularSpeedsX = [];
        this.angularSpeedsY = [];
        this.lastUpdatedAt = undefined;
        this.pointerIntersect = undefined;
    }
    addAnimation(animation) {
        this.animationChain.add(animation);
    }
    removeAnimation(animation) {
        this.animationChain.delete(animation);
    }
    updateAnimations() {
        Array.from(this.animationChain.values()).forEach(animation => {
            const adjustment = animation.getAdjustment();
            if (!adjustment) {
                this.removeAnimation(animation);
            }
            else if (adjustment.fov) {
                this.camera.update(adjustment);
                this.tiltAndPanToSpherePoint(adjustment.targetPoint);
            }
            else {
                this.camera.update(adjustment);
            }
        });
    }
    clearAnimation(isSphereRotate) {
        if (CookiesManagement.isRedirectAnimationProcessing && isSphereRotate) {
            setTimeout(() => CookiesManagement.init(), 0);
            CookiesManagement.isRedirectAnimationProcessing = false;
        }
        AutoRotateAnimation.clearInstance();
        this.animationChain.clear();
    }
    static getItemSize(arr) {
        return arr.indexOf(Math.max(...arr));
    }
    getCameraFov(value) {
        return 2 * Math.atan(value / (2 * this.cameraDistance)) * (180 / Math.PI);
    }
    static findProperFov(fovWidth, fovHeight) {
        return Math.max(fovWidth, fovHeight);
    }
    createZoomToAnimation(item, endCallback, options = {}) {
        const { delay, duration, wait, transitionType, panBeforeZoom, zoomStopPoint } = options;
        const cameraRadius = this.camera.initialZPosition();
        this.cameraDistance = cameraRadius + item.planogram.fixedRadius;
        const [width, height] = item.getSize().toArray();
        const fovWidth = this.getCameraFov(width / WebUtils.aspectRatio());
        const fovHeight = this.getCameraFov(height);
        const fullItemFOV = this.camera.clampFOV(CameraControls.findProperFov(fovWidth, fovHeight));
        const intersect = this.sphereShape.castRayFarthestPoint(this.camera.ray, new Vector3());
        const [sceneCenter, sceneNormal] = item.getCenter(this.sphereShape);
        this.clusterMidPoint.copy(sceneCenter);
        this.storedClusterNormal.copy(sceneNormal);
        return new ZoomToAnimation({
            currentFOV: this.camera.fov(),
            currentPoint: intersect,
            targetFOV: fullItemFOV,
            targetPoint: sceneCenter,
            endCallback,
            zoomStopPoint,
            delay,
            wait,
            duration: duration !== null && duration !== void 0 ? duration : this.planogram.animationSettings.duration,
            transitionType: transitionType !== null && transitionType !== void 0 ? transitionType : this.planogram.animationSettings.transition_type,
            panBeforeZoom: panBeforeZoom !== undefined ? panBeforeZoom : this.planogram.animationSettings.pan_before_zoom
        });
    }
    animateTo(item, endCallback, options) {
        const zoomToAnimation = this.createZoomToAnimation(item, endCallback, options);
        this.addAnimation(zoomToAnimation);
        return zoomToAnimation;
    }
    tiltAndPanBetween(latestIntersect, previousIntersect) {
        const tiltAngle = RotationUtils.tiltAngleBetween(latestIntersect, previousIntersect, this.camera.position);
        const panAngle = RotationUtils.panAngleBetween(latestIntersect, previousIntersect);
        if (this.lastUpdatedAt) {
            const timePeriod = Date.now() - this.lastUpdatedAt;
            this.angularSpeedsX = ArrayUtils.append(this.angularSpeedsX, CameraControls.limitSpeed(panAngle / timePeriod), CameraControls.MAX_SPEED_ELEMENT_COUNT);
            this.angularSpeedsY = ArrayUtils.append(this.angularSpeedsY, CameraControls.limitSpeed(tiltAngle / timePeriod), CameraControls.MAX_SPEED_ELEMENT_COUNT);
        }
        this.tiltAndPanBy({ tilt: tiltAngle, pan: panAngle });
    }
    moveCameraToFlattenView() {
        const point = this.sphereShape.castRayFarthestPoint(this.camera.ray, new Vector3());
        const normal = this.sphereShape.normalAt(this.sphereShape.reverse(point));
        this.camera.rotateToNormal({ point, normal });
    }
    isDragging() {
        return this.angularSpeedsX.length > 0 && this.angularSpeedsY.length > 0 && Date.now() - this.lastUpdatedAt < 50;
    }
}
