import '../../../../../../styles/overlay/harry-potter-shopify/components/input-field/index.scss';
export const SelectFieldView = ({ label, initialOptionLabel, options, identifier }) => {
    const optionsList = options.reduce((html, option) => html + `<option value="${option}">${option}</option>`, '');
    return `
    <div class="select-field-component">
      <label class="field">
        <div class="field-info">
            <div class="field-label">${label}</div>
        </div>
        <span class="input-field-border">
          <span class="left-border">
            <span class="square"></span>
          </span>
          <span class="right-border">
            <span class="square"></span>
          </span>
          <select id="${identifier || 'title'}" required tabindex="0">
            <option value="">${initialOptionLabel}</option>
            ${optionsList}
          </select>
        </span>
      </label>
    </div>
  `;
};
