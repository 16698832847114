import { L10nUtils } from '../../utils/l10n_utils';
import { controlButton } from './control-button';
export const backButton = () => {
    const icon = `
    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" fill="none" viewBox="0 0 34 34">
        <title>${L10nUtils.l10n('account.panel.back-button.alt-text')}</title>
        <circle cx="17" cy="17" r="16" stroke="currentColor" stroke-opacity=".6" stroke-width=".5"/>
        <path fill="currentColor" fill-rule="evenodd" d="M16.477 11.6c-.065-.065-.151-.1-.244-.1-.092 0-.179.035-.242.098l-5.394 5.393c-.063.063-.097.15-.097.241s.034.177.081.228l.024.02 5.384 5.383c.133.133.352.133.485 0s.133-.353 0-.486l-4.802-4.802h11.485c.188 0 .343-.155.343-.343 0-.187-.156-.343-.343-.343H11.672l4.804-4.804c.133-.133.133-.353.001-.485z" clip-rule="evenodd" opacity=".6"/>
    </svg>
  `;
    return controlButton({
        icon,
        className: 'back-btn'
    });
};
