import { WIDTH } from 'shared/config/PlanogramConfig';
import { Arithmetic, CylinderPoint } from 'shared/utils/modulo.util';
export const planogramWidth = WIDTH;
export const planogramArithmetic = new Arithmetic(WIDTH);
export default class PlanogramPoint extends CylinderPoint {
    constructor(position) {
        super(planogramWidth);
        if (position)
            this.set(position.x, position.y);
        else
            this.set(0, 0);
    }
}
