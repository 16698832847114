import { L10nService } from '../api/services/l10n.service';
import { L10nUtils } from '../utils/l10n_utils';
import Router from '../router';
import { sphereEventHandler } from '../custom_event_utils';
import { SPHERE_EVENT_NAMES as EVENTS } from '../event-names';
import { Search } from '../search/search';
import { LayoutSwitcher } from './layout-switcher/layout-switcher';
import { LAYOUT_SWITCHER_TYPE } from '../shared/constants';
export class L10nButtonComponent {
    constructor() {
        this.isEnabled = false;
        this.localizationService = new L10nService();
        this.showPicker = () => () => {
            this.isOverlayActive = true;
        };
        this.container = document.getElementById('sphere-l10n-container');
        if (!this.container) {
            console.error('l10n > Container for Language Switcher does not exist');
            return;
        }
        sphereEventHandler.listen(EVENTS.OVERLAY.SHOW_CONTENT, this.showPicker);
        this.localizationService
            .getAvailableLanguages()
            .then(list => {
            if (!list || list.length <= 1) {
                this.setAvailability(false);
                return;
            }
            this.initLayoutSwitcher(list);
            this.setAvailability(true);
        })
            .catch(() => {
            this.setAvailability(false);
        });
    }
    initLayoutSwitcher(langList) {
        this.layoutSwitcher = new LayoutSwitcher({
            container: this.container,
            type: LAYOUT_SWITCHER_TYPE.ICON,
            children: langList.map(({ icon, code, name }) => ({ element: `<img src="${icon}" alt="${name}">`, id: code })),
            handleSelect: code => {
                setTimeout(() => {
                    L10nUtils.selectLanguage(code);
                    Router.updateLangCode(code);
                }, 200);
            }
        });
        const currentLang = L10nUtils.getCurrentLanguage();
        this.layoutSwitcher.setActiveState(currentLang);
    }
    setAvailability(data) {
        this.isEnabled = data;
        if (data && !Search.isSearchActive && !this.isOverlayActive) {
            this.container.classList.remove('is-hidden');
        }
        else {
            this.container.classList.add('is-hidden');
        }
    }
    clearAvailableLanguagesList() {
        this.localizationService.clearStoredAvailableLanguages();
    }
    dispose() {
        var _a;
        for (const child of this.container.children) {
            child.remove();
        }
        sphereEventHandler.off(EVENTS.OVERLAY.SHOW_CONTENT, this.showPicker);
        (_a = this.layoutSwitcher) === null || _a === void 0 ? void 0 : _a.dispose();
    }
}
