import { Vector2 } from 'three';
export function modulo(a, m) {
    return ((a % m) + m) % m;
}
function moduloDistance(a, b, m) {
    return Math.min(modulo(Math.abs(b - a), m), modulo(Math.abs(-a - m + b), m), modulo(Math.abs(m - a + b), m));
}
export class Modulo {
    constructor(divisor) {
        this.divisor = divisor.clone();
    }
    moduloX(x) {
        return modulo(x, this.divisor.x);
    }
    moduloY(y) {
        return modulo(y, this.divisor.y);
    }
    moduloV(vector) {
        vector.x = modulo(vector.x, this.divisor.x);
        vector.y = modulo(vector.y, this.divisor.y);
        return vector;
    }
    moduloB(box) {
        this.moduloV(box.min);
        this.moduloV(box.max);
        return box;
    }
    distanceX(a, b) {
        return moduloDistance(a, b, this.divisor.x);
    }
    distanceY(a, b) {
        return moduloDistance(a, b, this.divisor.y);
    }
    distanceV(a, b) {
        return new Vector2(this.distanceX(a.x, b.x), this.distanceY(a.y, b.y));
    }
    distanceVB(point, box) {
        const pointModulo = this.moduloV(point.clone());
        const viewportCenter = this.moduloV(box.getCenter(new Vector2()));
        return this.distanceV(pointModulo, viewportCenter);
    }
    normalDistanceVB(point, box) {
        const distance = this.distanceVB(point, box);
        // account for viewport being a non-square rectangle
        const viewportSize = this.sizeB(box);
        distance.x *= viewportSize.y / viewportSize.x;
        return distance;
    }
    sizeB(box) {
        return this.distanceV(box.min, box.max);
    }
    iterateB(box, callback) {
        const start = this.moduloV(box.min.clone());
        const stop = this.moduloV(box.max.clone());
        const it = start.clone();
        do {
            callback(it.clone());
            if (it.y === stop.y) {
                it.y = start.y;
                it.x = this.moduloX(it.x + 1);
            }
            else {
                it.y = this.moduloY(it.y + 1);
            }
        } while (!(it.x === stop.x && it.y === stop.y));
        return box;
    }
}
