import pipe from 'callbag-pipe';
import subscribe from 'callbag-subscribe';
import makeBehaviorSubject from 'callbag-behavior-subject';
import { ApiService } from '../api';
import { URLS, UrlUtils } from '../urls';
import { EventType } from '../../interfaces/shopping-cart.interface';
import { L10nUtils } from '../../utils/l10n_utils';
import { Account } from '../../account/account';
import { ACCESS_TOKEN_KEY } from '../../shared/constants';
import { AppUtils } from '../../utils/app_utils';
import { ShoppingCartUtils } from '../../utils/shopping-cart_utils';
// 1 minute is equal to 60secs and 60secs is equal to 60 * 1000 = 60000ms
const SHOPPING_CART_UPDATE_INTERVAL = 60 * 1000;
const SHOPPING_CART_TIME_THRESHOLD = 30 * 60 * 1000;
export class IdealCartService extends ApiService {
    constructor(planogram, currencyService) {
        super();
        this.planogram = planogram;
        this.currencyService = currencyService;
        this.onShoppingCartStorageChange = this.onShoppingCartStorageChange.bind(this);
        this.checkoutId = `checkout-${this.planogram.clientName}`;
        const storedCheckoutState = ShoppingCartUtils.getStoredShoppingCart(this.checkoutId);
        this.checkoutSubject = makeBehaviorSubject(JSON.parse(storedCheckoutState) || null);
        this.processingSubject = makeBehaviorSubject(false);
        window.addEventListener('storage', this.onShoppingCartStorageChange);
        this.checkoutSubjectRef = pipe(this.checkoutSubject, subscribe({
            next: (data) => {
                if (data && !data.last_update) {
                    data = Object.assign(Object.assign({}, data), { last_update: new Date().getTime() });
                }
                this.shoppingCart = data;
            },
            error: () => {
                this.shoppingCart = null;
            }
        }));
    }
    get getMultipassToken() {
        return '';
    }
    fireShoppingCartUpdates(variant) {
        return this.shoppingCart ? this.updateShoppingCart(variant) : this.createShoppingCart(variant);
    }
    attachShoppingCartToUser() { }
    updateShoppingCartQuantity(variant) {
        const indexOfVariant = this.getIndexVariant(variant);
        if (indexOfVariant !== -1) {
            this.shoppingCart.products[indexOfVariant].quantity = variant.quantity;
        }
        this.processingSubject(EventType.Data, true);
        return this.updateCartRequest(variant, indexOfVariant !== -1)
            .then(data => this.saveShoppingCartState(data))
            .catch(err => this.clearShoppingCartState(err))
            .finally(() => this.processingSubject(EventType.Data, false));
    }
    clearShoppingCartState(err) {
        this.checkoutSubject(EventType.End, err);
        ShoppingCartUtils.deleteStoredShoppingCart(this.checkoutId);
        if (err) {
            throw err;
        }
    }
    clearShoppingCartToken() { }
    updateShoppingCartCurrency(currencyCode) {
        return Promise.resolve();
    }
    getShoppingCartState() {
        const currentLang = L10nUtils.getCurrentLanguage();
        const url = UrlUtils.insertFewValuesToUrl(URLS.IDEAL_GET_CHECKOUT_INFO, {
            session_id: this.shoppingCart.session_id,
            lang: currentLang,
            currency_code: this.currencyService.selectedCurrencyCode
        });
        this.processingSubject(EventType.Data, true);
        IdealCartService.isProcessing = true;
        return this.get(url)
            .then(resp => resp.json())
            .then(data => this.saveShoppingCartState(data))
            .catch(err => this.clearShoppingCartState(err))
            .finally(() => {
            this.processingSubject(EventType.Data, false);
            IdealCartService.isProcessing = false;
        });
    }
    removeItemFromCart(variant) {
        const indexOfVariant = this.getIndexVariant(variant);
        this.shoppingCart.products.splice(indexOfVariant, 1);
        this.processingSubject(EventType.Data, true);
        return this.deleteProduct(variant.sku)
            .then(data => this.saveShoppingCartState(data))
            .catch(err => this.clearShoppingCartState(err))
            .finally(() => this.processingSubject(EventType.Data, false));
    }
    deleteProduct(id) {
        const currentLang = L10nUtils.getCurrentLanguage();
        const data = {
            session_id: this.shoppingCart.session_id,
            sku: id,
            language_code: currentLang,
            currency_code: this.currencyService.selectedCurrencyCode
        };
        return this.delete(URLS.IDEAL_CHECKOUT_DELETE_PRODUCT, data)
            .then(resp => resp.json());
    }
    generateMultipassLink(link) {
        return Promise.resolve();
    }
    createCartRequest() {
        const currentLang = L10nUtils.getCurrentLanguage();
        const url = UrlUtils.insertFewValuesToUrl(URLS.IDEAL_CHECKOUT_CREATE, { lang: currentLang, currency_code: this.currencyService.selectedCurrencyCode });
        return this.post(url, {})
            .then(resp => resp.json());
    }
    updateCartRequest(variant, updateProduct = false) {
        const currentLang = L10nUtils.getCurrentLanguage();
        const url = updateProduct ? URLS.IDEAL_CHECKOUT_UPDATE_PRODUCT_QUANTITY : URLS.IDEAL_CHECKOUT_ADD_PRODUCT;
        const data = {
            session_id: this.shoppingCart.session_id,
            qty: variant.quantity,
            sku: variant.sku,
            language_code: currentLang,
            currency_code: this.currencyService.selectedCurrencyCode
        };
        return this.put(url, data).then(resp => resp.json());
    }
    createShoppingCart(variant) {
        const currentLang = L10nUtils.getCurrentLanguage();
        const requestData = {
            sku: variant.sku,
            qty: variant.quantity,
            language_code: currentLang,
            currency_code: this.currencyService.selectedCurrencyCode
        };
        this.processingSubject(1, true);
        return this.createCartRequest()
            .then(cart => this.addProductRequest(Object.assign(Object.assign({}, requestData), { session_id: cart.session_id }))
            .then(data => this.saveShoppingCartState(data))
            .catch(err => this.clearShoppingCartState(err))
            .finally(() => this.processingSubject(EventType.Data, false)))
            .catch(err => this.clearShoppingCartState(err))
            .finally(() => this.processingSubject(EventType.Data, false));
    }
    addProductRequest(data) {
        return this.put(URLS.IDEAL_CHECKOUT_ADD_PRODUCT, data)
            .then(resp => resp.json());
    }
    updateShoppingCart(variant) {
        var _a, _b;
        const indexOfVariant = this.getIndexVariant(variant);
        const overallQuantity = (_a = this.shoppingCart.products) === null || _a === void 0 ? void 0 : _a.reduce((acc, item) => {
            return item.sku === (variant === null || variant === void 0 ? void 0 : variant.sku) ? acc + item.quantity : acc;
        }, 0);
        const availableQuantity = variant.inventory_quantity - overallQuantity;
        const idealCartVariant = (_b = this.shoppingCart.products[indexOfVariant]) !== null && _b !== void 0 ? _b : variant;
        if (indexOfVariant !== -1) {
            idealCartVariant.quantity = Math.min(this.shoppingCart.products[indexOfVariant].quantity + variant.quantity, availableQuantity < variant.inventory_quantity ?
                this.shoppingCart.products[indexOfVariant].quantity + availableQuantity :
                variant.quantity);
        }
        else {
            idealCartVariant.quantity = variant.quantity > availableQuantity ? availableQuantity : variant.quantity;
        }
        this.processingSubject(1, true);
        return this.updateCartRequest(idealCartVariant, indexOfVariant !== -1)
            .then(data => this.saveShoppingCartState(data))
            .catch(err => this.clearShoppingCartState(err))
            .finally(() => this.processingSubject(EventType.Data, false));
    }
    onShoppingCartStorageChange({ storageArea, key, newValue }) {
        if (storageArea === window.localStorage && key === this.checkoutId) {
            if (newValue) {
                const checkout = JSON.parse(newValue);
                this.saveShoppingCartState(checkout);
            }
            else {
                this.clearShoppingCartState();
            }
        }
        else if (storageArea === window.localStorage && key === ACCESS_TOKEN_KEY && !newValue) {
            this.clearShoppingCartState();
        }
    }
    getIndexVariant(variant) {
        var _a;
        return (_a = this.shoppingCart.products) === null || _a === void 0 ? void 0 : _a.findIndex((product) => product.sku === variant.sku);
    }
    checkMultipassToken() {
        return Promise.resolve();
    }
    saveShoppingCartState(data) {
        this.checkoutSubject(EventType.Data, data);
        if (!Account.isLogged || this.isMultipassEnabled) {
            this.updateShoppingCartState();
            data.last_update = new Date().getTime();
            window.localStorage.setItem(this.checkoutId, JSON.stringify(data));
        }
        return data;
    }
    storeEmail(email) {
        if (email) {
            this.email = email;
        }
    }
    isShoppingCartOutdated() {
        var _a;
        if (!Account.isLogged && ((_a = this.shoppingCart) === null || _a === void 0 ? void 0 : _a.last_update)) {
            const currentTime = new Date().getTime();
            return this.shoppingCart.last_update + SHOPPING_CART_TIME_THRESHOLD < currentTime;
        }
        return false;
    }
    trackShoppingCartUpdates() {
        AppUtils.requestTimeoutRaf(() => this.updateShoppingCartState(), SHOPPING_CART_UPDATE_INTERVAL, fn => this.cancelAnimationFn = fn);
    }
    updateShoppingCartState(forceUpdate = false) {
        this.clearShoppingCartTimer();
        if (this.isShoppingCartOutdated() && this.shoppingCart !== null || forceUpdate) {
            this.getShoppingCartState()
                .finally(() => this.trackShoppingCartUpdates());
            return;
        }
        else if (this.shoppingCart) {
            this.trackShoppingCartUpdates();
        }
    }
    clearShoppingCartTimer() {
        var _a;
        (_a = this.cancelAnimationFn) === null || _a === void 0 ? void 0 : _a.call(this);
    }
    dispose() {
        window.removeEventListener('storage', this.onShoppingCartStorageChange);
        this.checkoutSubject(EventType.End, true);
        this.processingSubject(EventType.End, true);
        this.checkoutSubjectRef();
    }
}
