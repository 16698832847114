import { Animation } from './animation';
export class AutoRotateAnimation extends Animation {
    static get instance() {
        if (!AutoRotateAnimation._instance) {
            AutoRotateAnimation._instance = new AutoRotateAnimation();
        }
        return AutoRotateAnimation._instance;
    }
    static clearInstance() {
        AutoRotateAnimation._instance = undefined;
    }
    static get MAX_ZOOM_FRACTION() {
        return 0.95;
    }
    constructor() {
        super();
        this.base_pan_amount = 0.0027;
        this.zoomFraction = 0;
        this.direction = 0;
    }
    update() {
        const panAmount = this.base_pan_amount * (this.direction * (1 - this.zoomFraction));
        return { pan: panAmount };
    }
    addRotation(rotation, zoomFraction) {
        this.direction += rotation;
        this.zoomFraction = Math.min(zoomFraction, AutoRotateAnimation.MAX_ZOOM_FRACTION);
    }
    clearRotation() {
        this.zoomFraction = 0;
        this.direction = 0;
    }
    isNotRotating() {
        return this.direction === 0;
    }
}
