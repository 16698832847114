import { Vector2 } from 'three';
export class InputEventUtils {
    static get CLICK_MARGIN_OF_ERROR() {
        return 5;
    }
    static getTouchPosition(event) {
        return new Vector2(event.changedTouches[0].pageX, event.changedTouches[0].pageY);
    }
    static getEndTouchPosition(event) {
        return {
            x: event.changedTouches[0].pageX,
            y: event.changedTouches[0].pageY
        };
    }
    static getMousePosition(event) {
        return {
            x: event.clientX,
            y: event.clientY
        };
    }
    static getScreenPosition(event) {
        if (event.type === 'touchstart' || event.type === 'touchend' || event.type === 'touchmove') {
            return InputEventUtils.getTouchPosition(event);
        }
        return InputEventUtils.getMousePosition(event);
    }
    static distance(from, to) {
        const startPosition = new Vector2(from.x, from.y);
        const endPosition = new Vector2(to.x, to.y);
        return startPosition.distanceTo(endPosition);
    }
    static calculateTouchDistance(touch1, touch2) {
        const from = { x: touch1.pageX, y: touch1.pageY };
        const to = { x: touch2.pageX, y: touch2.pageY };
        return InputEventUtils.distance(from, to);
    }
    static getPinchCentre(touch1, touch2) {
        const middleX = (touch1.pageX + touch2.pageX) / 2;
        const middleY = (touch1.pageY + touch2.pageY) / 2;
        return { x: middleX, y: middleY };
    }
    static onSphereSurface(event) {
        return (event.target.closest('.block-sphere-controls') === null && event.target.closest('.button-list-item') === null);
    }
    static addSelectEvents(element, callback) {
        element.addEventListener('click', callback);
    }
    static addHoverEvent(element, callback) {
        element.addEventListener('mouseover', callback);
        element.addEventListener('mouseout', callback);
    }
    static addScrollEvent(element, callback) {
        element.addEventListener('scroll', callback.bind(this));
    }
    static isClick(startEvent, endEvent) {
        const startPosition = InputEventUtils.getScreenPosition(startEvent);
        const endPosition = InputEventUtils.getScreenPosition(endEvent);
        const moveDistance = InputEventUtils.distance(startPosition, endPosition);
        return moveDistance < InputEventUtils.CLICK_MARGIN_OF_ERROR;
    }
    static addSwipeEvent(element, onSwipe, options = {
        touch: true,
        mouse: false
    }) {
        let xDown = null;
        let yDown = null;
        const handleTouchStart = e => {
            const { x, y } = InputEventUtils.getScreenPosition(e);
            xDown = x;
            yDown = y;
        };
        const handleTouchMove = e => {
            if (!xDown || !yDown) {
                return;
            }
            const { x, y } = InputEventUtils.getScreenPosition(e);
            const xDiff = xDown - x;
            const yDiff = yDown - y;
            let direction;
            if (Math.abs(xDiff) > Math.abs(yDiff)) {
                direction = xDiff > 0 ? 'left' : 'right';
            }
            else {
                direction = yDiff > 0 ? 'up' : 'down';
            }
            if (xDiff || yDiff) {
                onSwipe({ xDiff, yDiff, direction, event: e });
                xDown = null;
                yDown = null;
            }
        };
        const handleTouchEnd = () => {
            if (xDown || yDown) {
                xDown = null;
                yDown = null;
            }
        };
        if (options.touch) {
            element.addEventListener('touchstart', handleTouchStart, false);
            element.addEventListener('touchmove', handleTouchMove, false);
            element.addEventListener('touchend', handleTouchEnd, false);
        }
        if (options.mouse) {
            element.addEventListener('mousedown', handleTouchStart, false);
            element.addEventListener('mousemove', handleTouchMove, false);
            element.addEventListener('mouseup', handleTouchEnd, false);
        }
    }
}
