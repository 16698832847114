import makeBehaviorSubject from 'callbag-behavior-subject';
import { ApiService } from '../api';
import { URLS, UrlUtils } from '../urls';
import { searchEventHandler, sphereEventHandler } from '../../custom_event_utils';
import { SEARCH_EVENT_NAMES as SEARCH_EVENTS, SPHERE_EVENT_NAMES as EVENTS } from '../../event-names';
import Router from '../../router';
import { EventType } from '../../interfaces/shopping-cart.interface';
export class SearchService extends ApiService {
    constructor() {
        super();
        this.searchResultsSubject = makeBehaviorSubject(null);
        this.searchSettingsSubject = makeBehaviorSubject(null);
        this.searchFilterSettingsSubject = makeBehaviorSubject(null);
        sphereEventHandler.listen(EVENTS.PLANOGRAM_CHANGED, (planogram) => {
            this.planogramVersionId = planogram.versionId;
            this.planogramName = planogram.name;
        });
    }
    updateSearchFilters(results) {
        this.searchFilterSettingsSubject(EventType.Data, results);
    }
    updateSearchSettings() {
        const searchSettingsUrl = UrlUtils.insertValueToUrl(URLS.SEARCH_SETTINGS, this.planogramVersionId);
        this.get(searchSettingsUrl)
            .then(resp => resp.json())
            .catch(err => {
            this.searchSettingsSubject(EventType.End, err);
        })
            .then(settings => {
            if ((settings === null || settings === void 0 ? void 0 : settings.enabled) === true) {
                this.searchSettingsSubject(EventType.Data, settings);
            }
            else {
                this.searchSettingsSubject(EventType.End, undefined);
                console.warn('Search is disabled for this Sphere.');
            }
        });
    }
    filterResults(query, filterId = '') {
        this.initAbortController();
        const filterUrl = UrlUtils.insertFewValuesToUrl(URLS.FILTER_URL, { planogramId: this.planogramVersionId, query: query, filterId: filterId });
        return this.getWithAbort(filterUrl, this.controllerAbort.signal)
            .then(resp => resp.json())
            .then(results => {
            this.controllerAbort = null;
            this.searchResultsSubject(EventType.Data, { results, filterId: filterId ? parseInt(filterId) : '' });
            return results;
        })
            .catch(err => {
            if (err instanceof DOMException && err.name === 'AbortError') {
                console.log('fetch aborted');
            }
            else {
                console.error(err);
            }
        });
    }
    toggleSearch(planogramName, isSearchActive) {
        Router.navigateToSearch(planogramName, isSearchActive);
    }
    updateSearchQuery(planogramName, searchQuery) {
        Router.updateSearchQuery(planogramName, searchQuery);
    }
    cancelPendingRequests() {
        if (!this.controllerAbort) {
            return;
        }
        searchEventHandler.emit(SEARCH_EVENTS.REQUEST_STATUS, { state: false });
        this.controllerAbort.abort();
    }
    initAbortController() {
        this.cancelPendingRequests();
        this.controllerAbort = new AbortController();
    }
}
