export function isCountdownActive(currentTime, targetTime) {
    if (!currentTime || !targetTime) {
        return false;
    }
    const currentDateObject = new Date(currentTime);
    const targetDateObject = new Date(targetTime);
    return currentDateObject.getTime() - targetDateObject.getTime() < 0;
}
export function getParsedTime(distance) {
    const parsedTime = {
        days: Math.floor(distance / (1000 * 60 * 60 * 24)),
        hours: Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
        minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((distance % (1000 * 60)) / 1000)
    };
    const formattedTime = {};
    for (const field in parsedTime) {
        if (field === 'days') {
            formattedTime[field] = parsedTime[field].toString();
            continue;
        }
        if (parsedTime[field] < 10) {
            formattedTime[field] = '0' + parsedTime[field].toString();
        }
        else {
            formattedTime[field] = parsedTime[field].toString();
        }
    }
    return formattedTime;
}
