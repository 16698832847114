import { ApiService } from '../api';
import { URLS, UrlUtils } from '../urls';
export class InfosOverlayService extends ApiService {
    constructor() {
        super(...arguments);
        this.infos = new Map();
    }
    getView(planogramId, versionId, infoType) {
        const info = this.getInfo(planogramId, infoType);
        const url = UrlUtils.insertFewValuesToUrl(URLS.PLANOGRAM_INFO, { planogramId, versionId });
        if (info) {
            return Promise.resolve(info);
        }
        return this.get(url).then(resp => resp.json()).then(infos => {
            this.setInfoData(planogramId, infos);
            return this.getInfo(planogramId, infoType);
        });
    }
    getInfo(planogramId, infoType) {
        const sphereInfos = this.infos.get(planogramId);
        if (sphereInfos) {
            return sphereInfos.find(info => info.slug === infoType);
        }
    }
    setInfoData(planogramId, infoData) {
        this.infos.set(planogramId, infoData);
    }
}
export default new InfosOverlayService();
