import pipe from 'callbag-pipe';
import subscribe from 'callbag-subscribe';
import { CheckboxField } from '../components/checkbox-field/checkbox-field-component';
import { HP_OVERLAY_THEMES } from '../../../../interfaces/product.interface';
import { InputFieldsSeekerTShirtComponent } from './input-fields-seeker-t-shirt/input-fields-seeker-t-shirt-component';
import { InputFieldsTriwizardShirtComponent } from './input-fields-triwizard-shirt/input-fields-triwizard-shirt-component';
import { InputFieldsCrestKeyringComponent } from './input-fields-crest-keyring/input-fields-crest-keyring-component';
import { InputFieldsLetterKeychainComponent } from './input-fields-letter-keychain/input-fields-letter-keychain-component';
import { InputFieldsNotebookComponent } from './input-fields-notebook/input-fields-notebook-component';
import { InputFieldsRobeComponent } from './input-fields-robe/input-fields-robe-component';
import { InputFieldsHogwartsTrunkComponent } from './input-fields-hogwarts-trunk/input-fields-hogwarts-trunk-component';
export class InputFieldsComponent {
    constructor(mainContainer, product, shopifyService) {
        this.mainContainer = mainContainer;
        this.shopifyService = shopifyService;
        this.withPersonalization = true;
        this.fieldsContainer = this.mainContainer.querySelector('.app-ecommerce-product-input-fields');
        this.slideContainer = this.mainContainer.querySelector('.input-fields-slide-container');
        this.fieldsWrapper = this.mainContainer.querySelector('.app-ecommerce-product-input-fields-container');
        this.checkboxWrapperEl = this.mainContainer.querySelector('.input-fields-checkbox .checkbox-field-component');
        this.resizeSlider = this.resizeSlider.bind(this);
        this.setInputFields(product);
        if (this.checkboxWrapperEl) {
            this.initSlideContainer();
            window.addEventListener('resize', this.resizeSlider);
        }
        this.selectedVariantValueSubjectRef = pipe(this.shopifyService.selectedProductSubject, subscribe({
            next: (variant) => {
                var _a, _b;
                (_a = this.fieldsContainer) === null || _a === void 0 ? void 0 : _a.classList.remove('is-hidden');
                if ((!variant.isAutoSelected && !variant) || (!variant.isAutoSelected && variant.inventory_quantity <= 0)) {
                    (_b = this.fieldsContainer) === null || _b === void 0 ? void 0 : _b.classList.add('is-hidden');
                }
            }
        }));
    }
    get values() {
        return this.inputsFields.values;
    }
    initSlideContainer() {
        this.slideContainer.style.height = `${this.slideContainer.scrollHeight}px`;
        this.checkboxField = new CheckboxField(this.checkboxWrapperEl, val => {
            this.withPersonalization = !val;
            if (val) {
                this.closeSlideContainer();
            }
            else {
                this.openSlideContainer();
            }
        });
    }
    openSlideContainer() {
        this.slideContainer.style.height = `${this.slideContainer.scrollHeight}px`;
    }
    closeSlideContainer() {
        this.slideContainer.style.height = '0';
    }
    validate() {
        return !this.withPersonalization || this.inputsFields.validate();
    }
    dispose() {
        var _a;
        (_a = this.inputsFields) === null || _a === void 0 ? void 0 : _a.dispose();
        this.selectedVariantValueSubjectRef();
        window.removeEventListener('resize', this.resizeSlider);
    }
    resizeSlider() {
        this.slideContainer.style.height = 'auto';
        this.openSlideContainer();
    }
    setInputFields(product) {
        switch (product.theme_name) {
            case HP_OVERLAY_THEMES.SEEKER_TSHIRT: {
                this.inputsFields = new InputFieldsSeekerTShirtComponent(this.fieldsWrapper, product, this.shopifyService);
                break;
            }
            case HP_OVERLAY_THEMES.TRIWIZARD_SHIRT: {
                this.inputsFields = new InputFieldsTriwizardShirtComponent(this.fieldsWrapper, product);
                break;
            }
            case HP_OVERLAY_THEMES.CREST_KEYRING: {
                this.inputsFields = new InputFieldsCrestKeyringComponent(this.fieldsWrapper, product);
                break;
            }
            case HP_OVERLAY_THEMES.LETTER_KEYCHAIN: {
                this.inputsFields = new InputFieldsLetterKeychainComponent(this.fieldsWrapper, product);
                break;
            }
            case HP_OVERLAY_THEMES.NOTEBOOK: {
                this.inputsFields = new InputFieldsNotebookComponent(this.fieldsWrapper, product);
                break;
            }
            case HP_OVERLAY_THEMES.ROBE: {
                this.inputsFields = new InputFieldsRobeComponent(this.fieldsWrapper, product);
                break;
            }
            case HP_OVERLAY_THEMES.HOGWARTS_TRUNK_US: {
                this.inputsFields = new InputFieldsHogwartsTrunkComponent(this.fieldsWrapper, product);
                break;
            }
        }
    }
}
