import MobileDetect from 'mobile-detect';
import { isTouchSupported, isMacOS } from './platform';
export class BrowserUtils {
    static get md() {
        if (!this._md) {
            this._md = new MobileDetect(navigator.userAgent);
        }
        return this._md;
    }
    static isSafari() {
        if (this._isSafari === undefined) {
            this._isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        }
        return this._isSafari;
    }
    static isTablet() {
        return !!this.md.tablet();
    }
    static isMobile() {
        return !!this.md.mobile();
    }
    static isMobileSafari() {
        return this.isMobile() && this.isSafari();
    }
    static detectWebkitInAppBrowser() {
        const standalone = navigator.standalone; // only webkit has this property
        const isSafari = /safari/i.test(navigator.userAgent); // all browsers on iOS report Safari in the userAgent
        return standalone === false && !isSafari;
    }
    static iPhoneMachineId() {
        const match = /iPhone(\d+,\d+)/gi.exec(navigator.userAgent);
        if (match === null)
            return undefined;
        return parseFloat(match[1].replace(',', '.'));
    }
    static pickImageVariant(thumbnails, maxQuality = false, isEmailVariant) {
        if (isEmailVariant) {
            return thumbnails === null || thumbnails === void 0 ? void 0 : thumbnails.VARIANT_THUMBNAIL_PNG_600;
        }
        let imagePath = thumbnails === null || thumbnails === void 0 ? void 0 : thumbnails.VARIANT_LARGE_WEBP;
        if (maxQuality && imagePath) {
            return imagePath;
        }
        const imageMediumPath = thumbnails === null || thumbnails === void 0 ? void 0 : thumbnails.VARIANT_MEDIUM_WEBP;
        const imageSmallPath = thumbnails === null || thumbnails === void 0 ? void 0 : thumbnails.VARIANT_SMALL_WEBP;
        if ((BrowserUtils.isTablet() || (isTouchSupported && isMacOS)) && imageMediumPath) {
            imagePath = imageMediumPath;
        }
        if (BrowserUtils.isMobile() && imageSmallPath) {
            imagePath = imageSmallPath;
        }
        return imagePath;
    }
}
