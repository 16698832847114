import { L10nUtils } from '../../utils/l10n_utils';
import { closeButton } from '../components/close-button';
import { button } from '../components/button';
export const profileView = () => {
    return `
    <div id="account-profile" class="account-profile-content">
      <div class="account-profile-header">
          <div class="account-profile-greeting"><span></span></div>
          ${closeButton()}
      </div>
      <div class="account-profile-body">
        <div class="account-profile-logout-text">
          <span>${L10nUtils.l10n('account.profile.logout-text')}</span>
        </div>
        <div class="account-profile-button-container">
           ${button({
        text: L10nUtils.l10n('account.profile.log-out-button.text'),
        className: 'log-out-btn'
    })}   
        </div>
      </div>
    </div>
  `;
};
