export class CheckboxField {
    constructor(inputWrapper, onChange) {
        this.inputWrapper = inputWrapper;
        this.inputEl = this.inputWrapper.querySelector('input');
        this.inputEl.addEventListener('change', () => {
            onChange(this.value);
        });
    }
    get value() {
        return this.inputEl.checked;
    }
}
