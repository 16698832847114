import pipe from 'callbag-pipe';
import subscribe from 'callbag-subscribe';
import { InputFieldComponent } from '../../components/input-field/input-field-component';
import { InputFieldsBaseComponent } from '../input-fields-base/input-fields-base-component';
export class InputFieldsSeekerTShirtComponent extends InputFieldsBaseComponent {
    constructor(container, product, shopifyService) {
        super(container, product);
        const inputFields = this.product.input_fields;
        const inputWrapper = this.container.querySelector('.input-field-component');
        this.input = new InputFieldComponent(inputWrapper, {
            uppercase: true
        });
        this.selectedVariantValueSubjectRef = pipe(shopifyService.selectedVariantValueSubject, subscribe({
            next: (val) => {
                if (val) {
                    this.onVariantSelect(val);
                }
            }
        }));
        this.setField(inputFields[0].name, this.input);
    }
    onVariantSelect(val) {
        switch (val) {
            case 'XS': {
                this.input.setMaxLength(7);
                break;
            }
            case 'S':
            case 'M': {
                this.input.setMaxLength(8);
                break;
            }
            default: {
                this.input.setMaxLength(9);
            }
        }
    }
    dispose() {
        this.selectedVariantValueSubjectRef();
    }
}
