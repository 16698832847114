import PlanogramPoint from 'shared/utils/PlanogramPoint';
import PlanogramBox from 'shared/utils/PlanogramBox';
import { assertTrue, debugCommand } from 'shared/utils/debug';
import { TILE_CONTENT_SIZE } from './parameters';
import { HEIGHT, WIDTH } from '../config/PlanogramConfig';
const MIN_VALUE = Number.MIN_VALUE;
const MAX_DISTANCE = Math.sqrt(WIDTH * WIDTH + HEIGHT * HEIGHT) * 0.5;
function tileSize(ratio, sameTileCount) {
    // TODO figure out why/if this 2 is necessary
    const size = sameTileCount ? 0 : (2 * TILE_CONTENT_SIZE) / ratio;
    return {
        x: size,
        y: size,
    };
}
export class TilePriority {
    constructor(targetPixelRatio, unloadedTileBias) {
        this.unloadedTileBias = unloadedTileBias;
        this.focusPoint = new PlanogramPoint();
        this.pixelsToSizeRatio = 1;
        this.minimumQuality = targetPixelRatio;
        this.maximumQuality = 2 * this.minimumQuality;
        debugCommand('tilePriority', () => this);
    }
    getFocusPoint() {
        return this.focusPoint;
    }
    setFocusPoint(point) {
        this.focusPoint.copy(point);
    }
    setPixelRatio(ratio) {
        this.pixelsToSizeRatio = ratio;
    }
    rateLocation(location, offset = 0) {
        const distance = Math.max(MIN_VALUE, this.focusPoint.distance(location) + offset);
        const rating = (distance / MAX_DISTANCE) * Math.sqrt(distance); // divide to avoid huge numbers
        assertTrue(!isNaN(rating), 'NaN rating');
        return rating;
    }
    ratePlanogramPixelRatio(ratio) {
        const qualityRatio = ratio / this.pixelsToSizeRatio;
        return Math.max(MIN_VALUE, qualityRatio);
    }
    needsUpgrade(chunk) {
        return (chunk.someNotLoaded ||
            this.ratePlanogramPixelRatio(chunk.minPixelPlanogramRatio) < this.minimumQuality);
    }
    needsDowngrade(chunk) {
        return this.ratePlanogramPixelRatio(chunk.maxPixelPlanogramRatio) > this.maximumQuality;
    }
    rateLocationAndRatio(location, ratio) {
        const locationRating = this.rateLocation(location);
        return locationRating * this.ratePlanogramPixelRatio(ratio);
    }
    rateUpgrade(chunk, box) {
        const rating = this.rateBestInBox(box, chunk.minPixelPlanogramRatio);
        return rating + (chunk.canUpgrade && this.needsUpgrade(chunk) ? 0 : +Infinity);
    }
    rateDowngrade(chunk, box) {
        const rating = this.rateWorstInBox(box, chunk.maxPixelPlanogramRatio);
        // TODO: account for atlas differently?
        return (-rating * (chunk.canDowngrade ? 1 : Math.pow(2, -this.unloadedTileBias)) +
            (chunk.someLoaded ? 0 : +Infinity));
    }
    rateAliasing(chunk, _box) {
        return -chunk.maxPixelPlanogramRatio + (chunk.canDowngrade ? 0 : +Infinity);
    }
    upgradeRatio(chunk) {
        return chunk.someNotLoaded ? Math.pow(2, (1 + this.unloadedTileBias)) : 2;
    }
    estimateWorstUpgrade(chunk) {
        const ratio = chunk.minPixelPlanogramRatio * this.upgradeRatio(chunk);
        const box = new PlanogramBox().fromCenterAndSize(chunk.point, tileSize(ratio, chunk.minDistanceToAtlas <= 0 || !chunk.someLoaded));
        return this.rateWorstInBox(box, ratio);
    }
    downgradeRatio(chunk) {
        if (chunk.maxDistanceToAtlas <= 0)
            return Math.pow(0.5, (chunk.distanceToUnload + this.unloadedTileBias));
        else
            return chunk.canDowngrade ? 0.5 : Math.pow(0.5, (1 + this.unloadedTileBias));
    }
    estimateBestDowngrade(chunk) {
        const ratio = chunk.maxPixelPlanogramRatio * this.downgradeRatio(chunk);
        const box = new PlanogramBox().fromCenterAndSize(chunk.point, tileSize(chunk.maxPixelPlanogramRatio, chunk.maxDistanceToAtlas <= 1 || !chunk.canDowngrade));
        return this.rateBestInBox(box, ratio);
    }
    rateBestInBox(box, planogramPixelRatio) {
        const bestPoint = box.clamp(this.focusPoint);
        return this.rateLocationAndRatio(bestPoint, planogramPixelRatio);
    }
    rateWorstInBox(box, planogramPixelRatio) {
        const worstPoint = box.farthestFrom(this.focusPoint);
        return this.rateLocationAndRatio(worstPoint, planogramPixelRatio);
    }
}
