import { Vector2 } from 'three';
import { PlanogramsService } from './api/services/planograms.service';
import { ErrorOverlay } from './errors_overlay';
import { PageLoadingSpeed } from './page_loading_speed';
import { PAGE_LOADING_TYPES } from './shared/constants';
import { AnimationPathType, SphereItemType } from 'shared/interfaces/planogram';
import { L10nUtils } from './utils/l10n_utils';
import Router from './router';
import { AppState } from './shared/app.state';
import { isLodItem } from './utils/planogram_utils';
import { fallbackFitFullSize } from 'shared/lod/helpers';
function migrateImageData(imageData) {
    var _a;
    imageData.lods.forEach(level => {
        level.lod = parseInt(level.lod);
    });
    if (imageData.data.full_size === undefined || imageData.data.fit_size === undefined) {
        fallbackFitFullSize(imageData.data, imageData.lods);
    }
    imageData.data.lods_version = (_a = imageData.data.lods_version) !== null && _a !== void 0 ? _a : 0;
}
function migrate(version, planogramName) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    const { tiles_path, tiles_paths } = version;
    version.tiles_path = (_a = tiles_paths === null || tiles_paths === void 0 ? void 0 : tiles_paths.webp) !== null && _a !== void 0 ? _a : tiles_path;
    version.primary_color = (_b = version.primary_color) !== null && _b !== void 0 ? _b : '#181b25';
    version.secondary_color = (_c = version.secondary_color) !== null && _c !== void 0 ? _c : '#2b3147';
    version.iframe_primary_color = (_d = version.iframe_primary_color) !== null && _d !== void 0 ? _d : '#ffffff';
    version.iframe_secondary_color = (_e = version.iframe_secondary_color) !== null && _e !== void 0 ? _e : '';
    const actionsJson = version.actions_json;
    actionsJson.animation_paths = (_f = actionsJson.animation_paths) !== null && _f !== void 0 ? _f : [];
    actionsJson.backgroundColor = (_g = actionsJson.backgroundColor) !== null && _g !== void 0 ? _g : [1, 1, 1];
    actionsJson.clustersOrder = (_h = actionsJson.clustersOrder) !== null && _h !== void 0 ? _h : [];
    // TODO temporary solution to avoid navigation to another spheres
    actionsJson.animation_paths = actionsJson.animation_paths.map(path => (Object.assign(Object.assign({}, path), { items: path.type !== AnimationPathType.Custom
            ? path.items.filter(item => item.planogramName === planogramName && item.language === L10nUtils.getCurrentLanguage())
            : path.items })));
    actionsJson.startPoint = (_j = actionsJson.startPoint) !== null && _j !== void 0 ? _j : 180;
    if (actionsJson.figmaBackground) {
        actionsJson.items = actionsJson.items.concat(actionsJson.figmaBackground.items);
    }
    actionsJson.items = (_k = actionsJson.items) === null || _k === void 0 ? void 0 : _k.filter(item => !(isLodItem(item) && item.lods.length === 0)).map(item => {
        var _a;
        if (typeof item.id !== 'string')
            item.id = item.id.toString();
        item.layer = (_a = item.layer) !== null && _a !== void 0 ? _a : 0;
        if (isLodItem(item))
            migrateImageData(item);
        return item;
    });
}
export class Planogram {
    size() {
        return new Vector2(this.width, this.height * 0.5);
    }
    get fixedRadius() {
        return this.width / (Math.PI * 2);
    }
    get curvature() {
        return 3;
    }
    get largeRadius() {
        return this.fixedRadius * this.curvature;
    }
    static get ALPHA() {
        return (10.0 * Math.PI * 2) / 360;
    }
    static pages() {
        return new Vector2(Planogram.PAGES_WIDE, Planogram.PAGES_HIGH);
    }
    constructor(name) {
        this.name = name;
        this.planogramsService = new PlanogramsService();
    }
    apiLoad() {
        return this.planogramsService.getPlanogram(this.name).then(res => {
            const version = res.planogram_version;
            const actionsJson = res.planogram_version.actions_json;
            if (typeof actionsJson === 'string')
                return this.planogramsService.getActionJson(actionsJson).then(data => {
                    version.actions_json = data;
                    return version;
                });
            else
                return version;
        });
    }
    load() {
        return new Promise((resolve, reject) => {
            var _a;
            ErrorOverlay.hide();
            PageLoadingSpeed.startMeasure(PAGE_LOADING_TYPES.SPHERE);
            this.detectedCurrency = (_a = document.querySelector('meta[name="currency_code"]')) === null || _a === void 0 ? void 0 : _a.getAttribute('content');
            const cdnTag = document.querySelector('meta[name="serialized-planogram-version"]');
            const promise = cdnTag
                ? this.planogramsService.getPlanogramFromCdn(cdnTag.getAttribute('content')).catch(err => {
                    console.error(err);
                    return this.apiLoad();
                })
                : this.apiLoad();
            // only use the tag once, otherwise navigating to other spheres will load wrong json
            // TODO: add planogram name to PlanogramVersion JSON response so that FE can validate that the correct JSON was received
            cdnTag === null || cdnTag === void 0 ? void 0 : cdnTag.remove();
            promise
                .then(version => {
                this.processLoadedVersion(version);
                resolve();
            })
                .catch(err => {
                this.handleErrorResponse(err);
                reject(err);
            });
        });
    }
    handleErrorResponse(err) {
        if (err.status === 404) {
            if (L10nUtils.getCurrentLanguage() === L10nUtils.fallbackLanguage) {
                ErrorOverlay.show404Error();
            }
            else {
                Router.updateLangCode(L10nUtils.fallbackLanguage);
            }
        }
        else if (err.status === 401 ||
            (err instanceof TypeError && ((err === null || err === void 0 ? void 0 : err.message) === 'cancelled' || (err === null || err === void 0 ? void 0 : err.message) === 'Load failed'))) {
            // iOS return TypeError on cancelling authorization attempt
            // instead of trying to request data without credentials
            ErrorOverlay.show404Error();
        }
        else {
            ErrorOverlay.show500Error();
        }
        if (err.status === undefined)
            console.error(err);
    }
    processLoadedVersion(versionConfig) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
        migrate(versionConfig, this.name);
        const planogramConfig = versionConfig.actions_json;
        this.id = versionConfig.planogram_id;
        this.isExternalDomain = versionConfig.client.is_external_domain;
        this.versionId = versionConfig.id;
        this.tilesPath = versionConfig.tiles_path;
        this.clientName = versionConfig.client.name;
        this.ecommerceEnabled = (_a = versionConfig.client) === null || _a === void 0 ? void 0 : _a.ecommerce_platform_enabled;
        this.isMultipassKeyAvailable = (_b = versionConfig.client) === null || _b === void 0 ? void 0 : _b.shopify_multipass_enabled;
        this.multipassRedirectUrl = (_c = versionConfig.client) === null || _c === void 0 ? void 0 : _c.shopify_multipass_url;
        this.ecommercePlatformName = (_e = (_d = versionConfig.client) === null || _d === void 0 ? void 0 : _d.shopping_platform) === null || _e === void 0 ? void 0 : _e.title;
        this.ecommercePlatformCurrency = (_h = (_g = (_f = versionConfig.client) === null || _f === void 0 ? void 0 : _f.shopping_platform) === null || _g === void 0 ? void 0 : _g.currency) !== null && _h !== void 0 ? _h : this.detectedCurrency;
        this.ecommercePlatformCurrencies = (_j = versionConfig.client) === null || _j === void 0 ? void 0 : _j.currencies;
        this.width = planogramConfig.width;
        this.height = planogramConfig.height;
        this.topLimit = planogramConfig.topLimit;
        this.bottomLimit = planogramConfig.bottomLimit;
        this.seoTitle = versionConfig.seo_title;
        this.seoDescription = versionConfig.seo_desc;
        this.backgroundColor = planogramConfig.backgroundColor;
        this.startPoint = (planogramConfig.startPoint / 360.0) * planogramConfig.width;
        this.clustersOrder = planogramConfig.clustersOrder;
        this.planogramVersion = planogramConfig.planogramVersion;
        this.planogramVersionControlButtons = versionConfig.planogram_version_control_buttons;
        this.planogramVersionLogo = versionConfig.planogram_version_logo;
        this.enabledGalleryOverlay = versionConfig.enabled_gallery_overlay;
        this.infoButtonSetting = versionConfig.info_button_setting;
        this.ecommerceOverlaySettingsPdp = versionConfig.ecommerce_overlay_settings_pdp;
        this.ecommerceOverlaySettingsShoppingCart = versionConfig.ecommerce_overlay_settings_shopping_cart;
        this.ecommerceOverlaySettingsSignIn = versionConfig.ecommerce_overlay_settings_sign_in;
        this.ecommerceOverlaySettingsButtons = versionConfig.ecommerce_overlay_settings_buttons;
        this.sharingButton = versionConfig.sharing_button;
        this.primaryColor = versionConfig.primary_color;
        this.secondaryColor = versionConfig.secondary_color;
        this.iframePrimaryColor = versionConfig.iframe_primary_color;
        this.iframeSecondaryColor = versionConfig.iframe_secondary_color;
        this.cameraPosition = versionConfig.camera_position;
        this.clientSocialMedias = versionConfig.client_social_medias;
        this.animationSettings = versionConfig.animation_settings;
        this.audioSettings = versionConfig.audio;
        this.otherAssets = Object.assign(Object.assign({}, versionConfig.other_assets.reduce((arr, val) => (Object.assign(Object.assign({}, arr), { [val.title]: val })), {})), (_k = versionConfig.navigation_arrow) === null || _k === void 0 ? void 0 : _k.navigation_arrow_other_assets.reduce((arr, val) => (Object.assign(Object.assign({}, arr), { [val.direction]: val.other_asset })), {}));
        this.audioBackgroundColor = versionConfig.audio_background_color;
        this.entranceAnimation = versionConfig.entrance_animation;
        this.volume = versionConfig.volume;
        this.navigationAlignment = versionConfig.navigation_alignment;
        this.navigationDistributeEvenly = versionConfig.navigation_distribute_evenly;
        if (planogramConfig.virtualTexture) {
            this.virtualTexture = this.createVirtualTextureFromJSON(planogramConfig.virtualTexture);
        }
        this.items = this.createItemsFromJSON(planogramConfig.items);
        this.animation_paths = planogramConfig.animation_paths;
        this.navigationArrow = versionConfig.navigation_arrow;
        this.background_images = versionConfig.background_images;
        this.productSEO = {};
        this.blurScale = (_l = versionConfig.blur) !== null && _l !== void 0 ? _l : 0.12;
        this.overlayBackdropOpacity = (_m = versionConfig.opacity) !== null && _m !== void 0 ? _m : 0.72;
        this.overlayBackdropColor = (_o = versionConfig.background_color) !== null && _o !== void 0 ? _o : '#1E1E28';
        const currLang = L10nUtils.getCurrentLanguage();
        (_p = versionConfig.planogram_version_products_seo) === null || _p === void 0 ? void 0 : _p.forEach(o => {
            var _a;
            return (this.productSEO[o.id] = {
                title: (_a = o.seo_title[currLang]) !== null && _a !== void 0 ? _a : o.seo_title[L10nUtils.fallbackLanguage]
            });
        });
        this.itemSEO = {};
        (_q = versionConfig.planogram_version_items_seo) === null || _q === void 0 ? void 0 : _q.forEach(o => (this.itemSEO[o.id] = {
            title: o.seo_title
        }));
        if (versionConfig.planogram_legacy) {
            console.warn('Legacy spheres are not supported, please regenerate sphere');
        }
        AppState.planogramName = this.name;
        AppState.isExternalDomain = this.isExternalDomain;
        PageLoadingSpeed.completeMeasure(PAGE_LOADING_TYPES.SPHERE);
    }
    static get COLUMN_COUNT() {
        return 50;
    }
    static get ROW_COUNT() {
        return 50;
    }
    createItemsFromJSON(items) {
        const filteredItems = items.filter(item => !(item.type === SphereItemType.Video && item.data.videoUrl === ''));
        const mappedItems = filteredItems.map(item => {
            item.renderOrder = 99999 - item.layer;
            return item;
        });
        // Assign the parent object to items
        mappedItems.forEach(item => {
            var _a;
            (_a = item.childrenIds) === null || _a === void 0 ? void 0 : _a.forEach(id => {
                const child = mappedItems.find(it => it.id === id);
                if (child === undefined)
                    console.warn(`Cluster ${item.id} has unknown child ${id}`);
                else
                    child.parent = item;
            });
        });
        // Sort the items by position
        mappedItems.sort((a, b) => {
            if (a.x < b.x) {
                return -1;
            }
            else if (a.x > b.x) {
                return 1;
            }
            else if (a.y < b.y) {
                return 1;
            }
            return -1;
        });
        this.itemsOrder = mappedItems.map(item => item.id);
        return mappedItems;
    }
    createVirtualTextureFromJSON(json) {
        return {
            x: json.x,
            y: json.y,
            width: json.width,
            height: json.height,
            pagesWide: json.pagesWide,
            pagesHigh: json.pagesHigh,
            pageSize: json.pageSize,
            worstLod: json.worstLod,
            permanentLod: json.permanentLod,
            pageBorderWidth: json.pageBorderWidth
        };
    }
    createImage(title, defaultSrc) {
        var _a;
        const img = document.createElement('img');
        const image = this.otherAssets[title];
        img.src = (_a = image === null || image === void 0 ? void 0 : image.url) !== null && _a !== void 0 ? _a : defaultSrc;
        return img;
    }
}
Planogram.DEFAULT_LODS_AMOUNT = 7;
Planogram.THRESHOLD_FOR_SWITCHING_LODS = 3;
// How many parts to split the sphere into
Planogram.PAGES_WIDE = 256;
Planogram.PAGES_HIGH = 64;
