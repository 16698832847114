export var ANIMATION_TYPE;
(function (ANIMATION_TYPE) {
    ANIMATION_TYPE["ZOOM"] = "zoom";
    ANIMATION_TYPE["DRAG"] = "drag";
    ANIMATION_TYPE["CLICK"] = "click";
})(ANIMATION_TYPE || (ANIMATION_TYPE = {}));
export var ANIMATION_POSITION;
(function (ANIMATION_POSITION) {
    ANIMATION_POSITION["CENTER"] = "center";
    ANIMATION_POSITION["TOP"] = "top";
    ANIMATION_POSITION["BOTTOM"] = "bottom";
})(ANIMATION_POSITION || (ANIMATION_POSITION = {}));
export var ALIGNMENT_TYPES;
(function (ALIGNMENT_TYPES) {
    ALIGNMENT_TYPES["LEFT"] = "left";
    ALIGNMENT_TYPES["MIDDLE"] = "middle";
    ALIGNMENT_TYPES["RIGHT"] = "right";
    ALIGNMENT_TYPES["DISTRIBUTE"] = "distribute";
})(ALIGNMENT_TYPES || (ALIGNMENT_TYPES = {}));
export var NAVIGATION_ARROW_TYPES;
(function (NAVIGATION_ARROW_TYPES) {
    NAVIGATION_ARROW_TYPES["LEFT"] = "left";
    NAVIGATION_ARROW_TYPES["RIGHT"] = "right";
})(NAVIGATION_ARROW_TYPES || (NAVIGATION_ARROW_TYPES = {}));
export class PlanogramData {
}
export var NAVIGATION_BUTTON_TYPE;
(function (NAVIGATION_BUTTON_TYPE) {
    NAVIGATION_BUTTON_TYPE["CONTROL_BUTTON"] = "control_button";
    NAVIGATION_BUTTON_TYPE["SEARCH"] = "search_setting";
    NAVIGATION_BUTTON_TYPE["MENU"] = "menu";
})(NAVIGATION_BUTTON_TYPE || (NAVIGATION_BUTTON_TYPE = {}));
export var ShareProviderTypes;
(function (ShareProviderTypes) {
    ShareProviderTypes["WHATSAPP"] = "whatsapp";
    ShareProviderTypes["TELEGRAM"] = "telegram";
    ShareProviderTypes["VIBER"] = "viber";
    ShareProviderTypes["TWITTER"] = "twitter";
    ShareProviderTypes["COPY_LINK"] = "copy_link";
})(ShareProviderTypes || (ShareProviderTypes = {}));
export var ShareButtonIconTypes;
(function (ShareButtonIconTypes) {
    ShareButtonIconTypes["DEFAULT"] = "default";
    ShareButtonIconTypes["ACTIVE"] = "active";
})(ShareButtonIconTypes || (ShareButtonIconTypes = {}));
