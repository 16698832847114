// TODO: de-duplicate with editor/models/enums
var Condition;
(function (Condition) {
    Condition[Condition["LESS"] = 0] = "LESS";
    Condition[Condition["GREATER"] = 1] = "GREATER";
    Condition[Condition["LESS_OR_EQUAL"] = 2] = "LESS_OR_EQUAL";
    Condition[Condition["GREATER_OR_EQUAL"] = 3] = "GREATER_OR_EQUAL";
    Condition[Condition["EQUAL"] = 4] = "EQUAL";
})(Condition || (Condition = {}));
function roundTo(value, digits = 2) {
    const pow = Math.pow(10, digits);
    return Math.round(value * pow) / pow;
}
function floatEq(a, b) {
    if (a === b)
        return true;
    const relativeEpsilon = Math.max(1, Math.min(Math.abs(a), Math.abs(b)));
    return Math.abs(a - b) <= Number.EPSILON * relativeEpsilon;
}
function epsilonEq(a, b, epsilon) {
    if (a === b)
        return true;
    return Math.abs(a - b) <= epsilon;
}
function floatLt(a, b) {
    return a < b && !floatEq(a, b);
}
function floatGt(a, b) {
    return a > b && !floatEq(a, b);
}
function floatLte(a, b) {
    return a < b || floatEq(a, b);
}
function floatGte(a, b) {
    return a > b || floatEq(a, b);
}
function floatCompare(a, condition, b) {
    switch (condition) {
        case Condition.LESS:
            return floatLt(a, b);
        case Condition.LESS_OR_EQUAL:
            return floatLte(a, b);
        case Condition.GREATER:
            return floatGt(a, b);
        case Condition.GREATER_OR_EQUAL:
            return floatGte(a, b);
        case Condition.EQUAL:
            return floatEq(a, b);
        default:
            throw new Error('Unknown condition!');
    }
}
function strictInRange(v, min, max) {
    return (v - min) * (v - max) < 0;
}
const solveQuadraticEquation = (a, b, c) => {
    if (floatEq(a, 0)) {
        // When a===0 we're not solving a quadratic, but a linear equation
        if (floatEq(b, 0)) {
            // When b is 0, either c is non-zero and no result is valid,
            // or c is zero and any value is valid (returned [0] represents only one of those solutions)
            return floatEq(c, 0) ? [0] : [];
        }
        return [-c / b];
    }
    function quadraticEquation(multiplier) {
        return (-b + multiplier * Math.sqrt(d)) / (2 * a);
    }
    const d = b * b - 4 * a * c;
    if (d < 0)
        return [];
    else if (floatEq(d, 0))
        return [quadraticEquation(+1)];
    else
        return [quadraticEquation(+1), quadraticEquation(-1)];
};
export { roundTo, floatEq, epsilonEq, floatLt, floatGt, floatGte, floatCompare, strictInRange, solveQuadraticEquation, };
