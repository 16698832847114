import { InputFieldComponent } from '../../components/input-field/input-field-component';
import { SelectFieldComponent } from '../../components/select-field/select-field-component';
export class InputFieldsBaseComponent {
    constructor(container, product, shopifyService) {
        this.container = container;
        this.product = product;
        this.shopifyService = shopifyService;
        this.fields = new Map();
    }
    get values() {
        return Array.from(this.fields.keys()).map((key) => ({
            key,
            value: key.startsWith('_') ?
                this.getField(key) :
                this.getField(key).value
        }));
    }
    getField(key) {
        return this.fields.get(key);
    }
    setField(key, value) {
        this.fields.set(key, value);
    }
    validate() {
        for (const input of this.fields.values()) {
            const isValid = input instanceof (InputFieldComponent || SelectFieldComponent) ? input.validate() : true;
            if (!isValid) {
                return false;
            }
        }
        return true;
    }
    dispose() { }
}
