var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ProductInfoAjTraceyView } from './product-info-ajtracey-view';
import { ProductInfoBase } from './product-info-base_overlay';
export class ProductInfoAjTracey extends ProductInfoBase {
    init() {
        const _super = Object.create(null, {
            init: { get: () => super.init }
        });
        return __awaiter(this, void 0, void 0, function* () {
            yield _super.init.call(this);
            this.handleStoryMode();
        });
    }
    handleClick(e) {
        if (this.isViewGallery()) {
            const tokens = ['app-product-overlay', 'app-product-content-wrapper', 'app-product-carousel'];
            if (e.target.closest('#app-image-carousel')) {
                tokens.push('splide__slide');
            }
            return tokens.some(token => e.target.classList.contains(token));
        }
        else {
            return e.target === this.mainContainer;
        }
    }
    initDescriptionControls() {
        // No description
    }
    setItemContent() {
        this.container.innerHTML = ProductInfoAjTraceyView(Object.assign({}, this.product), this.item.planogram.name);
    }
    handleStoryMode() {
        if (this.product.price_in_user_currency_str) {
            return;
        }
        // Hide custom button if price is empty
        const customButton = document.getElementById('custom-button');
        const galleryCheckoutButtonContainer = document.getElementById('checkout-gallery-button');
        const galleryInfoButtonContainer = document.getElementById('info-gallery-button');
        const checkoutButton = document.getElementById('checkout-button');
        this.mainContainer.classList.remove('checkout-flow');
        this.mainContainer.classList.add('story-mode');
        customButton === null || customButton === void 0 ? void 0 : customButton.classList.add('is-hidden');
        galleryInfoButtonContainer === null || galleryInfoButtonContainer === void 0 ? void 0 : galleryInfoButtonContainer.classList.add('no-checkout');
        galleryCheckoutButtonContainer === null || galleryCheckoutButtonContainer === void 0 ? void 0 : galleryCheckoutButtonContainer.classList.add('is-hidden');
        checkoutButton === null || checkoutButton === void 0 ? void 0 : checkoutButton.classList.add('is-hidden');
    }
    setThumbsSwitchLength() {
        if (this.gallerySplide && this.thumbnailSplide) {
            const thumbsLength = this.thumbnailSplide.length - 1;
            const maxLength = 5;
            const perPage = Math.min(thumbsLength, maxLength);
            this.thumbnailSplide.options = { perPage, focus: perPage - 1 };
            this.gallerySplide.emit('resize', {});
            this.thumbnailSplide.emit('resize', {});
        }
    }
    getThumbsCarouselConfig(productSlides) {
        return {
            autoWidth: true,
            autoHeight: true,
            gap: '1.46vw',
            speed: 150,
            rewind: true,
            pagination: false,
            isNavigation: true,
            lazyLoad: 'sequential',
            start: productSlides.imageIndex,
            perPage: Math.min(productSlides.length - 1, 5),
            focus: 4,
            perMove: 1,
            keyboard: false,
            breakpoints: {
                1200: {
                    gap: 16
                }
            }
        };
    }
}
