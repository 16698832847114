var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { layoutSwitcherView } from './layout-switcher.view';
import { InputEventUtils } from '../../utils/input_event_utils';
import { OverlayScrollbar } from '../overlay_scrollbar';
import { sphereEventHandler } from '../../custom_event_utils';
import { SPHERE_EVENT_NAMES as EVENTS } from '../../event-names';
export class LayoutSwitcher {
    get ANIMATION_DELAY() {
        return 100;
    }
    get isListOpened() {
        return this.switcher.classList.contains(LayoutSwitcher.isOpenedClass);
    }
    constructor({ container, children, type, handleSelect }) {
        var _a, _b, _c;
        this.handleResize = this.handleResize.bind(this);
        this.container = container;
        this.handleSelect = handleSelect;
        this.itemsList = children;
        this.layoutType = type;
        this.container.innerHTML = layoutSwitcherView({ type });
        this.itemsContainer = this.container.querySelector('.layout-items_wrapper');
        this.layoutSwitcherContainer = this.container.querySelector('.layout-switcher_container');
        this.switcher = this.container.firstElementChild;
        this.search = this.container.querySelector('.layout-switcher_search input');
        this.renderItems(children);
        this.registerClickEventHandler();
        this.registerInputEventHandler();
        this.setActiveState((_a = children[0]) === null || _a === void 0 ? void 0 : _a.id);
        sphereEventHandler.listen(EVENTS.CONTROL.CLICK, () => {
            var _a;
            (_a = this.search) === null || _a === void 0 ? void 0 : _a.blur();
        });
        window.addEventListener('resize', this.handleResize);
        (_b = this.search) === null || _b === void 0 ? void 0 : _b.addEventListener('click', () => {
            var _a;
            this.renderItems(this.itemsList);
            this.setOpeningStatus(!this.isListOpened);
            if (!LayoutSwitcher.isLayoutActive) {
                (_a = this.search) === null || _a === void 0 ? void 0 : _a.blur();
            }
            else {
                this.search.placeholder = '';
            }
        });
        (_c = this.search) === null || _c === void 0 ? void 0 : _c.addEventListener('blur', this.handleSearchBlur.bind(this));
        const renderItemsCallback = () => {
            renderItemsObserver.disconnect();
            return new OverlayScrollbar('.scroll-wrapper', { autoHide: 'never' });
        };
        const renderItemsObserver = new MutationObserver(renderItemsCallback);
        renderItemsObserver.observe(this.itemsContainer, { childList: true });
    }
    registerClickEventHandler() {
        InputEventUtils.addSelectEvents(this.itemsContainer, this.handleClick.bind(this));
    }
    renderItems(items) {
        this.clearList();
        const resultItems = document.createDocumentFragment();
        items.forEach(item => {
            var _a;
            const itemContainer = document.createElement('div');
            const itemContent = document.createElement('div');
            itemContainer.setAttribute('data-item-id', item.id);
            itemContainer.classList.add('layout-switcher_item');
            itemContent.classList.add('layout-switcher_item_content');
            itemContent.innerHTML = `${(_a = item.symbol) !== null && _a !== void 0 ? _a : ''} ${item.element}`;
            itemContainer.appendChild(itemContent);
            resultItems.appendChild(itemContainer);
        });
        this.itemsContainer.appendChild(resultItems);
        this.items = this.container.querySelectorAll('.layout-switcher_item');
        setTimeout(() => {
            if (LayoutSwitcher.isLayoutActive) {
                this.setSwitcherHeight();
            }
        }, this.ANIMATION_DELAY);
    }
    clearList() {
        this.itemsContainer.innerHTML = '';
    }
    setSearchPlaceholder() {
        var _a;
        if (!this.search) {
            return;
        }
        this.search.placeholder = `${(_a = this.selectedItem.symbol) !== null && _a !== void 0 ? _a : ''} ${this.selectedItem.id}`;
    }
    handleSearchBlur() {
        this.search.value = '';
        this.setSearchPlaceholder();
        this.handleResize();
    }
    registerInputEventHandler() {
        var _a;
        (_a = this.search) === null || _a === void 0 ? void 0 : _a.addEventListener('input', (e) => {
            var _a;
            const query = (_a = e.target.value) === null || _a === void 0 ? void 0 : _a.toUpperCase();
            const searchedCurrencies = this.itemsList.filter(currency => currency.id.includes(query));
            this.renderItems(searchedCurrencies);
        });
    }
    handleResize() {
        setTimeout(() => {
            this.switcher.classList.remove(LayoutSwitcher.isOpenedClass);
            if (this.layoutType === 'text') {
                this.clearList();
            }
        }, this.ANIMATION_DELAY);
        this.clearSwitcherHeight();
        LayoutSwitcher.isLayoutActive = false;
    }
    setOpeningStatus(data) {
        if (data) {
            this.switcher.classList.add(LayoutSwitcher.isOpenedClass);
            this.setSwitcherHeight();
            LayoutSwitcher.isLayoutActive = true;
        }
        else {
            this.handleResize();
        }
    }
    handleClick(e) {
        return __awaiter(this, void 0, void 0, function* () {
            const item = e.target.closest('.layout-switcher_item');
            if (!this.isListOpened) {
                this.setOpeningStatus(true);
                return;
            }
            if (!item.classList.contains(LayoutSwitcher.isActiveClass)) {
                const { itemId } = item.dataset;
                yield this.handleSelect(itemId);
                this.setActiveState(itemId);
            }
            this.setOpeningStatus(false);
        });
    }
    setSwitcherHeight() {
        this.switcher.style.height = this.layoutSwitcherContainer.offsetHeight + 'px';
    }
    clearSwitcherHeight() {
        this.switcher.style.height = '';
    }
    setActiveState(itemId) {
        const itemEl = this.container.querySelector(`.layout-switcher_item[data-item-id=${itemId}]`);
        this.selectedItem = this.itemsList.find(item => item.id === itemId);
        if (itemEl) {
            this.items.forEach(item => item.classList.remove(LayoutSwitcher.isActiveClass));
            itemEl.classList.add(LayoutSwitcher.isActiveClass);
            this.setSearchPlaceholder();
        }
    }
    dispose() {
        window.removeEventListener('resize', this.handleResize);
    }
}
LayoutSwitcher.isOpenedClass = 'is-opened';
LayoutSwitcher.isActiveClass = 'is-active';
