import { InputEventUtils } from '../utils/input_event_utils';
import { JourneyOverlayNavigationBar } from './journey-overlay-navigation-bar';
import { overlayEventsHandler } from '../custom_event_utils';
import { SPHERE_EVENT_NAMES } from '../event-names';
import { NAVIGATION_ARROW_TYPES } from '../interfaces/planogram.interface';
import { ActionType } from 'shared/interfaces/planogram';
const AUTOPLAY_INTERVAL = 5000;
export class JourneyOverlay {
    constructor(container, journeyItems, inputHandler, animationTime = 500, closeCallback, autoplay) {
        this.container = container;
        this.journeyItems = journeyItems;
        this.inputHandler = inputHandler;
        this.animationTime = animationTime;
        this.closeCallback = closeCallback;
        this.autoplay = autoplay;
        this.container = container;
        const root = document.documentElement;
        root.style.setProperty('--journey-animation-time', `${this.animationTime}ms`);
        this.pauseAutoPlay = this.pauseAutoPlay.bind(this);
        this.toggleFullscreen = this.toggleFullscreen.bind(this);
        this.container.innerHTML = `
      <div class="journey-overlay overlay-container is-flex is-vertical is-vertically-aligned-center is-horizontally-aligned-center">
        <div class="journey-overlay-backdrop"></div>
        <div class="journey-overlay-wrapper">
          <div class="overlay-button overlay-button-close will-close">
            <span></span>
          </div>
          <div class="journey-overlay-transition-wrapper">
            <div class="journey-overlay-inner-content"></div>
          </div>
        </div>
      </div>
    `;
        const closeButton = this.container.querySelector('.journey-overlay .overlay-button-close');
        this.container.classList.remove('is-hidden');
        this.initInnerOverlays();
        if (closeButton) {
            InputEventUtils.addSelectEvents(closeButton, this.closeOverlay.bind(this));
        }
        else {
            console.error('element not found - ".overlay-button-close"');
        }
        this.handleAutoplay();
        this.navBar = new JourneyOverlayNavigationBar({
            autoplay: this.autoplay,
            container: this.container,
            onPrev: () => this.handleNavigation(NAVIGATION_ARROW_TYPES.LEFT, true),
            onToggleAutoPlay: () => this.handleToggleAutoplay(),
            onNext: () => this.handleNavigation(NAVIGATION_ARROW_TYPES.RIGHT, true),
            onExit: () => this.closeOverlay()
        });
        overlayEventsHandler.listen([SPHERE_EVENT_NAMES.OVERLAY.OVERLAY_USER_ACTION, SPHERE_EVENT_NAMES.OVERLAY.OVERLAY_USER_SCROLL].join(' '), this.pauseAutoPlay);
        overlayEventsHandler.listen(SPHERE_EVENT_NAMES.OVERLAY.FULLSCREEN, this.toggleFullscreen);
    }
    initInnerOverlays() {
        const internalContainer = this.container.querySelector('.journey-overlay-inner-content');
        this.journeyItems.items.forEach((item, index) => {
            var _a;
            const overlayContainer = document.createElement('div');
            const overlayContent = document.createElement('div');
            const { prevIndex, nextIndex } = this.getNearestItems();
            overlayContent.classList.add('journey-overlay-inner-item-content');
            overlayContainer.classList.add('journey-overlay-inner-item');
            overlayContainer.style.setProperty('--index', `${index}`);
            const autoplayVideo = index === this.inputHandler.currentAnimationIndex && this.autoplay;
            this.inputHandler.overlay.showItem(item, undefined, overlayContent, autoplayVideo, {
                title: this.inputHandler.animationPath.items[index].showSeoTitle,
                fullscreen: ((_a = item.action) === null || _a === void 0 ? void 0 : _a.type) !== ActionType.VideoOverlay
            });
            if (index === this.inputHandler.currentAnimationIndex) {
                overlayContainer.classList.add('active');
            }
            else if (index === prevIndex) {
                overlayContainer.classList.add('prev');
            }
            else if (index === nextIndex) {
                overlayContainer.classList.add('next');
            }
            overlayContainer.append(overlayContent);
            internalContainer.append(overlayContainer);
        });
    }
    toggleFullscreen() {
        this.container.classList.toggle('fullscreen-mode');
    }
    handleAutoplay() {
        if (this.autoplay)
            this.intervalAutoplayId = setInterval(() => {
                this.handleNavigation(NAVIGATION_ARROW_TYPES.RIGHT);
            }, AUTOPLAY_INTERVAL);
        else
            clearInterval(this.intervalAutoplayId);
    }
    handleToggleAutoplay() {
        this.autoplay = !this.autoplay;
        this.navBar.autoplay = this.autoplay;
        this.handleAutoplay();
    }
    pauseAutoPlay() {
        this.autoplay = true;
        this.handleToggleAutoplay();
        overlayEventsHandler.emit(SPHERE_EVENT_NAMES.OVERLAY.INTERACTION);
    }
    handleNavigation(arrowType, manualClick) {
        var _a, _b, _c;
        const isLastItem = this.inputHandler.currentAnimationIndex === this.journeyItems.items.length - 1;
        if (this.autoplay && isLastItem && !this.inputHandler.animationPath.loop)
            this.pauseAutoPlay();
        const reverse = arrowType === NAVIGATION_ARROW_TYPES.LEFT;
        const transitionContainer = this.container.querySelector('.journey-overlay-inner-content');
        this.inputHandler.handleNextAnimation(reverse);
        const itemsContainers = this.container.querySelectorAll('.journey-overlay-inner-item');
        itemsContainers.forEach(item => item.classList.remove('active', 'prev', 'next'));
        const { prevIndex, nextIndex } = this.getNearestItems();
        if (prevIndex !== this.inputHandler.currentAnimationIndex) {
            (_a = itemsContainers[prevIndex]) === null || _a === void 0 ? void 0 : _a.classList.add('prev');
        }
        itemsContainers[this.inputHandler.currentAnimationIndex].classList.add('active');
        if (nextIndex !== this.inputHandler.currentAnimationIndex) {
            itemsContainers[nextIndex].classList.add('next');
        }
        transitionContainer.style.setProperty('--dir', reverse ? '-1' : '1');
        if (manualClick) {
            this.pauseAutoPlay();
        }
        const currentActiveElement = (_b = itemsContainers[this.inputHandler.currentAnimationIndex]) === null || _b === void 0 ? void 0 : _b.querySelector('video, iframe');
        const prevActiveElement = (_c = itemsContainers[reverse ? nextIndex : prevIndex]) === null || _c === void 0 ? void 0 : _c.querySelector('video, iframe');
        if (currentActiveElement)
            this.handleMediaElement(currentActiveElement, 'play');
        if (prevActiveElement)
            this.handleMediaElement(prevActiveElement, 'pause');
    }
    handleMediaElement(element, action) {
        if (!element)
            return;
        if (element instanceof HTMLVideoElement)
            action === 'play' ? element.play() : element.pause();
        else if (action === 'pause')
            element.src = element.src; // reloads the iframe
    }
    getNearestItems() {
        const prevIndex = this.inputHandler.currentAnimationIndex - 1 >= 0
            ? this.inputHandler.currentAnimationIndex - 1
            : this.journeyItems.items.length - 1;
        const nextIndex = (this.inputHandler.currentAnimationIndex + 1) % this.journeyItems.items.length;
        return {
            prevIndex,
            nextIndex
        };
    }
    closeOverlay(e) {
        if (e) {
            e.stopPropagation();
        }
        if (this.closeCallback) {
            overlayEventsHandler.emit(SPHERE_EVENT_NAMES.OVERLAY.JOURNEY_OVERLAY_CLOSE);
            this.closeCallback();
            this.dispose();
        }
        else {
            console.error('"Close" function is not set up');
        }
    }
    handleClick(e) {
        const innerContainer = this.container.querySelector('.journey-overlay-inner-content');
        if (innerContainer.contains(e.target)) {
            this.pauseAutoPlay();
        }
        return e.target.classList.contains('journey-overlay-backdrop');
    }
    dispose() {
        this.container.classList.add('is-hidden');
        this.container.innerHTML = '';
        clearInterval(this.intervalAutoplayId);
        overlayEventsHandler.removeAll([
            SPHERE_EVENT_NAMES.OVERLAY.OVERLAY_USER_ACTION,
            SPHERE_EVENT_NAMES.OVERLAY.OVERLAY_USER_SCROLL,
            SPHERE_EVENT_NAMES.OVERLAY.FULLSCREEN,
            SPHERE_EVENT_NAMES.OVERLAY.INTERACTION,
            SPHERE_EVENT_NAMES.OVERLAY.JOURNEY_OVERLAY_CLOSE
        ].join(' '));
    }
}
