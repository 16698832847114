export const loader = ({ message = null }) => {
    return `
    <div class="loader-wrapper ${message ? '' : 'no-message'}">
      <div class="loader-animation">
        <svg class="circular" viewBox="25 25 50 50">
          <circle class="path" cx="50" cy="50" r="20" stroke="currentColor" fill="none" stroke-width="3" stroke-miterlimit="10"/>
        </svg>
      </div>
      ${message ? `<span class="loader-text">${message}</span>` : ''}
    </div>
  `;
};
