import subscribe from 'callbag-subscribe';
import pipe from 'callbag-pipe';
import { SearchInput } from './search-input';
import { SearchResults } from './search-results';
import { searchEventHandler, sphereEventHandler } from '../custom_event_utils';
import { SearchFilters } from './search-filters';
import { SearchNoResults } from './search-no-results';
import { SEARCH_EVENT_NAMES as SEARCH_EVENTS, SPHERE_EVENT_NAMES as EVENTS } from '../event-names';
import { isAppleDevice } from '../utils/platform';
const BLUR_SEARCH_SCALE = 1.0;
export class Search {
    static get SEARCH_QUERY_MINIMUM_LENGTH() {
        return 3;
    }
    constructor(searchService, planogram, inputHandler) {
        this.searchService = searchService;
        this.planogram = planogram;
        this.inputHandler = inputHandler;
        this.init();
        this.searchInput = new SearchInput(this.searchService);
        this.searchFilters = new SearchFilters(this.searchService);
        this.searchResults = new SearchResults(this.searchService, (productLink, productName) => this.inputHandler.redirectToProduct(productLink, productName));
        this.searchNoResults = new SearchNoResults();
        sphereEventHandler.listen(EVENTS.PLANOGRAM_CHANGED, () => {
            Search.isSearchActive = false;
            this.disableSearch();
        });
        searchEventHandler.listen(SEARCH_EVENTS.SHOW_SEARCH, options => {
            Search.isSearchActive = options.isSearchActive;
            if (Search.isSearchActive) {
                this.inputHandler.resetAutoplay();
                this.hideSphereElements();
            }
            else {
                this.clearSearch();
                this.showSphereElements();
            }
            this.searchService.toggleSearch(this.planogram.name, Search.isSearchActive);
        });
        searchEventHandler.listen(SEARCH_EVENTS.UPDATE_SEARCH, data => {
            this.updateSearchState(data === null || data === void 0 ? void 0 : data.results);
        });
        searchEventHandler.listen(SEARCH_EVENTS.CLOSE_SEARCH, () => {
            var _a;
            (_a = this.searchInput) === null || _a === void 0 ? void 0 : _a.closeInput();
            this.clearSearch();
        });
        searchEventHandler.listen(SEARCH_EVENTS.SHOW_SPHERE_ELEMENTS, () => {
            this.showSphereElements();
        });
        pipe(this.searchService.searchSettingsSubject, subscribe({
            next: (settings) => {
                if (settings) {
                    this.searchWrapper.classList.remove('is-completely-hidden');
                }
                this.searchInput.updateSearchSettings(settings);
                this.searchFilters.updateSearchSettings(settings);
                this.searchResults.updateSearchSettings(settings);
                this.searchNoResults.updateSearchSettings(settings);
                if (Search.isSearchActive) {
                    this.searchInput.showInput();
                }
            },
            error: err => {
                if (window.location.href.includes('search')) {
                    searchEventHandler.emit(SEARCH_EVENTS.SHOW_SEARCH, { isSearchActive: false });
                }
                if (err) {
                    console.warn(`Search error: ${JSON.stringify(err)}`);
                }
            }
        }));
    }
    init() {
        const fullscreenButtons = document.getElementById('fullscreen-button-list');
        const sphereLogo = document.getElementById('sphere-logo');
        const l10nControl = document.getElementById('sphere-l10n-container');
        const topRightContainer = document.getElementById('top-right-container');
        const topLeftContainer = document.getElementById('top-left-container');
        const bottomContainer = document.getElementById('bottom-container');
        this.searchWrapper = document.querySelector('.search-wrapper');
        this.inputEl = document.getElementById('search-input');
        this.elementsToHide = [
            fullscreenButtons,
            sphereLogo,
            l10nControl,
            topRightContainer,
            topLeftContainer,
            bottomContainer
        ];
        this.searchWrapper.addEventListener('touchmove', Search.disableScroll);
        this.inputEl.addEventListener('blur', this.resetSearchPosition.bind(this));
        if (window.visualViewport && isAppleDevice)
            window.visualViewport.addEventListener('resize', this.updateSearchPos.bind(this));
    }
    showSphereElements() {
        this.elementsToHide.forEach(item => item === null || item === void 0 ? void 0 : item.classList.remove('is-hidden'));
    }
    hideSphereElements() {
        this.inputHandler.hideOverlay();
        this.elementsToHide.forEach(item => item === null || item === void 0 ? void 0 : item.classList.add('is-hidden'));
    }
    showSearch() {
        Search.isSearchActive = true;
        this.hideSphereElements();
        this.searchInput.showInput();
    }
    updateSearchState(results) {
        if (this.inputHandler.isOverlayShowing()) {
            return;
        }
        const searchQuery = this.inputEl.value;
        if ((searchQuery === null || searchQuery === void 0 ? void 0 : searchQuery.length) >= Search.SEARCH_QUERY_MINIMUM_LENGTH) {
            sphereEventHandler.emit(EVENTS.SPHERE.BLUR_CANVAS, { kernelSize: BLUR_SEARCH_SCALE });
            if (results === null || results === void 0 ? void 0 : results.products.length) {
                this.searchWrapper.classList.remove('no-results-view');
            }
            else {
                this.searchWrapper.classList.add('no-results-view');
            }
        }
        else {
            this.searchWrapper.classList.remove('no-results-view');
            this.searchService.cancelPendingRequests();
            this.searchResults.clearResults();
            this.searchFilters.clearFilters();
            sphereEventHandler.emit(EVENTS.SPHERE.UNBLUR_CANVAS);
        }
    }
    clearSearch() {
        this.inputEl.value = '';
        this.searchInput.setBorderState(this.inputEl.value);
        this.updateSearchState();
    }
    disableSearch() {
        this.searchWrapper.classList.add('is-completely-hidden');
    }
    static disableScroll(e) {
        e.stopPropagation();
        e.preventDefault();
    }
    updateSearchPos() {
        window.scroll(0, 0);
        if (window.visualViewport) {
            const offset = window.innerHeight - window.visualViewport.height;
            this.searchWrapper.style.top = `-${offset}px`;
        }
    }
    resetSearchPosition() {
        this.searchWrapper.style.top = '0';
    }
}
