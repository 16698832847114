import makeBehaviorSubject from 'callbag-behavior-subject';
import subscribe from 'callbag-subscribe';
import pipe from 'callbag-pipe';
import { CURRENCY_CODE_KEY, CURRENCY_KEY } from '../../shared/constants';
import Router from '../../router';
import { EventType } from '../../interfaces/shopping-cart.interface';
export class CurrencyService {
    get selectedCurrencyCode() {
        return new URL(window.location.href).searchParams.get(CURRENCY_KEY) ||
            window.localStorage.getItem(CURRENCY_CODE_KEY);
    }
    constructor(currenciesList, detectedCurrencyCode) {
        var _a, _b;
        this.currenciesList = currenciesList;
        const initialCurrencyCode = (_b = (_a = this.getCurrencyByCode(this.selectedCurrencyCode)) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : detectedCurrencyCode;
        Router.removeParamFromUrl(CURRENCY_KEY);
        this.changeCurrencySubject = makeBehaviorSubject(initialCurrencyCode);
        this.currencySubjectRef = pipe(this.changeCurrencySubject, subscribe({
            next: (currencyCode) => {
                window.localStorage.setItem(CURRENCY_CODE_KEY, currencyCode);
            }
        }));
    }
    setCurrency(currencyCode) {
        this.changeCurrencySubject(EventType.Data, currencyCode);
    }
    getCurrencyByCode(currencyCode) {
        return this.currenciesList.find(c => c.name === currencyCode);
    }
    dispose() {
        this.currencySubjectRef();
    }
}
