import { InputFieldsBaseComponent } from '../input-fields-base/input-fields-base-component';
import { SelectFieldComponent } from '../../components/select-field/select-field-component';
import { InputFieldComponent } from '../../components/input-field/input-field-component';
export class InputFieldsLetterKeychainComponent extends InputFieldsBaseComponent {
    constructor(container, product) {
        super(container, product);
        const inputFields = this.product.input_fields;
        this.setField(inputFields[0].name, new SelectFieldComponent(this.container.querySelector('.select-field-component')));
        this.setField(inputFields[1].name, new InputFieldComponent(this.container.querySelectorAll('.input-field-component')[0]));
        this.setField(inputFields[2].name, new InputFieldComponent(this.container.querySelectorAll('.input-field-component')[1]));
    }
}
