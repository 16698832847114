import { InputEventUtils } from '../utils/input_event_utils';
import { CookiesManagement } from '../cookies_management';
import { sphereEventHandler } from '../custom_event_utils';
import { SPHERE_EVENT_NAMES } from '../event-names';
import { throttle, debounce } from 'lodash';
import { Vector2 } from 'three';
const INPUT_DELAY_MILISECONDS = 150;
const MOUSE_BUTTONS = {
    LEFT: 1,
    MIDDLE: 4,
    RIGHT: 2
};
const WHEEL_ZOOM_FACTOR = 0.881;
const TRACKPAD_ZOOM_FACTOR = 0.93;
export class MouseControls {
    constructor(domElement, cameraControls, inputHandler) {
        this.domElement = domElement;
        this.cameraControls = cameraControls;
        this.inputHandler = inputHandler;
        this.throttleSetCursor = throttle((e) => sphereEventHandler.emit(SPHERE_EVENT_NAMES.SPHERE.SET_CURSOR, e), INPUT_DELAY_MILISECONDS, {
            leading: true
        });
        this.debounceSetCursor = debounce((e) => sphereEventHandler.emit(SPHERE_EVENT_NAMES.SPHERE.SET_CURSOR, e), INPUT_DELAY_MILISECONDS);
        this.onMouseDown = this.onMouseDown.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);
        this.onWheel = this.onWheel.bind(this);
        this.domElement.addEventListener('mousedown', e => this.onMouseDown(e), { capture: false });
        this.domElement.addEventListener('mousemove', e => this.onMouseMove(e), { capture: false });
        this.domElement.addEventListener('mouseup', e => this.onMouseUp(e), { capture: false });
        this.domElement.addEventListener('wheel', e => this.onWheel(e), { passive: false });
    }
    onMouseDown(event) {
        this.cameraControls.clearAnimation();
        this.pointerOnLeftDown = undefined;
        if (event.buttons === MOUSE_BUTTONS.LEFT && InputEventUtils.onSphereSurface(event)) {
            event.preventDefault();
            this.mouseDownEvent = event;
            this.pointerOnLeftDown = new Vector2(event.clientX, event.clientY);
            this.cameraControls.onMovementStart(this.pointerOnLeftDown);
        }
    }
    onMouseMove(event) {
        const x = event.clientX;
        const y = event.clientY;
        if (this.mouseDownEvent) {
            event.preventDefault();
            if (event.buttons === MOUSE_BUTTONS.LEFT) {
                this.inputHandler.isClusterSelected = false;
                this.cameraControls.tiltAndPanTo({ x, y });
            }
            else {
                event.stopPropagation();
            }
        }
        else {
            this.throttleSetCursor(event);
            this.debounceSetCursor(event);
        }
    }
    onMouseUp(event) {
        const isRedirectAnimationProcessing = CookiesManagement.isRedirectAnimationProcessing;
        if (isRedirectAnimationProcessing) {
            this.cameraControls.clearAnimation(true);
        }
        if (!this.mouseDownEvent) {
            return;
        }
        event.preventDefault();
        if (this.isLeftButton()) {
            if (InputEventUtils.isClick(this.mouseDownEvent, event)) {
                sphereEventHandler.emit(SPHERE_EVENT_NAMES.CONTROL.CLICK, event);
                this.inputHandler.handleClick(event.clientX, event.clientY);
            }
            else {
                event.stopPropagation();
            }
            this.cameraControls.onMovementEnd();
        }
        this.mouseDownEvent = undefined;
    }
    onWheel(event) {
        this.cameraControls.clearAnimation();
        if (InputEventUtils.onSphereSurface(event)) {
            event.preventDefault();
            this.handleWheel(event);
        }
        else {
            event.stopPropagation();
        }
    }
    handleWheel(event) {
        const cursorPoint = { x: event.clientX, y: event.clientY };
        let zoomFactor;
        if (event.deltaY < 0) {
            if (this.isTrackPad(event)) {
                zoomFactor = TRACKPAD_ZOOM_FACTOR;
            }
            else {
                zoomFactor = WHEEL_ZOOM_FACTOR;
            }
        }
        else if (event.deltaY > 0) {
            if (this.isTrackPad(event)) {
                zoomFactor = 1 / TRACKPAD_ZOOM_FACTOR;
            }
            else {
                zoomFactor = 1 / WHEEL_ZOOM_FACTOR;
            }
        }
        if (zoomFactor) {
            this.inputHandler.isClusterSelected = false;
            this.cameraControls.zoomToPoint(cursorPoint, zoomFactor);
        }
    }
    isTrackPad(event) {
        return Number.isInteger(event.deltaY);
    }
    isLeftButton() {
        return this.pointerOnLeftDown !== undefined;
    }
    dispose() {
        this.domElement.removeEventListener('mousedown', this.onMouseDown, { capture: false });
        this.domElement.removeEventListener('mousemove', this.onMouseMove, { capture: false });
        this.domElement.removeEventListener('mouseup', this.onMouseUp, { capture: false });
        this.domElement.removeEventListener('wheel', this.onWheel);
    }
}
