import { BufferGeometry, Float32BufferAttribute, InterleavedBuffer, InterleavedBufferAttribute, MathUtils, Vector2, Vector3 } from 'three';
import { Planogram } from '../planogram';
export class SphereGeometry extends BufferGeometry {
    static get ROTATION_OFFSET() {
        return 1.5 * Math.PI;
    }
    constructor(alpha, largeRadius, fixedRadius, widthSegments, heightSegments, azimuthStart, azimuthLength, planogramHeight, itemY, itemHeight, requiresUVs, startUV = new Vector2(1, 1), endUV = new Vector2(0, 0)) {
        super();
        this.parameters = {
            alpha,
            largeRadius,
            fixedRadius,
            widthSegments,
            heightSegments,
            azimuthStart,
            azimuthLength,
            planogramHeight,
            itemY,
            itemHeight,
            requiresUVs,
            startUV,
            endUV
        };
        this.setDefaultsAndLimits();
        this.initCoreArrays();
        this.generateVertices();
        this.buildGeometry(requiresUVs);
    }
    dispose() {
        this.indices = null;
        this.vertices = null;
        this.normals = null;
        this.uvs = null;
        this.position = null;
        this.index = null;
        super.dispose();
        this.deleteAttribute('position');
        this.deleteAttribute('uv');
    }
    setDefaultsAndLimits() {
        this.alpha = this.parameters.alpha;
        this.largeRadius = this.parameters.largeRadius;
        this.fixedRadius = this.parameters.fixedRadius;
        this.widthSegments = this.parameters.widthSegments;
        this.heightSegments = this.parameters.heightSegments;
        this.azimuthStart = this.parameters.azimuthStart - SphereGeometry.ROTATION_OFFSET;
        this.azimuthLength = this.parameters.azimuthLength;
        this.planogramHeight = this.parameters.planogramHeight;
        this.itemY = this.parameters.itemY;
        this.itemHeight = this.parameters.itemHeight;
        this.startUV = this.parameters.startUV;
        this.endUV = this.parameters.endUV;
    }
    initCoreArrays() {
        this.indices = [];
        this.vertices = [];
        this.normals = [];
        this.uvs = [];
    }
    get alpha() {
        return this.parameters.alpha;
    }
    set alpha(newAlpha) {
        this.parameters.alpha = newAlpha;
    }
    get largeRadius() {
        return this.parameters.largeRadius;
    }
    set largeRadius(radius) {
        let largeR = radius || 1;
        if (largeR < this.parameters.fixedRadius) {
            largeR = this.parameters.fixedRadius;
        }
        this.parameters.largeRadius = largeR;
    }
    get fixedRadius() {
        return this.parameters.fixedRadius;
    }
    set fixedRadius(radius) {
        this.parameters.fixedRadius = radius || 1;
    }
    get widthSegments() {
        return this.parameters.widthSegments;
    }
    set widthSegments(width) {
        this.parameters.widthSegments = Math.max(1, Math.floor(width));
    }
    get heightSegments() {
        return this.parameters.heightSegments;
    }
    set heightSegments(height) {
        this.parameters.heightSegments = Math.max(1, Math.floor(height));
    }
    get azimuthStart() {
        return this.parameters.azimuthStart;
    }
    set azimuthStart(azimuthRads) {
        this.parameters.azimuthStart = azimuthRads !== undefined ? azimuthRads : 0;
    }
    get azimuthLength() {
        return this.parameters.azimuthLength;
    }
    set azimuthLength(length) {
        const azimuthLen = length !== undefined ? length : Math.PI * 2;
        this.parameters.azimuthLength = MathUtils.clamp(azimuthLen, 0, Math.PI * 2);
    }
    /*
     * The point at which the curve changes from using the
     * large radius, to the finishing smaller curve.
     */
    static calcIntersectPoint(alpha, largeRadius, fixedRadius) {
        return [Math.cos(alpha) * largeRadius - (largeRadius - fixedRadius), Math.sin(alpha) * largeRadius];
    }
    /*
     * The angle from the sphere origin to the point at which the curve
     * changes from using the large radius, to the finishing smaller curve.
     */
    static cutoffAltitudeAngle(intersect) {
        return Math.atan2(intersect[1], intersect[0]);
    }
    /*
     * Finds length 'a' given sides 'a', 'b' and non opposite 'angle'
     * Does this using the laws of cosines.
     */
    static calcThirdTriangleSide(c, b, angle) {
        return b * Math.cos(angle) + Math.sqrt(Math.pow(c, 2) - Math.pow(b, 2) * Math.pow(Math.sin(angle), 2));
    }
    /*
     * Radius of the capping top sphere
     */
    static smallRadius(intersectX, alpha) {
        return intersectX / Math.cos(alpha);
    }
    /*
     * Distance from origin to where the large radius cuts it.
     */
    static largeRadiusOriginCut(alpha, largeRadius, fixedRadius) {
        return Math.tan(alpha) * (largeRadius - fixedRadius);
    }
    static arcLength(angleInRads, radius) {
        return angleInRads * radius;
    }
    static angleFromArcLength(arcLength, radius) {
        return arcLength / radius;
    }
    // Length from top pole to bottom pole along the sphere surface
    static calcTopToBottomSurfaceLength(alpha, largeRadius, fixedRadius) {
        const midArcLength = 2 * SphereGeometry.arcLength(alpha, largeRadius);
        const intersect = SphereGeometry.calcIntersectPoint(alpha, largeRadius, fixedRadius);
        const smallRadius = SphereGeometry.smallRadius(intersect[0], alpha);
        const endingArcAngle = Math.PI / 2 - alpha;
        const endingArcLength = SphereGeometry.arcLength(endingArcAngle, smallRadius);
        return midArcLength + 2 * endingArcLength;
    }
    // Calculates the (x,y) point on sphere given a distance from the south pole.
    // length: sphere surface length from pole to point
    // fullSurfaceLength: sphere surface length from pole to pole
    static calcSpherePoint(length, fullSurfaceLength, alpha, largeRadius, fixedRadius) {
        // Sphere is symetrical so just do calc for top
        let posLength = fullSurfaceLength / 2;
        const inTopHalf = length >= posLength;
        if (inTopHalf) {
            posLength = length - posLength;
        }
        else {
            posLength -= length;
        }
        // Within mid large radius
        let spherePoint;
        const mainArcLength = SphereGeometry.arcLength(alpha, largeRadius);
        if (posLength <= mainArcLength) {
            spherePoint = SphereGeometry.calcSpherePointInMainArc(posLength, largeRadius, fixedRadius);
        }
        else {
            spherePoint = SphereGeometry.calcSpherePointInEndingArc(posLength, fullSurfaceLength, alpha, largeRadius, fixedRadius);
        }
        if (!inTopHalf) {
            spherePoint.point[1] *= -1;
        }
        spherePoint.normal.normalize();
        return spherePoint;
    }
    static calcSpherePointInMainArc(posLength, largeRadius, fixedRadius) {
        // Within mid large radius
        const angle = SphereGeometry.angleFromArcLength(posLength, largeRadius);
        const point = SphereGeometry.calcIntersectPoint(angle, largeRadius, fixedRadius);
        const normal = new Vector2(point[0] + largeRadius, point[1]);
        return { point, normal };
    }
    static calcSpherePointInEndingArc(posLength, fullSurfaceLength, alpha, largeRadius, fixedRadius) {
        // Within top ending arc
        const toTopLength = fullSurfaceLength / 2 - posLength;
        const adj = Math.cos(alpha) * largeRadius - (largeRadius - fixedRadius);
        const smallRadius = adj / Math.cos(alpha);
        const angle = SphereGeometry.angleFromArcLength(toTopLength, smallRadius);
        const x = Math.sin(angle) * smallRadius;
        const y = Math.cos(angle) * smallRadius;
        const yCut = SphereGeometry.largeRadiusOriginCut(alpha, largeRadius, fixedRadius);
        const point = [x, y + yCut];
        const normal = new Vector2(x, y);
        return { point, normal };
    }
    static distortionAdjustment(originalIntersect, ySurfaceDistance, fullSurfaceLength, alpha, largeRadius, fixedRadius) {
        const adjustment = originalIntersect.normal.angle();
        const radius = originalIntersect.point[0];
        const shortening = adjustment * (1.0 - radius / fixedRadius);
        let yDist = ySurfaceDistance + shortening;
        if (ySurfaceDistance >= fullSurfaceLength / 2) {
            const upperDistance = ySurfaceDistance - fullSurfaceLength / 2;
            yDist = upperDistance - shortening + fullSurfaceLength / 2;
        }
        return SphereGeometry.calcSpherePoint(yDist, fullSurfaceLength, alpha, largeRadius, fixedRadius);
    }
    static projectCoordinatesToSphere(coordinates2D, planogram) {
        const coordinates3D = new Array((coordinates2D.length / 2) * 3);
        const { height, width, largeRadius, fixedRadius } = planogram;
        const surfaceLength = SphereGeometry.calcTopToBottomSurfaceLength(Planogram.ALPHA, largeRadius, fixedRadius);
        const heightAdjustment = (surfaceLength - height) / 2;
        const rotationVector = new Vector3(0, 1, 0);
        const pointVector = new Vector3(0, 0, 0);
        let yDistance, rotationAngle;
        for (let i = 0; i < coordinates2D.length / 2; i++) {
            yDistance = coordinates2D[i * 2 + 1] + heightAdjustment;
            rotationAngle =
                SphereGeometry.calcAzimuthStartRadians(coordinates2D[i * 2], 0, width) - SphereGeometry.ROTATION_OFFSET;
            const intersect = SphereGeometry.calcSpherePoint(yDistance, surfaceLength, Planogram.ALPHA, largeRadius, fixedRadius);
            const adjustedIntersect = SphereGeometry.distortionAdjustment(intersect, yDistance, surfaceLength, Planogram.ALPHA, largeRadius, fixedRadius);
            pointVector.setX(adjustedIntersect.point[0]);
            pointVector.setY(adjustedIntersect.point[1]);
            pointVector.setZ(0);
            pointVector.applyAxisAngle(rotationVector, rotationAngle);
            coordinates3D[i * 3] = pointVector.x;
            coordinates3D[i * 3 + 1] = pointVector.y;
            coordinates3D[i * 3 + 2] = pointVector.z;
        }
        return coordinates3D;
    }
    generateVertices() {
        const grid = [];
        let index = 0;
        const surfaceLength = SphereGeometry.calcTopToBottomSurfaceLength(this.alpha, this.largeRadius, this.fixedRadius);
        const heightAdjustment = (surfaceLength - this.planogramHeight) / 2;
        const gridStep = this.itemHeight / this.heightSegments;
        let yDistance = this.itemY + heightAdjustment;
        const uvSegmentWidth = (this.endUV.x - this.startUV.x) / this.widthSegments;
        const uvSegmentHeight = (this.endUV.y - this.startUV.y) / this.heightSegments;
        for (let iy = 0; iy <= this.heightSegments; iy += 1) {
            const verticesRow = [];
            const v = this.startUV.y + iy * uvSegmentHeight;
            for (let ix = 0; ix <= this.widthSegments; ix += 1) {
                this.calcVertexDetails(ix, iy, uvSegmentWidth, uvSegmentHeight, v, yDistance, surfaceLength);
                verticesRow.push(index);
                index += 1;
            }
            grid.push(verticesRow);
            yDistance += gridStep;
        }
        this.calcIndices(grid);
    }
    calcVertexDetails(ix, iy, uvSegmentWidth, uvSegmentHeight, v, yDistance, surfaceLength) {
        const segmentSize = ix / this.widthSegments;
        const u = this.startUV.x + ix * uvSegmentWidth;
        const intersect = SphereGeometry.calcSpherePoint(yDistance, surfaceLength, this.alpha, this.largeRadius, this.fixedRadius);
        const adjustedIntersect = SphereGeometry.distortionAdjustment(intersect, yDistance, surfaceLength, this.alpha, this.largeRadius, this.fixedRadius);
        const angle = this.azimuthStart + segmentSize * this.azimuthLength;
        const vertex = this.rotate2Dinto3DVector(adjustedIntersect.point[0], adjustedIntersect.point[1], angle);
        const normalVertex = this.rotate2Dinto3DVector(intersect.normal.x, intersect.normal.y, angle);
        this.vertices.push(vertex.x, vertex.y, vertex.z);
        this.calcAndPushSphereNormal(normalVertex);
        this.uvs.push(u, 1 - v);
        if (ix === this.widthSegments && iy === this.heightSegments) {
            this.position = vertex.clone();
        }
    }
    calcAndPushSphereNormal(normal) {
        this.normals.push(normal.x, normal.y, normal.z);
    }
    calcIndices(grid) {
        for (let iy = 0; iy < this.heightSegments; iy += 1) {
            for (let ix = 0; ix < this.widthSegments; ix += 1) {
                const a = grid[iy][ix + 1];
                const b = grid[iy][ix];
                const c = grid[iy + 1][ix];
                const d = grid[iy + 1][ix + 1];
                this.indices.push(a, b, d);
                this.indices.push(b, c, d);
            }
        }
    }
    rotate2Dinto3DVector(x, y, rotationAngle) {
        const yaxis = new Vector3(0, 1, 0);
        const xyAsVec3 = new Vector3(x, y, 0);
        xyAsVec3.applyAxisAngle(yaxis, rotationAngle);
        return xyAsVec3;
    }
    buildGeometry(requiresUVs) {
        this.setIndex(this.indices);
        const buffer = new ArrayBuffer((this.vertices.length + this.uvs.length) * 4);
        const interleavedFloat32Buffer = new Float32Array(buffer);
        if (requiresUVs) {
            for (let bi = 0, vi = 0, uvi = 0; vi < this.vertices.length; bi += 5, vi += 3, uvi += 2) {
                interleavedFloat32Buffer[bi] = this.vertices[vi];
                interleavedFloat32Buffer[bi + 1] = this.vertices[vi + 1];
                interleavedFloat32Buffer[bi + 2] = this.vertices[vi + 2];
                interleavedFloat32Buffer[bi + 3] = this.uvs[uvi];
                interleavedFloat32Buffer[bi + 4] = this.uvs[uvi + 1];
            }
            const interleavedBuffer32 = new InterleavedBuffer(interleavedFloat32Buffer, 5);
            this.setAttribute('position', new InterleavedBufferAttribute(interleavedBuffer32, 3, 0, false));
            this.setAttribute('uv', new InterleavedBufferAttribute(interleavedBuffer32, 2, 3, true));
        }
        else {
            this.setAttribute('position', new Float32BufferAttribute(this.vertices, 3));
        }
    }
    static calcAzimuthStartRadians(x, width, planogramWidth) {
        return Math.PI * 2 - (width + x) * ((Math.PI * 2) / planogramWidth);
    }
    static calcAzimuthLengthRadians(width, planogramWidth) {
        return width * ((Math.PI * 2) / planogramWidth);
    }
}
