export const input = ({ type = 'text', placeholder, name, required = false }) => {
    return `
    <div class="account-overlay-input-wrapper">
      <input 
        name="${name}" 
        type="${type}" 
        placeholder="${placeholder}"
        ${required && 'required'}
        disabled
      />
    </div>
  `;
};
