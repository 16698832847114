import { shoppingCartItemComponentView } from './shopping-cart-item_component.view';
import { Metrics } from '../../metrics';
import { MATOMO_EVENT_NAMES } from '../../metric-events';
import { AppState } from '../../shared/app.state';
import { UrlUtils } from '../../api/urls';
import { ShoppingCartItemBaseComponent } from './shopping-cart-item_base_component';
export class IdealShoppingCartItemComponent extends ShoppingCartItemBaseComponent {
    constructor(shoppingCartItems, product, currencyService, shoppingCartService) {
        super(shoppingCartItems, product, currencyService, shoppingCartService);
        this.shoppingCartItems = shoppingCartItems;
        this.product = product;
        this.currencyService = currencyService;
        this.shoppingCartService = shoppingCartService;
    }
    renderItem() {
        this.mainContainer.classList.add('app-shopping-cart-item');
        if (this.product.quantity <= 0) {
            this.product.quantity = 0;
            this.mainContainer.classList.add('out-of-stock');
        }
        const shoppingCartItemContainer = document.createElement('div');
        this.mainContainer.append(shoppingCartItemContainer);
        this.productName = UrlUtils.slugify(this.product.title);
        shoppingCartItemContainer.outerHTML = shoppingCartItemComponentView(this.product, this.getProductPrice());
        this.updateDisableState();
        return this.mainContainer;
    }
    changeQuantity(el) {
        var _a, _b, _c;
        const operation = el.classList.contains('app-shopping-cart-item-increase') ? 'increase' : 'decrease';
        const currQuantity = this.product.quantity;
        switch (operation) {
            case 'increase': {
                if (this.overallQuantity + 1 > ((_a = this.product) === null || _a === void 0 ? void 0 : _a.quantity_available)) {
                    return;
                }
                this.product.quantity += 1;
                Metrics.storeTheEvent(AppState.planogramName, 'click', MATOMO_EVENT_NAMES.CLICK_SHOPPING_CART_OVERLAY_INCREASE_ITEM_QUANTITY((_b = this.product) === null || _b === void 0 ? void 0 : _b.sku, this.productName));
                this.shoppingCartItems.updateShoppingCart(this.product);
                break;
            }
            case 'decrease': {
                if (currQuantity - 1 >= 1) {
                    this.product.quantity -= 1;
                    Metrics.storeTheEvent(AppState.planogramName, 'click', MATOMO_EVENT_NAMES.CLICK_SHOPPING_CART_OVERLAY_DECREASE_ITEM_QUANTITY((_c = this.product) === null || _c === void 0 ? void 0 : _c.sku, this.productName));
                    this.shoppingCartItems.updateShoppingCart(this.product);
                }
                break;
            }
        }
        this.updateDisableState();
    }
    updateDisableState() {
        const increaseButton = this.mainContainer.querySelector('.app-shopping-cart-item-increase');
        const decreaseButton = this.mainContainer.querySelector('.app-shopping-cart-item-decrease');
        this.countOverallQuantity();
        if (increaseButton) {
            this.overallQuantity >= this.product.quantity_available
                ? increaseButton.classList.add('disabled')
                : increaseButton.classList.remove('disabled');
        }
        if (decreaseButton) {
            this.product.quantity <= 1
                ? decreaseButton.classList.add('disabled')
                : decreaseButton.classList.remove('disabled');
        }
    }
    deleteItem() {
        var _a;
        Metrics.storeTheEvent(AppState.planogramName, 'click', MATOMO_EVENT_NAMES.CLICK_SHOPPING_CART_OVERLAY_REMOVE_PRODUCT((_a = this.product) === null || _a === void 0 ? void 0 : _a.sku, this.productName));
        this.shoppingCartItems.removeItemFromCart(this.product);
    }
    countOverallQuantity() {
        var _a, _b;
        this.overallQuantity = ((_b = (_a = this.shoppingCartService.shoppingCart) === null || _a === void 0 ? void 0 : _a.products) === null || _b === void 0 ? void 0 : _b.reduce((acc, item) => {
            var _a;
            return item.sku === ((_a = this.product) === null || _a === void 0 ? void 0 : _a.sku) ? acc + item.quantity : acc;
        }, 0)) || 0;
    }
}
