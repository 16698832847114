import pipe from 'callbag-pipe';
import subscribe from 'callbag-subscribe';
import { L10nUtils } from './utils/l10n_utils';
import { sphereEventHandler } from './custom_event_utils';
import { SPHERE_EVENT_NAMES } from './event-names';
import { CookiesManagement } from './cookies_management';
function enter(element) {
    if (element.requestFullscreen) {
        element.requestFullscreen();
    }
    else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
    }
    else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
    }
    else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
    }
    CookiesManagement.allowFullscreen = true;
}
function exit(doc) {
    if (doc.exitFullscreen && doc.fullscreenElement) {
        doc.exitFullscreen();
    }
    else if (doc.webkitExitFullscreen && doc.webkitFullscreenElement) {
        doc.webkitExitFullscreen();
    }
    else if (doc.mozCancelFullScreen && doc.mozFullscreenElementn) {
        doc.mozCancelFullScreen();
    }
    else if (doc.msExitFullscreen && doc.msFullscreenElement) {
        doc.msExitFullscreen();
    }
    CookiesManagement.allowFullscreen = false;
}
export class Fullscreen {
    constructor(clickElement, elementToFullscreen, doc, mainElement, resizeCallback) {
        this.clickElement = clickElement;
        this.elementToFullscreen = elementToFullscreen;
        this.doc = doc;
        this.resizeCallback = resizeCallback;
        this.enterRef = () => enter(elementToFullscreen);
        this.exitRef = () => exit(doc);
        if (clickElement && this.fullscreenEnabled()) {
            this.setUpButton();
            this.clickElement.addEventListener('click', e => this.toggle(e), false);
            this.clickElement.addEventListener('touchend', e => this.toggle(e), false);
            this.clickElement.addEventListener('wheel', e => mainElement.dispatchEvent(new WheelEvent(e.type, e)), {
                passive: false
            });
            this.addFullscreenChangeListeners();
        }
        sphereEventHandler.listen(SPHERE_EVENT_NAMES.COOKIES.ALLOW_FULLSCREEN, this.enterRef);
        sphereEventHandler.listen(SPHERE_EVENT_NAMES.COOKIES.DISABLE_FULLSCREEN, this.exitRef);
        pipe(L10nUtils.languageLoadedSubject, subscribe({
            next: (langCode) => {
                if (!langCode) {
                    return;
                }
                this.handleLanguageChanged();
            }
        }));
    }
    addFullscreenChangeListeners() {
        const events = ['fullscreenchange', 'webkitfullscreenchange', 'mozfullscreenchange', 'MSFullscreenChange'];
        for (const event of events) {
            this.doc.addEventListener(event, e => this.toggleButton(e), true);
        }
    }
    toggle(event) {
        event === null || event === void 0 ? void 0 : event.preventDefault();
        event === null || event === void 0 ? void 0 : event.stopPropagation();
        if (this.isFullscreen()) {
            exit(this.doc);
        }
        else {
            enter(this.elementToFullscreen);
        }
    }
    fullscreenEnabled() {
        return (this.doc.fullscreenEnabled === true ||
            this.doc['webkitFullscreenEnabled'] === true ||
            this.doc['mozFullScreenEnabled'] === true ||
            this.doc['msFullscreenEnabled'] === true);
    }
    isFullscreen() {
        return !(this.doc.fullscreenElement == null &&
            this.doc['webkitFullscreenElement'] == null &&
            this.doc['mozFullScreenElement'] == null &&
            this.doc['msFullscreenElement'] == null);
    }
    setUpButton() {
        const fullscreenOnImage = document.getElementById('fullscreen-on-button');
        fullscreenOnImage.classList.remove('is-hidden');
    }
    toggleButton(event) {
        event.preventDefault();
        event.stopPropagation();
        const fullscreenOnImage = document.getElementById('fullscreen-on-button');
        const fullscreenOffImage = document.getElementById('fullscreen-off-button');
        if (this.isFullscreen()) {
            fullscreenOffImage.classList.remove('is-hidden');
            fullscreenOnImage.classList.add('is-hidden');
        }
        else {
            fullscreenOnImage.classList.remove('is-hidden');
            fullscreenOffImage.classList.add('is-hidden');
        }
        if (this.resizeCallback) {
            this.resizeCallback();
        }
    }
    handleLanguageChanged() {
        const fullscreenOnImage = document.getElementById('fullscreen-on-button').querySelector('img');
        const fullscreenOffImage = document.getElementById('fullscreen-off-button').querySelector('img');
        if (fullscreenOnImage) {
            fullscreenOnImage.setAttribute('alt', L10nUtils.l10n('fullscreen.on-button.alt-text'));
        }
        if (fullscreenOffImage) {
            fullscreenOffImage.setAttribute('alt', L10nUtils.l10n('fullscreen.off-button.alt-text'));
        }
    }
}
