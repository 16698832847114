export const SPHERE_EVENT_NAMES = {
    CAMERA: {
        ZOOM_BY: 'camera-zoom_by'
    },
    OVERLAY: {
        SHOW_CONTENT: 'overlay-show_content',
        CLOSE_CONTENT: 'overlay-close_content',
        SHOW_INFO_OVERLAY_CONTENT: 'overlay-show_info_overlay_content',
        OVERLAY_USER_ACTION: 'overlay-user-action',
        OVERLAY_USER_SCROLL: 'overlay-user-scroll',
        INTERACTION: 'overlay-interaction',
        FULLSCREEN: 'overlay-fullscreen',
        JOURNEY_OVERLAY_CLOSE: 'journey-overlay-close'
    },
    CONTROL: {
        CLICK: 'control-click',
        TAP: 'control-click',
        CLICK_AND_TAP: 'control-click control-click',
        CLICK_ITEM: 'control-click-item'
    },
    SPHERE: {
        RESET: 'sphere-reset',
        INIT: 'sphere-init',
        UPDATE: 'sphere-update',
        BLUR_CANVAS: 'sphere-blur_canvas',
        UNBLUR_CANVAS: 'sphere-focus_canvas',
        SET_CURSOR: 'sphere-set_cursor'
    },
    DEBUG: {
        CAMERA_POSITION: 'debug-camera_position'
    },
    VIDEO: {
        PLAY: 'play',
        PLAY_WITH_AUDIO: 'play-with-audio',
        STOP_WITH_AUDIO: 'stop',
        SHARE: 'video-share'
    },
    TILT_AND_PAN_TO: 'tilt_and_pan_to',
    ON_MOVEMENT_START: 'on_movement_start',
    ANIMATE_ZOOM_FOV: 'animate_zoom_fov',
    AUTOROTATE: 'autorotate',
    PLANOGRAM_CHANGED: 'planogram-changed',
    ACCOUNT: {
        OPEN: 'account-open',
        CLOSE: 'account-close'
    },
    CART: {
        OPEN: 'cart-open',
        CLOSE: 'cart-close'
    },
    COOKIES: {
        ALLOW_AUDIO: 'allow-audio',
        DISABLE_AUDIO: 'disable-audio',
        ALLOW_ZOOM_CONTROLS: 'allow-zoom-controls',
        ALLOW_FULLSCREEN: 'allow-fullscreen',
        DISABLE_FULLSCREEN: 'disable-fullscreen'
    },
    ENTRANCE_ANIMATION: {
        ZOOM_ANIMATION: 'zoom_animation',
        DRAG_ANIMATION: 'drag_animation'
    },
    AUDIO: {
        PLAY_ACTION: 'audio-play-action'
    },
    HEATMAP: {
        CLICK: 'heatmap-click',
        MOVE: 'heatmap-move',
        ZOOM: 'heatmap-zoom'
    }
};
export const SEARCH_EVENT_NAMES = {
    UPDATE_SEARCH: 'updateSearch',
    SHOW_SEARCH: 'showSearch',
    CLOSE_SEARCH: 'closeSearch',
    REQUEST_STATUS: 'requestStatus',
    SHOW_SPHERE_ELEMENTS: 'showSphereElements'
};
