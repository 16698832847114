import { isAppleDevice } from '../utils/platform';
import { searchEventHandler } from '../custom_event_utils';
import { throttle, debounce } from 'lodash';
import { UrlUtils } from '../api/urls';
import { Search } from './search';
import { FontLoader } from '../font_loader';
import { SEARCH_EVENT_NAMES as SEARCH_EVENTS } from '../event-names';
import { Metrics } from '../metrics';
import { MATOMO_EVENT_NAMES } from '../metric-events';
export class SearchInput {
    constructor(searchService) {
        this.searchService = searchService;
        this.isSearchInputDisabled = false;
        this.initInput();
        this.registerListeners();
    }
    static get ANIMATION_TIME() {
        return 300;
    }
    static get THROTTLE_DELAY() {
        return 200;
    }
    static get METRIC_EVENT_DELAY() {
        return 2000;
    }
    initInput() {
        this.searchWrapper = document.querySelector('.search-wrapper');
        this.inputEl = document.getElementById('search-input');
        this.searchButton = document.getElementById('search-button');
        this.applySearchSettings();
        if (!this.searchButton) {
            console.error('element with id - "search-button" not found');
            return;
        }
        if (!this.inputEl) {
            console.error('element with id - "search-input" not found');
            return;
        }
    }
    updateSearchSettings(settings) {
        this.searchSettings = settings;
        this.initInput();
    }
    toggle() {
        this.searchWrapper.classList.toggle('is-search-active');
        const isSearchActive = this.searchWrapper.classList.contains('is-search-active');
        if (isSearchActive) {
            this.inputFocus();
            Metrics.storeTheEvent(this.searchService.planogramName, 'click', MATOMO_EVENT_NAMES.CLICK_SEARCH_BUTTON_DEFAULT);
        }
        else {
            Metrics.storeTheEvent(this.searchService.planogramName, 'click', MATOMO_EVENT_NAMES.CLICK_SEARCH_BUTTON_ACTIVE);
        }
        searchEventHandler.emit(SEARCH_EVENTS.SHOW_SEARCH, { isSearchActive });
    }
    closeInput() {
        this.searchWrapper.classList.remove('is-search-active');
        this.inputEl.blur();
        this.searchedQuery = '';
    }
    showInput() {
        this.searchWrapper.classList.add('is-search-active');
        this.inputFocus();
        this.searchedQuery = SearchInput.getSearchedPhraseFromUrl();
        if (this.searchedQuery && this.searchSettings) {
            this.inputEl.value = this.searchedQuery;
            if (this.searchedQuery.length >= Search.SEARCH_QUERY_MINIMUM_LENGTH) {
                this.requestResults(this.searchedQuery);
            }
            this.setBorderState(this.searchedQuery);
        }
    }
    disableSearchInput() {
        this.isSearchInputDisabled = true;
    }
    registerListeners() {
        this.inputEl.addEventListener('input', throttle(e => {
            this.handleSearch(e);
        }, SearchInput.THROTTLE_DELAY));
        this.inputEl.addEventListener('input', debounce(e => {
            const query = e.target.value;
            if (query.length >= Search.SEARCH_QUERY_MINIMUM_LENGTH) {
                Metrics.storeTheEvent(this.searchService.planogramName, 'input', MATOMO_EVENT_NAMES.INPUT_SEARCH_QUERY(UrlUtils.slugify(query)));
            }
        }, SearchInput.METRIC_EVENT_DELAY));
        this.inputEl.addEventListener('blur', e => {
            requestAnimationFrame(() => {
                window.scroll(0, 0);
            });
        });
        this.inputEl.addEventListener('touchstart', () => this.inputEl.focus());
        this.searchButton.addEventListener('click', this.toggle.bind(this));
    }
    requestResults(query) {
        searchEventHandler.emit(SEARCH_EVENTS.REQUEST_STATUS, { state: true });
        this.searchService.filterResults(query).then(results => {
            searchEventHandler.emit(SEARCH_EVENTS.REQUEST_STATUS, { state: false });
            this.searchService.updateSearchFilters(results);
        });
    }
    applySearchSettings() {
        var _a, _b, _c, _d;
        if (!this.searchSettings) {
            return;
        }
        const iconWrapper = this.searchButton.querySelector('.search-button-icon-wrapper');
        const root = document.documentElement;
        const activeSettings = (_a = this.searchSettings) === null || _a === void 0 ? void 0 : _a.search_setting.search_active_icon;
        if (activeSettings) {
            const img = document.createElement('img');
            img.setAttribute('src', activeSettings.url);
            img.classList.add('search-active-icon');
            iconWrapper.appendChild(img);
        }
        root.style.setProperty('--search-button-active-color', activeSettings.button_color);
        root.style.setProperty('--search-input-color', activeSettings.input_color);
        this.inputEl.setAttribute('placeholder', (_b = this.searchSettings) === null || _b === void 0 ? void 0 : _b.search_setting.search_title);
        if ((_c = this.searchSettings.search_setting) === null || _c === void 0 ? void 0 : _c.search_setting_font) {
            FontLoader.mountCustomFonts([(_d = this.searchSettings.search_setting) === null || _d === void 0 ? void 0 : _d.search_setting_font]);
        }
    }
    parseSettings(stateSettings) {
        const obj = {};
        stateSettings === null || stateSettings === void 0 ? void 0 : stateSettings.forEach(iconSettings => {
            if (iconSettings === null || iconSettings === void 0 ? void 0 : iconSettings.input_color) {
                obj[iconSettings.identifier + 'InputColor'] = iconSettings.input_color;
            }
            obj[iconSettings.identifier + 'ButtonColor'] = iconSettings === null || iconSettings === void 0 ? void 0 : iconSettings.button_color;
            obj[iconSettings.identifier + 'IconUrl'] = iconSettings === null || iconSettings === void 0 ? void 0 : iconSettings.url;
        });
        return obj;
    }
    inputFocus() {
        // workaround for ios devices
        // on ios first focus event doesn't display keyboard
        let tempInputEl;
        if (isAppleDevice) {
            // Align temp input element approx. to be where the input element is
            tempInputEl = document.createElement('input');
            tempInputEl.setAttribute('readonly', 'true');
            tempInputEl.style.position = 'absolute';
            tempInputEl.style.top = this.inputEl.offsetTop + 'px';
            tempInputEl.style.left = this.inputEl.offsetLeft + 'px';
            tempInputEl.style.height = '0';
            tempInputEl.style.opacity = '0';
            // Put this temp element as a child of the page <body> and focus on it
            document.body.appendChild(tempInputEl);
            tempInputEl.focus();
        }
        // The keyboard is open. Now do a delayed focus on the target element
        setTimeout(() => {
            this.inputEl.focus();
            // Remove the temp element
            if (tempInputEl) {
                document.body.removeChild(tempInputEl);
            }
        }, SearchInput.ANIMATION_TIME);
    }
    handleSearch(e) {
        if (this.isSearchInputDisabled) {
            return;
        }
        const searchQuery = e.target.value;
        this.searchService.updateSearchQuery(this.searchService.planogramName, searchQuery);
        this.setBorderState(searchQuery);
        if (searchQuery.length < Search.SEARCH_QUERY_MINIMUM_LENGTH) {
            searchEventHandler.emit(SEARCH_EVENTS.UPDATE_SEARCH);
            return;
        }
        this.requestResults(searchQuery);
    }
    static getSearchedPhraseFromUrl() {
        try {
            const searchParams = new URLSearchParams(window.location.search);
            const query = searchParams.get('query');
            return query && decodeURIComponent(query);
        }
        catch (e) {
            console.error("Can't encode searched phrase");
        }
        return '';
    }
    setBorderState(searchQuery) {
        if (searchQuery.length) {
            this.searchWrapper.classList.add('show-border');
        }
        else {
            this.searchWrapper.classList.remove('show-border');
        }
    }
}
