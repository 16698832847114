export class HTMLUtils {
    static setScale(htmlElement, percentage = 100) {
        if (!htmlElement) {
            console.warn('Argument is not HTMLElement.');
            return;
        }
        const parsedValue = parseInt(percentage.toString(), 10) / 100;
        const root = document.documentElement;
        root.style.setProperty(`--control-button-scale`, parsedValue.toString());
        htmlElement.setAttribute('style', `transform: scale(${parsedValue})`);
    }
    static removeChildElements(container) {
        while (container.lastChild) {
            container.removeChild(container.lastChild);
        }
    }
}
export function appendHtml(element, html) {
    const template = document.createElement('div');
    template.innerHTML = html.trim();
    for (let i = 0; i < template.childNodes.length; i++) {
        element.appendChild(template.childNodes[i]);
    }
}
