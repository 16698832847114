import { URLS } from '../../api/urls';
import { L10nUtils } from '../../utils/l10n_utils';
export const productInfoUalView = (product, planogramName, isSharingEnabled) => {
    const checkMarkSrc = URLS.OVERLAY_CHECK_MARK;
    const twitterShareLinkSrc = URLS.OVERLAY_TWITTER_ICON;
    const whatsappShareLinkSrc = URLS.OVERLAY_WHATSAPP_ICON;
    const telegramShareLinkSrc = URLS.OVERLAY_TELEGRAM_ICON;
    const viberShareLinkSrc = URLS.OVERLAY_VIBER_ICON;
    const shareLinkSrc = URLS.OVERLAY_SHARE_LINK_ICON;
    const ualLogo = URLS.UAL_LOGO;
    const instagrammIcon = `<svg x="0px" y = "0px" viewBox = "0 0 24 24" >
      <path d = "M12,2.16c3.2,0,3.58,0,4.85.07,3.25.15,4.77,1.69,4.92,4.92.06,1.27.07,1.65.07,4.85s0,3.59-.07,4.85c-.15,3.23-1.66,4.77-4.92,4.92-1.27.06-1.64.07-4.85.07s-3.58,0-4.85-.07c-3.26-.15-4.77-1.7-4.92-4.92-.06-1.27-.07-1.64-.07-4.85s0-3.58.07-4.85C2.38,3.92,3.9,2.38,7.15,2.23,8.42,2.18,8.8,2.16,12,2.16ZM12,0C8.74,0,8.33,0,7.05.07c-4.35.2-6.78,2.62-7,7C0,8.33,0,8.74,0,12S0,15.67.07,17c.2,4.36,2.62,6.78,7,7C8.33,24,8.74,24,12,24s3.67,0,4.95-.07c4.35-.2,6.78-2.62,7-7C24,15.67,24,15.26,24,12s0-3.67-.07-4.95c-.2-4.35-2.62-6.78-7-7C15.67,0,15.26,0,12,0Zm0,5.84A6.16,6.16,0,1,0,18.16,12,6.16,6.16,0,0,0,12,5.84ZM12,16a4,4,0,1,1,4-4A4,4,0,0,1,12,16ZM18.41,4.15a1.44,1.44,0,1,0,1.43,1.44A1.44,1.44,0,0,0,18.41,4.15Z" > </path>
    </svg >`;
    const linkedinIcon = `<svg x="0px" y="0px" viewBox="0 0 24 24">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M19 0H5C2.239 0 0 2.239 0 5V19C0 21.761 2.239 24 5 24H19C21.762 24 24 21.761 24 19V5C24 2.239 21.762 0 19 0ZM8 19H5V8H8V19ZM6.5 6.732C5.534 6.732 4.75 5.942 4.75 4.968C4.75 3.994 5.534 3.204 6.5 3.204C7.466 3.204 8.25 3.994 8.25 4.968C8.25 5.942 7.467 6.732 6.5 6.732ZM20 19H17V13.396C17 10.028 13 10.283 13 13.396V19H10V8H13V9.765C14.396 7.179 20 6.988 20 12.241V19Z"></path>
    </svg>`;
    const fileIcon = `<svg x="0px" y = "0px" viewBox="0 0 23 28">
      <g fill="none" fill-rule="evenodd">
          <g fill="#100f0d">
              <path d="M17.169 6.254h5.15c-.097-.16-5.758-5.821-5.95-5.949v5.118c0 .48.352.831.8.831M10.552 10.433c-.03-.06-.097-.137-.25-.137-.156 0-.202.116-.218.152-.15.335.021 1.295.438 2.278.269-1.25.159-2.045.03-2.293" transform="translate(-849 -393) translate(849 393)"/>
              <path d="M17.704 18.88l-.002.002c-.438.436-1.031.686-1.629.686-.832 0-1.825-.457-2.95-1.36-.548.113-1.152.275-1.848.462l-.12.032c-.57.155-1.08.336-1.53.495l-.151.054c-1.18 2.11-2.26 3.296-3.21 3.526-.123.031-.25.063-.385.063-.34 0-.627-.106-.878-.325-.42-.355-.608-.849-.513-1.354.2-1.028 1.555-2.05 4.024-3.041.228-.431.433-.898.631-1.35.381-.949.658-1.71.848-2.327-.521-.974-1.608-3.273-1.024-4.549.264-.56.756-.868 1.383-.868.577 0 1.032.233 1.317.676.541.78.483 2.285-.182 4.595.305.514.619.956.885 1.249.452.482.836.858 1.173 1.146 2.446-.42 4.004-.215 4.503.595l.023.058c.074.208.3.842-.365 1.535zm-.17-10.94c-1.574 0-2.854-1.277-2.854-2.847V0H2.54C1.851 0 1.228.256.742.74.257 1.226 0 1.847 0 2.536v22.93C0 26.863 1.14 28 2.541 28h17.556c1.401 0 2.541-1.137 2.541-2.535V7.94h-5.104z" transform="translate(-849 -393) translate(849 393)"/>
              <path d="M11.278 16.522c-.114-.114-.24-.271-.382-.454l-.1.275c-.118.32-.24.651-.373.995l-.05.121c.133-.04.254-.075.377-.11.332-.088.697-.186 1.03-.282-.156-.155-.304-.322-.468-.506l-.034-.039zM5.697 21.454c.014.15.12.158.208.15.296-.032 1.013-.716 1.609-1.61-1.192.596-1.837 1.248-1.817 1.46M14.752 17.82c.507.298.953.477 1.34.477.329.03.612-.024.715-.298.1-.263-.625-.328-2.055-.179" transform="translate(-849 -393) translate(849 393)"/>
          </g>
      </g>
    </svg>`;
    const shareIcon = `<svg class="share-icon" x="0px" y = "0px" viewBox="0 0 28 28">
      <g fill="none" fill-rule="evenodd">
        <path stroke="#100f0d" d="M0.5 0.5H27.5V27.5H0.5z" transform="translate(-912 -393) translate(912 393)"/>
        <path fill="#100f0d" fill-rule="nonzero" d="M16.225 5.833v2.235c-.164.042-.328.086-.49.134-.91.266-1.786.62-2.611 1.053-.825.432-1.598.945-2.308 1.52-.711.576-1.356 1.216-1.928 1.907-.573.69-1.071 1.434-1.49 2.208-.418.777-.754 1.586-1.007 2.415-.25.824-.418 1.67-.498 2.514-.076.78-.078 1.564-.012 2.33.002.022.036.024.038 0 .09-.762.246-1.511.468-2.234.24-.779.555-1.527.937-2.23.383-.703.831-1.36 1.333-1.958.503-.6 1.062-1.145 1.658-1.623.599-.48 1.24-.895 1.904-1.24.667-.344 1.36-.62 2.064-.82.64-.185 1.291-.307 1.942-.369v2.23l6.447-4.033-6.447-4.039z" transform="translate(-912 -393) translate(912 393)"/>
      </g>
    </svg>`;
    return `
    <div id="product-details-container" class="app-ual-template is-view-gallery">
        <div class="app-product-overlay ${planogramName}-overlay">
            <div class="app-gallery-gradient"></div>
            <div class="app-product-overlay-controls">
              <div class="overlay-button overlay-button-back overlay-back" role="button" tabindex="0">
                <span></span>
              </div>
              <div class="overlay-button overlay-button-close" role="button" tabindex="0">
                <span></span>
              </div>
            </div>
            <div class="app-product-content-wrapper">
              <div class="app-product-carousel">
                <div id="app-image-carousel" class="splide">
                  <div class="splide__arrows">
                    <button class="splide__arrow splide__arrow--prev">
                      <span class="overlay-arrow overlay-arrow-prev"></span>
                    </button>
                    <button class="splide__arrow splide__arrow--next">
                      <span class="overlay-arrow overlay-arrow-next"></span>
                    </button>
                  </div>
                  <div class="splide__track">
                    <ul class="splide__list">
                    </ul>
                  </div>
                </div>
                <div class="app-thumbnail-carousel-container">
                   <div class="app-product-gallery-buttons-container">
                    <div id="info-gallery-button" role="button" tabindex="0">
                      <div>
                        <img alt="Info icon">
                      </div>
                    </div>
                    <div id="checkout-gallery-button" class="is-completely-hidden" role="button" tabindex="0">
                      <div>
                        <img alt="Checkout icon">
                      </div>
                    </div>
                  </div>
                  <div id="app-thumbnail-carousel" class="splide">
                    <div id="app-thumbnail-splide-arrows" class="splide__arrows">
                      <button class="splide__arrow splide__arrow--prev">
                        <span class="overlay-arrow overlay-arrow-prev"></span>
                      </button>
                      <button class="splide__arrow splide__arrow--next">
                        <span class="overlay-arrow overlay-arrow-next"></span>
                      </button>
                    </div>
                    <div class="splide__track">
                      <ul class="splide__list">
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="app-product-details-header">
                ${product.course_title ? `<div class="app-product-brand-name">${product.course_title}</div>` : ''}
              </div>
              <div class="app-product-details-container ${product.course_title ? '' : 'no-brand'}">
                <div class="app-product-scrollable-container allow-scroll">
                  <div class="app-product-title">${product.name}</div>
                  ${renderLinks(product)}
                  <div class="app-product-social-links">
                    ${product.graduate_instagram_link ?
        `<div class="app-product-social">
                      <a class="social-link" href=${product.graduate_instagram_link} target="_blank" aria-label="Instagram Icon">${instagrammIcon}</a>
                    </div>` :
        ''}
                    ${product.graduate_linkedin_link ?
        `<div class="app-product-social">
                      <a class="social-link" href=${product.graduate_linkedin_link} target="_blank" aria-label="UAL on Linkedin">${linkedinIcon}</a>
                    </div>` :
        ''}
                    ${product.graduate_portfolio_pdf ?
        `<div class="app-product-social">
                      <a class="social-link" href=${product.graduate_portfolio_pdf} target="_blank" aria-label="File icon">${fileIcon}</a>
                    </div>` :
        ''}
                    ${isSharingEnabled ?
        `<div class="app-product-social">
                      <div id="share-mobile" class="social-link" target="_blank" aria-label="File icon">
                        ${shareIcon}
                      </div>
                    </div>` :
        ''}
                  </div>
                </div>
                <div class="app-product-description">
                  <div id="product-description-button" class="overlay-btn app-product-description-title">
                    <div class="text">
                      ${L10nUtils.l10n('product-details-overlay.ual.information-button.text')}
                      <div class="overlay-button overlay-button-close app-product-description-icon-close" role="button" tabindex="0">
                        <span></span>
                      </div>
                    </div>
                  </div>
                  <div class="app-product-description-text-wrapper">
                    <div class="app-product-description-text allow-scroll">
                      ${product.description}
                    </div>
                    <div class="text-fade"></div>
                  </div>
                </div>
              </div>
              <div class="ual-logo">
                <img src=${ualLogo} alt="ual" />
              </div>
            </div>
          </div>
          ${isSharingEnabled ?
        `<div id="share-mobile-popup" class="is-hidden">
            <div class="share-success-message">
              <img class="check-mark" src="${checkMarkSrc}" alt="checkmark icon">
              <span>${L10nUtils.l10n('product-details-overlay.share.notification')}</span>
            </div>
            <div class="app-popup ${planogramName}-popup">
              <div class="share-button-imgs-wrapper app-product-social-links">
                <img src="${whatsappShareLinkSrc}" class="app-product-social share-button-whatsapp share-button is-hidden" alt="whatsapp icon" role="button" tabindex="0">
                <img src="${telegramShareLinkSrc}" class="app-product-social share-button-telegram share-button is-hidden" alt="telegram icon" role="button" tabindex="0">
                <img src="${viberShareLinkSrc}" class="app-product-social share-button-viber share-button is-hidden" alt="vider icon" role="button" tabindex="0">
                <img src="${twitterShareLinkSrc}" class="app-product-social share-button-twitter share-button is-hidden" alt="twitter icon" role="button" tabindex="0">
                <img src="${shareLinkSrc}" class="app-product-social share-button-copy_link share-button is-hidden" alt="share icon" role="button" tabindex="0">
              </div>
            </div>
            <div class="app-popup-backdrop"></div>
          </div>` :
        ''}
        </div>
    </div>
  `;
};
function renderLinks(product) {
    if (product.graduate_profile_link || product.graduate_website_link || product.email_link) {
        return `<div class="app-product-sub-header-wrapper">
      ${product.graduate_profile_link ?
            `<div class="app-product-sub-header">${L10nUtils.l10n('product-details-overlay.ual.subheader1')}
          <a class="app-product-site-link" href=${product.graduate_profile_link} target="_blank">
            ${L10nUtils.l10n('product-details-overlay.ual.profile')}
          </a>
        </div>` :
            ``}
      ${product.graduate_website_link ?
            `<div class="app-product-sub-header">${L10nUtils.l10n('product-details-overlay.ual.subheader1')}
          <a class="app-product-site-link" href=${product.graduate_website_link} target="_blank">
            ${L10nUtils.l10n('product-details-overlay.ual.website')}
          </a>
        </div>` :
            ``}
      ${product.email_link ?
            `<div class="app-product-sub-header">${L10nUtils.l10n('product-details-overlay.ual.subheader2')}
          <a class="app-product-site-link" href="mailto:${product.email_link}" target="_blank">
            ${L10nUtils.l10n('product-details-overlay.ual.media')}
          </a>
        </div>` :
            ``}
      </div>`;
    }
    else {
        return '';
    }
}
