import { CLIENT_NAMES } from '../shared/constants';
import { SocialFeedSource } from 'shared/interfaces/planogram';
const CLUSTER_NAMING_REGEX = /^(?:cluster-)?(\w+)(?:-caption)?$/;
export class AppUtils {
    // TODO HP24 temporary solution for HP sphere (detection for Pins Sphere)
    static isWBClient(name) {
        return name === CLIENT_NAMES.WB;
    }
    static isSassenbachClient(name, planogramName) {
        // TODO remove when setting to control mobile popup orientation appearing will be added
        const isListedSphere = [
            'sixt_bmw_i4_sylt',
            'sixt_bmw_i4_sylt_inside',
            'sixt_bmw_sylt_ix1',
            'sixt_bmw_sylt_ix1_inside'
        ].includes(planogramName);
        return name === CLIENT_NAMES.SASSENBACH && isListedSphere;
    }
    static isHarryPotterClient(planogram, name) {
        const isHarryPotter = [
            CLIENT_NAMES.HARRY_POTTER,
            CLIENT_NAMES.HARRY_POTTER_UK,
            CLIENT_NAMES.HARRY_POTTER_US
        ].includes(name);
        return AppUtils.isShopifyEcommercePlatform(planogram) && isHarryPotter;
    }
    static isShopifyEcommercePlatform(planogram) {
        return planogram.ecommerceEnabled && planogram.ecommercePlatformName === 'Shopify';
    }
    static isIdealEcommercePlatform(planogram) {
        return planogram.ecommerceEnabled && planogram.ecommercePlatformName === 'Ideal360';
    }
    // TODO Selfridges temporary solution
    static isSelfridgesClient(name) {
        return name === CLIENT_NAMES.SELFRIDGES;
    }
    // TODO Sothebys temporary solution
    static isSothebysClient(name) {
        return name === CLIENT_NAMES.SOTHEBYS;
    }
    // TODO AjTracey temporary solution
    static isAjTraceyClient(name) {
        return name === CLIENT_NAMES.AJTRACEY;
    }
    // TODO Lego temporary solution
    static isLegoClient(name) {
        return name === CLIENT_NAMES.LEGO;
    }
    // TODO UAL temporary solution
    static isUALClient(name) {
        return name === CLIENT_NAMES.UAL;
    }
    // TODO Ninjago temporary solution
    static isNinjagoClient(name) {
        return name === CLIENT_NAMES.NINJAGO;
    }
    // TODO Khalili temporary solution
    static isKhaliliClient(name) {
        return name === CLIENT_NAMES.KHALILI;
    }
    // Deep clone of object implementation. Reference is here: https://www.30secondsofcode.org/js/s/deep-clone
    static deepClone(obj) {
        if (obj === null) {
            return null;
        }
        const clone = Object.assign({}, obj);
        Object.keys(clone).forEach(key => (clone[key] = typeof obj[key] === 'object' ? AppUtils.deepClone(obj[key]) : obj[key]));
        if (Array.isArray(obj)) {
            clone.length = obj.length;
            return Array.from(clone);
        }
        return clone;
    }
    static getPlanogramName(planogramName) {
        return planogramName.replace(/-v[\d]+$/g, '');
    }
    static extractClusterFullName(clusterCaption) {
        const clusterNameSliceIndex = clusterCaption.indexOf('-caption');
        return clusterCaption.slice(0, clusterNameSliceIndex + 1);
    }
    static getPriceValueFromStr(priceStr) {
        try {
            return parseFloat(priceStr.replace(/[^0-9.]/, ''));
        }
        catch (err) {
            console.error('price can not be converted to number');
        }
    }
    // #fff #ffffff
    static hex2rgb(hex) {
        const color = /^#?([a-f\d]{1,2})([a-f\d]{1,2})([a-f\d]{1,2})$/i.exec(hex);
        const hexLength = hex.length;
        const r = hexLength > 4 ? parseInt(color[1], 16) : parseInt(color[1] + color[1], 16);
        const g = hexLength > 4 ? parseInt(color[2], 16) : parseInt(color[2] + color[2], 16);
        const b = hexLength > 4 ? parseInt(color[3], 16) : parseInt(color[3] + color[3], 16);
        return color ? `${r}, ${g}, ${b}` : '';
    }
    static hex2rgbArray(hex) {
        const color = /^#?([a-f\d]{1,2})([a-f\d]{1,2})([a-f\d]{1,2})$/i.exec(hex);
        const hexLength = hex.length;
        const r = hexLength > 4 ? parseInt(color[1], 16) : parseInt(color[1] + color[1], 16);
        const g = hexLength > 4 ? parseInt(color[2], 16) : parseInt(color[2] + color[2], 16);
        const b = hexLength > 4 ? parseInt(color[3], 16) : parseInt(color[3] + color[3], 16);
        return [r, g, b];
    }
    static requestTimeoutRaf(fn, delay, registerCancel) {
        const start = new Date().getTime();
        let raf;
        const loop = () => {
            const delta = new Date().getTime() - start;
            if (delta >= delay) {
                fn();
                registerCancel(null);
                return;
            }
            raf = requestAnimationFrame(loop);
            registerCancel(() => cancelAnimationFrame(raf));
        };
        raf = requestAnimationFrame(loop);
        registerCancel(() => cancelAnimationFrame(raf));
    }
    static extractClusterName(clusterFullName) {
        var _a;
        if (!clusterFullName) {
            return '';
        }
        const clusterName = clusterFullName.match(CLUSTER_NAMING_REGEX);
        return (_a = clusterName[1]) !== null && _a !== void 0 ? _a : '';
    }
    static ArrToObj(arr) {
        return arr.reduce((map, obj) => {
            map[obj.identifier] = obj.name;
            return map;
        }, {});
    }
    static isProfileAndCartIconsEnabled(buttons) {
        return buttons.reduce((obj, button) => {
            obj[button.button_type] = button.enabled;
            return obj;
        }, {});
    }
    static generateSocialContentLink(data) {
        switch (true) {
            case data.source === SocialFeedSource.Instagram:
                return `https://www.instagram.com/${data.contentValue}/embed`;
            case data.source === SocialFeedSource.TikTok:
                return `https://www.tiktok.com/embed/${data.contentValue}`;
            case data.source === SocialFeedSource.Twitch:
                const embedType = data.contentType === 'VODs' ? 'video' : 'channel';
                if (data.contentType === 'Clips') {
                    return `https://clips.twitch.tv/embed?clip=${data.contentValue}&parent=${window.location.hostname}`;
                }
                return `https://player.twitch.tv/?${embedType}=${data.contentValue}&parent=${window.location.hostname}`;
            case data.source === SocialFeedSource.Snapchat:
                return `${data.contentValue}/${data.contentValue.includes('embed') ? '' : 'embed'}`;
            case data.source === SocialFeedSource.Linkfire:
                return `${data.contentValue}/widget${data.contentType === 'Clickthroughs' ? '?view=clickthrough' : ''}`;
            case data.source === SocialFeedSource.YouTube:
                if (data.contentType === 'Playlists') {
                    return `https://youtube.com/embed/?list=${data.contentValue}`;
                }
                if (data.contentType === 'Channels') {
                    return `https://youtube.com/embed/?listType=playlist&list=${data.contentValue.replace(/^[UC]{2}/, 'UU')}`;
                }
                return `https://youtube.com/embed/${data.contentValue}`;
            case data.source === SocialFeedSource.Facebook:
                const isIframe = /(?:<iframe[^>]*)(?:(?:\/>)|(?:>.*?<\/iframe>))/.test(data.contentValue);
                if (!isIframe) {
                    const type = this.getFacebookContentType(data.contentType);
                    return `https://www.facebook.com/plugins/${type}.php?href=${encodeURI(data.contentValue)}&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=337285528697659`;
                }
                const regex = /<iframe[^>]+src="(http|https:\/\/[^">]+)"/g;
                const url = regex.exec(data.contentValue);
                return url === null || url === void 0 ? void 0 : url[1];
            default:
                return data.contentValue;
        }
    }
    static getFacebookContentType(type) {
        const facebookContentTypes = {
            Pages: 'page',
            Posts: 'post',
            Videos: 'video'
        };
        return facebookContentTypes[type];
    }
    static isImage(url) {
        return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
    }
    static overallQuantity(shoppingCart, variant, isLimitAvailable) {
        const items = isLimitAvailable ? shoppingCart === null || shoppingCart === void 0 ? void 0 : shoppingCart.products : shoppingCart === null || shoppingCart === void 0 ? void 0 : shoppingCart.checkout;
        return ((items === null || items === void 0 ? void 0 : items.reduce((acc, item) => {
            if (isLimitAvailable) {
                return item.product_id === (variant === null || variant === void 0 ? void 0 : variant.product_id) ? acc + item.quantity : acc;
            }
            return item.variantId === (variant === null || variant === void 0 ? void 0 : variant.variant_id) ? acc + item.quantity : acc;
        }, 0)) || 0);
    }
    static titleCase(str) {
        const smallWords = /^(a|an|and|as|at|but|by|en|for|if|in|nor|of|on|or|per|the|to|v.?|vs.?|via)$/i;
        const alphanumericPattern = /([A-Za-z0-9\u00C0-\u00FF])/;
        const wordSeparators = /([ :–—-])/;
        return str
            .split(wordSeparators)
            .map(function (current, index, array) {
            if (
            /* Check for small words */
            current.search(smallWords) > -1 &&
                /* Skip first and last word */
                index !== 0 &&
                index !== array.length - 1 &&
                /* Ignore title end and subtitle start */
                array[index - 3] !== ':' &&
                array[index + 1] !== ':' &&
                /* Ignore small words that start a hyphenated phrase */
                (array[index + 1] !== '-' || (array[index - 1] === '-' && array[index + 1] === '-'))) {
                return current.toLowerCase();
            }
            /* Ignore intentional capitalization */
            if (current.substr(1).search(/[A-Z]|\../) > -1) {
                return current;
            }
            /* Ignore URLs */
            if (array[index + 1] === ':' && array[index + 2] !== '') {
                return current;
            }
            /* Capitalize the first letter */
            return current.replace(alphanumericPattern, function (match) {
                return match.toUpperCase();
            });
        })
            .join('');
    }
    static isHtmlElement(node) {
        return node.nodeType === Node.ELEMENT_NODE;
    }
    static showLoader() {
        const loader = document.querySelector('#loading-screen').cloneNode(true);
        if (AppUtils.isHtmlElement(loader)) {
            loader.classList.remove('is-hidden');
        }
        return loader;
    }
    static removeLoader() {
        const container = document.querySelector('#overlay .iframe-overlay');
        const loader = container.querySelector('#loading-screen');
        if (!loader)
            return;
        container.removeChild(loader);
    }
    static getSubstringId(id, objectType) {
        var _a;
        const substringId = id.split('--');
        const isProductWithUuid = objectType === 'product' && substringId[1];
        const itemId = (_a = substringId[1]) !== null && _a !== void 0 ? _a : id;
        const uuid = isProductWithUuid ? substringId[0] : undefined;
        return [itemId, uuid];
    }
}
