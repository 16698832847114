import { ProductInfoBase } from './product-info-base_overlay';
import { productInfoKhaliliView } from './product-info-khalili.view';
export class ProductInfoKhalili extends ProductInfoBase {
    constructor() {
        super(...arguments);
        this.supportGalleryView = false;
    }
    setCustomButtons() { }
    initProductPrice() { }
    initDescriptionControls() {
        var _a;
        if ((_a = this.product.description) === null || _a === void 0 ? void 0 : _a.length) {
            super.initDescriptionControls();
        }
        else {
            this.mainContainer.classList.add('without-information-tab');
        }
    }
    handleClick(e) {
        if (this.isViewGallery()) {
            const tokens = ['app-product-overlay', 'app-product-content-wrapper', 'app-product-carousel'];
            if (e.target.closest('#app-image-carousel')) {
                tokens.push('splide__slide');
            }
            return tokens.some(token => e.target.classList.contains(token));
        }
        else {
            return e.target === this.mainContainer;
        }
    }
    setItemContent() {
        this.container.innerHTML = productInfoKhaliliView(Object.assign({}, this.product), this.item.planogram.name);
    }
    setThumbsSwitchLength() {
        // no thumbnail for this client
    }
}
