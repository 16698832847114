import { UrlUtils } from '../api/urls';
import { Overlay } from '../overlay';
import { FullScreenButton } from '../components/overlay-fullscreen-mode';
import { SphereItemType } from 'shared/interfaces/planogram';
export class ImageOverlay {
    constructor(wrapper, options, item, closeCallback) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        this.wrapper = wrapper;
        this.closeCallback = closeCallback;
        const productData = item.type === SphereItemType.Product ? item.data : undefined;
        const imageData = item.type === SphereItemType.Image ? item.data : undefined;
        const thumbnailUrl = (_c = (_b = ((_a = productData === null || productData === void 0 ? void 0 : productData.picture) !== null && _a !== void 0 ? _a : imageData)) === null || _b === void 0 ? void 0 : _b.thumbnails) === null || _c === void 0 ? void 0 : _c.VARIANT_LARGE_WEBP;
        const imageUrl = UrlUtils.generateThumbnailURL((_f = (_e = (_d = productData === null || productData === void 0 ? void 0 : productData.picture) === null || _d === void 0 ? void 0 : _d.url) !== null && _e !== void 0 ? _e : imageData === null || imageData === void 0 ? void 0 : imageData.url) !== null && _f !== void 0 ? _f : '', thumbnailUrl);
        const container = document.createElement('div');
        container.classList.add('app-image-overlay-container');
        container.setAttribute('aria-live', 'polite');
        const closeButton = document.createElement('div');
        closeButton.classList.add('overlay-button', 'overlay-button-close');
        closeButton.setAttribute('role', 'button');
        closeButton.setAttribute('tabIndex', '0');
        closeButton.appendChild(document.createElement('span'));
        container.appendChild(closeButton);
        const imageWrapper = document.createElement('div');
        const image = document.createElement('img');
        imageWrapper.classList.add('image-wrapper');
        image.setAttribute('aria-label', (_h = (_g = imageData === null || imageData === void 0 ? void 0 : imageData.accessibility) === null || _g === void 0 ? void 0 : _g.description) !== null && _h !== void 0 ? _h : '');
        image.setAttribute('src', imageUrl);
        imageWrapper.appendChild(image);
        container.appendChild(imageWrapper);
        const seoTitle = (_j = imageData === null || imageData === void 0 ? void 0 : imageData.accessibility) === null || _j === void 0 ? void 0 : _j.title;
        if ((options === null || options === void 0 ? void 0 : options.title) && seoTitle !== undefined) {
            container.classList.add('with-title');
            Overlay.showSeoTitle(imageWrapper, seoTitle);
        }
        if (options === null || options === void 0 ? void 0 : options.fullscreen) {
            const fullscreenContainer = document.createElement('div');
            fullscreenContainer.classList.add('image-fullscreen-container');
            imageWrapper.appendChild(fullscreenContainer);
            this.fullscreenButton = new FullScreenButton(fullscreenContainer);
        }
        const backdrop = document.createElement('div');
        backdrop.classList.add('app-overlay-backdrop');
        this.wrapper.replaceChildren(container, backdrop);
    }
    closeOverlay(e) {
        e.preventDefault();
        e.stopPropagation();
        if (this.closeCallback) {
            this.closeCallback();
        }
        else {
            console.error('"Close" function is not set up');
        }
    }
    handleClick(e) {
        return e.target.tagName !== 'IMG';
    }
}
