import { ApiService } from '../api';
import { URLS, UrlUtils } from '../urls';
import { L10nUtils } from '../../utils/l10n_utils';
const storedAvailableLanguages = new Map();
export class L10nService extends ApiService {
    getAvailableLanguages() {
        const availableLanguages = L10nService.getAvailableLanguages();
        const planogramId = L10nUtils.getPlanogramId();
        const url = UrlUtils.insertValueToUrl(URLS.L10N_LANGUAGES, planogramId);
        if (availableLanguages) {
            return Promise.resolve(availableLanguages);
        }
        return this.get(url)
            .then(resp => resp.json())
            .then(json => {
            L10nService.setAvailableLanguages(json);
            return json;
        });
    }
    getL10nDictionary(languageCode) {
        const url = UrlUtils.insertValueToUrl(URLS.L10N_DICTIONARY, languageCode);
        return this.getWithoutToken(url).then(resp => resp.json());
    }
    clearStoredAvailableLanguages() {
        storedAvailableLanguages.clear();
    }
    static getAvailableLanguages() {
        return storedAvailableLanguages.get('languages');
    }
    static setAvailableLanguages(languages) {
        storedAvailableLanguages.set('languages', languages);
    }
}
