import { ApiService } from '../api';
import { URLS, UrlUtils } from '../urls';
import { L10nUtils } from '../../utils/l10n_utils';
const storedSettings = new Map();
export class CookiesService extends ApiService {
    getSettings() {
        const currentLang = L10nUtils.getCurrentLanguage();
        const settings = CookiesService.getSettings(currentLang);
        const url = UrlUtils.insertValueToUrl(URLS.COOKIES_SETTINS, currentLang);
        if (settings) {
            return Promise.resolve(settings);
        }
        return this.get(url)
            .then(resp => resp.json())
            .then(data => {
            CookiesService.setSettings(currentLang, data);
            return data;
        });
    }
    static getSettings(languageCode) {
        return storedSettings.get(languageCode);
    }
    static setSettings(languageCode, data) {
        storedSettings.set(languageCode, data);
    }
}
