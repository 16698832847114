import { debugFloatPrameter, debugIntParameter } from 'shared/utils/debug';
export const TILE_SIZE = debugIntParameter('TILE_SIZE', 512);
export const TILE_BORDER_SIZE = 2.0;
export const TILE_CONTENT_SIZE = TILE_SIZE - TILE_BORDER_SIZE;
export const MIN_ATLASED_SIZE = 64;
export const MIN_ATLAS_POWER = Math.log2(TILE_SIZE / MIN_ATLASED_SIZE);
export const TILES_IN_TEXTURE = 16; // supports up to 16x16 tiles per physical texture, so physical textures up to 8k
export const EMPTY_MAP_TEXTURE = 254;
export const ERROR_MAP_TEXTURE = 255;
export const PHYSICAL_TEXTURE_DEBUG_SIZE = debugFloatPrameter('PHYSICAL_TEXTURE_DEBUG_SIZE', 0.9);
export const TEXTURE_SIZE_PACKING = 16;
export const ATLAS_PACKING = 16;
