import { variantsComponentView } from './variants_component.view';
import { InputEventUtils } from '../../../../utils/input_event_utils';
import { Metrics } from '../../../../metrics';
import { MATOMO_EVENT_NAMES } from '../../../../metric-events';
import { AppState } from '../../../../shared/app.state';
import { UrlUtils } from '../../../../api/urls';
export class VariantsComponent {
    constructor(option, product, mainContainer, shopifyService) {
        var _a;
        this.option = option;
        this.product = product;
        this.mainContainer = mainContainer;
        this.shopifyService = shopifyService;
        if (!this.option.values.includes('Default Title')) {
            this.option = Object.assign(Object.assign({}, this.option), { values: ['Please select', ...this.option.values] });
        }
        this.initComponent();
        this.variantsContainer = this.mainContainer.querySelector(`.option${this.option.position}`);
        this.selectedInput = this.variantsContainer.querySelector('input:checked');
        this.selectedValue = (_a = this.selectedInput) === null || _a === void 0 ? void 0 : _a.value;
        this.shopifyService.updateProductVariant(`option${this.option.position}`, this.selectedValue);
        InputEventUtils.addSelectEvents(this.variantsContainer, this.handleClicks.bind(this));
    }
    initComponent() {
        var _a, _b;
        const variantsContainer = this.mainContainer.querySelector('.app-ecommerce-variants-container');
        const variantsWrapper = document.createElement('div');
        variantsContainer.append(variantsWrapper);
        variantsWrapper.outerHTML = variantsComponentView(this.option);
        if ((_b = (_a = this.product) === null || _a === void 0 ? void 0 : _a.variants) === null || _b === void 0 ? void 0 : _b.length) {
            this.selectVariant();
        }
    }
    selectVariant() {
        const selectedInput = this.mainContainer
            .querySelector(`input[name='option${this.option.position}-${this.option.id}']`);
        if (selectedInput) {
            selectedInput.checked = true;
        }
    }
    handleInputChange() {
        this.selectedInput = this.variantsContainer.querySelector('input:checked');
        const currentValue = this.selectedInput.value;
        Metrics.storeTheEvent(AppState.planogramName, 'select', MATOMO_EVENT_NAMES.SELECT_ECOMMERCE_OVERLAY_PRODUCT_VARIANT(UrlUtils.slugify(this.option.name), UrlUtils.slugify(currentValue), this.product.identifier, UrlUtils.slugify(this.product.name)));
        if (this.selectedValue !== currentValue) {
            this.selectedValue = currentValue;
            this.shopifyService.updateProductVariant(`option${this.option.position}`, this.selectedValue);
        }
    }
    handleClicks(e) {
        var _a, _b, _c;
        // to prevent duplication of click events (click on input is fired after clicking on label)
        if (e.target.tagName === 'INPUT') {
            this.handleInputChange();
            return;
        }
        else {
            // to hide validation message when list is active
            (_a = this.selectedInput) === null || _a === void 0 ? void 0 : _a.setCustomValidity('');
            (_b = this.selectedInput) === null || _b === void 0 ? void 0 : _b.reportValidity();
        }
        if (((_c = this.option) === null || _c === void 0 ? void 0 : _c.values.length) <= 1) {
            this.variantsContainer.classList.add('disabled');
            return;
        }
        const allVariants = this.mainContainer.querySelectorAll('.app-ecommerce-product-variants');
        allVariants.forEach(variant => {
            if (variant !== this.variantsContainer) {
                variant.classList.remove('show-list');
            }
        });
        this.variantsContainer.classList.remove('disabled');
        this.variantsContainer.classList.toggle('show-list');
    }
    validate() {
        var _a, _b, _c, _d;
        if (((_a = this.selectedInput) === null || _a === void 0 ? void 0 : _a.value) === 'Please select') {
            (_b = this.selectedInput) === null || _b === void 0 ? void 0 : _b.setCustomValidity('Please select one of the variants');
        }
        else {
            (_c = this.selectedInput) === null || _c === void 0 ? void 0 : _c.setCustomValidity('');
        }
        return (_d = this.selectedInput) === null || _d === void 0 ? void 0 : _d.reportValidity();
    }
}
