import { L10nUtils } from '../../../../utils/l10n_utils';
export const productFieldsComponentView = (product, showDetails = false) => {
    return `
    <div class="app-ecommerce-product-additional-fields-container app-ecommerce-product-additional-fields-container">
      <div class="app-ecommerce-product-additional-field app-ecommerce-product-additional-field ${showDetails ? 'active' : ''}">
        <div class="app-ecommerce-product-additional-field-title-wrapper app-ecommerce-product-additional-field-title-wrapper">
          <div class="app-ecommerce-product-additional-field-title app-ecommerce-product-additional-field-title">${L10nUtils.l10n('shopify-product-overlay.product-field-title')}</div>
          <span class="app-ecommerce-product-additional-field-icon app-ecommerce-product-additional-field-icon"></span>
        </div>
        <div class="app-ecommerce-product-additional-field-content">${product.description}</div>
      </div>
    </div>
  `;
};
