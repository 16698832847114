import { ACCOUNT_OVERLAY_TYPE } from '../../shared/constants';
import { AccountBaseOverlay } from '../account-base-overlay/account-base-overlay';
import { Metrics } from '../../metrics';
import { AppState } from '../../shared/app.state';
import { MATOMO_EVENT_NAMES } from '../../metric-events';
export class AccountResetPass extends AccountBaseOverlay {
    init() { }
    onSubmit(e) {
        Metrics.storeTheEvent(AppState.planogramName, 'click', MATOMO_EVENT_NAMES.CLICK_USER_PROFILE_OVERLAY_RESET_PASSWORD);
        e.preventDefault();
        const data = new FormData(this.formEl);
        this.accountService.setLoadingState(true);
        this.accountService
            .resetPassword(data.get('email').toString())
            .then(() => {
            this.accountService.setOverlayType(ACCOUNT_OVERLAY_TYPE.EMAIL_SENT);
            this.hideError();
            this.clearForm();
        })
            .catch(error => this.onError(error))
            .finally(() => this.accountService.setLoadingState(false));
    }
}
