import { isContentOverlayAction } from 'shared/interfaces/planogram';
import { FullScreenButton } from '../components/overlay-fullscreen-mode';
import { Overlay } from '../overlay';
export class ContentOverlay {
    constructor(container, url, item, options, closeCallback) {
        this.url = url;
        this.item = item;
        this.options = options;
        this.closeCallback = closeCallback;
        this.container = container;
        container.innerHTML = '';
        const root = document.createElement('div');
        root.classList.add('iframe-overlay', 'content-overlay', 'overlay-container', 'is-flex', 'is-vertical', 'is-vertically-aligned-center', 'is-horizontally-aligned-center');
        this.container.appendChild(root);
        const closeButton = document.createElement('div');
        closeButton.classList.add('overlay-button', 'overlay-button-close', 'will-close');
        closeButton.appendChild(document.createElement('span'));
        closeButton.addEventListener('click', this.closeOverlay.bind(this));
        root.appendChild(closeButton);
        const holder = document.createElement('div');
        holder.classList.add('iframe-holder');
        holder.style.setProperty('background-color', 'transparent');
        root.appendChild(holder);
        this.fetchType(url).then(type => {
            var _a, _b;
            const isImage = type.match(/^image\/.*$/) !== null;
            const contentWrapper = document.createElement('div');
            const content = document.createElement(isImage ? 'img' : 'iframe');
            contentWrapper.classList.add('content-wrapper');
            const fullscreenButtonWrapper = document.createElement('div');
            fullscreenButtonWrapper.classList.add('iframe-fullscreen-button');
            if (isImage) {
                holder.style.setProperty('background-color', 'transparent');
                root.classList.add('is-image');
            }
            else {
                holder.style.removeProperty('background-color');
            }
            content.onload = e => {
                if (isImage) {
                    const target = e.target;
                    holder.style.setProperty('aspect-ratio', `${target.naturalWidth} / ${target.naturalHeight}`);
                }
            };
            content.src = this.url;
            content.style.objectFit = 'contain';
            content.style.width = '100%';
            content.style.height = '100%';
            contentWrapper.appendChild(content);
            root.appendChild(fullscreenButtonWrapper);
            holder.appendChild(contentWrapper);
            if ((_a = this.options) === null || _a === void 0 ? void 0 : _a.fullscreen) {
                const fullscreenButtonContainer = this.container.querySelector('.iframe-fullscreen-button');
                this.fullscreenButton = new FullScreenButton(fullscreenButtonContainer);
            }
            if (((_b = this.options) === null || _b === void 0 ? void 0 : _b.title) && isContentOverlayAction(this.item.action)) {
                root.classList.add('with-title');
                Overlay.showSeoTitle(root, this.item.action.data.iframeLink);
            }
        });
    }
    fetchType(url) {
        return fetch(url, { method: 'HEAD' }).then(r => r.headers.get('Content-Type'));
    }
    closeOverlay(e) {
        if (e) {
            e.stopPropagation();
        }
        if (this.closeCallback) {
            this.closeCallback();
        }
        else {
            console.error('"Close" function is not set up');
        }
    }
    handleClick(e) {
        return e.target.classList.contains('iframe-overlay');
    }
}
