var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Text } from 'troika-three-text';
import { SphereItem } from '../sphere_item';
import { FONT_UNIT_RATIO, DESIGNER_PLANOGRAM_HEIGHT } from '../shared/constants';
import { SphereItemType } from 'shared/interfaces/planogram';
import { WebUtils } from '../utils/web_utils';
import { SphereGeometry } from '../geometries/sphere_geometry';
import { LinearFilter, MeshBasicMaterial, Texture } from 'three';
import { FontLoader } from '../font_loader';
// The max size of the final text texture
const MAX_TEXTURE_SIZE = 8192;
// How much to increase the original size of the text - this way we
// increase the resolution / quality of the rendered text
const TEXTURE_SCALING = 1.5;
export class TextComponent extends SphereItem {
    constructor(itemData, planogram) {
        super(itemData, planogram);
        this.createMeshPromisesResolves = [];
    }
    onClick(position) { }
    onHoverEnter() { }
    onHoverLeave() { }
    // Calculates bounding box of the text.
    calculateSize(data) {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        this.applyFontStyle(canvas, ctx, data);
        const { topOffsetFromCenter } = data;
        let maxLeft = Number.MIN_SAFE_INTEGER;
        let maxRight = Number.MIN_SAFE_INTEGER;
        let topP = 0;
        let bottomP = 0;
        const lineHeight = Math.round(data.lineHeight * TextComponent.fontUnitSize);
        const lines = this.type === SphereItemType.Text ? data.text.split('\n') : data.textLines;
        lines.forEach((line, index) => {
            if (!line) {
                return;
            }
            const { actualBoundingBoxLeft, actualBoundingBoxRight, actualBoundingBoxAscent, actualBoundingBoxDescent } = ctx.measureText(line);
            if (actualBoundingBoxLeft > maxLeft) {
                maxLeft = actualBoundingBoxLeft;
            }
            if (actualBoundingBoxRight > maxRight) {
                maxRight = actualBoundingBoxRight;
            }
            if (index === 0) {
                topP = actualBoundingBoxAscent;
            }
            if (index === lines.length - 1) {
                bottomP = actualBoundingBoxDescent;
            }
        });
        const height = Math.max(0, topP + bottomP + (lines.length - 1) * lineHeight);
        const width = Math.max(0, maxLeft + maxRight);
        const horizontalOffset = maxLeft;
        const verticalOffset = topP;
        if (this.type === SphereItemType.Text) {
            // old Text component
            this.x = this.x - horizontalOffset;
            this.y = this.y + topOffsetFromCenter - height;
        }
        else {
            this.x = data.textPosition[0] - horizontalOffset + 0;
            this.y = data.textPosition[1] + topOffsetFromCenter - height;
        }
        // Update dimensions
        this.width = width;
        this.height = height;
        this.fontSpecs = { width, height, horizontalOffset, verticalOffset };
        this.azimuthStartRadians = SphereGeometry.calcAzimuthStartRadians(this.x, this.width, this.planogram.width);
        this.createMeshPromisesResolves.forEach(resolve => resolve(undefined));
        canvas.width = canvas.height = 0;
        canvas.remove();
    }
    createMesh(capabilities) {
        const _super = Object.create(null, {
            createMesh: { get: () => super.createMesh }
        });
        return __awaiter(this, void 0, void 0, function* () {
            yield this.createMaterial(this.itemData.data, capabilities);
            return _super.createMesh.call(this, capabilities);
        });
    }
    createMaterial(data, capabilities) {
        return __awaiter(this, void 0, void 0, function* () {
            this.material = new MeshBasicMaterial({
                transparent: true,
                opacity: 0.0,
                depthTest: false,
                depthWrite: false
            });
            try {
                yield this.loadFont(data);
            }
            catch (e) {
                console.error(e);
                return;
            }
            this.calculateSize(data);
            this.createTextTexture(data, capabilities);
        });
    }
    static get fontUnitSize() {
        return FONT_UNIT_RATIO * DESIGNER_PLANOGRAM_HEIGHT;
    }
    static get letterSpacingUnitSizes() {
        return Text.fontUnitSize / 1000;
    }
    createTextTexture(data, capabilities) {
        const maxTextureSize = Math.min(capabilities.maxTextureSize, MAX_TEXTURE_SIZE);
        // If the initial size of the text is too big we can't increase
        // the resolution by too much or we will start having memory issues
        const textureScale = maxTextureSize / Math.max(Math.max(this.width, this.height), maxTextureSize / TEXTURE_SCALING);
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const letterSpacing = data.letterSpacing * TextComponent.letterSpacingUnitSizes;
        const lineHeight = Math.round(data.lineHeight * TextComponent.fontUnitSize);
        const width = this.width * textureScale;
        const height = this.height * textureScale;
        const horizontalOffset = this.fontSpecs.horizontalOffset * textureScale;
        const verticalOffset = this.fontSpecs.verticalOffset * textureScale;
        // Resize canvas to match text size
        canvas.width = width;
        canvas.height = height;
        canvas.style.width = width + 'px';
        canvas.style.height = height + 'px';
        canvas.style.letterSpacing = letterSpacing + 'px';
        this.applyFontStyle(canvas, ctx, data, textureScale);
        const lines = this.type === SphereItemType.Text ? data.text.split('\n') : data.textLines;
        lines.forEach((text, index) => {
            ctx.fillText(text, horizontalOffset, verticalOffset + lineHeight * textureScale * index);
        });
        const texture = new Texture(canvas);
        texture.minFilter = LinearFilter;
        texture.magFilter = LinearFilter;
        this.material.map = texture;
        this.material.opacity = data.alpha || 1.0;
        this.material.map.needsUpdate = true;
        this.material.needsUpdate = true;
        texture.onUpdate = () => {
            canvas.width = 0;
            canvas.height = 0;
            canvas.remove();
            texture.onUpdate = undefined;
        };
    }
    loadFont(data) {
        return __awaiter(this, void 0, void 0, function* () {
            const font = data.fontFamily;
            const family = WebUtils.removeFileExtension(font.name) || 'custom-font';
            const weight = this.computeFontWeight(data.weight !== undefined ? data.weight : '');
            let fontFace = undefined;
            yield document.fonts.ready;
            for (const it of document.fonts) {
                if (it.family === family &&
                    it.display !== 'swap' &&
                    this.computeFontWeight(it.weight) === weight &&
                    FontLoader.getCustomFont(font.file_url)) {
                    fontFace = it;
                    break;
                }
            }
            if (!fontFace) {
                fontFace = FontLoader.addCustomFont(font);
            }
            if (fontFace.status !== 'loaded') {
                yield fontFace.loaded;
            }
        });
    }
    computeFontWeight(weight) {
        const str = weight.toLowerCase();
        let computedWeight;
        switch (str) {
            case 'thin':
                computedWeight = '100';
                break;
            case 'hairline':
                computedWeight = '100';
                break;
            case 'extra light':
                computedWeight = '200';
                break;
            case 'ultra light':
                computedWeight = '200';
                break;
            case 'light':
                computedWeight = '300';
                break;
            case 'book':
                computedWeight = '300';
                break;
            case 'regular':
                computedWeight = '400';
                break;
            case 'normal':
                computedWeight = '400';
                break;
            case 'medium':
                computedWeight = '500';
                break;
            case 'semi bold':
                computedWeight = '600';
                break;
            case 'bold':
                computedWeight = '700';
                break;
            case 'extra bold':
                computedWeight = '800';
                break;
            case 'black':
                computedWeight = '900';
                break;
            default:
                computedWeight = '400';
                break;
        }
        return computedWeight;
    }
    dispose() {
        var _a, _b;
        (_b = (_a = this.material) === null || _a === void 0 ? void 0 : _a.map) === null || _b === void 0 ? void 0 : _b.dispose();
        super.dispose();
    }
    applyFontStyle(canvas, ctx, data, scale = 1) {
        const fontSize = Math.round(data.fontSize * TextComponent.fontUnitSize);
        const letterSpacing = data.letterSpacing * TextComponent.letterSpacingUnitSizes;
        canvas.style.letterSpacing = letterSpacing + 'px';
        ctx.font = `${fontSize * scale}px "${WebUtils.removeFileExtension(data.fontFamily.name) || 'custom-font'}"`;
        ctx.textAlign = data.alignment || 'left';
        ctx.textBaseline = 'top';
        ctx.fillStyle = data.color || 'white';
    }
}
