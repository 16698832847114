var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { isAnimateAction, isProductOverlayAction, isSingleImageAction } from 'shared/interfaces/planogram';
import { ActionType } from 'shared/interfaces/planogram';
import { CLICK_EVENT_TYPES, HEATMAP_EVENT_TYPES } from '../../shared/constants';
import { SphereItemType } from 'shared/interfaces/planogram';
import { ApiService } from '../api';
import { URLS } from '../urls';
import { AppUtils } from '../../utils/app_utils';
import { CookiesManagement } from '../../cookies_management';
import { debounce } from 'lodash';
import { sphereEventHandler } from '../../custom_event_utils';
import { SPHERE_EVENT_NAMES } from '../../event-names';
const itemTypeToClickType = {
    [SphereItemType.Image]: CLICK_EVENT_TYPES.IMAGE,
    [SphereItemType.Product]: CLICK_EVENT_TYPES.PRODUCT_OVERLAY,
    [SphereItemType.Cluster]: CLICK_EVENT_TYPES.CLUSTER
};
function actionTypeToClickEvent(action) {
    var _a;
    if (action.type === ActionType.AboutUs)
        return CLICK_EVENT_TYPES.ABOUT_US;
    if (action.type === ActionType.LegalNotice)
        return CLICK_EVENT_TYPES.COPYRIGHT;
    if (action.type === ActionType.ExternalLink)
        return CLICK_EVENT_TYPES.LINK;
    if (action.type === ActionType.PrivacyPolicy)
        return CLICK_EVENT_TYPES.PRIVACY;
    if (isAnimateAction(action))
        return (_a = itemTypeToClickType[action.data.itemType]) !== null && _a !== void 0 ? _a : CLICK_EVENT_TYPES.EMPTY;
    if (isSingleImageAction(action))
        return CLICK_EVENT_TYPES.IMAGE;
    if (isProductOverlayAction(action))
        return CLICK_EVENT_TYPES.PRODUCT_OVERLAY;
}
function makeItemUrl(item) {
    var _a, _b, _c, _d, _e;
    let itemUrl = '';
    const action = item.action;
    if (isAnimateAction(action)) {
        switch (action.data.itemType) {
            case SphereItemType.Cluster:
                itemUrl = `&object_type=cluster&object_id=${AppUtils.extractClusterName(action.data.clusterLink)}`;
                break;
            case SphereItemType.Image:
                itemUrl = `&object_type=image&object_id=${(_a = action === null || action === void 0 ? void 0 : action.data) === null || _a === void 0 ? void 0 : _a.imageId}`;
                break;
            case SphereItemType.Product:
                itemUrl = `&object_type=product&object_id=${(_b = action === null || action === void 0 ? void 0 : action.data) === null || _b === void 0 ? void 0 : _b.productIdentifier}`;
                break;
        }
    }
    if (isProductOverlayAction(action)) {
        itemUrl = `&object_type=product&object_id=${item.data.product.identifier || action.data.productIdentifier}`;
    }
    if (isSingleImageAction(action)) {
        itemUrl = `&object_type=image&object_id=${((_d = (_c = item.data) === null || _c === void 0 ? void 0 : _c.picture) === null || _d === void 0 ? void 0 : _d.id) || ((_e = item.data) === null || _e === void 0 ? void 0 : _e.id)}`;
    }
    return `&click_event_type=${actionTypeToClickEvent(item.action)}${itemUrl}`;
}
export class HeatMapService extends ApiService {
    constructor(planogramVersionId, screenWidth, screenHeight, getPlanogramCoordinates, cookie) {
        super();
        this.planogramVersionId = planogramVersionId;
        this.screenWidth = screenWidth;
        this.screenHeight = screenHeight;
        this.getPlanogramCoordinates = getPlanogramCoordinates;
        this.MATOMO_USER_ID_REGEX = /_pk_id\.[^\..]+\.[^=]+=([^\.]+)/;
        this.MATOMO_SESSION_ID_REGEX = /_pk_ses\.[^\..]+\.[^=]+=([^\. ;]+)/;
        this.previousZoomTime = Date.now();
        this.previousZoomLevel = 0.0;
        this.debounceZoomFunction = debounce((coords, newZoomLevel) => {
            const zoomDuration = Math.round((Date.now() - this.previousZoomTime) / 1000.0);
            const baseUrl = this.generateBaseUrl(newZoomLevel > this.previousZoomLevel ? HEATMAP_EVENT_TYPES.ZOOM_IN : HEATMAP_EVENT_TYPES.ZOOM_OUT, coords, this.previousZoomLevel);
            this.previousZoomTime = Date.now();
            const zoomUrl = `${baseUrl}&duration=${zoomDuration}&final_zoom_level=${newZoomLevel}`;
            this.previousZoomLevel = newZoomLevel;
            this.get(zoomUrl, {});
        }, 400).bind(this);
        this.previousMovePosition = undefined;
        this.updatePlanogram(planogramVersionId, cookie);
        sphereEventHandler.listen(SPHERE_EVENT_NAMES.HEATMAP.CLICK, ({ x, y, zoomLevel, item }) => this.sendClickEvent(x, y, zoomLevel, item));
        sphereEventHandler.listen(SPHERE_EVENT_NAMES.HEATMAP.ZOOM, ({ x, y, zoomLevel, newZoomLevel }) => this.sendZoomEvent(x, y, zoomLevel, newZoomLevel));
        sphereEventHandler.listen(SPHERE_EVENT_NAMES.HEATMAP.MOVE, ({ x, y, zoomLevel, direction }) => this.sendMoveEvent(x, y, zoomLevel, direction));
    }
    get(url, headers) {
        const _super = Object.create(null, {
            get: { get: () => super.get }
        });
        return __awaiter(this, void 0, void 0, function* () {
            if (!ENABLE_HEATMAP_SERVICE)
                return new Response();
            return _super.get.call(this, url, headers);
        });
    }
    updatePlanogram(planogramVersionId, cookie) {
        this.planogramVersionId = planogramVersionId;
        if (cookie) {
            this.matomoUserId = cookie.match(this.MATOMO_USER_ID_REGEX);
            this.matomoSessionId = cookie.match(this.MATOMO_SESSION_ID_REGEX);
        }
    }
    updateScreenSize(screenWidth, screenHeight) {
        this.screenWidth = screenWidth;
        this.screenHeight = screenHeight;
    }
    generateBaseUrl(eventType, coords, zoomLevel) {
        const timestamp = new Date(Date.now()).toISOString();
        CookiesManagement.updateLastActionTime();
        return `${URLS.HEATMAP_URL}?\
planogram_version_id=${this.planogramVersionId}&event_type=${eventType}\
&timestamp=${timestamp}\
&x=${Math.round(coords.x)}&y=${Math.round(coords.y)}\
&screen_width=${this.screenWidth}\
&screen_height=${this.screenHeight}\
&zoom_level=${zoomLevel}\
${this.matomoUserId ? `&matomo_user_id=${this.matomoUserId[1]}` : ''}\
${this.matomoSessionId ? `&matomo_session_id=${this.matomoSessionId[1]}` : ''}`;
    }
    sendClickEvent(x, y, zoomLevel, item) {
        zoomLevel = Math.round(zoomLevel * 100) / 100.0;
        const coords = this.getPlanogramCoordinates(x, y);
        const baseUrl = this.generateBaseUrl(HEATMAP_EVENT_TYPES.CLICK, coords, zoomLevel);
        const itemUrl = (item === null || item === void 0 ? void 0 : item.action) !== undefined ? makeItemUrl(item) : '';
        const clickUrl = `${baseUrl}${itemUrl}`;
        this.get(clickUrl, {});
    }
    sendZoomEvent(x, y, zoomLevel, newZoomLevel) {
        zoomLevel = Math.round(zoomLevel * 100) / 100.0;
        if (newZoomLevel === this.previousZoomLevel) {
            return;
        }
        const coords = this.getPlanogramCoordinates(x, y);
        this.debounceZoomFunction(coords, newZoomLevel);
    }
    sendMoveEvent(x, y, zoomLevel, direction) {
        if (this.previousMovePosition === undefined) {
            this.previousMovePosition = this.getPlanogramCoordinates(x, y);
            this.previousMoveTime = Date.now();
            return;
        }
        zoomLevel = Math.round(zoomLevel * 100) / 100.0;
        const baseUrl = this.generateBaseUrl(HEATMAP_EVENT_TYPES.MOVE, this.previousMovePosition, zoomLevel);
        const duration = Math.round((Date.now() - this.previousMoveTime) / 1000.0);
        this.previousMoveTime = Date.now();
        const coords = this.getPlanogramCoordinates(x, y);
        const moveUrl = `${baseUrl}&duration=${duration}&direction=${direction}&destination_x=${Math.round(coords[0])}&destination_y=${Math.round(coords[1])}`;
        this.previousMovePosition = this.getPlanogramCoordinates(x, y);
        this.get(moveUrl, {});
    }
}
