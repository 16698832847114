import { Vector2, Vector3 } from 'three';
export const rotate2Dinto3DVector = (x, y, rotationAngle) => {
    const yaxis = new Vector3(0, 1, 0);
    const xyAsVec3 = new Vector3(x, y, 0);
    xyAsVec3.applyAxisAngle(yaxis, rotationAngle);
    return xyAsVec3;
};
export const setWorldPosition = (object, worldPosition) => {
    if (object.parent && object.parent.type !== 'Scene') {
        object.parent.updateMatrixWorld();
        const localPosition = object.parent.worldToLocal(worldPosition);
        object.position.copy(localPosition);
    }
    else {
        object.position.copy(worldPosition);
    }
};
export const calculateZXFlattenAngle = (vector) => {
    const topDownVector = new Vector2(vector.x, vector.z);
    return topDownVector.angle();
};
export const rotateToYZPlane = (vector) => {
    // Squash into a 2d vector on an x,y graph as if looking down the 3D y axis
    const fromTopVec2 = new Vector2(vector.x, vector.z);
    const angleFromX = fromTopVec2.angle();
    // Find z by rotating onto the 2d y axis
    fromTopVec2.rotateAround(new Vector2(0, 0), -(angleFromX - Math.PI / 2));
    return new Vector3(0, vector.y, fromTopVec2.y);
};
