import { updateUIElementsVisibility } from './ui_elements_utils';
export function showLoadingScreen(show) {
    const loadingScreen = document.getElementById('loading-screen');
    updateUIElementsVisibility(!show);
    if (show) {
        loadingScreen.classList.remove('is-hidden');
    }
    else {
        loadingScreen.classList.add('is-hidden');
    }
}
