import { Vector2 } from 'three';
export class MathUtils {
    static log(value, base) {
        return Math.log(value) / (base ? Math.log(base) : 1.0);
    }
}
export function normalizeMouse(x, y) {
    return new Vector2((x / window.innerWidth) * 2 - 1, -((y / window.innerHeight) * 2) + 1);
}
export function invertYAxis(point, planogramHeight) {
    const subVector = new Vector2(0, planogramHeight);
    const invertYVector = new Vector2(1, -1);
    return point.sub(subVector).multiply(invertYVector);
}
