import makeBehaviorSubject from 'callbag-behavior-subject';
import { ApiService } from '../api';
import { ShoppingCartUtils } from '../../utils/shopping-cart_utils';
import { EventType } from '../../interfaces/shopping-cart.interface';
export class ShopifyService extends ApiService {
    constructor() {
        super();
        this.product = null;
        this.productVariantsSubject = makeBehaviorSubject(this.selectedProductOptions);
        this.selectedProductSubject = makeBehaviorSubject(null);
        this.selectedVariantValueSubject = makeBehaviorSubject('');
        this.selectedProductOptions = { option1: null, option2: null, option3: null };
    }
    updateProductVariant(variantName, variantValue) {
        this.selectedProductOptions[variantName] = variantValue;
        this.selectedVariantValueSubject(EventType.Data, variantValue);
        this.productVariantsSubject(EventType.Data, this.selectedProductOptions);
        this.getSelectedVariant();
    }
    updateProductData(product) {
        this.product = product;
        this.getSelectedVariant();
    }
    getSelectedVariant() {
        if (!this.product) {
            return;
        }
        const imagesIds = this.product.product_images.map(image => image.shopify_id).join(',');
        let selectedVariant = this.product.variants.filter(variant => {
            return ShoppingCartUtils.isMatchingVariant(this.selectedProductOptions, variant);
        });
        if (!selectedVariant.length && this.selectedProductOptions.option1) {
            selectedVariant = this.product.variants
                .filter((variant) => variant.option1 === this.selectedProductOptions.option1)
                .filter((variant) => imagesIds.includes(`${variant.image_id}`));
        }
        if (!selectedVariant.length) {
            selectedVariant = [Object.assign(Object.assign({}, this.product.variants[0]), { isAutoSelected: true })];
        }
        this.selectedProductSubject(EventType.Data, selectedVariant[0]);
    }
}
