import { Box2 } from 'three';
import { Modulo } from '../utils/moduloUtils';
const STUTTER_TARGET = 0.1;
function normalizedLpf(newValue, oldValue, eps, dt) {
    const t = Math.pow(eps, Math.max(0, dt));
    return (1 - t) * newValue + t * oldValue;
}
function updateAllowedVideos(oldValue, stutterCount, dt) {
    const change = stutterCount > STUTTER_TARGET ? -1 : 1;
    const eps = Math.pow(0.1, (Math.abs(stutterCount - STUTTER_TARGET) / STUTTER_TARGET));
    return normalizedLpf(oldValue + change, oldValue, eps, dt);
}
export class VideoLimiter {
    constructor(planogram, videos, preloadLimit = +Infinity, playingLimit = +Infinity) {
        this.videos = videos;
        this.preloadLimit = preloadLimit;
        this.playingLimit = playingLimit;
        this.viewport = new Box2();
        this.modulo = new Modulo(planogram.size());
        this.stutterCount = STUTTER_TARGET;
        this.allowedToPlay = 2;
    }
    updateViewport(viewport) {
        this.viewport = viewport;
    }
    priority(v) {
        const fullSize = v.fullSize ? 0 : 1;
        const keepManuallyPlayed = !v.autoplay && v.isPlaying ? 0 : 1;
        const skipPausedManualVideos = v.autoplay ? 0 : 1;
        const visible = v.isVisible ? 0 : 1;
        const distance = this.modulo.distanceVB(v.getViewportCenter(), this.viewport);
        return [keepManuallyPlayed, skipPausedManualVideos, fullSize, distance.length(), visible];
    }
    compare(a, b) {
        let comp = 0;
        for (let i = 0; i < a.length; i++) {
            comp = a[i] - b[i];
            if (comp !== 0)
                break;
        }
        return comp;
    }
    updateVideos(loadCount, playCount) {
        this.videos
            .map(v => ({ v, p: this.priority(v) }))
            .sort((a, b) => this.compare(a.p, b.p))
            .map(a => a.v)
            .forEach((v, i) => {
            v.allowToLoad(i < loadCount || i < playCount);
            v.allowToPlay(i < playCount);
        });
    }
    update(dt) {
        dt = Math.min(1, dt); // ignore very long frames
        const playingVideos = this.videos.filter(v => v.isPlaying);
        const playingCount = playingVideos.length;
        const stutteringCount = playingVideos.filter(v => v.isStuttering()).length;
        this.stutterCount = normalizedLpf(stutteringCount / Math.max(1, playingCount), this.stutterCount, 0.1, dt);
        const target = updateAllowedVideos(this.allowedToPlay, this.stutterCount, dt);
        this.allowedToPlay = Math.min(this.playingLimit, playingCount + 1, this.videos.length, Math.max(target, 1));
        this.updateVideos(this.preloadLimit, Math.round(this.allowedToPlay));
    }
}
