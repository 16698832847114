import { ActionType } from 'shared/interfaces/planogram';
import { SphereItemType } from 'shared/interfaces/planogram';
const CLIENT_NAMES = {
    LEGO: 'bricks',
    WB: 'explore',
    SELFRIDGES: 'selfridges',
    SOTHEBYS: 'sothebys',
    NINJAGO: 'legoninjagouniverse',
    AJTRACEY: 'ajtracey',
    UAL: 'ual',
    KHALILI: 'khalili',
    HARRY_POTTER: 'harrypotter',
    HARRY_POTTER_UK: 'harrypotteruk',
    HARRY_POTTER_US: 'harrypotterus',
    SASSENBACH: 'sassenbach'
};
const FONT_UNIT_RATIO = 0.0025;
const DESIGNER_PLANOGRAM_HEIGHT = 2500;
const DEFAULT_CLIENT_NAME = CLIENT_NAMES.LEGO;
const PLANOGRAM_NAME = {
    MAIN: 'main',
    SUPERHEROES: 'superheroes',
    FRIENDS: 'friends',
    PINS: 'pins',
    NINJAGO: 'ninjago',
    HARRYPOTTER: 'harrypotter',
    JURASSIC: 'jurassic'
};
var BUTTONS_NAMES;
(function (BUTTONS_NAMES) {
    BUTTONS_NAMES["AMAZON"] = "amazon";
    BUTTONS_NAMES["RAKUTEN"] = "rakuten";
    BUTTONS_NAMES["CHECKOUT"] = "checkout";
    BUTTONS_NAMES["EBAY"] = "ebay";
    BUTTONS_NAMES["CUSTOM"] = "custom";
    BUTTONS_NAMES["CUSTOM_PIN"] = "custom_pin";
})(BUTTONS_NAMES || (BUTTONS_NAMES = {}));
var CUSTOM_OVERLAYS;
(function (CUSTOM_OVERLAYS) {
    CUSTOM_OVERLAYS["ABOUT_US"] = "about-us";
    CUSTOM_OVERLAYS["VIDEO"] = "video";
    CUSTOM_OVERLAYS["PRIVACY_POLICY"] = "privacy-policy";
    CUSTOM_OVERLAYS["CONTACT_US"] = "contact-us";
    CUSTOM_OVERLAYS["COPYRIGHT"] = "copyright";
    CUSTOM_OVERLAYS["IFRAME"] = "iframe";
    CUSTOM_OVERLAYS["COOKIES_POLICY"] = "cookies-policy";
})(CUSTOM_OVERLAYS || (CUSTOM_OVERLAYS = {}));
const INFO_OVERLAYS = {
    ABOUT_US: CUSTOM_OVERLAYS.ABOUT_US,
    COPYRIGHT: CUSTOM_OVERLAYS.COPYRIGHT,
    PRIVACY_POLICY: CUSTOM_OVERLAYS.PRIVACY_POLICY,
    CONTACT_US: CUSTOM_OVERLAYS.CONTACT_US,
    COOKIES_POLICY: CUSTOM_OVERLAYS.COOKIES_POLICY
};
const ITEM_TYPE_TO_URL_PREFIX_MAP = {
    [SphereItemType.Curve]: 's',
    [SphereItemType.Shape]: 's',
    [SphereItemType.Video]: 'y',
    [SphereItemType.TextArea]: 't',
    [SphereItemType.Text]: 't',
    [SphereItemType.Image]: 'i',
    [SphereItemType.Product]: 'p',
    [SphereItemType.Cluster]: 'c'
};
export const ACTION_TYPE_TO_URL_PREFIX = {
    [ActionType.ContentOverlay]: 'o',
    [ActionType.IframeLink]: 'f',
    [ActionType.SocialLink]: 'sm',
    [ActionType.CarouselOverlay]: 'co_'
};
var CLICK_EVENT_TYPES;
(function (CLICK_EVENT_TYPES) {
    CLICK_EVENT_TYPES["EMPTY"] = "webgl-empty";
    CLICK_EVENT_TYPES["SPHERE"] = "webgl-sphere";
    CLICK_EVENT_TYPES["CLUSTER"] = "webgl-cluster";
    CLICK_EVENT_TYPES["PRODUCT_OVERLAY"] = "webgl-product_overlay";
    CLICK_EVENT_TYPES["LINK"] = "webgl-click-link";
    CLICK_EVENT_TYPES["CENTRAL_CARD"] = "webgl-click-central_card";
    CLICK_EVENT_TYPES["PRIVACY"] = "webgl-open-privacy_overlay";
    CLICK_EVENT_TYPES["COPYRIGHT"] = "webgl-open-copyright_overlay";
    CLICK_EVENT_TYPES["IMAGE"] = "webgl-image";
    CLICK_EVENT_TYPES["ABOUT_US"] = "webgl-open-about_us_overlay";
})(CLICK_EVENT_TYPES || (CLICK_EVENT_TYPES = {}));
var HEATMAP_EVENT_TYPES;
(function (HEATMAP_EVENT_TYPES) {
    HEATMAP_EVENT_TYPES["CLICK"] = "click";
    HEATMAP_EVENT_TYPES["MOVE"] = "move";
    HEATMAP_EVENT_TYPES["ZOOM_IN"] = "zoom-in";
    HEATMAP_EVENT_TYPES["ZOOM_OUT"] = "zoom-out";
})(HEATMAP_EVENT_TYPES || (HEATMAP_EVENT_TYPES = {}));
var CSS_VARIABLE_TYPES;
(function (CSS_VARIABLE_TYPES) {
    CSS_VARIABLE_TYPES["SHARING_BUTTON"] = "share-button";
    // TODO: Add all used css variables
})(CSS_VARIABLE_TYPES || (CSS_VARIABLE_TYPES = {}));
var PRODUCT_GALLERY_ITEM;
(function (PRODUCT_GALLERY_ITEM) {
    PRODUCT_GALLERY_ITEM["VIDEO"] = "video";
    PRODUCT_GALLERY_ITEM["IMAGE"] = "image";
})(PRODUCT_GALLERY_ITEM || (PRODUCT_GALLERY_ITEM = {}));
var PRODUCT_VIDEO_THUMB_TYPE;
(function (PRODUCT_VIDEO_THUMB_TYPE) {
    PRODUCT_VIDEO_THUMB_TYPE["THUMB_PREV"] = "thumbPrev";
    PRODUCT_VIDEO_THUMB_TYPE["VIDEO_PREV"] = "videoPrev";
})(PRODUCT_VIDEO_THUMB_TYPE || (PRODUCT_VIDEO_THUMB_TYPE = {}));
var PRODUCT_VIDEO_PROVIDER;
(function (PRODUCT_VIDEO_PROVIDER) {
    PRODUCT_VIDEO_PROVIDER["HTML"] = "html-video";
    PRODUCT_VIDEO_PROVIDER["YOUTUBE"] = "youtube";
    PRODUCT_VIDEO_PROVIDER["VIMEO"] = "vimeo";
    PRODUCT_VIDEO_PROVIDER["DIRECT_LINK"] = "direct";
    PRODUCT_VIDEO_PROVIDER["CDN"] = "cdn";
})(PRODUCT_VIDEO_PROVIDER || (PRODUCT_VIDEO_PROVIDER = {}));
var DIRECTION;
(function (DIRECTION) {
    DIRECTION["LEFT"] = "left";
    DIRECTION["RIGHT"] = "right";
    DIRECTION["UP"] = "up";
    DIRECTION["DOWN"] = "down";
})(DIRECTION || (DIRECTION = {}));
var PAGE_LOADING_TYPES;
(function (PAGE_LOADING_TYPES) {
    PAGE_LOADING_TYPES["SPHERE"] = "sphere";
    PAGE_LOADING_TYPES["PRODUCT"] = "product";
    PAGE_LOADING_TYPES["CHECKOUT"] = "checkout";
})(PAGE_LOADING_TYPES || (PAGE_LOADING_TYPES = {}));
var ACCOUNT_OVERLAY_TYPE;
(function (ACCOUNT_OVERLAY_TYPE) {
    ACCOUNT_OVERLAY_TYPE["SIGN_IN"] = "sign-in";
    ACCOUNT_OVERLAY_TYPE["SIGN_UP"] = "sign-up";
    ACCOUNT_OVERLAY_TYPE["RESET_PASS"] = "reset-pass";
    ACCOUNT_OVERLAY_TYPE["EMAIL_SENT"] = "email-sent";
    ACCOUNT_OVERLAY_TYPE["PROFILE"] = "profile";
})(ACCOUNT_OVERLAY_TYPE || (ACCOUNT_OVERLAY_TYPE = {}));
var LAYOUT_SWITCHER_TYPE;
(function (LAYOUT_SWITCHER_TYPE) {
    LAYOUT_SWITCHER_TYPE["TEXT"] = "text";
    LAYOUT_SWITCHER_TYPE["ICON"] = "icon";
})(LAYOUT_SWITCHER_TYPE || (LAYOUT_SWITCHER_TYPE = {}));
var ECOMMERCE_BUTTON_TYPE;
(function (ECOMMERCE_BUTTON_TYPE) {
    ECOMMERCE_BUTTON_TYPE["USER_PROFILE"] = "user_profile";
    ECOMMERCE_BUTTON_TYPE["SHOPPING_CART"] = "shopping_cart";
})(ECOMMERCE_BUTTON_TYPE || (ECOMMERCE_BUTTON_TYPE = {}));
var COOKIES_SESSION_TYPE;
(function (COOKIES_SESSION_TYPE) {
    COOKIES_SESSION_TYPE["LAST_TIME_FROM_ACTION"] = "lastTimeFromAction";
})(COOKIES_SESSION_TYPE || (COOKIES_SESSION_TYPE = {}));
const ACCESS_TOKEN_KEY = 'accesstoken';
const CUSTOM_HEADER_KEY = 'x-lite';
const CURRENCY_CODE_KEY = 'selected-currency-code';
const SHOPIFY_MULTIPASS_TOKEN_KEY = 'multipass_token';
const CURRENCY_KEY = 'currency';
const ANIMATION_PATH_KEY = 'animationPath';
const SPHERE_BG_EVENT_NAME = 'sphere_background';
const CLUSTER_CAPTION_REGEX = /^(cluster-).+(-caption)$/g;
const CLUSTER_NAME_REGEX = /^(cluster-)(?:(?!-caption|caption).)+$/g;
const YOUTUBE_URL_REGEX = /(?:youtube\.com\/\S*(?:(?:\/e(?:mbed))?\/|watch\?(?:\S*?&?v\=))|youtu\.be\/)([a-zA-Z0-9_-]{6,11})/g;
const VIMEO_URL_REGEX = /^((?:https?:)?\/\/)?((?:www|m|player)\.)?((?:vimeo\.com))(?:$|\/|)(\S+)?$/;
export { CLIENT_NAMES, DEFAULT_CLIENT_NAME, PLANOGRAM_NAME, CUSTOM_OVERLAYS, SphereItemType as SPHERE_ITEM_TYPES, CLICK_EVENT_TYPES, HEATMAP_EVENT_TYPES, INFO_OVERLAYS, CSS_VARIABLE_TYPES, VIMEO_URL_REGEX, PRODUCT_GALLERY_ITEM, PRODUCT_VIDEO_PROVIDER, DIRECTION, CLUSTER_CAPTION_REGEX, CLUSTER_NAME_REGEX, YOUTUBE_URL_REGEX, BUTTONS_NAMES, PAGE_LOADING_TYPES, ACCOUNT_OVERLAY_TYPE, LAYOUT_SWITCHER_TYPE, ACCESS_TOKEN_KEY, FONT_UNIT_RATIO, DESIGNER_PLANOGRAM_HEIGHT, PRODUCT_VIDEO_THUMB_TYPE, CURRENCY_CODE_KEY, COOKIES_SESSION_TYPE, SHOPIFY_MULTIPASS_TOKEN_KEY, ECOMMERCE_BUTTON_TYPE, CURRENCY_KEY, ANIMATION_PATH_KEY, ITEM_TYPE_TO_URL_PREFIX_MAP, CUSTOM_HEADER_KEY, SPHERE_BG_EVENT_NAME };
