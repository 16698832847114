import { InputEventUtils } from '../utils/input_event_utils';
import { sphereEventHandler } from '../custom_event_utils';
import { SPHERE_EVENT_NAMES } from '../event-names';
const CLICK_MARGIN_OF_ERROR = 5;
const DOUBLE_TAP_DELAY = 200;
const SCALE_SPEED = 2e-3;
export class TouchControls {
    constructor(domElement, cameraControls, inputHandler) {
        this.domElement = domElement;
        this.cameraControls = cameraControls;
        this.inputHandler = inputHandler;
        this.lastTapTimestamp = 0;
        this.onTouchStart = (event) => {
            if (InputEventUtils.onSphereSurface(event)) {
                this.isDoubleTap = false;
                this.startPointer = InputEventUtils.getTouchPosition(event);
                this.previousPoint = this.startPointer.clone();
                if (event.timeStamp - this.lastTapTimestamp < DOUBLE_TAP_DELAY && event.touches.length === 1) {
                    this.isDoubleTap = true;
                    this.previousTouchDistance = 0;
                }
                this.lastTapTimestamp = event.timeStamp;
                if (event.touches.length > 1) {
                    this.previousTouchDistance = InputEventUtils.calculateTouchDistance(event.touches[0], event.touches[1]);
                    const pinchCenter = InputEventUtils.getPinchCentre(event.touches[0], event.touches[1]);
                    this.cameraControls.onMovementStart(pinchCenter);
                }
                else {
                    this.cameraControls.onMovementStart(this.startPointer);
                }
            }
            this.previousEvent = event;
        };
        this.onTouchStart = this.onTouchStart.bind(this);
        this.onTouchEnd = this.onTouchEnd.bind(this);
        this.onTouchMove = this.onTouchMove.bind(this);
        this.domElement.addEventListener('touchstart', this.onTouchStart, { passive: false });
        this.domElement.addEventListener('touchend', this.onTouchEnd, { capture: true });
        this.domElement.addEventListener('touchmove', this.onTouchMove, { passive: false });
    }
    onTouchMove(event) {
        if (!this.startPointer) {
            return;
        }
        if (this.previousEvent.touches.length !== event.touches.length) {
            this.onTouchStart(event);
            return;
        }
        if (event.cancelable) {
            event.preventDefault();
            event.stopPropagation();
        }
        this.cameraControls.clearAnimation();
        if (this.isDoubleTap) {
            this.doubleTapZoom(event);
        }
        else if (event.touches.length > 1) {
            this._pinch(event);
            this.pinched = true;
        }
        else {
            this.inputHandler.isClusterSelected = false;
            const latestPointer = InputEventUtils.getTouchPosition(event);
            this.cameraControls.tiltAndPanTo(latestPointer);
        }
        this.previousEvent = event;
    }
    onTouchEnd(event) {
        if (!this.startPointer) {
            return;
        }
        this.isDoubleTap = false;
        if (this._isTap(event)) {
            event.preventDefault();
            const position = InputEventUtils.getEndTouchPosition(event);
            sphereEventHandler.emit(SPHERE_EVENT_NAMES.CONTROL.TAP, event);
            this.inputHandler.handleClick(position.x, position.y);
        }
        else {
            event.stopPropagation();
        }
        if (event.touches.length === 0) {
            this.pinched = false;
            this.cameraControls.onMovementEnd();
            this.startPointer = undefined;
            this.previousEvent = undefined;
        }
    }
    doubleTapZoom(event) {
        const endPoint = InputEventUtils.getTouchPosition(event);
        const distance = SCALE_SPEED * (this.startPointer.y - endPoint.y);
        const diffX = this.previousPoint.x - endPoint.x;
        const diffY = this.previousPoint.y - endPoint.y;
        this.previousPoint = endPoint.clone();
        if (Math.abs(diffX) > Math.abs(diffY)) {
            return;
        }
        const zoomScaleFactor = Math.pow(2, (distance - this.previousTouchDistance));
        this.cameraControls.zoomToPoint(this.startPointer, zoomScaleFactor);
        this.previousTouchDistance = distance;
    }
    _pinch(event) {
        const currentTouchDistance = InputEventUtils.calculateTouchDistance(event.touches[0], event.touches[1]);
        const pinchCenter = InputEventUtils.getPinchCentre(event.touches[0], event.touches[1]);
        const zoomScaleFactor = this.previousTouchDistance / currentTouchDistance;
        this.cameraControls.zoomToPoint(pinchCenter, zoomScaleFactor);
        this.previousTouchDistance = currentTouchDistance;
        this.inputHandler.isClusterSelected = false;
    }
    _isTap(event) {
        if (this.pinched || event.touches.length > 0) {
            return false;
        }
        const currentPointer = InputEventUtils.getEndTouchPosition(event);
        const moveDistance = InputEventUtils.distance(this.startPointer, currentPointer);
        return moveDistance < CLICK_MARGIN_OF_ERROR;
    }
    dispose() {
        this.domElement.removeEventListener('touchstart', this.onTouchStart);
        this.domElement.removeEventListener('touchend', this.onTouchEnd, { capture: true });
        this.domElement.removeEventListener('touchmove', this.onTouchMove);
    }
}
