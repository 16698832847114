import { Vector2, Vector3 } from 'three';
import { assertDefined } from './debug';
const EPSILON = 1e-5;
const SEARCH_STEPS = 30;
export function sampleCircle(angle, radius) {
    return new Vector2(Math.cos(angle), Math.sin(angle)).multiplyScalar(radius);
}
/**
"Times" at which the ray `origin + times * direction` intersects a sphere with center at the origin
*/
export function intersectSphere(ray, radius) {
    // solve quadratic |origin + direction * t| = radius^2 for t
    const a = ray.direction.lengthSq();
    const b = 2 * ray.origin.dot(ray.direction);
    const c = ray.origin.lengthSq() - radius * radius;
    const delta = b * b - 4 * a * c;
    const result = [];
    if (delta === 0) {
        result.push(-b / (2 * a));
    }
    else {
        const sqrtDelta = Math.sqrt(delta);
        result.push((-b - sqrtDelta) / (2 * a));
        result.push((-b + sqrtDelta) / (2 * a));
    }
    return result.filter(t => t >= 0);
}
export class SphereShape {
    getEquatorRadius() {
        return this.equatorRadius;
    }
    constructor(switchAngle, shellCurvature, equatorLength) {
        this.switchAngle = switchAngle;
        this.equatorRadius = equatorLength / (Math.PI * 2);
        this.shellRadius = shellCurvature * this.equatorRadius;
        this.domeRadius =
            this.shellRadius - (this.shellRadius - this.equatorRadius) / Math.cos(switchAngle);
        const maxAngle = Math.PI * 0.5;
        this.switchEquatorDistance = this.switchAngle * this.shellRadius;
        this.maxEquatorDistance =
            this.switchEquatorDistance + (maxAngle - this.switchAngle) * this.domeRadius;
        this.domeHeight = Math.tan(this.switchAngle) * (this.shellRadius - this.equatorRadius);
        this.switchHeight = this.shellRadius * Math.sin(this.switchAngle);
        this.distanceEpsilon = EPSILON * equatorLength;
    }
    pointAngle(point) {
        const equatorDistance = Math.abs(point.y) * this.maxEquatorDistance;
        return (Math.sign(point.y) *
            (equatorDistance < this.switchEquatorDistance
                ? equatorDistance / this.shellRadius
                : (equatorDistance - this.switchEquatorDistance) / this.domeRadius + this.switchAngle));
    }
    radiusAtPoint(point) {
        const angle = Math.abs(this.pointAngle(point));
        if (angle < this.switchAngle) {
            return (Math.abs(sampleCircle(angle, this.shellRadius).x) - (this.shellRadius - this.equatorRadius));
        }
        else {
            return Math.abs(sampleCircle(angle, this.domeRadius).x);
        }
    }
    heightAtPoint(point) {
        const angle = this.pointAngle(point);
        if (Math.abs(angle) < this.switchAngle) {
            return Math.sin(angle) * this.shellRadius;
        }
        else {
            return Math.sin(angle) * this.domeRadius + Math.sign(angle) * this.domeHeight;
        }
    }
    // normal pointing inwards
    normalAt(point) {
        const angle = this.pointAngle(point);
        const vectorToZero = this.sample(point);
        if (Math.abs(angle) < this.switchAngle) {
            const shellCenter = sampleCircle((point.x + 1) * Math.PI, this.shellRadius - this.equatorRadius);
            vectorToZero.x -= shellCenter.x;
            vectorToZero.z -= shellCenter.y;
        }
        else {
            vectorToZero.y -= Math.sign(angle) * this.domeHeight;
        }
        return vectorToZero.multiplyScalar(-1).normalize();
    }
    get height() {
        return this.sample(new Vector2(0, 1)).y;
    }
    sample(point) {
        const radius = this.radiusAtPoint(point);
        const height = this.heightAtPoint(point);
        const circleSectionPoint = sampleCircle(point.x * Math.PI, radius);
        return new Vector3(circleSectionPoint.x, height, circleSectionPoint.y);
    }
    /**
    A Sphere point, which has the same height and angle in XZ plane as the sample.
    Returns undefined if the Sphere has no points at this height.
    */
    reverse(sample) {
        const result = new Vector2(0, 0);
        result.x = Math.atan2(sample.z, sample.x) / Math.PI;
        const height = Math.abs(sample.y);
        if (height < this.switchHeight) {
            const verticalSine = height / this.shellRadius;
            if (verticalSine < -1 || 1 < verticalSine) {
                return undefined;
            }
            const shellAngle = Math.asin(verticalSine);
            result.y = (Math.sign(sample.y) * shellAngle * this.shellRadius) / this.maxEquatorDistance;
        }
        else {
            const verticalSine = (height - this.domeHeight) / this.domeRadius;
            if (verticalSine < -1 || 1 < verticalSine) {
                return undefined;
            }
            const domeAngle = Math.asin(verticalSine);
            result.y =
                (Math.sign(sample.y) *
                    ((domeAngle - this.switchAngle) * this.domeRadius + this.switchEquatorDistance)) /
                    this.maxEquatorDistance;
        }
        return result;
    }
    equatorLength() {
        return this.equatorRadius * 2 * Math.PI;
    }
    meridianLength() {
        return this.maxEquatorDistance * 2;
    }
    planogramCoordinate(spherePoint) {
        const point = spherePoint.clone();
        point.x += 1.5;
        if (point.x > 1)
            point.x -= 2;
        point.x *= -1;
        const ratio = new Vector2(-this.equatorLength(), this.meridianLength()).divideScalar(2);
        return point.multiply(ratio);
    }
    // TODO: merge with planogramCoordinate
    planogramCoordinateViewer(point, planogramSize) {
        point = point.clone();
        point.x += 1.5;
        if (point.x > 1)
            point.x -= 2;
        point.x *= -1;
        const ratio = new Vector2(-this.equatorLength(), this.meridianLength()).divide(planogramSize);
        return point.multiply(ratio).addScalar(1).multiply(planogramSize).multiplyScalar(0.5);
    }
    fromPlanogramCoordinate(planogramPoint) {
        const ratio = new Vector2(-this.equatorLength(), this.meridianLength()).divideScalar(2);
        const point = planogramPoint.clone().divide(ratio);
        point.x *= -1;
        if (point.x < 0.5)
            point.x += 2;
        point.x -= 1.5;
        return point;
    }
    fromPlanogramCoordinateViewer(planogramPoint, planogramSize) {
        const ratio = new Vector2(-this.equatorLength(), this.meridianLength()).divide(planogramSize);
        const point = planogramPoint
            .clone()
            .divideScalar(0.5)
            .divide(planogramSize)
            .subScalar(1)
            .divide(ratio);
        point.x *= -1;
        if (point.x < 0.5)
            point.x += 2;
        point.x -= 1.5;
        return point;
    }
    /**
    Distance from the point to the Sphere's circle at the same height
    Returns 0 for poins on the Sphere, positive values for points outside and negative values for points inside
    */
    radialOffset(sample) {
        const point = this.reverse(sample);
        if (!point)
            return +Infinity;
        const radius = this.radiusAtPoint(point);
        const distanceToY = new Vector2(sample.x, sample.z).length();
        const offset = distanceToY - radius;
        const absoluteOffset = Math.abs(offset);
        if (absoluteOffset < this.distanceEpsilon) {
            return 0;
        }
        else {
            return Math.sign(offset) * (absoluteOffset - this.distanceEpsilon);
        }
    }
    binarySearchCast(ray, inner, outer) {
        let middle = 0;
        let absoluteOffset = 0;
        const point = new Vector3();
        for (let steps = SEARCH_STEPS; steps > 0; steps--) {
            middle = (inner + outer) * 0.5;
            ray.at(middle, point);
            const offset = this.radialOffset(point);
            absoluteOffset = Math.abs(offset);
            if (offset > 0) {
                outer = middle;
            }
            else if (offset < 0) {
                inner = middle;
            }
        }
        if (absoluteOffset < this.distanceEpsilon) {
            return middle;
        }
        else {
            return undefined;
        }
    }
    castRay(ray) {
        const boundingT = intersectSphere(ray, Math.max(this.equatorRadius, this.domeRadius + this.domeHeight));
        const result = [];
        if (boundingT.length === 2) {
            const tMiddle = (-1 * ray.direction.dot(ray.origin)) / ray.direction.lengthSq();
            // handle the case when the ray is touching the side of the Sphere
            if (Math.abs(this.radialOffset(ray.at(tMiddle, new Vector3()))) < this.distanceEpsilon) {
                result.push(tMiddle);
                return result;
            }
            // two cases for the nearest and farthest intersections
            const nearest = this.binarySearchCast(ray, tMiddle, boundingT[0]);
            if (nearest) {
                result.push(nearest);
            }
            const farthest = this.binarySearchCast(ray, tMiddle, boundingT[1]);
            if (farthest) {
                result.push(farthest);
            }
            if (result.length === 2 && Math.abs(result[0] - result[1]) < EPSILON) {
                result.pop();
            }
        }
        else if (boundingT.length === 1) {
            // the bounding intersection might be exactly on the sphere
            // doubling it guarantees that it is outside, at the cost of 1 binary search iteration
            const intersection = this.binarySearchCast(ray, 0, 2 * boundingT[0]);
            if (intersection) {
                result.push(intersection);
            }
        }
        return result;
    }
    castRayNearest(ray) {
        return this.castRay(ray)[0];
    }
    castRayFarthest(ray) {
        const intersections = this.castRay(ray);
        return intersections[intersections.length - 1];
    }
    castRayFarthestPoint(ray, output) {
        const t = this.castRayFarthest(ray);
        if (t === undefined)
            throw new Error('No intersection');
        ray.at(t, output);
        return output;
    }
    horizonLinePlanogramHeight(horizonLine) {
        const horizonY = horizonLine * this.getEquatorRadius();
        const spherePoint = this.reverse(new Vector3(0, horizonY, 0));
        assertDefined(spherePoint, 'horizon line is on planogram');
        return this.planogramCoordinate(spherePoint).y;
    }
}
