import pipe from 'callbag-pipe';
import subscribe from 'callbag-subscribe';
import { shoppingCartPanelView } from './shopping-cart-panel.view';
import { shoppingCartButtonView } from './shopping-cart-button.view';
import { ShoppingCartService } from '../api/services/shopping-cart.service';
import { ShoppingCartItems } from './shopping-cart-items';
import { WebUtils } from '../utils/web_utils';
import { InputEventUtils } from '../utils/input_event_utils';
import { Metrics } from '../metrics';
import { MATOMO_EVENT_NAMES } from '../metric-events';
import { CurrencyUtils } from '../utils/currency_utils';
import { FontLoader } from '../font_loader';
import { AppUtils } from '../utils/app_utils';
import { AppState } from '../shared/app.state';
import { L10nUtils } from '../utils/l10n_utils';
import { OverlayScrollbar } from '../components/overlay_scrollbar';
import { appendHtml } from '../utils/html_utils';
import { UrlUtils } from '../api/urls';
import { shopifyOverlayHandler, sphereEventHandler } from '../custom_event_utils';
import { ECOMMERCE_BUTTON_TYPE } from '../shared/constants';
import { SPHERE_EVENT_NAMES } from '../event-names';
export class ShoppingCartComponent {
    get panelVisibility() {
        return this.panelElement.classList.contains('is-shopping-cart-panel-active');
    }
    set panelVisibility(value) {
        if (value === true) {
            this.panelElement.classList.add('is-shopping-cart-panel-active');
        }
        else if (value === false) {
            this.panelElement.classList.remove('is-shopping-cart-panel-active');
        }
        else {
            console.warn('Shopping Cart Button > incorrect value ' + value);
        }
        shopifyOverlayHandler.emit('shoppingCartVisibility', { value });
    }
    get panel() {
        return this.panelElement;
    }
    get button() {
        return this.buttonElement;
    }
    get hasItemsWithError() {
        return document.querySelector('.app-shopping-cart-item.limit-error');
    }
    constructor(planogram, shoppingCartService, currencyService, redirectToProduct, quantityPicker, wrapperSelector) {
        this.planogram = planogram;
        this.shoppingCartService = shoppingCartService;
        this.currencyService = currencyService;
        this.redirectToProduct = redirectToProduct;
        this.quantityPicker = quantityPicker;
        this.wrapperSelector = wrapperSelector;
        this.activeLoadings = 0;
        this.initView();
        this.handleClicks();
        this.checkoutSubjectRef = pipe(this.shoppingCartService.checkoutSubject, subscribe({
            next: (checkout) => {
                if ((checkout &&
                    checkout.currency_code !== this.currencyService.selectedCurrencyCode &&
                    !ShoppingCartService.isProcessing) ||
                    (checkout && checkout.language_code !== L10nUtils.getCurrentLanguage())) {
                    this.shoppingCartService.updateShoppingCartCurrency(this.currencyService.selectedCurrencyCode);
                }
                this.getQuantityBadgeProducts(checkout === null || checkout === void 0 ? void 0 : checkout.products);
                this.updateShoppingCartState(checkout);
                this.updateSubtotalPrice(checkout === null || checkout === void 0 ? void 0 : checkout.subtotal_price);
            },
            error: err => {
                this.getQuantityBadgeProducts(null);
                this.updateShoppingCartState(null, err);
                this.updateSubtotalPrice(ShoppingCartComponent.defaultSubtotalPrice);
            }
        }));
        this.currencySubjectRef = pipe(this.currencyService.changeCurrencySubject, subscribe({
            next: (currency) => {
                if (this.checkout &&
                    this.checkout.currency_code !== this.currencyService.selectedCurrencyCode &&
                    !ShoppingCartService.isProcessing) {
                    this.shoppingCartService.updateShoppingCartCurrency(currency);
                }
                else if (!this.checkout) {
                    this.updateSubtotalPrice(ShoppingCartComponent.defaultSubtotalPrice);
                }
            }
        }));
        this.loaderSubjectRef = pipe(this.shoppingCartService.processingSubject, subscribe({
            next: (loading) => {
                this.showLoader(loading);
            }
        }));
        this.overlayScroll = new OverlayScrollbar('.app-shopping-cart-items-wrapper');
    }
    initView() {
        this.overlaySettings = this.planogram.ecommerceOverlaySettingsShoppingCart;
        this.badgeSettings = this.planogram.ecommerceOverlaySettingsButtons;
        this.buttonElement = document.createElement('div');
        appendHtml(this.buttonElement, shoppingCartButtonView());
        this.scButtonElement = this.buttonElement.querySelector('.app-shopping-cart-button-wrapper');
        this.panelElement = document.createElement('div');
        const isHarryPotterClient = AppUtils.isHarryPotterClient(this.planogram, this.planogram.clientName);
        appendHtml(this.panelElement, shoppingCartPanelView(this.overlaySettings, this.shoppingCartService.isMultipassEnabled, isHarryPotterClient));
        document.getElementById('shopping-cart-panel').append(this.panel);
        this.subtotalPriceElement = this.panelElement.querySelector('.app-shopping-cart-subtotal-value');
        this.scItemsWrapper = this.panelElement.querySelector('.app-shopping-cart-items-wrapper');
        this.scCheckoutButton = this.panelElement.querySelector('.app-shopping-cart-checkout-button');
        this.backdropWrapper = this.panelElement.querySelector('.app-shopping-cart-panel-backdrop');
        this.scPanelContainer = this.panelElement.querySelector('.app-shopping-cart-panel-container');
        this.shoppingCartItems = new ShoppingCartItems(this.panelElement, this.shoppingCartService, this.currencyService, (productLink) => this.navigateToProduct(productLink));
        this.setOverlayFonts();
        this.setColorAndButtonSettings(this.overlaySettings, 'ecommerce-shopping-cart');
        this.setColorAndButtonSettings(this.badgeSettings, 'ecommerce-buttons');
    }
    getQuantityBadgeProducts(checkout) {
        const productsQuantity = (checkout === null || checkout === void 0 ? void 0 : checkout.length) ? checkout === null || checkout === void 0 ? void 0 : checkout.reduce((sum, product) => sum + product.quantity, 0) : null;
        const badgeCounter = this.scButtonElement.querySelector('#badge-counter');
        badgeCounter.classList.remove('app-shopping-cart-control-button-badge-counter');
        if (productsQuantity) {
            badgeCounter.classList.add('app-shopping-cart-control-button-badge-counter');
            badgeCounter.innerText = productsQuantity > 9 ? '9+' : `${productsQuantity}`;
        }
    }
    showLoader(isActive = false) {
        var _a, _b;
        isActive ? this.activeLoadings++ : this.activeLoadings--;
        if (this.activeLoadings <= 0) {
            (_a = this.scButtonElement) === null || _a === void 0 ? void 0 : _a.classList.remove('loading');
            this.scPanelContainer.classList.remove('loading');
            this.activeLoadings = 0;
        }
        else {
            (_b = this.scButtonElement) === null || _b === void 0 ? void 0 : _b.classList.add('loading');
            this.scPanelContainer.classList.add('loading');
        }
    }
    setOverlayFonts() {
        var _a;
        (_a = this.overlaySettings) === null || _a === void 0 ? void 0 : _a.ecommerce_fonts.forEach(font => {
            const fontSettings = Object.assign(Object.assign({}, font), { assignment: `ecommerce-shopping-cart-${font.assignment}` });
            FontLoader.mountCustomFont(fontSettings);
        });
    }
    setColorAndButtonSettings(settings, assignment) {
        var _a, _b;
        const root = document.documentElement;
        if (settings === null || settings === void 0 ? void 0 : settings.background_color) {
            const primaryColorRgb = AppUtils.hex2rgb(settings.background_color);
            root.style.setProperty(`--${assignment}-primary-color`, `${settings.background_color}`);
            root.style.setProperty(`--${assignment}-primary-color-rgb`, `${primaryColorRgb}`);
        }
        if (settings === null || settings === void 0 ? void 0 : settings.secondary_color) {
            const secondaryColorRgb = AppUtils.hex2rgb(settings.secondary_color);
            root.style.setProperty(`--${assignment}-secondary-color`, `${settings.secondary_color}`);
            root.style.setProperty(`--${assignment}-secondary-color-rgb`, `${secondaryColorRgb}`);
        }
        if (settings === null || settings === void 0 ? void 0 : settings.controls_color) {
            const controlsColorRgb = AppUtils.hex2rgb(settings.controls_color);
            root.style.setProperty(`--${assignment}-controls-color`, `${settings.controls_color}`);
            root.style.setProperty(`--${assignment}-controls-color-rgb`, `${controlsColorRgb}`);
        }
        if ((_a = settings === null || settings === void 0 ? void 0 : settings.ecommerce_buttons) === null || _a === void 0 ? void 0 : _a.length) {
            settings === null || settings === void 0 ? void 0 : settings.ecommerce_buttons.forEach(button => {
                if (button.color) {
                    root.style.setProperty(`--${assignment}-${button.button_type}-button-color`, `${button.color}`);
                }
                if (button.color_disabled) {
                    root.style.setProperty(`--${assignment}-${button.button_type}-button-color-disabled`, `${button.color_disabled}`);
                }
                if (button.use_border) {
                    root.style.setProperty(`--${assignment}-${button.button_type}-button-border-color`, `${button.border_color}`);
                    root.style.setProperty(`--${assignment}-${button.button_type}-button-border-width`, `${button.border_width.replace(/px/gi, '')}px`);
                }
                if (button.use_border_disabled) {
                    root.style.setProperty(`--${assignment}-${button.button_type}-button-border-color-disabled`, `${button.border_color_disabled}`);
                    root.style.setProperty(`--${assignment}-${button.button_type}-button-border-width-disabled`, `${button.border_width_disabled.replace(/px/gi, '')}px`);
                }
            });
        }
        if ((_b = settings === null || settings === void 0 ? void 0 : settings.ecommerce_icon_buttons) === null || _b === void 0 ? void 0 : _b.length) {
            settings === null || settings === void 0 ? void 0 : settings.ecommerce_icon_buttons.forEach(button => {
                var _a, _b;
                if (button.color) {
                    root.style.setProperty(`--${assignment}-${button.button_type}-button-color`, `${button.color}`);
                }
                if (button.button_type === ECOMMERCE_BUTTON_TYPE.SHOPPING_CART && ((_a = button.other_asset) === null || _a === void 0 ? void 0 : _a.url)) {
                    const imgElem = document.createElement('img');
                    imgElem.onerror = () => {
                        this.scButtonElement.querySelector('img').remove();
                    };
                    imgElem.setAttribute('src', (_b = button.other_asset) === null || _b === void 0 ? void 0 : _b.url);
                    this.scButtonElement.appendChild(imgElem);
                }
            });
        }
    }
    handleClicks() {
        if (this.scButtonElement) {
            InputEventUtils.addSelectEvents(this.scButtonElement, () => {
                Metrics.storeTheEvent(AppState.planogramName, 'open', MATOMO_EVENT_NAMES.OPEN_SHOPPING_CART);
                this.panelVisibility = true;
                sphereEventHandler.emit(SPHERE_EVENT_NAMES.CART.OPEN);
            });
        }
        InputEventUtils.addSelectEvents(this.scCheckoutButton, () => {
            var _a;
            if (!this.scCheckoutButton.classList.contains('app-disabled')) {
                Metrics.storeTheEvent(AppState.planogramName, 'click', MATOMO_EVENT_NAMES.CLICK_SHOPPING_CART_OVERLAY_CHECKOUT_BUTTON);
                const checkoutUrl = UrlUtils.getUrlWithQueries((_a = this.checkout) === null || _a === void 0 ? void 0 : _a.checkout_url, {
                    locale: L10nUtils.getCurrentLanguage(),
                    planogram_id: this.planogram.id.toString()
                });
                if (this.shoppingCartService.getMultipassToken) {
                    const link = window.open();
                    this.scCheckoutButton.classList.add('loading');
                    this.shoppingCartService
                        .generateMultipassLink(checkoutUrl)
                        .then(resp => (link.location = resp === null || resp === void 0 ? void 0 : resp.link))
                        .catch(() => (link.location = checkoutUrl))
                        .finally(() => this.scCheckoutButton.classList.remove('loading'));
                }
                else {
                    WebUtils.openLink(checkoutUrl);
                }
            }
        });
        const closeButton = this.panelElement.querySelector('.app-shopping-cart-close-button');
        InputEventUtils.addSelectEvents(closeButton, () => {
            Metrics.storeTheEvent(AppState.planogramName, 'click', MATOMO_EVENT_NAMES.CLICK_SHOPPING_CART_OVERLAY_CLOSE_BUTTON);
            this.panelVisibility = false;
            sphereEventHandler.emit(SPHERE_EVENT_NAMES.CART.CLOSE);
        });
        InputEventUtils.addSelectEvents(this.backdropWrapper, () => {
            this.panelVisibility = false;
            sphereEventHandler.emit(SPHERE_EVENT_NAMES.CART.CLOSE);
        });
    }
    updateShoppingCartState(checkout, err) {
        var _a, _b, _c;
        this.checkout = checkout;
        this.updateCheckoutButtonState();
        if ((_a = checkout === null || checkout === void 0 ? void 0 : checkout.products) === null || _a === void 0 ? void 0 : _a.length) {
            (_b = this.scItemsWrapper) === null || _b === void 0 ? void 0 : _b.classList.remove('empty');
        }
        else {
            (_c = this.scItemsWrapper) === null || _c === void 0 ? void 0 : _c.classList.add('empty');
        }
    }
    navigateToProduct(productLink) {
        this.panelVisibility = false;
        this.redirectToProduct(productLink);
    }
    updateCheckoutButtonState() {
        var _a, _b;
        const isCheckoutAvailable = (_b = (_a = this.checkout) === null || _a === void 0 ? void 0 : _a.products) === null || _b === void 0 ? void 0 : _b.some((product) => {
            return product.quantity_available > 0;
        });
        if (!isCheckoutAvailable || this.hasItemsWithError) {
            this.scCheckoutButton.classList.add('app-disabled');
        }
        else {
            this.scCheckoutButton.classList.remove('app-disabled');
        }
    }
    updateSubtotalPrice(subtotalPrice) {
        const { selectedCurrencyCode, currenciesList } = this.currencyService;
        this.subtotalPriceElement.innerHTML = CurrencyUtils.getPriceWithSymbolOrCode(subtotalPrice, selectedCurrencyCode, currenciesList);
    }
    dispose() {
        var _a, _b;
        (_a = this.checkoutSubjectRef) === null || _a === void 0 ? void 0 : _a.call(this);
        (_b = this.currencySubjectRef) === null || _b === void 0 ? void 0 : _b.call(this);
        this.loaderSubjectRef();
    }
}
ShoppingCartComponent.defaultSubtotalPrice = '0.00';
