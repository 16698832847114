import { CDN_RESOURCES, URLS, UrlUtils } from '../../api/urls';
import { L10nUtils } from '../../utils/l10n_utils';
import { AppUtils } from '../../utils/app_utils';
export const shoppingCartItemComponentView = (product, productPrice) => {
    var _a, _b;
    return `
    <div class="app-shopping-cart-item-image">
      <img src="${product.image ? pickSuitableImage(product.image.variants) : URLS.OVERLAY_GALLERY_IMAGE_FALLBACK}" alt="">
    </div>
    <div class="app-shopping-cart-item-info">
      <div class="app-shopping-cart-item-title ${product.sphere_name && product.product_id || product.store_url ? 'has-clickable-title' : ''}">${(_a = product.title) !== null && _a !== void 0 ? _a : product.name}</div>
      <div class="app-shopping-cart-item-options ${product.option1 && product.option1 !== 'Default Title' ? '' : 'is-hidden'}">
        ${product.option1 && product.option1 !== 'Default Title'
        ? `<span class="app-shopping-cart-item-option">${product.option1}</span>`
        : ''}
        ${product.option2 ? `<span class="app-shopping-cart-item-option">${product.option2}</span>` : ''}
        ${product.option3 ? `<span class="app-shopping-cart-item-option">${product.option3}</span>` : ''}
      </div>
      ${((_b = product.custom_attributes) === null || _b === void 0 ? void 0 : _b.length) ? renderCustomAttributes(product) : ''}
      <div class="app-shopping-cart-item-quantity-controls">
        <div class="app-shopping-cart-item-quantity-picker input-field-border">
          <div class="left-border">
            <div class="square"></div>
          </div>
          <div class="right-border">
            <div class="square"></div>
          </div>
          <div class="app-shopping-cart-item-quantity-button app-shopping-cart-item-decrease disabled">
            <span></span>
          </div>
          <div class="app-shopping-cart-item-quantity-value">${product.quantity}</div>
          <div class="app-shopping-cart-item-quantity-button app-shopping-cart-item-increase">
            <span></span>
          </div>
        </div>
        <div class="app-shopping-cart-item-price">
          <span>${productPrice}</span>
        </div>
      </div>
    </div>
    <div class="app-shopping-cart-item-controls">
      <div class="app-shopping-cart-item-delete">
        <span></span>
      </div>
    </div>
    <div class="app-shopping-cart-item-error-notification">
      <span>${L10nUtils.l10n('shopping-cart.item.out-of-stock')}</span>
    </div>
    <div class="app-shopping-cart-item-limit-notification">
      <span>${L10nUtils.l10n('shopping-cart.item.limitation-error')}</span>
    </div>
  `;
};
function renderCustomAttributes(product) {
    const customAttributesContainer = document.createElement('div');
    const customAttributes = document.createDocumentFragment();
    customAttributesContainer.classList.add('app-shopping-cart-item-custom-attributes');
    product.custom_attributes.forEach(el => {
        if (el.key.startsWith('_')) {
            return;
        }
        const item = document.createElement('div');
        item.classList.add('app-shopping-cart-item-custom-attribute');
        if (AppUtils.isImage(el.value)) {
            const link = document.createElement('a');
            const borderWrapper = document.createElement('div');
            const leftBorder = document.createElement('div');
            const leftSquareBorder = document.createElement('div');
            const rightBorder = document.createElement('div');
            const rightBorderSquare = document.createElement('div');
            borderWrapper.classList.add('btn-border');
            leftBorder.classList.add('left-border');
            rightBorder.classList.add('right-border');
            leftSquareBorder.classList.add('square');
            rightBorderSquare.classList.add('square');
            borderWrapper.appendChild(leftBorder);
            leftBorder.appendChild(leftSquareBorder);
            borderWrapper.appendChild(rightBorder);
            rightBorder.appendChild(rightBorderSquare);
            link.href = el.value;
            link.setAttribute('target', '_blank');
            link.innerText = 'Preview';
            item.classList.add('image-preview', 'hp-custom-button');
            item.appendChild(borderWrapper);
            item.appendChild(link);
        }
        else {
            const title = el.key.replace(/\*$/, '');
            item.innerHTML = `<span class="app-shopping-cart-item-custom-attribute-title">${title}:</span> ${el.value}`;
        }
        customAttributes.appendChild(item);
    });
    customAttributesContainer.appendChild(customAttributes);
    return customAttributesContainer.outerHTML;
}
function pickSuitableImage(variants) {
    const imgPath = variants.VARIANT_SMALL_WEBP;
    return imgPath ?
        UrlUtils.insertValueToUrl(CDN_RESOURCES.PRODUCT_IMAGE, imgPath) :
        URLS.OVERLAY_GALLERY_IMAGE_FALLBACK;
}
