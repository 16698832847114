import { InputEventUtils } from '../utils/input_event_utils';
import { OverlayScrollbar } from '../components/overlay_scrollbar';
import { AppUtils } from '../utils/app_utils';
import { FullScreenButton } from '../components/overlay-fullscreen-mode';
export class SocialContentOverlay {
    constructor(container, embedCode, showLoader, options, closeCallback) {
        var _a;
        this.embedCode = embedCode;
        this.showLoader = showLoader;
        this.options = options;
        this.closeCallback = closeCallback;
        this.container = container;
        this.container.innerHTML = '';
        const root = document.createElement('div');
        root.classList.add('iframe-overlay', 'overlay-container', 'is-flex', 'is-vertical', 'is-vertically-aligned-center', 'is-horizontally-aligned-center', 'social-media-overlay', 'x');
        this.container.appendChild(root);
        const closeButton = document.createElement('div');
        closeButton.classList.add('overlay-button', 'overlay-button-close', 'will-close');
        closeButton.appendChild(document.createElement('span'));
        closeButton.addEventListener('click', this.closeOverlay.bind(this));
        const fullscreenButtonWrapper = document.createElement('div');
        fullscreenButtonWrapper.classList.add('iframe-fullscreen-button');
        const overlayBackDrop = document.createElement('div');
        overlayBackDrop.classList.add('overlay-backdrop');
        root.appendChild(overlayBackDrop);
        root.appendChild(closeButton);
        root.appendChild(fullscreenButtonWrapper);
        const holder = document.createElement('div');
        holder.classList.add('iframe-holder');
        root.appendChild(holder);
        if (this.showLoader) {
            root.appendChild(AppUtils.showLoader());
        }
        holder.innerHTML = embedCode;
        this.executeScriptElements(holder);
        this.overlayScrollbar = new OverlayScrollbar('.iframe-holder', { sizeAutoCapable: false });
        if (closeButton) {
            InputEventUtils.addSelectEvents(closeButton, this.closeOverlay.bind(this));
        }
        else {
            console.error('element not found - ".overlay-button-close"');
        }
        if ((_a = this.options) === null || _a === void 0 ? void 0 : _a.fullscreen) {
            const fullscreenButtonContainer = this.container.querySelector('.iframe-fullscreen-button');
            this.fullscreenButton = new FullScreenButton(fullscreenButtonContainer);
        }
        const observer = new MutationObserver(records => {
            for (const record of records) {
                for (const node of record.addedNodes) {
                    const iframe = AppUtils.isHtmlElement(node) && node.querySelector('iframe');
                    if (iframe) {
                        iframe.onload = () => {
                            AppUtils.removeLoader();
                        };
                    }
                    else {
                        AppUtils.removeLoader();
                    }
                    observer.disconnect();
                }
            }
        });
        observer.observe(root, { childList: true, subtree: true });
    }
    executeScriptElements(containerElement) {
        const scriptElements = containerElement.querySelectorAll('script');
        Array.from(scriptElements).forEach((scriptElement) => {
            const clonedElement = document.createElement('script');
            Array.from(scriptElement.attributes).forEach((attribute) => {
                clonedElement.setAttribute(attribute.name, attribute.value);
            });
            clonedElement.text = scriptElement.text;
            scriptElement.parentNode.replaceChild(clonedElement, scriptElement);
        });
    }
    closeOverlay(e) {
        if (e) {
            e.stopPropagation();
        }
        if (this.closeCallback) {
            this.closeCallback();
        }
        else {
            console.error('"Close" function is not set up');
        }
    }
    handleClick(e) {
        return e.target.classList.contains('iframe-overlay');
    }
}
