import { FontLoader } from '../font_loader';
export class SearchNoResults {
    constructor() {
        this.initNoResults();
    }
    initNoResults() {
        this.mainContainer = document.getElementById('search-results-container');
        const noResultElement = document.createElement('div');
        const noResultsHtml = this.applySettings();
        if (noResultsHtml) {
            this.mainContainer.append(noResultElement);
            noResultElement.outerHTML = noResultsHtml;
        }
    }
    applySettings() {
        var _a, _b, _c, _d, _e;
        if (!this.searchSettings) {
            return;
        }
        this.clearNoResults();
        if ((_a = this.searchSettings.search_setting) === null || _a === void 0 ? void 0 : _a.no_result_search_setting_font) {
            FontLoader.mountCustomFonts([this.searchSettings.search_setting.no_result_search_setting_font]);
        }
        return `
    <div class="no-results ${!((_b = this.searchSettings.search_setting) === null || _b === void 0 ? void 0 : _b.no_result_search_title) ? 'without-title' : ''}">
      ${((_c = this.searchSettings.search_setting.no_result_image) === null || _c === void 0 ? void 0 : _c.url)
            ? `<img src="${this.searchSettings.search_setting.no_result_image.url}" aria-hidden="true" alt="no results icon">`
            : ''}
      ${((_d = this.searchSettings.search_setting) === null || _d === void 0 ? void 0 : _d.no_result_search_title)
            ? `<span>${(_e = this.searchSettings.search_setting) === null || _e === void 0 ? void 0 : _e.no_result_search_title}</span>`
            : ''}
    </div>
    `;
    }
    clearNoResults() {
        const noResultsContainer = document.querySelector('.no-results');
        noResultsContainer === null || noResultsContainer === void 0 ? void 0 : noResultsContainer.remove();
    }
    updateSearchSettings(settings) {
        this.searchSettings = settings;
        this.initNoResults();
    }
}
