var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import pipe from 'callbag-pipe';
import subscribe from 'callbag-subscribe';
import { OutOfStockComponentView } from './out-of-stock-component.view';
import { BrowserUtils } from '../../../../../utils/browser_utils';
import { CDN_RESOURCES, UrlUtils } from '../../../../../api/urls';
import { OutOfStockService } from '../../../../../api/services/out-of-stock.service';
import { Account } from '../../../../../account/account';
import { Metrics } from '../../../../../metrics';
import { MATOMO_EVENT_NAMES } from '../../../../../metric-events';
export class OutOfStockComponent {
    constructor(planogram, mainContainer, product, shopifyService, shoppingCartService) {
        this.planogram = planogram;
        this.mainContainer = mainContainer;
        this.product = product;
        this.shopifyService = shopifyService;
        this.shoppingCartService = shoppingCartService;
        this.isAnonymous = true;
        this.outOfStockService = new OutOfStockService();
        this.selectedProductSubjectRef = pipe(this.shopifyService.selectedProductSubject, subscribe({
            next: (variant) => {
                if (variant) {
                    this.variant = variant;
                    this.renderComponent();
                }
            }
        }));
    }
    renderComponent() {
        const outOfStockContainer = this.mainContainer.querySelector('.app-ecommerce-out-of-stock-container');
        outOfStockContainer.innerHTML = '';
        if (this.variant.inventory_quantity || this.variant.isAutoSelected) {
            return;
        }
        const outOfStock = document.createElement('div');
        outOfStockContainer.append(outOfStock);
        outOfStock.outerHTML = OutOfStockComponentView(this.product);
        this.mainContentContainer = outOfStockContainer.querySelector('.app-shopify-out-of-stock-form');
        this.responseContainer = outOfStockContainer.querySelector('.app-shopify-out-of-stock-response');
        this.agreementContainer = outOfStockContainer.querySelector('.app-shopify-out-of-stock-agreement');
        this.inputFieldWrapper = outOfStockContainer.querySelector('.input-field-wrapper');
        this.inputField = outOfStockContainer.querySelector('#out-of-stock-email');
        this.notifyMeButton = outOfStockContainer.querySelector('#notify-me-button');
        this.notifyMeButton.onclick = () => this.handleButtonClicks();
    }
    handleButtonClicks() {
        return __awaiter(this, void 0, void 0, function* () {
            var _a, _b;
            if (this.notifyMeButton.classList.contains('loading')) {
                return;
            }
            if (this.shoppingCartService.getMultipassToken || Account.isLogged) {
                this.isAnonymous = false;
            }
            if (this.isAnonymous && this.inputFieldWrapper.classList.contains('is-hidden')) {
                this.inputFieldWrapper.classList.remove('is-hidden');
                this.agreementContainer.classList.remove('is-hidden');
                return;
            }
            if (this.isAnonymous && !this.inputField.reportValidity()) {
                return;
            }
            Metrics.storeTheEvent(this.planogram.name, 'click', MATOMO_EVENT_NAMES.CLICK_ECOMMERCE_OVERLAY_NOTIFY_ME_BUTTON((_a = this.product) === null || _a === void 0 ? void 0 : _a.identifier, UrlUtils.slugify((_b = this.product) === null || _b === void 0 ? void 0 : _b.name)));
        });
    }
    getImageUrl() {
        const image = this.product.product_images.find(img => {
            return img.shopify_id === this.variant.image_id || img.id === this.variant.image_id;
        }) || this.product.product_images[0];
        const path = BrowserUtils.pickImageVariant(image.variants, false, true);
        return UrlUtils.insertValueToUrl(CDN_RESOURCES.PRODUCT_IMAGE, path);
    }
    dispose() {
        this.selectedProductSubjectRef();
    }
}
