import { InputFieldView } from '../../components/input-field/input-field-component.view';
export const InputFieldsSeekerTShirtComponentView = (product) => {
    const field = InputFieldView({
        label: product.input_fields[0].identifier,
        helper: 'Max letters (XS:7) (S,M:8) (L,XL,XXL:9)',
        maxlength: 8
    });
    return `
    <div class="input-field-wrapper">${field}</div>
  `;
};
