import Router from '../router';
import { URLS, UrlUtils } from '../api/urls';
import { AppState } from '../shared/app.state';
export class WebUtils {
    static getCookieDomain() {
        let cookieDomain = ''; // default value for domain means it would be same full domain
        // Set cookie on domain level for non-sphere domains
        if (AppState.isExternalDomain && window.cookieCustomDomain !== 'COOKIE_CUSTOM_DOMAIN') {
            cookieDomain = window.cookieCustomDomain;
        }
        return cookieDomain;
    }
    static openLink(url) {
        var _a;
        (_a = window.open(url, '_blank')) === null || _a === void 0 ? void 0 : _a.focus();
    }
    static redirectToSpecificUrl(url) {
        window.open(url, '_self');
    }
    static applyConfiguredNavigation(configs) {
        if (!configs) {
            return;
        }
        const type = configs.navigation_type;
        if (type === 'url') {
            const url = configs.navigation_value;
            if (!url) {
                console.error('Incorrect value in navigation for click on active item', url);
                return;
            }
            if (configs.open_in_new_page) {
                WebUtils.openLink(url);
            }
            else {
                WebUtils.redirectToSpecificUrl(url);
            }
        }
        else if (type === 'planogram') {
            const name = configs.navigation_value;
            const lang = configs.language_code;
            if (!name) {
                console.error('Incorrect value in navigation for click on active item', name);
                return;
            }
            if (configs.open_in_new_page) {
                WebUtils.openLink(UrlUtils.insertValueToUrl(URLS.SPHERE_URL, `${lang}/${name}`));
            }
            else {
                Router.navigateToPlanogramWithLang(name, lang);
            }
        }
        else {
            console.error('Unexpected type of navigation for click on active item', type);
        }
    }
    static handleTextFadeOnScroll(element, scrollData) {
        var _a, _b, _c;
        const disappearingFadeGap = 2;
        const textFadeElements = (_a = element.parentNode) === null || _a === void 0 ? void 0 : _a.querySelectorAll('.text-fade');
        let textFadeTop;
        let textFadeBottom;
        if ((textFadeElements === null || textFadeElements === void 0 ? void 0 : textFadeElements.length) === 2) {
            textFadeTop = textFadeElements[0];
            textFadeBottom = textFadeElements[1];
        }
        else if ((textFadeElements === null || textFadeElements === void 0 ? void 0 : textFadeElements.length) === 1) {
            textFadeBottom = textFadeElements[0];
        }
        else {
            return;
        }
        if (textFadeTop) {
            if (scrollData.position.y === 0) {
                textFadeTop.classList.add('is-hidden');
            }
            else {
                textFadeTop.classList.remove('is-hidden');
            }
        }
        if (textFadeBottom) {
            if (((_b = scrollData === null || scrollData === void 0 ? void 0 : scrollData.position) === null || _b === void 0 ? void 0 : _b.y) + disappearingFadeGap >= ((_c = scrollData === null || scrollData === void 0 ? void 0 : scrollData.max) === null || _c === void 0 ? void 0 : _c.y)) {
                textFadeBottom.classList.add('is-hidden');
            }
            else {
                textFadeBottom.classList.remove('is-hidden');
            }
        }
    }
    static isScrolledToTheEnd(target, startY, endY) {
        return ((target.offsetHeight + target.scrollTop === target.scrollHeight && startY > endY) ||
            (target.scrollTop === 0 && endY > startY));
    }
    static scrollTo(element, offset, fullWidthDuration = 500) {
        const start = element.scrollLeft;
        const change = offset - start;
        const duration = Math.abs((change / element.clientWidth) * fullWidthDuration);
        const startDate = Date.now();
        // t = current time
        // b = start value
        // c = change in value
        // d = duration
        /* eslint-disable no-param-reassign,no-mixed-operators */
        const easeInOutQuad = (t, b, c, d) => {
            t /= d / 2;
            if (t < 1) {
                return (c / 2) * t * t + b;
            }
            t--;
            return (-c / 2) * (t * (t - 2) - 1) + b;
        };
        /* eslint-enable no-param-reassign,no-mixed-operators */
        const animateScroll = () => {
            const currentDate = Date.now();
            const currentTime = currentDate - startDate;
            element.scrollLeft = parseInt(easeInOutQuad(currentTime, start, change, duration), 10);
            if (currentTime < duration) {
                requestAnimationFrame(animateScroll);
            }
            else {
                element.scrollLeft = offset;
            }
        };
        animateScroll();
    }
    static copyToClipboard(value) {
        const tempInput = document.createElement('input');
        tempInput.style.setProperty('position', 'absolute');
        tempInput.style.setProperty('left', '-1000px');
        tempInput.style.setProperty('top', '-1000px');
        tempInput.value = value;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand('copy');
        document.body.removeChild(tempInput);
    }
    static removeFileExtension(value) {
        return value.substring(0, value.lastIndexOf('.')).replace('Font-', '') || value.replace('Font-', '');
    }
    static getItemName(item, separator = '_') {
        return item.itemData ? `${separator}${item.itemData.name}` : '';
    }
    static aspectRatio() {
        return window.innerWidth / window.innerHeight;
    }
}
