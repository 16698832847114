export class Animation {
    constructor(endCallback) {
        this.endCallback = endCallback;
    }
    addChainedAnimation(animation) {
        this.chainedAnimation = animation;
    }
    getAdjustment() {
        let adjustment;
        if (this.chainedAnimation) {
            adjustment = this.chainedAnimation.update();
        }
        if (!adjustment) {
            adjustment = this.update();
            if (!adjustment && this.endCallback) {
                this.endCallback();
            }
        }
        return adjustment;
    }
}
