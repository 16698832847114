import pipe from 'callbag-pipe';
import subscribe from 'callbag-subscribe';
import { InputEventUtils } from '../../utils/input_event_utils';
import { L10nUtils } from '../../utils/l10n_utils';
import { Metrics } from '../../metrics';
import { AppState } from '../../shared/app.state';
import { MATOMO_EVENT_NAMES } from '../../metric-events';
export class AccountProfile {
    constructor(accountService, shoppingCartService, wrapperEl) {
        this.accountService = accountService;
        this.shoppingCartService = shoppingCartService;
        this.wrapperEl = wrapperEl;
        this.init();
        pipe(this.accountService.accountUserDataSubject, subscribe({
            next: this.setUserProfileData.bind(this)
        }));
        InputEventUtils.addSelectEvents(this.logOutBtn, this.onLogOut.bind(this));
    }
    init() {
        this.greetingEl = this.wrapperEl.querySelector('.account-profile-greeting span');
        this.logOutBtn = this.wrapperEl.querySelector('.log-out-btn');
    }
    setUserProfileData(userData) {
        var _a;
        this.greetingEl.innerText = `${L10nUtils.l10n('account.profile.greeting.text')}, ${(_a = userData === null || userData === void 0 ? void 0 : userData.display_name) !== null && _a !== void 0 ? _a : 'User'}`;
    }
    onLogOut() {
        Metrics.storeTheEvent(AppState.planogramName, 'click', MATOMO_EVENT_NAMES.CLICK_USER_PROFILE_OVERLAY_LOGOUT);
        this.accountService.setLoadingState(true);
        if (this.shoppingCartService.isMultipassEnabled) {
            setTimeout(() => {
                this.accountService.setUserData(null);
                this.accountService.setActiveState(false);
                this.shoppingCartService.clearShoppingCartToken();
                this.accountService.setLoadingState(false);
            }, 1000);
            return;
        }
        this.accountService
            .logOut()
            .then(() => {
            this.accountService.setUserData(null);
            this.accountService.setActiveState(false);
            this.shoppingCartService.clearShoppingCartState();
        })
            .finally(() => this.accountService.setLoadingState(false));
    }
}
