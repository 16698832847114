import { L10nUtils } from '../utils/l10n_utils';
import { loader } from '../components/loader';
export const shoppingCartWidgetView = (settings, isMultipassEnabled) => {
    return `
    <div class="app-shopping-cart-panel-wrapper">
      <div class="app-shopping-cart-panel-container">
        <div class="app-shopping-cart-items-wrapper">
          <div class="app-shopping-cart-notification-wrapper">
            <div class="app-shopping-cart-added-notification">
              <span class="app-shopping-cart-added-notification-text"></span>
            </div>
          </div>
        </div>
        <div class="app-shopping-cart-footer-wrapper">
          <div
          class="app-shopping-cart-checkout-button app-disabled
          ${(settings === null || settings === void 0 ? void 0 : settings.ecommerce_buttons[0].use_border) ? ' with-border' : ''}
          ${(settings === null || settings === void 0 ? void 0 : settings.ecommerce_buttons[0].use_border_disabled) ? ' disabled-with-border' : ''}">
            <span>${L10nUtils.l10n('shopping-cart.panel.footer.button')}</span>
            ${isMultipassEnabled ? loader({}) : ''}
          </div>
          <div class="app-shopping-cart-continue-button">
            <span>${L10nUtils.l10n('shopping-cart.panel.continue.button')}</span>
          </div>
        </div>
        <div class="app-shopping-cart-loader">
          ${loader({ message: L10nUtils.l10n('shopping-cart.loader.text') })}
        </div>
      </div>
    </div>
    <div class="app-shopping-cart-panel-backdrop"></div>
  `;
};
