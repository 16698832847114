import { CookiesTableView } from './cookies-table-view';
import { InputEventUtils } from '../utils/input_event_utils';
import { overlayEventsHandler } from '../custom_event_utils';
import { URLS } from '../api/urls';
import Cookies from 'js-cookie';
import { L10nUtils } from '../utils/l10n_utils';
import { OverlayScrollbar } from '../components/overlay_scrollbar';
import { CookiesService } from '../api/services/cookies.service';
const DEVELOPERS_DOMAIN_REGEX = /^(design|test|demo)+/g;
let cookies;
export class CookiesTable {
    constructor(container, options, closeCallback) {
        this.options = options;
        this.closeCallback = closeCallback;
        this.isFullTable = !!(options === null || options === void 0 ? void 0 : options.isFullTable);
        const classes = [];
        let title = L10nUtils.l10n('cookies-overlay.table-header.text');
        if (this.isFullTable) {
            title = L10nUtils.l10n('cookies-overlay.full.table-header.text');
            classes.push('full-table');
        }
        this.container = container;
        this.container.innerHTML = CookiesTableView({ title });
        const closeButton = document.querySelector('.cookies-table-overlay-controls .overlay-button-close');
        const backButton = document.querySelector('.cookies-table-overlay-controls .overlay-button-back');
        const cookiesWrapperScrollArea = document.querySelector('.overlay-container .cookies-table .cookies-wrapper');
        this.options.necessaryCookies ? classes.push('necessary') : classes.push('analytic');
        cookiesWrapperScrollArea.classList.add(...classes);
        const currentCookies = Cookies.get();
        const cookiesService = new CookiesService();
        cookiesService.getSettings().then(data => {
            var _a;
            const parsedCookies = ((_a = data.cookies) === null || _a === void 0 ? void 0 : _a.map((cookie, index) => ({
                name: cookie.name,
                type: cookie.cookie_type,
                description: cookie.purpose,
                expiry: cookie.expiry,
                dataCollected: cookie.cookie_data,
                iconUrl: URLS[`OVERLAY_COOKIE_ICON_${index + 1}`],
                useRegex: true
            }))) || [];
            const cookiesData = [
                {
                    name: '_pk_ses',
                    type: L10nUtils.l10n('cookies-overlay.table-content.cookie1-type'),
                    description: L10nUtils.l10n('cookies-overlay.table-content.cookie1-description'),
                    expiry: L10nUtils.l10n('cookies-overlay.table-content.cookie1-expiry'),
                    dataCollected: L10nUtils.l10n('cookies-overlay.table-content.cookie1-data-collected'),
                    iconUrl: URLS.OVERLAY_COOKIE_ICON_1,
                    useRegex: true
                },
                {
                    name: '_pk_id',
                    type: L10nUtils.l10n('cookies-overlay.table-content.cookie2-type'),
                    description: L10nUtils.l10n('cookies-overlay.table-content.cookie2-description'),
                    iconUrl: URLS.OVERLAY_COOKIE_ICON_2,
                    expiry: L10nUtils.l10n('cookies-overlay.table-content.cookie2-expiry'),
                    dataCollected: L10nUtils.l10n('cookies-overlay.table-content.cookie2-data-collected'),
                    useRegex: true
                },
                {
                    name: 'entrance_animation',
                    type: L10nUtils.l10n('cookies-overlay.table-content.cookie4-type'),
                    description: L10nUtils.l10n('cookies-overlay.table-content.cookie4-description'),
                    expiry: L10nUtils.l10n('cookies-overlay.table-content.cookie4-expiry'),
                    dataCollected: L10nUtils.l10n('cookies-overlay.table-content.cookie4-data-collected'),
                    iconUrl: URLS.OVERLAY_COOKIE_ICON_3
                },
                {
                    name: 'cookieconsent_status',
                    type: L10nUtils.l10n('cookies-overlay.table-content.cookie3-type'),
                    description: L10nUtils.l10n('cookies-overlay.table-content.cookie3-description'),
                    expiry: L10nUtils.l10n('cookies-overlay.table-content.cookie3-expiry'),
                    dataCollected: L10nUtils.l10n('cookies-overlay.table-content.cookie3-data-collected'),
                    iconUrl: URLS.OVERLAY_COOKIE_ICON_4
                }
            ];
            cookies = [...cookiesData, ...parsedCookies];
            const storedCookiesNames = Object.keys(currentCookies);
            const staticCookiesNames = cookies
                .map(cookie => cookie.name)
                .filter(name => !storedCookiesNames.join(', ').includes(name));
            const cookiesNames = this.isFullTable
                ? [...new Set([...staticCookiesNames, ...storedCookiesNames])]
                : storedCookiesNames;
            cookiesNames.forEach(name => {
                const cookieData = cookies.find(item => {
                    const regexp = new RegExp(`^(?:${item.name})+?([a-zA-Z0-9.]+)*$`, 'gm');
                    return name.match(regexp);
                });
                if (cookieData) {
                    const cookie = this.createCookieItem(Object.assign(Object.assign({}, cookieData), { name: this.isFullTable ? cookieData.name : name || cookieData.name }));
                    const cookieItem = document.createElement('div');
                    cookiesWrapperScrollArea.append(cookieItem);
                    cookieItem.outerHTML = cookie;
                }
            });
            const removeCookieButtons = document.querySelectorAll('.cookies-wrapper .remove-btn');
            if (removeCookieButtons) {
                removeCookieButtons.forEach(removeCookieButton => {
                    InputEventUtils.addSelectEvents(removeCookieButton, this.removeCookie.bind(this));
                });
            }
            else {
                console.error('elements not found - ".remove-btn-s"');
            }
            this.overlayScrollbar = new OverlayScrollbar('.allow-scroll');
        });
        if (closeButton) {
            InputEventUtils.addSelectEvents(closeButton, this.closeOverlay.bind(this));
        }
        else {
            console.error('element not found - ".overlay-button-close"');
        }
        if (backButton) {
            InputEventUtils.addSelectEvents(backButton, this.toggleView.bind(this));
        }
        else {
            console.error('element not found - ".overlay-button-back"');
        }
    }
    toggleView(e) {
        if (e) {
            e.stopPropagation();
        }
        overlayEventsHandler.emit('hideCookieTable');
    }
    removeCookie(e) {
        e.stopPropagation();
        const domain = window.location.hostname.replace(DEVELOPERS_DOMAIN_REGEX, ''); // TODO: verify that this makes any sense
        const target = e.target;
        const parentElement = target.closest('.cookie-item');
        const cookieName = parentElement.getAttribute('data-name');
        cookies = cookies.filter(cookie => cookie.name !== cookieName);
        parentElement.remove();
        Cookies.remove(cookieName);
        if (Cookies.get(cookieName)) {
            Cookies.remove(cookieName, { domain: domain });
        }
        else if (Cookies.get(cookieName)) {
            Cookies.remove(cookieName, { path: '', domain: domain });
        }
        else if (Cookies.get(cookieName)) {
            console.error('An error occurred when we try to delete the cookie');
        }
    }
    closeOverlay(e) {
        e.stopPropagation();
        if (this.closeCallback) {
            this.closeCallback();
        }
        else {
            console.error('"Close" function is not set up');
        }
    }
    createCookieItem(cookie) {
        const removeBtnSrc = URLS.OVERLAY_REMOVE_ICON;
        const defaultIconUrl = URLS.OVERLAY_COOKIE_ICON_1;
        return `
    <div class="cookie-item is-flex is-vertically-aligned-top ${cookie.useRegex ? 'analytic' : 'necessary'}" data-name="${cookie.name}">
      <div data-label="${L10nUtils.l10n('cookies-overlay.table-header.name-column')}" class="cookie-name is-flex is-vertically-aligned-top">
        <span class="cookie-icon">
          <img src="${cookie.iconUrl ? cookie.iconUrl : defaultIconUrl}" alt="">
        </span>
        <span class="cookie-label">${cookie.name}</span>
        <div class="delete-btn ${this.isFullTable ? 'is-hidden' : ''}"><img class="remove-btn" src="${removeBtnSrc}"></div>
      </div>
      <div data-label="${L10nUtils.l10n('cookies-overlay.table-header.data-collected-column')}" class="cookie-data-collected"><span>${cookie.dataCollected}</span></div>
      <div data-label="${L10nUtils.l10n('cookies-overlay.table-header.type-column')}" class="cookie-type"><span>${cookie.type}</span></div>
      <div data-label="${L10nUtils.l10n('cookies-overlay.table-header.description-column')}" class="cookie-description">${cookie.description}</div>
      <div data-label="${L10nUtils.l10n('cookies-overlay.table-header.expiry-column')}" class="cookie-expiry"><span>${cookie.expiry}</span></div>
      <div class="delete-btn ${this.isFullTable ? 'is-hidden' : ''}"><img class="remove-btn" src="${removeBtnSrc}"></div>
    </div>
    `;
    }
    // Using in ProductCanvas
    handleClick(event) {
        return false;
    }
}
